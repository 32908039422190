import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import axios from '../../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { generalDataUpdate, setShortcodeVisible, setAlternateTextVisible, setColorVisible } from '../../../features/utilities/generalSlice'
import { showAddCategoryOffcanvas } from '../../../features/Offcanvas/addCategoryOffcanvasSlice'
import { activeTabUpdatedName, itemSearch, setOrderDayFilter, emptyTranscript, setCategoryName, setTransactionDayFilter, categoryIdUpdate, checkoutItemType, cartDraftIndex, cartDraftData, cartOrderIndex, setTempCategoryId, setStockActionType, setBranchID } from '../../../features/Data/dataSlice'
import { showAddTransactionModal } from '../../../features/Offcanvas/addTransactionModalSlice'
import { showQuickAddOffcanvas } from '../../../features/Offcanvas/quickAddOffcanvasSlice'
import { showOrderTypeModal } from '../../../features/Offcanvas/orderTypeModalSlice'
import { showFileUploadModal } from '../../../features/Offcanvas/imageEditModalSlice';
import Multiselect from 'multiselect-react-dropdown';

const SearchWithButtons = ({
    generalDataUpdate,
    gridView,
    view,
    pageName,
    showAddCategoryOffcanvas,
    activeTabUpdatedName,
    itemSearch,
    showAddTransactionModal,
    itemName,
    setOrderDayFilter,
    dayOrderFilter,
    transcriptEmpty,
    emptyTranscript,
    categoryName,
    setCategoryName,
    setTransactionDayFilter,
    dayTransactionFilter,
    categoryIdUpdate,
    categories,
    showQuickAddOffcanvas,
    setShortcodeVisible,
    setAlternateTextVisible,
    setColorVisible,
    shortcodeVisible,
    alternateTextVisible,
    colorVisible,
    topScrollValue,
    type,
    checkoutItemType,
    draftData,
    draftIndex,
    cartDraftIndex,
    cartDraftData,
    cartOrderIndex,
    showOrderTypeModal,
    stockActionColor,
    setTempCategoryId,
    stockActionType,
    setStockActionType,
    showFileUploadModal,
    allItemsStore,
    tempSetupObj,
    role,
    businessData,
    buisness_ID,
    setBranchID,
    authToken,
    branchID
}) => {
    const [activeIcon, showActiveIcon] = useState('grid')
    const [searchInput, showSearchInput] = useState(false)
    const [ID, setID] = useState(null)
    const search = useLocation().search
    const orderType = new URLSearchParams(search).get('tab')
    const categoryType = new URLSearchParams(search).get('category')
    const transactionCategory = new URLSearchParams(search).get('tcategory')
    const transactionType = new URLSearchParams(search).get('type')
    const [searchText, setSearchText] = useState('')
    const [transactionCategoryList, setTransactionCategoryList] = useState(null)
    const [activeTab, setActiveTab] = useState(0)
    const [stockActionInput, showStockActionInput] = useState('vendor')
    const [BgClass, setbgClass] = useState('')
    const stateName = window.location.pathname.split('/')
    const [action, setAction] = useState('')
    const [selectedItemCount, setSelectedItemCount] = useState(0)
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [datePicker, setDatePicker] = useState(null)
    const [branchList, setBranchList] = useState()
    const [selectedBranchName, setSelectedBranchName]= useState('')
    const [bakeryList, setBakeryList] = useState(null)
    const [selectedValue, setSelectedValue] = useState(null)
    const tokenData = jwt_decode(authToken)
    let navigate = useNavigate()
    useEffect(() => {
        setAction(new URLSearchParams(search).get('action'))
    }, [search])
    useEffect(() => {
        if (view === undefined || view === null || view === '' || view === 'grid') {
            generalDataUpdate({view: 'grid'})
        }
    }, [view])
    useEffect(() => {
        if (businessData && buisness_ID) {
          setBranchList(businessData && businessData.filter((itm) =>  itm.ID === buisness_ID)[0]?.Branches)
        }
    }, [])
    useEffect(() => {
        axios.get('/TransactionCategories').then(res => {
            if (res.data.message === 'Success') {
                setTransactionCategoryList(res.data.data)
            }
        })
        axios.get('/Businesses/getBusinessBakeryWise').then(res => {
            if (res.data.message === 'Success') {
                let tempBakeryList = []
                res.data.data.map((v,k) => {
                    tempBakeryList?.push({
                        'Bakery': v.Name,
                        'Branch': v.Name,
                        'BranchID': 'all'
                    })
                    v?.Shops?.map((v1,k1) => {
                        tempBakeryList?.push({
                            'Bakery': v.Name,
                            'Branch': v1.Name,
                            'BranchID': v1.ID
                        })
                    })
                })
                setBakeryList(tempBakeryList)
            }
        })
    }, [])
    const handleViewSwitch = (viewName) => {
        generalDataUpdate({view: viewName})
    }
    const handleListViewSwitch = () => {
        generalDataUpdate({gridView: false})
        showActiveIcon('list')
    }
    const handleGridViewSwitch = () => {
        generalDataUpdate({gridView: true})
        showActiveIcon('grid')
    }
    const handleCategroyModal = () => {
        showAddCategoryOffcanvas({addCategoryOffcanvasVisible: true})
    }

    const handleAddOrder = (tempTabName) => {
        if (tempTabName === 'sales') {
            activeTabUpdatedName({activeTabName: 'sales'})
            navigate('/ordersDetail')
        } else if (tempTabName === 'purchase') {
            activeTabUpdatedName({activeTabName: 'purchase'})
            navigate('/ordersDetail')
        } else if (tempTabName === 'wastage') {
            activeTabUpdatedName({activeTabName: 'wastage'})
            navigate('/ordersDetail')
        }
    }

    const handleItemSearch = (itemName) => {
        itemSearch({itemName: itemName})
    }

    useEffect(() => {
        setSearchText(itemName)
        if (categoryName !== undefined && categoryName !== null && categoryName !== '') {
            setSearchText(categoryName)
        }
    }, [itemName, categoryName])

    const handleSearchShow =() => {
        showSearchInput(true)
    }
    const handleSearchHide =() => {
        showSearchInput(false)
    }

    const handleOrderTypeFilter = (orderType) => {
        navigate('/orders?tab=' + orderType + '&category=' + categoryType)
    }

    const handleCategoryFilter = (categoryType) => {
        navigate('/orders?tab=' + orderType + '&category=' + categoryType)
    }

    const handleTransactionTypeFilter = (transactionType) => {
        navigate('/transactions?type='  + transactionType + '&tcategory=' + transactionCategory)
    }

    const handleTransactionCategoryFilter = (transactionCategory) => {
        navigate('/transactions?type='  + transactionType + '&tcategory=' + transactionCategory)
    }

    const handleAddTransactionModal = () => {
        showAddTransactionModal({addTransactionModalVisible: true, transactionData: null})
    }

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    function startSpeechRecognition() {
        SpeechRecognition.startListening({ continuous: true, language: 'en-IN' });
    }

    useEffect(() => {
        if (transcriptEmpty === true) {
            resetTranscript()
            emptyTranscript({transcriptEmpty: false})
        }
    }, [transcriptEmpty])

    function stopSpeechRecognition() {
        SpeechRecognition.stopListening();
        handleItemSearch(transcript);
        setTimeout(() => {
            resetTranscript();
        }, 300);
    }

    const isSpeechRecognitionDoable = () => {
        if (!browserSupportsSpeechRecognition) {
            return (<span className='fs-16' title='Voice to Text is not supported on this browser!'>
                <ion-icon name="mic-off-outline"></ion-icon>
            </span>)
        } else {
            return (
                <>
                    { 
                        listening ?
                            <button type='button' className='speaker-on speaker-btn position-absolute e-2 t-1' title='Stop Listening' onClick={() => stopSpeechRecognition()}>
                                <div class="pulse-ring" />
                                <div className='listening-animation'>
                                    <div className='sides border-circle' />
                                    <div className='center border-circle' />
                                    <div className='sides border-circle' />
                                </div>
                                <ion-icon name="mic-off-outline" class="off-mic" style={{ cursor: 'pointer' }}></ion-icon>
                            </button>
                        :
                            <button type='button' className='speaker-off speaker-btn position-absolute e-2 t-1' title='Type by Voice' style={{color: (stateName[1] === 'manageStock') ? stockActionColor : '', borderColor: (stateName[1] === 'manageStock') ?  stockActionColor : ''}} onClick={() => startSpeechRecognition()}>
                                <ion-icon name="mic-outline" style={{ cursor: 'pointer' }}></ion-icon>
                            </button>
                    }
                </>
            )
        }
    }
    const clearSearch = () => {
        setSearchText('')
        itemSearch({itemName: ''})
        setCategoryName({categoryName: ''})
        categoryIdUpdate({categoryID: 'all'})
        setTempCategoryId({tempcategoryId: null})
    }

    const handleOrderDateWise = (day) => {
        if (day === 'Custom') {
            setShowDatePicker(true)
        } else {
            setOrderDayFilter({dayOrderFilter: day})
            setDatePicker(null)
            setShowDatePicker(false)
        }
    }

    useEffect(() => {
        if (datePicker !== null && datePicker !== '' && datePicker !== undefined) {
            setOrderDayFilter({dayOrderFilter: datePicker})
        }
    }, [datePicker])

    const handleTransactionDateWise = (day) => {
        setTransactionDayFilter({dayTransactionFilter: day})
    }

    const handleTransactionCategory = (categoryID, activeCategoryName) => {
        // console.log(categoryID, activeCategoryName)
    }

    const handleCategorySwitch = (categoryID, categoryName) => {
        categoryIdUpdate({categoryID: (categoryID === undefined || categoryID === null || categoryID === '') ? 1 : categoryID})
        setID(categoryID)
        setCategoryName({categoryName: categoryName})
    }

    const handleAlternateToggle = (toggleType) => {
        if (toggleType === 'shortcode') {
            setAlternateTextVisible({alternateTextVisible: 'shortcode'})
        } else if (toggleType === 'alternateText') {
            setAlternateTextVisible({alternateTextVisible: 'alternateText'})
        } else if (toggleType === 'englishText') {
            setAlternateTextVisible({alternateTextVisible: 'englishText'})
        } else if (toggleType === 'color') {
            setColorVisible()
        }
    }

    const handleActiveTab = (ID, type) => {
        setActiveTab(ID)
        if (type === null || type === undefined || type === '') {
            checkoutItemType({type: topScrollValue[0].name})
        } else {
            checkoutItemType({type: type})
        }

        // if (shortCut === true) {
        //     hideMainActionSheet()
        //     navigate('/newOrder')
        // }

        let tempObj = JSON.parse(JSON.stringify(draftData))
        let addNewItem = false
        if (draftIndex !== null && draftIndex !== undefined && draftIndex !== '') {
            tempObj[draftIndex].OrderType = type 
            addNewItem = false
        } else {
            for (let i = 0; i < draftData.length; i++) {
                if (draftData[i].Status === -2) {
                    tempObj[i].Status = -1
                    tempObj[i].OrderType = type
                    tempObj[i].TotalQuantity = 0
                    cartDraftIndex({draftIndex: i})
                    cartOrderIndex({odIndex: 0})
                    break
                }
            }
        }
        cartDraftData({draftData: tempObj})
    }
    const handleStock = (stockAction) => {
        showStockActionInput(stockAction)
    }
    useEffect(() => {
        if (role == 6) {
            setStockActionType({stockActionType: 'addStock'})
        } else {
            setStockActionType({stockActionType: 'request'})
        }
        if (businessData) {
            businessData[0].Branches.map((v,k) => {
                if (v.ID == tokenData.userInfo.DefaultLocationID) {
                    setSelectedBranchName(v.Name)           
                }
            })
        }
    }, [])
    const handleStockType = (type) => {
        setStockActionType({stockActionType: type})
    }
    const uploadCsv = () => {
        showFileUploadModal({fileUploadModalVisible: true})
    }
    useEffect(() => {
        if (tempSetupObj) {
            let tempSetupObjCount = 0
            Object.keys(tempSetupObj).map((v,k) => {
                tempSetupObjCount += tempSetupObj[v].Items.length
            })
            setSelectedItemCount(tempSetupObjCount)
        }
    }, [tempSetupObj])
    const handleSwicthBranchLocation = (branchData) => {
        if (branchData === 'all') {
            // let branches = []
            // branchList.map((v,k) => {
            //     branches.push(v.ID)
            // })
            // setBranchID({branchID: branches})
            // setSelectedBranchName('Kamothe Bakery')
        } else {
            setBranchID({branchID: branchData.ID})
            setSelectedBranchName(branchData.Name)
        }
    }
    let tempSelectedBranch = []
    const handleSelected = (selectedList, selectedItem) => {
        // console.log(selectedItem, selectedList)
        if(selectedItem.BranchID == 'all') {
            let newBranchListID = []
            let newBranchListName = []
            businessData[0].Branches.map((v,k) => {
                newBranchListID.push(v.ID)
            })
            bakeryList.map((v,k) => {
                if (v.Bakery == selectedItem.Branch) {
                    newBranchListName.push({
                        'BranchID': v.BranchID,
                        'Branch': v.Branch
                    })
                }
            })
            setBranchID({branchID: newBranchListID})
            setSelectedValue(newBranchListName)
        } else {
            if (typeof branchID == 'string') {
                tempSelectedBranch = JSON.parse(JSON.stringify([branchID]))
            } else {
                tempSelectedBranch = JSON.parse(JSON.stringify(branchID))
            }
            if (tempSelectedBranch.includes(selectedItem.BranchID)) {
            } else {
                tempSelectedBranch.push(selectedItem.BranchID)
            }
            setBranchID({branchID: tempSelectedBranch})
        }
    };
    useEffect(() => {
        if (branchID) {
            // setSelectedValue([{
            //     'BranchID': [branchID],
            //     'Branch': selectedBranchName,
            // }])
            let newBranchListID = []
            let newBranchListName = []
            bakeryList?.map((v,k) => {
                newBranchListID.push(v.BranchID)
                newBranchListName.push({
                    'BranchID': v.BranchID,
                    'Branch': v.Branch
                })
            })
            console.log('filterWirthScroll', 'line no 424')
            setBranchID({branchID: newBranchListID})
            setSelectedValue(newBranchListName)
        } else {
            setSelectedValue([{
                'BranchID': [tokenData.userInfo.DefaultLocationID],
                'Branch': selectedBranchName,
            }])
        }
    }, [selectedBranchName, bakeryList])
    const handleRemoveSelected = (selectedList, selectedItem) => {
        if (selectedItem.BranchID == 'all') {
            setSelectedValue([{
                'BranchID': [tokenData.userInfo.DefaultLocationID],
                'Branch': selectedBranchName,
            }])
            setBranchID({branchID: [tokenData.userInfo.DefaultLocationID]})
        } else {
            let tempSelectedArr = JSON.parse(JSON.stringify(branchID))
            tempSelectedArr = tempSelectedArr.filter(item => item !== selectedItem.BranchID);
            setBranchID({branchID: tempSelectedArr})
        }
    }
    useEffect(() => {
        if (new URLSearchParams(search).get('tab') == 'production') {
            if (typeof branchID == 'object') {
                if (branchID?.length > 0) {
                    let tempBranchID = []
                    branchID?.map((v,k) => {
                        if (v != 'all') {
                            tempBranchID.push(v)
                        }
                    })
                    console.log('filterWithScroll', 'line no 457')
                    setBranchID({branchID: tempBranchID})
                }
            }
        } else {
            setBranchID({branchID: tokenData.userInfo.DefaultLocationID})
        }
    }, [pageName])
    return (
        <div class="row">
            {pageName === 'Summary' &&
                <div class="col-12 col-md-12 d-flex align-items-center justify-content-end mb-2 mt-1">
                    <>
                        {showDatePicker === false &&
                            <div class="dropdown d-flex me-1" style={{cursor: 'pointer'}}>
                                <div class="badge bg-primary dropdown-toggle fs-8" data-bs-toggle="dropdown">
                                    <span style={{textTransform: 'uppercase'}}>
                                        <ion-icon name="calendar-outline" class="m-0 fs-8" style={{width: '18px'}}></ion-icon>
                                        {dayOrderFilter}
                                    </span>
                                    <ion-icon name="chevron-down-outline" class="fs-6"></ion-icon>
                                </div>
                                <div class="dropdown-menu p-0">
                                    <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Today')}}>Today</a>
                                    <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Yesterday')}}>Yesterday</a>
                                    <div class="divider"></div>
                                    <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Custom')}}>Custom</a>
                                </div>
                            </div>
                        }
                        {showDatePicker &&
                            <div class="form-group boxed me-1" style={{width: '250px'}}>
                                <div class="input-wrapper">
                                    <input
                                        type="date"
                                        class="form-control"
                                        value={datePicker}
                                        onChange={e => {
                                            setDatePicker(e.target.value)
                                        }}
                                        id="datePicker"
                                        placeholder="Date Picker"
                                        autocomplete="off"
                                    />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                    <label class="form-label fs-10 d-flex align-items-center mt-1" onClick={() => {handleOrderDateWise('Today')}}>
                                        <ion-icon name="chevron-back-outline"></ion-icon>Back
                                    </label>
                                </div>
                            </div>
                        }
                    </>
                </div>
            }
            <div class={(pageName === 'AddRemoveStock' || pageName === 'ManageItems') ? "col-6 col-md-4 d-flex align-items-center" : "col-6 col-md-6 d-flex align-items-center"}>
                {pageName === 'Transactions' &&
                    <>
                        <div class="dropdown d-flex me-1" style={{cursor: 'pointer'}}>
                            <div class="badge bg-primary dropdown-toggle fs-8" data-bs-toggle="dropdown">
                                <span style={{textTransform: 'uppercase'}}>
                                    <ion-icon name="calendar-outline" class="m-0 fs-8" style={{width: '18px'}}></ion-icon>
                                    {dayTransactionFilter}
                                </span>
                                <ion-icon name="chevron-down-outline" class="fs-6"></ion-icon>
                            </div>
                            <div class="dropdown-menu p-0">
                                <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleTransactionDateWise('Today')}}>Today</a>
                                <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleTransactionDateWise('Yesterday')}}>Yesterday</a>
                                <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleTransactionDateWise('Last7')}}>Last 7 Days</a>
                                <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleTransactionDateWise('Last30')}}>Last 30 Days</a>
                                <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleTransactionDateWise('All')}}>All</a>
                            </div>
                        </div>
                        <div class="dropdown d-flex me-1" style={{cursor: 'pointer'}}>
                            <div class="badge bg-primary dropdown-toggle fs-8" data-bs-toggle="dropdown">
                                <span style={{textTransform: 'uppercase'}}>
                                    <ion-icon name="grid-outline" class="m-0 fs-8" style={{width: '18px'}}></ion-icon>
                                    Sale
                                </span>
                                <ion-icon name="chevron-down-outline" class="fs-6"></ion-icon>
                            </div>
                            <div class="dropdown-menu p-0">
                                {transactionCategoryList !== null &&
                                    <>
                                        {transactionCategoryList.map(value => {
                                            return <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleTransactionCategory(value.ID, value.Title)}}>{value.Title}</a>
                                        })}
                                    </>
                                }
                            </div>
                        </div>
                    </>
                }
                {pageName === 'Orders' &&
                    <>
                        {showDatePicker === false &&
                            <div class="dropdown d-flex me-1" style={{cursor: 'pointer'}}>
                                <div class="badge bg-primary dropdown-toggle fs-8" data-bs-toggle="dropdown">
                                    <span style={{textTransform: 'uppercase'}}>
                                        <ion-icon name="calendar-outline" class="m-0 fs-8" style={{width: '18px'}}></ion-icon>
                                        {dayOrderFilter}
                                    </span>
                                    <ion-icon name="chevron-down-outline" class="fs-6"></ion-icon>
                                </div>
                                <div class="dropdown-menu p-0">
                                    <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Today')}}>Today</a>
                                    <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Yesterday')}}>Yesterday</a>
                                    {/* <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Last7')}}>Last 7 Days</a> */}
                                    {/* <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Last30')}}>Last 30 Days</a> */}
                                    {/* <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('ThisMonth')}}>This Month</a> */}
                                    {/* <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('PrevMonth')}}>Previous Month</a> */}
                                    {/* <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('All')}}>All</a> */}
                                    <div class="divider"></div>
                                    <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Custom')}}>Custom</a>
                                </div>
                            </div>
                        }
                        {showDatePicker &&
                            <div class="form-group boxed me-1">
                                <div class="input-wrapper">
                                    <input
                                        type="date"
                                        class="form-control"
                                        value={datePicker}
                                        onChange={e => {
                                            setDatePicker(e.target.value)
                                        }}
                                        id="datePicker"
                                        placeholder="Date Picker"
                                        autocomplete="off"
                                    />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                    <label class="form-label fs-10 d-flex align-items-center mt-1" onClick={() => {handleOrderDateWise('Today')}}>
                                        <ion-icon name="chevron-back-outline"></ion-icon>Back
                                    </label>
                                </div>
                          </div>
                        }
                    </>
                }
                {(pageName === 'NewOrder' || pageName === 'ManageStock' || pageName === "Items" || pageName === 'BusinessSetup') &&
                    <>
                        <div class="search-form ms-1 me-1">
                            <div class="form-group searchbox">
                                {
                                    listening &&
                                    <span className='position-absolute' style={{top: '5px', left: '39px'}}>{transcript}</span>
                                }
                                <input type="text" value={listening ? '' : searchText} class="form-control" placeholder={listening ? '' : "Search"} style={{borderRadius: '50px'}} onChange={(e) => {handleItemSearch(e.target.value)}} />
                                <i class="input-icon">
                                    <ion-icon name="search-outline"></ion-icon>
                                </i>
                                <div class="dropdown icon-searchbar-custom-position" style={{position: 'absolute'}}>
                                    {(searchText === '' || searchText === null || searchText === undefined) &&
                                        <button type='button' className='btn btn-tarnsparent dropdown-toggle position-absolute e-2 t-1 p-0 d-none d-sm-inline-flex' data-bs-toggle="dropdown" style={{ cursor: 'pointer', height: '20px',width: '20px',display: 'flex',alignItems: 'center',justifyContent: 'center', color: (stateName[1] === 'manageStock') ? stockActionColor : '#24265d' }}>
                                            <ion-icon name="apps-outline" style={{ cursor: 'pointer' }}></ion-icon>
                                        </button>
                                    }
                                    <div class="dropdown-menu p-0" style={{zIndex: 10}}>
                                        {(categories !== null && categories !== undefined) &&
                                            <>
                                                {categories.map((value,key) => {
                                                    return <a href={'/addRemoveStock#' + value.Name} class="dropdown-item p-1 d-flex justify-content-between">{value.Name}</a>
                                                })}
                                            </>
                                        }
                                    </div>
                                </div>
                                {(searchText === '' || searchText === null || searchText === undefined) &&
                                    <div class="d-none d-sm-block">
                                        {isSpeechRecognitionDoable()}
                                    </div>
                                }
                                {(searchText !== '' && searchText !== null && searchText !== undefined) &&
                                    <>
                                        <i class="input-icon" onClick={clearSearch} style={{right: 10, left: 'auto', zIndex: 999}}>
                                            <ion-icon name="close-outline" class="fs-4 text-secondary"></ion-icon>
                                        </i>
                                    </>
                                }
                            </div>
                                
                        </div>
                        <div class="dropdown d-none">
                            <div class="badge bg-primary dropdown-toggle fs-8" data-bs-toggle="dropdown">
                                <span style={{textTransform: 'uppercase'}}>
                                    <ion-icon name="apps-outline" class="m-0 fs-8" style={{width: '18px'}}></ion-icon>
                                    {(categoryName !== '' && categoryName !== null && categoryName !== undefined) ? categoryName : 'Categories'}
                                </span>
                                <ion-icon name="chevron-down-outline" class="fs-6"></ion-icon>
                            </div>
                            <div class="dropdown-menu p-0" style={{zIndex: 10}}>
                                {(categories !== null && categories !== undefined) &&
                                    <>
                                        {categories.map((value,key) => {
                                            return <a href={'/addRemoveStock#' + value.Name} class="dropdown-item p-1 d-flex justify-content-between">{value.Name}</a>
                                        })}
                                    </>
                                }
                            </div>
                        </div>
                    </>
                }
                {(pageName === 'ManageItems') &&
                    <>
                        <div class="search-form ms-1 me-1">
                            <div class="form-group searchbox">
                                {
                                    listening &&
                                    <span className='position-absolute' style={{top: '5px', left: '39px'}}>{transcript}</span>
                                }
                                <input type="text" value={listening ? '' : searchText} class="form-control" placeholder={listening ? '' : "Search"} style={{borderRadius: '50px'}} onChange={(e) => {handleItemSearch(e.target.value)}} />
                                <i class="input-icon">
                                    <ion-icon name="search-outline"></ion-icon>
                                </i>
                                <div class="dropdown icon-searchbar-custom-position" style={{position: 'absolute'}}>
                                    {(searchText === '' || searchText === null || searchText === undefined) &&
                                        <button type='button' className='btn btn-tarnsparent dropdown-toggle position-absolute e-2 t-1 p-0 d-none d-sm-inline-flex' data-bs-toggle="dropdown" style={{ cursor: 'pointer', height: '20px',width: '20px',display: 'flex',alignItems: 'center',justifyContent: 'center', color: (stateName[1] === 'manageStock') ? stockActionColor : '#24265d' }}>
                                            <ion-icon name="apps-outline" style={{ cursor: 'pointer' }}></ion-icon>
                                        </button>
                                    }
                                    <div class="dropdown-menu p-0" style={{zIndex: 10}}>
                                        {(categories !== null && categories !== undefined) &&
                                            <>
                                                {categories.map((value,key) => {
                                                    return <a href={'/addRemoveStock#' + value.Name} class="dropdown-item p-1 d-flex justify-content-between">{value.Name}</a>
                                                })}
                                            </>
                                        }
                                    </div>
                                </div>
                                {(searchText === '' || searchText === null || searchText === undefined) &&
                                    <div class="d-none d-sm-block">
                                        {isSpeechRecognitionDoable()}
                                    </div>
                                }
                                {(searchText !== '' && searchText !== null && searchText !== undefined) &&
                                    <>
                                        <i class="input-icon" onClick={clearSearch} style={{right: 10, left: 'auto', zIndex: 999}}>
                                            <ion-icon name="close-outline" class="fs-4 text-secondary"></ion-icon>
                                        </i>
                                    </>
                                }
                            </div>   
                        </div>
                        <div class="dropdown d-none">
                            <div class="badge bg-primary dropdown-toggle fs-8" data-bs-toggle="dropdown">
                                <span style={{textTransform: 'uppercase'}}>
                                    <ion-icon name="apps-outline" class="m-0 fs-8" style={{width: '18px'}}></ion-icon>
                                    {(categoryName !== '' && categoryName !== null && categoryName !== undefined) ? categoryName : 'Categories'}
                                </span>
                                <ion-icon name="chevron-down-outline" class="fs-6"></ion-icon>
                            </div>
                            <div class="dropdown-menu p-0" style={{zIndex: 10}}>
                                {(categories !== null && categories !== undefined) &&
                                    <>
                                        {categories.map((value,key) => {
                                            return <a to={'/addRemoveStock#' + value.Name} class="dropdown-item p-1 d-flex justify-content-between">{value.Name}</a>
                                        })}
                                    </>
                                }
                            </div>
                        </div>
                    </>
                }
                {pageName === 'StockReport' &&
                    <>
                        {showDatePicker === false &&
                            <div class="dropdown d-flex me-1" style={{cursor: 'pointer'}}>
                                <div class="badge bg-primary dropdown-toggle fs-8" data-bs-toggle="dropdown">
                                    <span style={{textTransform: 'uppercase'}}>
                                        <ion-icon name="calendar-outline" class="m-0 fs-8" style={{width: '18px'}}></ion-icon>
                                        {dayOrderFilter}
                                    </span>
                                    <ion-icon name="chevron-down-outline" class="fs-6"></ion-icon>
                                </div>
                                <div class="dropdown-menu p-0">
                                    <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Today')}}>Today</a>
                                    <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Yesterday')}}>Yesterday</a>
                                    <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Last7')}}>Last 7 Days</a>
                                    <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Last30')}}>Last 30 Days</a>
                                    {/* <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('ThisMonth')}}>This Month</a> */}
                                    {/* <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('PrevMonth')}}>Previous Month</a> */}
                                    <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('All')}}>All</a>
                                    <div class="divider"></div>
                                    <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Custom')}}>Custom</a>
                                </div>
                            </div>
                        }
                        {showDatePicker &&
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <input
                                        type="date"
                                        class="form-control"
                                        value={datePicker}
                                        onChange={e => {
                                            setDatePicker(e.target.value)
                                        }}
                                        id="datePicker"
                                        placeholder="Date Picker"
                                        autocomplete="off"
                                    />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                    <label class="form-label fs-10 d-flex align-items-center mt-1" onClick={() => {handleOrderDateWise('Today')}}>
                                        <ion-icon name="chevron-back-outline"></ion-icon>Back
                                    </label>
                                </div>
                          </div>
                        }
                    </>
                }
            </div>
            <div class={(pageName === 'AddRemoveStock' || pageName === 'ManageItems') ? "col-6 col-md-8 d-flex align-items-center justify-content-end" : "col-6 col-md-6 d-flex align-items-center justify-content-end"}>
                {!searchInput &&
                    <>
                        {pageName === 'BusinessSetup' &&
                            <>
                                Adding&nbsp;<span class="badge bg-primary" style={{height: '16px'}}>{(selectedItemCount) ? selectedItemCount : 0}</span>&nbsp;Items
                            </>
                        }
                        {pageName === 'NewOrder' &&
                            <>
                                <div class="dropdown">
                                    <Link to="/customOrder" class="btn btn-primary btn-sm ps-1 pe-1 p-0 me-1" type="button">
                                        <ion-icon name="add-outline" class="me-0 fs-6"></ion-icon> Add Custom
                                    </Link>
                                </div>
                                <div class="dropdown">
                                    <button class="btn btn-outline-primary btn-sm dropdown-toggle ps-1 pe-1 p-0 me-1" type="button" data-bs-toggle="dropdown" style={{minWidth: '30px', fontSize: '10px'}} onClick={() => {showOrderTypeModal({orderTypeModalVisible: true, reference: 'NewOrder'})}}>
                                        {(type === 'Counter Sale') &&
                                            <>
                                                <ion-icon name="storefront-outline" class="me-0 fs-10"></ion-icon>&nbsp;
                                            </>
                                        }
                                        {(type === 'Dine In') &&
                                            <>
                                                <ion-icon name="restaurant-outline" class="me-0 fs-10"></ion-icon>&nbsp;
                                            </>
                                        }
                                        {(type === 'Pick Up') &&
                                            <>
                                                <ion-icon name="bag-handle-outline" class="me-0 fs-10"></ion-icon>&nbsp;
                                            </>
                                        }
                                        {(type === 'Zomato / Swiggy') &&
                                            <>
                                                <ion-icon name="phone-portrait-outline" class="me-0 fs-10"></ion-icon>&nbsp;
                                            </>
                                        }
                                        {(type) ? type : 'Counter Sale'}
                                    </button>
                                    {/* <div class="dropdown-menu p-0">
                                        {topScrollValue.map((v,k) => {
                                            return <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleActiveTab(k, v.name)}}>{v.name} <ion-icon name={(type === v.name) ? 'checkmark-circle-outline'  :'ellipse-outline'} class="m-0 text-secondary" style={{width: '18px'}}></ion-icon></a> 
                                        })}
                                    </div> */}
                                </div>
                                <div class="dropdown">
                                    <button class="btn bg-transparent btn-sm dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" style={{minWidth: '30px', fontSize: '13px'}}>
                                        View<ion-icon name="chevron-down-outline" class="me-0 fs-10"></ion-icon>
                                    </button>
                                    <div class="dropdown-menu p-0">
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleViewSwitch('list')}}>List <ion-icon name={`${(view === 'list') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary" style={{width: '18px'}}></ion-icon></a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleViewSwitch('grid')}}>Grid <ion-icon name={`${(view === 'grid') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary" style={{width: '18px'}}></ion-icon></a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleViewSwitch('button')}}>Button <ion-icon name={`${(view === 'button') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary" style={{width: '18px'}}></ion-icon></a>
                                        <div class="dropdown-divider mt-0"></div>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleAlternateToggle('englishText')}}>English Text <ion-icon name={`${(alternateTextVisible === 'englishText') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary ms-2" style={{width: '18px'}}></ion-icon></a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleAlternateToggle('shortcode')}}>Shortcode <ion-icon name={`${(alternateTextVisible === 'shortcode') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary ms-2" style={{width: '18px'}}></ion-icon></a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleAlternateToggle('alternateText')}}>Alternate Text <ion-icon name={`${(alternateTextVisible === 'alternateText') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary ms-2" style={{width: '18px'}}></ion-icon></a>
                                        <div class="dropdown-divider mt-0"></div>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleAlternateToggle('color')}}>Color <ion-icon name={`${(colorVisible === 'true' || colorVisible === true) ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary ms-2" style={{width: '18px'}}></ion-icon></a>
                                        {/* <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)">In Stock <ion-icon name={`${(colorVisible === 'true' || colorVisible === true) ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary ms-2" style={{width: '18px'}}></ion-icon></a> */}
                                    </div>
                                </div>
                            </>
                        }
                        {pageName === 'ManageStock' &&
                            <>
                                <div class="dropdown">
                                    <button class="btn btn-outline-primary btn-sm dropdown-toggle ps-1 pe-1 p-0 me-1" type="button" data-bs-toggle="dropdown" style={{minWidth: '30px', fontSize: '10px', borderColor: (stateName[1] === 'manageStock') ? stockActionColor : '#24265d', color: (stateName[1] === 'manageStock') ? stockActionColor : '#24265d'}} onClick={() => {showOrderTypeModal({orderTypeModalVisible: true, reference: 'ManageStock'})}}>
                                        <>
                                            {action === 'addStock' &&
                                                <>
                                                    <ion-icon name="bag-add-outline" class="fs-6" style={{marginRight: '2px'}}></ion-icon>
                                                    Add Stock
                                                </>
                                            }
                                        </>
                                        <>
                                            {action === 'removeStock' &&
                                                <>
                                                    <ion-icon name="bag-remove-outline" class="fs-6" style={{marginRight: '2px'}}></ion-icon>
                                                    Remove Stock
                                                </>
                                            }
                                        </>
                                        <>
                                            {action === 'transferOut' &&
                                                <>
                                                    <ion-icon name="swap-horizontal-outline" class="fs-6" style={{marginRight: '2px'}}></ion-icon>
                                                    Transfer Out
                                                </>
                                            }
                                        </>
                                        <>
                                            {action === 'request' &&
                                                <>
                                                    <ion-icon name="bag-handle-outline" class="fs-6" style={{marginRight: '2px'}}></ion-icon>
                                                    Request
                                                </>
                                            }
                                        </>
                                    </button>
                                    {/* <div class="dropdown-menu p-0">
                                        {topScrollValue.map((v,k) => {
                                            return <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleActiveTab(k, v.name)}}>{v.name} <ion-icon name={(type === v.name) ? 'checkmark-circle-outline'  :'ellipse-outline'} class="m-0 text-secondary" style={{width: '18px'}}></ion-icon></a> 
                                        })}
                                    </div> */}
                                </div>
                                <div class="dropdown">
                                    <button class="btn bg-transparent btn-sm dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" style={{minWidth: '30px', fontSize: '13px'}}>
                                        View<ion-icon name="chevron-down-outline" class="me-0 fs-10"></ion-icon>
                                    </button>
                                    <div class="dropdown-menu p-0">
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleViewSwitch('list')}}>List <ion-icon name={`${(view === 'list') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary" style={{width: '18px'}}></ion-icon></a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleViewSwitch('grid')}}>Grid <ion-icon name={`${(view === 'grid') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary" style={{width: '18px'}}></ion-icon></a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleViewSwitch('button')}}>Button <ion-icon name={`${(view === 'button') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary" style={{width: '18px'}}></ion-icon></a>
                                        <div class="dropdown-divider mt-0"></div>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleAlternateToggle('englishText')}}>English Text <ion-icon name={`${(alternateTextVisible === 'englishText') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary ms-2" style={{width: '18px'}}></ion-icon></a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleAlternateToggle('shortcode')}}>Shortcode <ion-icon name={`${(alternateTextVisible === 'shortcode') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary ms-2" style={{width: '18px'}}></ion-icon></a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleAlternateToggle('alternateText')}}>Alternate Text <ion-icon name={`${(alternateTextVisible === 'alternateText') ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary ms-2" style={{width: '18px'}}></ion-icon></a>
                                        <div class="dropdown-divider mt-0"></div>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleAlternateToggle('color')}}>Color <ion-icon name={`${(colorVisible === 'true' || colorVisible === true) ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary ms-2" style={{width: '18px'}}></ion-icon></a>
                                        {/* <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)">In Stock <ion-icon name={`${(colorVisible === 'true' || colorVisible === true) ? 'checkmark-circle-outline' : 'ellipse-outline'}`} class="m-0 text-secondary ms-2" style={{width: '18px'}}></ion-icon></a> */}
                                    </div>
                                </div>
                            </>
                        }
                        {/* {pageName === 'Orders' &&
                            <div class="dropdown">
                                <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" disabled>
                                    <ion-icon name="download-outline" class="fs-5"></ion-icon>Export
                                </button>
                                <Link to="/customOrder">
                                    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button">
                                        <ion-icon name="calendar-outline" class="fs-5"></ion-icon>Custom Order
                                    </button>
                                </Link>
                            </div>
                        } */}
                        {pageName === 'Transactions' &&
                            <>
                                <div class="dropdown">
                                    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" disabled>
                                        <ion-icon name="download-outline" class="fs-5"></ion-icon>Export
                                    </button>
                                </div>
                            </>
                        }
                        {pageName === 'AddRemoveStock' &&
                            <>
                                <div class="section full mt-0 mb-1" style={{zIndex: 1}}>
                                    <div class="d-flex align-items-center">
                                        {role == 6 &&
                                            <div class="dropdown me-1">
                                                <button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown">
                                                    <ion-icon name={"location-outline"}></ion-icon>
                                                    {selectedBranchName}
                                                </button>
                                                <div class="dropdown-menu pb-0">
                                                    {branchList && branchList.map((v,k) => {
                                                        return <a class="dropdown-item pt-0" href="javascript:void(0)" onClick={() => {handleSwicthBranchLocation(v)}}> {v.Name}</a>
                                                    })}
                                                </div>
                                            </div>
                                        }
                                        <div class="dropdown me-1">
                                            <button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown">
                                                {stockActionType !== 'request' &&
                                                    <>
                                                        <ion-icon name={(stockActionType === 'addStock') ? "bag-add-outline" : "bag-remove-outline"}></ion-icon> {(stockActionType === 'addStock') ? 'Add Stock' : 'Remove Stock'}
                                                    </>
                                                }
                                                {stockActionType === 'request' &&
                                                    <>
                                                        <ion-icon name={"bag-check-outline"}></ion-icon> Request
                                                    </>
                                                }
                                            </button>
                                            <div class="dropdown-menu pb-0">
                                                {role == 6 &&
                                                    <>
                                                        <a class="dropdown-item pt-0" href="javascript:void(0)" onClick={() => {handleStockType('addStock')}}><ion-icon name="bag-add-outline" class="text-secondary" style={{marginRight: '5px'}}></ion-icon> Add Stock</a>
                                                        <a class="dropdown-item pt-0" href="javascript:void(0)" onClick={() => {handleStockType('removeStock')}}><ion-icon name="bag-remove-outline" class="text-secondary" style={{marginRight: '5px'}}></ion-icon> Remove Stock</a>
                                                    </>
                                                }
                                                {role == 5 &&
                                                    <a class="dropdown-item pt-0" href="javascript:void(0)" onClick={() => {handleStockType('request')}}><ion-icon name="bag-check-outline" class="text-secondary" style={{marginRight: '5px'}}></ion-icon> Request Stock</a>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {pageName === 'ManageItems' &&
                            <>
                                <div class="section full mt-0">
                                    <button class="btn btn-secondary btn-sm" onClick={uploadCsv}><ion-icon name="download-outline"></ion-icon> Import Items</button>
                                </div>
                            </>
                        }
                        {((pageName === 'Items' && (new URLSearchParams(search).get('tab') == 'production'))) &&
                            <>
                                {showDatePicker === false &&
                                    <div class="dropdown d-flex me-1" style={{cursor: 'pointer'}}>
                                        <div class="badge bg-primary dropdown-toggle fs-8" data-bs-toggle="dropdown">
                                            <span style={{textTransform: 'uppercase'}}>
                                                <ion-icon name="calendar-outline" class="m-0 fs-8" style={{width: '18px'}}></ion-icon>
                                                {dayOrderFilter}
                                            </span>
                                            <ion-icon name="chevron-down-outline" class="fs-6"></ion-icon>
                                        </div>
                                        <div class="dropdown-menu p-0">
                                            <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Today')}}>Today</a>
                                            <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Yesterday')}}>Yesterday</a>
                                            {/* <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Last7')}}>Last 7 Days</a> */}
                                            {/* <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Last30')}}>Last 30 Days</a> */}
                                            {/* <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('ThisMonth')}}>This Month</a> */}
                                            {/* <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('PrevMonth')}}>Previous Month</a> */}
                                            {/* <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('All')}}>All</a> */}
                                            <div class="divider"></div>
                                            <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Custom')}}>Custom</a>
                                        </div>
                                    </div>
                                }
                                {showDatePicker &&
                                    <div class="form-group boxed me-1">
                                        <div class="input-wrapper">
                                            <input
                                                type="date"
                                                class="form-control"
                                                value={datePicker}
                                                onChange={e => {
                                                    setDatePicker(e.target.value)
                                                }}
                                                id="datePicker"
                                                placeholder="Date Picker"
                                                autocomplete="off"
                                            />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            <label class="form-label fs-10 d-flex align-items-center mt-1" onClick={() => {handleOrderDateWise('Today')}}>
                                                <ion-icon name="chevron-back-outline"></ion-icon>Back
                                            </label>
                                        </div>
                                    </div>
                                }
                                {/* <div class="dropdown me-1">
                                    <button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown">
                                        <ion-icon name={"location-outline"}></ion-icon>
                                        {selectedBranchName}
                                    </button>
                                    <div class="dropdown-menu pb-0">
                                        <a class="dropdown-item pt-0" href="javascript:void(0)" onClick={() => {handleSwicthBranchLocation('all')}}> Kamothe Bakery</a>
                                        {branchList && branchList.map((v,k) => {
                                            return <a class="dropdown-item pt-0" href="javascript:void(0)" onClick={() => {handleSwicthBranchLocation(v)}}> {v.Name}</a>
                                        })}
                                    </div>
                                </div> */}
                                {(bakeryList?.length > 0) &&
                                    <Multiselect
                                        displayValue="Branch"
                                        groupBy="Bakery"
                                        onRemove={handleRemoveSelected}
                                        onSelect={handleSelected}
                                        options={bakeryList}
                                        selectedValues={selectedValue}
                                        showCheckbox
                                        showArrow={true}
                                        style={{
                                            chips: {
                                              background: '#24265d',
                                              color: 'white',
                                            },
                                            searchBox: {
                                                background: 'white',
                                            },
                                            groupHeading: {
                                                borderBottom: 'solid 2px black'
                                            }
                                        }}
                                    />
                                }
                            </>
                        }
                    </>
                }
                {searchInput &&
                    <form class="search-form ms-1 me-1">
                        <div class="form-group searchbox">
                            <input type="text" class="form-control" placeholder="Search" style={{borderRadius: '50px'}} onChange={(e) => {handleItemSearch(e.target.value)}} />
                            <i class="input-icon">
                                <ion-icon name="search-outline"></ion-icon>
                            </i>
                            <i class="input-icon" style={{right: 0, left: 'auto'}} onClick={handleSearchHide}>
                                <ion-icon name="chevron-forward-outline" class="fs-5"></ion-icon>
                            </i>
                        </div>
                    </form>
                }
            </div>
        </div>
    )
}

const mapStateToProps = ({ general, data, stock, auth }) => {
    const {
        view,
        gridView,
        shortcodeVisible,
        alternateTextVisible,
        colorVisible
    } = general
    const {
        itemName,
        dayOrderFilter,
        transcriptEmpty,
        categoryName,
        dayTransactionFilter,
        categories,
        topScrollValue,
        type,
        draftData,
        draftIndex,
        stockActionType,
        allItemsStore,
        tempSetupObj,
        businessData,
        branchID
    } = data
    const {
        stockActionColor
    } = stock
    const {
        role,
        buisness_ID,
        authToken
    } = auth
    return {
        view,
        gridView,
        itemName,
        dayOrderFilter,
        transcriptEmpty,
        categoryName,
        dayTransactionFilter,
        categories,
        shortcodeVisible,
        alternateTextVisible,
        colorVisible,
        topScrollValue,
        type,
        draftData,
        draftIndex,
        stockActionColor,
        stockActionType,
        allItemsStore,
        tempSetupObj,
        role,
        businessData,
        buisness_ID,
        authToken,
        branchID
    }
  }
  
const mapDispatchToProps = {
    generalDataUpdate,
    showAddCategoryOffcanvas,
    activeTabUpdatedName,
    itemSearch,
    showAddTransactionModal,
    setOrderDayFilter,
    emptyTranscript,
    setCategoryName,
    setTransactionDayFilter,
    categoryIdUpdate,
    showQuickAddOffcanvas,
    setShortcodeVisible,
    setAlternateTextVisible,
    setColorVisible,
    checkoutItemType,
    cartDraftIndex,
    cartDraftData,
    cartOrderIndex,
    showOrderTypeModal,
    setTempCategoryId,
    setStockActionType,
    showFileUploadModal,
    setBranchID
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchWithButtons)
