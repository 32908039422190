import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import axios from '../../../AxiosConfig'
import { useNavigate, useLocation } from 'react-router-dom'
import { hideInwardOutwardModal } from '../../../features/Offcanvas/inwardOutwardModalSlice'
import { updateEditStock } from '../../../features/Data/stockSlice'
import { itemsDataUpdate } from '../../../features/Data/dataSlice'
import moment from 'moment-timezone'

const InwardOutwardDetailModal = ({
    inwardOutwardModalVisible,
    hideInwardOutwardModal,
    inwardOutwardDetailData,
    updateEditStock,
    itemsDataUpdate,
    role
}) => {
    console.log(inwardOutwardDetailData)
    const [totalStock, setTotalStock] = useState(0)
    const search = useLocation().search
    const activeTabName = new URLSearchParams(search).get('tab')
    console.log(activeTabName)
    const targetTimezone = 'Asia/Kolkata'
    let navigate = useNavigate()
    useEffect(() => {
        if (inwardOutwardDetailData) {
            let totalStockSum = 0
            inwardOutwardDetailData?.Items?.map((v,k) => {
                totalStockSum = totalStockSum  + parseInt(v.quantity) 
            })
            setTotalStock(totalStockSum)
        }
    }, [inwardOutwardDetailData])
    const handleEditStock = (type) => {
        if (inwardOutwardDetailData) {
            updateEditStock({editStockData: inwardOutwardDetailData.Items, stockRequestID: inwardOutwardDetailData.ID})
            hideInwardOutwardModal()
            navigate('/addRemoveStock?type=' + type)
        }
    }
    const handleAcceptStock = () => {
        let acceptStockData = JSON.parse(JSON.stringify(inwardOutwardDetailData))
        acceptStockData.StockType = 'addStock'
        axios.put('/Stock', acceptStockData).then(res => {
            if (res.data.message === 'Success') {
                axios.get('/Items/business').then(res => {
                    if (res.data.message === 'Success') {
                        itemsDataUpdate({items: res.data.data})
                        hideInwardOutwardModal()
                    } else {
                        
                    }
                })
            } else {
                console.log('Something went wrong')
            }
        })
    }
    return (
        <div>
            <Modal show={inwardOutwardModalVisible} centered={true}>
                <div>
                    <div class="rounded-circle d-flex align-items-center justify-content-center text-light" style={{height: '25px', width: '25px', background: 'rgba(0,0,0,0.3)', position: 'absolute', top: '-13px', right: '-13px', cursor: 'pointer'}} onClick={() => {hideInwardOutwardModal()}}>
                        <ion-icon name="close-outline"></ion-icon>
                    </div>
                </div>
                <Modal.Body class="modal-body mt-0 pt-0">
                    <div class="section full mt-0">
                        <div class="invoice m-0 p-1 shadow-none">
                            <div class="invoice-person mt-2">
                                <div class="invoice-from p-0">
                                    <h4 class="fw-bolder">
                                        <h4 class="fw-bolder">
                                            {inwardOutwardDetailData?.CurrentStatus === 'addStock' &&
                                                <>
                                                    Added
                                                </>
                                            }
                                            {inwardOutwardDetailData?.CurrentStatus === 'removeStock' &&
                                                <>
                                                    Removed
                                                </>
                                            }
                                            {inwardOutwardDetailData?.CurrentStatus === 'transferOut' &&
                                                <>
                                                    Transfered
                                                </>
                                            }
                                            {inwardOutwardDetailData?.CurrentStatus === 'request' &&
                                                <>
                                                    Request
                                                </>
                                            }
                                            {inwardOutwardDetailData?.CurrentStatus === 'modifyRequest' &&
                                                <>
                                                    Modified Request
                                                </>
                                            }
                                            {inwardOutwardDetailData?.CurrentStatus === 'inTransit' &&
                                                <>
                                                    In Transit
                                                </>
                                            }
                                        </h4>
                                    </h4>
                                </div>
                                <div class="invoice-to p-0">
                                    {moment(inwardOutwardDetailData?.DateTime).tz(targetTimezone).format('DD-MM-YYYY, HH:mm:ss')}
                                </div>
                            </div>
                            <div class="invoice-detail mt-1">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <td class="p-1 ps-0">ITEM</td>
                                                <td class="p-1 pe-0">STOCK</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {inwardOutwardDetailData?.Items.map((v,k) => {
                                                return <tr>
                                                    <td class="p-1 ps-0">{v.ItemTitle}</td>
                                                    <td class="p-1 pe-0">{v.quantity}</td>
                                                </tr>
                                            })}
                                            <tr style={{borderColor: 'transparent'}}>
                                                <td class="fs-6">Total Stock</td>
                                                <td class="fs-6">{totalStock}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {activeTabName !== 'production' &&
                                <>
                                    {role == 6 &&
                                        <>
                                            {(inwardOutwardDetailData?.CurrentStatus == 'modifyRequest' || inwardOutwardDetailData?.CurrentStatus == 'request') &&
                                                <>
                                                    {(inwardOutwardDetailData?.CurrentStatus == inwardOutwardDetailData?.StockType) &&
                                                        <div class="d-flex justify-content-center mt-2">
                                                            <button class="btn btn-secondary me-1" onClick={() => {handleEditStock('addStock')}}><ion-icon name="pencil-outline" class="fs-6"></ion-icon>Edit / Proceed</button>
                                                            <button class="btn btn-warning" onClick={() => {handleEditStock((inwardOutwardDetailData?.CurrentStatus == 'modifyRequest') ? 'modified' : 'modify')}}>Modify Request</button>
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                    {role == 5 &&
                                        <>
                                        {(inwardOutwardDetailData?.CurrentStatus == 'inTransit') &&
                                            <>
                                                {(inwardOutwardDetailData?.CurrentStatus == inwardOutwardDetailData?.StockType) &&
                                                    <div class="d-flex justify-content-center">
                                                        <div class="mt-2 me-1">
                                                            <button class="btn btn-success" onClick={handleAcceptStock}><ion-icon name="checkmark-circle-outline" class="fs-4"></ion-icon>Accept</button>
                                                        </div>
                                                        <div class="mt-2">
                                                            <button class="btn btn-danger" disabled><ion-icon name="close-circle-outline" class="fs-4"></ion-icon>Reject</button>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ inwardOutward, auth }) => {
    const {
        inwardOutwardModalVisible,
        inwardOutwardDetailData
    } = inwardOutward
    const {
        role
    } = auth
    return {
        inwardOutwardModalVisible,
        inwardOutwardDetailData,
        role
    }
  }
  
  const mapDispatchToProps = {
    hideInwardOutwardModal,
    updateEditStock,
    itemsDataUpdate
  }

export default connect(mapStateToProps, mapDispatchToProps)(InwardOutwardDetailModal)