import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    categoryModalVisible: false
}

export const CategoryModalSlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
      showCategoryModal: (state, action) => {
        const {
            categoryModalVisible
        } = action.payload
        state.categoryModalVisible = categoryModalVisible
      },
      hideCategoryModal: (state) => {
        state.categoryModalVisible = false
      }
    }
})

export const { showCategoryModal, hideCategoryModal } = CategoryModalSlice.actions;


export default CategoryModalSlice.reducer