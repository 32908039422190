import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useNavigate } from 'react-router-dom'
import { connect } from "react-redux"
import { hideAttendanceOffcanvas } from '../../../features/Offcanvas/confirmationModalSlice'

const ConfirmationOffcanvas = ({
    attendanceOffcanvasVisible,
    hideAttendanceOffcanvas
}) => {
    let navigate = useNavigate()
    const markAttendance = () => {
        localStorage.setItem('firstLogin', 'true')
        navigate('/userLogin')
    }
    const closeAttendanceOffcanvas = () => {
        localStorage.setItem('firstLogin', 'true')
        hideAttendanceOffcanvas()
    }
    return (
        <div>
            <Offcanvas show={attendanceOffcanvasVisible} placement={'top'} style={{backgroundColor: 'transparent'}}>
                <Offcanvas.Body class="">
                    <div class="notification-box" style={{top: 0}}>
                        <div class="notification-dialog ios-style">
                            <div class="notification-header bg-white text-dark">
                                <div class="in">
                                    <strong class="text-dark">Attendance</strong>
                                </div>
                                <div class="right">
                                    <a href="javascript:void(0)" class="close-button text-dark" onClick={() => {hideAttendanceOffcanvas()}}>
                                        <ion-icon name="close-circle"></ion-icon>
                                    </a>
                                </div>
                            </div>
                            <div class="notification-content bg-white">
                                <div class="in">
                                    <div class="text text-dark">
                                        Mark Attendance to Proceed
                                    </div>
                                </div>
                            </div>
                            <div class="notification-footer" style={{borderTop: 'none'}}>
                                <div class="notification-button bg-success" onClick={markAttendance}>
                                    <ion-icon name="checkmark-circle-outline"></ion-icon>
                                    Mark Attendance
                                </div>
                                <div class="notification-button bg-secondary" onClick={closeAttendanceOffcanvas}>
                                    <ion-icon name="close-circle-outline"></ion-icon>
                                    Ignore
                                </div>
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

const mapStateToProps = ({ confirmation }) => {
    const {
        attendanceOffcanvasVisible
    } = confirmation
    return {
        attendanceOffcanvasVisible
    }
}
  
const mapDispatchToProps = {
    hideAttendanceOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationOffcanvas)