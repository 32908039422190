import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    mainMenuOffcanvasVisible: false
}

export const MainMenuOffcanvasSlice = createSlice({
    name: 'mainMenu',
    initialState,
    reducers: {
      showMainMenuOffcanvas: (state, action) => {
        const {
            mainMenuOffcanvasVisible
        } = action.payload
        state.mainMenuOffcanvasVisible = mainMenuOffcanvasVisible
      },
      hideMainMenuOffcanvas: (state) => {
        state.mainMenuOffcanvasVisible = false
      }
    }
})

export const { showMainMenuOffcanvas, hideMainMenuOffcanvas } = MainMenuOffcanvasSlice.actions;


export default MainMenuOffcanvasSlice.reducer