import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    userListOffcanvasVisible: false
}

export const UserListOffcanvasSlice = createSlice({
    name: 'userList',
    initialState,
    reducers: {
      showUserListOffcanvas: (state, action) => {
        const {
            userListOffcanvasVisible
        } = action.payload
        state.userListOffcanvasVisible = userListOffcanvasVisible
      },
      hideUserListOffcanvas: (state) => {
        state.userListOffcanvasVisible = false
      }
    }
})

export const { showUserListOffcanvas, hideUserListOffcanvas } = UserListOffcanvasSlice.actions;


export default UserListOffcanvasSlice.reducer