import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import ItemCard from '../BitComponents/ItemCard'
import { itemSearch } from '../../../features/Data/dataSlice'

const ItemsGridView = ({
    itemCardDetail,
    cardBg,
    countInRow,
    categoryID,
    itemName,
    pageName,
    itemSearch,
    checkoutItemList,
    configObj
}) => {
    const [filteredCategory, setFilteredCategory] = useState('')
    const [allCategoryFlag, setAllCategoryFlag] = useState(true)
    const [stateName, setStateName] = useState('')
    const page = window.location.pathname.split('/')
    useEffect(() => {
        setStateName(page[1])
    }, [page])
    useEffect(() => {
      itemSearch({itemName: ''})
    }, [pageName])

    useEffect(() => {
        if (itemCardDetail) {
            let filtered;
            if (categoryID !== 'all' && categoryID !== undefined) {
                setAllCategoryFlag(false)
                filtered = itemCardDetail.filter((item) => categoryID === item.Item_CategoryID);
            } else {
                setAllCategoryFlag(true)
                filtered = itemCardDetail
            }
            if(itemName?.trim().length > 0) filtered = filtered.filter((item) => (item?.ItemTitle?.toLowerCase()?.includes(itemName?.toLowerCase()?.trim()) || item?.AlternateText?.includes(itemName?.trim()) || item?.Shortcode?.includes(itemName?.trim())))
            setFilteredCategory(filtered)
        }
    }, [categoryID, itemName, itemCardDetail])

    return (
        <div class="row">
            {(filteredCategory.length > 0) &&
                <>
                    {(filteredCategory !== null) &&
                        filteredCategory.map((value, key) => {
                        return (
                            <>
                                {configObj?.Config?.inStock &&
                                    <>
                                        {stateName === 'newOrder' && ((value.TotalSaleQty !== 0 && value.TotalSaleQty !== '0')) &&
                                            <>
                                                {stateName === 'newOrder' &&
                                                    <div class={`col-4 col-md-${'4'} col-lg-${countInRow} mb-2`} key={key}>
                                                        <>
                                                            {(allCategoryFlag === false) &&
                                                                <ItemCard itemCardDetail={value} cardBg={cardBg} pageName={pageName}></ItemCard>
                                                            }
                                                            {(allCategoryFlag === true) &&
                                                                <ItemCard itemCardDetail={value} cardBg={cardBg} pageName={pageName}></ItemCard>
                                                            }
                                                        </>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </>
                                }
                                {(configObj?.Config?.inStock === false || configObj?.Config?.inStock === null || configObj?.Config?.inStock === undefined) &&
                                    <>
                                        {stateName === 'newOrder' &&
                                            <>
                                                {stateName === 'newOrder' &&
                                                    <div class={`col-4 col-md-${'4'} col-lg-${countInRow} mb-2`} key={key}>
                                                        <>
                                                            {(allCategoryFlag === false) &&
                                                                <ItemCard itemCardDetail={value} cardBg={cardBg} pageName={pageName}></ItemCard>
                                                            }
                                                            {(allCategoryFlag === true) &&
                                                                <ItemCard itemCardDetail={value} cardBg={cardBg} pageName={pageName}></ItemCard>
                                                            }
                                                        </>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </>
                                }
                                {stateName === 'manageStock' &&
                                    <>
                                        {value.Stockable === 't' &&
                                            <div class={`col-4 col-md-${'4'} col-lg-${countInRow} mb-2`} key={key}>
                                                <ItemCard itemCardDetail={value} cardBg={cardBg} pageName={pageName}></ItemCard>
                                            </div>
                                        }
                                    </>
                                }
                            </>
                        )})
                    }
                </>
            }
            {(filteredCategory.length === 0) &&
                <>
                    {allCategoryFlag === false &&
                        <div class={`col-12 col-md-12 mb-1 mt-5 d-flex align-items-center justify-content-center flex-column`}>
                            <h2 class="text-danger">No Items Found</h2>
                            <button class="btn btn-outline-primary">+ Add Item</button> 
                        </div>
                    }
                </>
            }
        </div>
    )
}

const mapStateToProps = ({ data, checkoutItems }) => {
    const {
        categoryID,
        itemName,
        configObj
    } = data
    const {
        checkoutItemList
    } = checkoutItems
    return {
        categoryID,
        itemName,
        checkoutItemList,
        configObj
    }
  }
  
const mapDispatchToProps = {
    itemSearch
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemsGridView)
