import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    customOrderDetailModalVisible: false,
    customOrderDetailData: null,
    opticPrintBill: false,
    opticPrintBillData: null,
    checkboxModalVisible: false,
    printType: null
}

export const CustomOrderDetailModalSlice = createSlice({
    name: 'customOrderDetail',
    initialState,
    reducers: {
      showCustomOrderDetailModal: (state, action) => {
        const {
            customOrderDetailModalVisible,
            customOrderDetailData
        } = action.payload
        state.customOrderDetailModalVisible = customOrderDetailModalVisible
        state.customOrderDetailData = customOrderDetailData
      },
      hideCustomOrderDetailModal: (state) => {
        state.customOrderDetailModalVisible = false
      },
      setOpticPrintBill: (state, action) => {
        const {
            opticPrintBill,
            opticPrintBillData,
            printType
        } = action.payload
        state.opticPrintBill = opticPrintBill 
        state.opticPrintBillData = opticPrintBillData
        state.printType = printType
      },
      showCheckboxModal: (state, action) => {
        const {
            checkboxModalVisible
        } = action.payload
        state.checkboxModalVisible = checkboxModalVisible
      },
      hideCheckboxModal: (state, action) => {
        state.checkboxModalVisible = false
      },
    }
})

export const { showCustomOrderDetailModal, hideCustomOrderDetailModal, setOpticPrintBill, showCheckboxModal, hideCheckboxModal } = CustomOrderDetailModalSlice.actions;


export default CustomOrderDetailModalSlice.reducer