import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import Footer from '../layout/Footer/Footer'
import Header from '../layout/Header/Header'
import { useLocation } from 'react-router-dom'
import axios from '../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import config from '../../config'
import Header2 from '../layout/Header/Header2'
import MainMenuOffcanvas from '../layout/Sidebar/MainMenuOffcanvas'
import { setSyncCallback, setBranchID } from '../../features/Data/dataSlice'

const Layout = ({
    children,
    syncCallback,
    setSyncCallback,
    setBranchID,
    authToken,
    businessData,
    syncActionType
}) => {
  const tokenData = jwt_decode(authToken)
  const search = useLocation().search
  useEffect(() => {
    const intervalID1 = setInterval(() => {
      let syncOrders = JSON.parse(localStorage.getItem('Orders'))
      let syncPurchase = JSON.parse(localStorage.getItem('Purchase'))
      let syncWastage = JSON.parse(localStorage.getItem('Wastage'))
      if (syncOrders !== null && syncPurchase !== null && syncWastage !== null) {
        if (syncOrders.length > 0 && syncPurchase.length > 0 && syncWastage.length > 0) {
          // setSyncCallback(true)
        } 
      }
    }, 60000)
    return () => {
      clearInterval(intervalID1)
    }
  }, [])
  useEffect(() => {
    // setInterval(() => {
      if (syncCallback === true) {
        let syncOrders = JSON.parse(localStorage.getItem('Orders'))
        let syncPurchase = JSON.parse(localStorage.getItem('Purchase'))
        let syncWastage = JSON.parse(localStorage.getItem('Wastage'))
        let syncCarts = JSON.parse(localStorage.getItem('Carts'))
        let sync = {
          "Orders": syncOrders ,
          "Purchase": syncPurchase,
          "Wastage": syncWastage,
          "Carts": syncCarts
        }
        if (syncOrders === null && syncPurchase === null && syncWastage === null) {
        } else if (syncOrders.length > 0 || syncPurchase.length > 0 || syncWastage.length > 0) {
          axios.put(config.apiUrl + '/Order/PlaceOrder', sync).then(res=>{
            if (res.data.message === 'Success') {
              if (syncActionType != 'logout') {
                Object.keys(res.data.data).map((value, key) => {
                  if (value === 'Orders') {
                    const newArr = res.data.data[value].filter((v) => v !== 'done')
                    localStorage.setItem('Orders', JSON.stringify(newArr))
                  } else if (value === 'Purchase') {
                    const newArr = res.data.data[value].filter((v) => v !== 'done')
                    localStorage.setItem('Purchase', JSON.stringify(newArr))
                  } else if (value === 'Wastage') {
                    const newArr = res.data.data[value].filter((v) => v !== 'done')
                    localStorage.setItem('Wastage', JSON.stringify(newArr))
                  }
                })
                setSyncCallback({syncCallback: false})
              } else {
                setSyncCallback({syncCallback: false, syncActionType: syncActionType})
              }
            } else {
              // showAppToast({ toastMessage:'Something Went Wrong', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
            }
          })
        }
      }
    // }, 10000)
  }, [syncCallback])
//   useEffect(() => {
//     // if (new URLSearchParams(search).get('tab') != 'production') {
//     setBranchID({branchID: tokenData.userInfo.DefaultLocationID})
//     // }
// }, [search])
  // const root = document.documentElement;
  // root.style.setProperty('--primary-color', (businessData && businessData[0].ThemeColor) ? businessData[0].ThemeColor : '#24265d');
    return (
      <div className="App">
        <div className="">
            <div className="row mx-0 px-0">
                <div className="col-12 col-md-12 col-lg-12">
                  <div style={{display: 'block'}}>
                    <Header></Header>
                  </div>
                    <Header2></Header2>
                    {children}
                    <Footer></Footer>
                    <MainMenuOffcanvas></MainMenuOffcanvas>
                </div>
            </div>
        </div>
      </div>
    )
}

const mapStateToProps = ({ data, auth }) => {
  const {
    syncCallback,
    businessData,
    syncActionType
  } = data
  const {
    authToken
  } = auth
    return {
      syncCallback,
      authToken,
      businessData,
      syncActionType
    }
}
  
const mapDispatchToProps = {
  setSyncCallback,
  setBranchID
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
