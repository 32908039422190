import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import ItemButton from '../BitComponents/ItemButton'
import { itemSearch } from '../../../features/Data/dataSlice'

const ItemsButtonView = ({
    itemCardDetail,
    cardBg,
    countInRow,
    categoryID,
    itemName,
    pageName,
    itemSearch,
    configObj
}) => {
    const [filteredCategory, setFilteredCategory] = useState('')
    const [allCategoryFlag, setAllCategoryFlag] = useState(true)
    const [stateName, setStateName] = useState('')
    const page = window.location.pathname.split('/')
    useEffect(() => {
        setStateName(page[1])
    }, [page])

    useEffect(() => {
      itemSearch({itemName: ''})
    }, [pageName])
    

    useEffect(() => {
        if (itemCardDetail) {
            let filtered;
            if (categoryID !== 'all') {
                setAllCategoryFlag(false)
                filtered = itemCardDetail.filter((item) => categoryID === item.Item_CategoryID);
            } else {
                setAllCategoryFlag(true)
                filtered = itemCardDetail
            }
            if(itemName?.trim().length > 0) filtered = filtered.filter((item) => item.ItemTitle.toLowerCase().includes(itemName.toLowerCase().trim()))
            setFilteredCategory(filtered)
        }
    }, [categoryID, itemName, itemCardDetail])

    return (
        <div class="row">
            {(filteredCategory.length > 0) &&
                <>
                    {(filteredCategory !== null) &&
                        filteredCategory.map((value, key) => {
                        return (
                            <>
                                {configObj?.Config?.inStock &&
                                    <>
                                        {stateName === 'newOrder' && ((value.TotalSaleQty !== 0 && value.TotalSaleQty !== '0')) &&
                                            <>
                                                <div class={`col-6 col-md-${'4'} col-lg-${'3'} mb-1`} key={key}>
                                                    {(allCategoryFlag === false) &&
                                                        <ItemButton itemCardDetail={value} cardBg={cardBg} pageName={pageName}></ItemButton>
                                                    }
                                                    {(allCategoryFlag === true) &&
                                                        <ItemButton itemCardDetail={value} cardBg={cardBg} pageName={pageName}></ItemButton>
                                                    }
                                                </div>
                                            </>
                                        }
                                        {stateName === 'manageStock' &&
                                            <>
                                                {value.Stockable === 't' &&
                                                    <div class={`col-6 col-md-${'4'} col-lg-${'3'} mb-1`} key={key}>
                                                        <ItemButton itemCardDetail={value} cardBg={cardBg} pageName={pageName}></ItemButton>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </>
                                }
                                {(configObj?.Config?.inStock === false || configObj?.Config?.inStock === null || configObj?.Config?.inStock === undefined) &&
                                    <>
                                        {stateName === 'newOrder' &&
                                            <>
                                                <div class={`col-6 col-md-${'4'} col-lg-${'3'} mb-1`} key={key}>
                                                    {(allCategoryFlag === false) &&
                                                        <ItemButton itemCardDetail={value} cardBg={cardBg} pageName={pageName}></ItemButton>
                                                    }
                                                    {(allCategoryFlag === true) &&
                                                        <ItemButton itemCardDetail={value} cardBg={cardBg} pageName={pageName}></ItemButton>
                                                    }
                                                </div>
                                            </>
                                        }
                                        {stateName === 'manageStock' &&
                                            <>
                                                {value.Stockable === 't' &&
                                                    <div class={`col-6 col-md-${'4'} col-lg-${'3'} mb-1`} key={key}>
                                                        <ItemButton itemCardDetail={value} cardBg={cardBg} pageName={pageName}></ItemButton>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </>
                        )})
                    }
                </>
            }
            {(filteredCategory.length === 0) &&
                <>
                    {allCategoryFlag === false &&
                        <div class={`col-12 col-md-12 mb-1 mt-5 d-flex align-items-center justify-content-center flex-column`}>
                            <h2 class="text-danger">No Items Found</h2>
                            <button class="btn btn-outline-primary">+ Add Item</button> 
                        </div>
                    }
                </>
            }
        </div>
    )
}

const mapStateToProps = ({ data }) => {
    const {
        categoryID,
        itemName,
        configObj
    } = data
    return {
        categoryID,
        itemName,
        configObj
    }
  }
  
const mapDispatchToProps = {
    itemSearch
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemsButtonView)
