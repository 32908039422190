import React, { useState, useEffect } from 'react';

const CategorySizeSummaryTable = ({
    categoryWiseData
}) => {
    // const [totalSum, setTotalSum] = useState(0)
    useEffect(() => {
        let totalSum = 0;
        // categoryWiseData?.map((v,k) => {
        //     totalSum += parseInt(v.Sum)
        // })
        // setTotalSum((totalSum) ? totalSum : 0)
    }, [categoryWiseData])
    return (
        <div class="border p-0">
            <>
                {(categoryWiseData?.data?.length === 0) &&
                    <h4 class="text-danger fw-bold text-center mt-1">No Data Found</h4>
                }
                {(categoryWiseData?.data?.length > 0) &&
                    <div class="table-responsive border bg-white">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" rowSpan={2} class="fw-bold border-end text-center align-middle fs-6">Category</th>
                                    <th scope="col" colSpan={3} class="fw-bold border-end text-center border-bottom fs-6">Pastries</th>
                                    <th scope="col" colSpan={3} class="fw-bold border-end text-center border-bottom fs-6">Half Kg</th>
                                    <th scope="col" colSpan={3} class="fw-bold border-end text-center border-bottom fs-6">One Kg</th>
                                    <th scope="col" colSpan={3} class="fw-bold border-end text-center border-bottom fs-6">Other</th>
                                </tr>
                                <tr>
                                    <th scope="col" class="fw-bold border-end text-end fs-6">Stock</th>
                                    <th scope="col" class="fw-bold border-end text-end fs-6">Sale(Qty)</th>
                                    <th scope="col" class="fw-bold border-end text-end fs-6">Sale(₹)</th>
                                    <th scope="col" class="fw-bold border-end text-end fs-6">Stock</th>
                                    <th scope="col" class="fw-bold border-end text-end fs-6">Sale(Qty)</th>
                                    <th scope="col" class="fw-bold border-end text-end fs-6">Sale(₹)</th>
                                    <th scope="col" class="fw-bold border-end text-end fs-6">Stock</th>
                                    <th scope="col" class="fw-bold border-end text-end fs-6">Sale(Qty)</th>
                                    <th scope="col" class="fw-bold border-end text-end fs-6">Sale(₹)</th>
                                    <th scope="col" class="fw-bold border-end text-end fs-6">Stock</th>
                                    <th scope="col" class="fw-bold border-end text-end fs-6">Sale(Qty)</th>
                                    <th scope="col" class="fw-bold border-end text-end fs-6">Sale(₹)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {categoryWiseData?.data?.map((v1, k1)=>{
                                    console.log(v1)
                                    return <tr>
                                        <td class="border-end fw-bold fs-6">{v1.Name}</td>
                                        <td class="border-end text-end fw-bold fs-6">{v1?.Pastry?.Stock}</td>
                                        <td class="border-end text-end fw-bold fs-6">{v1?.Pastry?.SaleQty}</td>
                                        <td class="border-end text-end fw-bold fs-6">₹{v1?.Pastry?.Sale}</td>
                                        <td class="border-end text-end fw-bold fs-6">{v1['Half Kg']?.Stock}</td>
                                        <td class="border-end text-end fw-bold fs-6">{v1['Half Kg']?.SaleQty}</td>
                                        <td class="border-end text-end fw-bold fs-6">₹{v1['Half Kg']?.Sale}</td>
                                        <td class="border-end text-end fw-bold fs-6">{v1['One Kg']?.Stock}</td>
                                        <td class="border-end text-end fw-bold fs-6">{v1['One Kg']?.SaleQty}</td>
                                        <td class="border-end text-end fw-bold fs-6">₹{v1['One Kg']?.Sale}</td>
                                        <td class="border-end text-end fw-bold fs-6">{v1['Other']?.Stock}</td>
                                        <td class="border-end text-end fw-bold fs-6">{v1['Other']?.SaleQty}</td>
                                        <td class="border-end text-end fw-bold fs-6">₹{v1['Other']?.Sale}</td>
                                    </tr>
                                })}
                                <tr>
                                    <td class="border-end fw-bold fs-6">Total</td>
                                    <td class="border-end text-end fw-bold fs-6">{categoryWiseData?.total?.Pastry?.Stock}</td>
                                    <td class="border-end text-end fw-bold fs-6">{categoryWiseData?.total?.Pastry?.SaleQty}</td>
                                    <td class="border-end text-end fw-bold fs-6">₹{categoryWiseData?.total?.Pastry?.Sale}</td>
                                    <td class="border-end text-end fw-bold fs-6">{categoryWiseData?.total['Half Kg']?.Stock}</td>
                                    <td class="border-end text-end fw-bold fs-6">{categoryWiseData?.total['Half Kg']?.SaleQty}</td>
                                    <td class="border-end text-end fw-bold fs-6">₹{categoryWiseData?.total['Half Kg']?.Sale}</td>
                                    <td class="border-end text-end fw-bold fs-6">{categoryWiseData?.total['One Kg']?.Stock}</td>
                                    <td class="border-end text-end fw-bold fs-6">{categoryWiseData?.total['One Kg']?.SaleQty}</td>
                                    <td class="border-end text-end fw-bold fs-6">₹{categoryWiseData?.total['One Kg']?.Sale}</td>
                                    <td class="border-end text-end fw-bold fs-6">{categoryWiseData?.total['Other']?.Stock}</td>
                                    <td class="border-end text-end fw-bold fs-6">{categoryWiseData?.total['Other']?.SaleQty}</td>
                                    <td class="border-end text-end fw-bold fs-6">₹{categoryWiseData?.total['Other']?.Sale}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
            </>
        </div>
    );
};

export default CategorySizeSummaryTable;