import React, { useState, useEffect } from 'react';

const PaymentMethodSummaryTable = ({
    paymentData
}) => {
    const [paymetMethodTotal, setPaymetMethodTotal] = useState(0)
    useEffect(() => {
        let paymentTotal;
        paymentTotal = (paymentData?.Orders?.PaymentMethod?.Cash) + (paymentData?.Orders?.PaymentMethod?.UPI) + (paymentData?.Orders?.PaymentMethod?.Card) + (paymentData?.Orders?.PaymentMethod?.Cheque) + (paymentData?.Orders?.PaymentMethod?.Online) + (paymentData?.Orders?.PaymentMethod?.Other)
        setPaymetMethodTotal(paymentTotal)
    }, [paymentData])
    return (
         <div class="border p-0">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" class="border-end text-nowrap fw-bold">Method</th>
                            <th scope="col" class="border-end text-nowrap fw-bold text-end">₹{(paymetMethodTotal) ? paymetMethodTotal : 0}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="border-end text-nowrap fw-bold">Cash</td>
                            <td class="border-end text-nowrap text-end">₹{paymentData?.Orders?.PaymentMethod?.Cash}</td>
                        </tr>
                        <tr>
                            <td class="border-end text-nowrap fw-bold">UPI</td>
                            <td class="border-end text-nowrap text-end">₹{paymentData?.Orders?.PaymentMethod?.UPI}</td>
                        </tr>
                        <tr>
                            <td class="border-end text-nowrap fw-bold">Card</td>
                            <td class="border-end text-nowrap text-end">₹{paymentData?.Orders?.PaymentMethod?.Card}</td>
                        </tr>
                        <tr>
                            <td class="border-end text-nowrap fw-bold">Cheque</td>
                            <td class="border-end text-nowrap text-end">₹{paymentData?.Orders?.PaymentMethod?.Cheque}</td>
                        </tr>
                        <tr>
                            <td class="border-end text-nowrap fw-bold">Payment Gateway</td>
                            <td class="border-end text-nowrap text-end">₹{paymentData?.Orders?.PaymentMethod?.Online}</td>
                        </tr>
                        <tr>
                            <td class="border-end text-nowrap fw-bold">Other</td>
                            <td class="border-end text-nowrap text-end">₹{paymentData?.Orders?.PaymentMethod?.Other}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PaymentMethodSummaryTable;