import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    shortcutSearchModalVisible: false
}

export const ShortcutSearchModal = createSlice({
    name: 'shortcut',
    initialState,
    reducers: {
      showShortcutSearchModal: (state, action) => {
        const {
            shortcutSearchModalVisible
        } = action.payload
        state.shortcutSearchModalVisible = shortcutSearchModalVisible
      },
      hideShortcutSearchModal: (state) => {
        state.shortcutSearchModalVisible = false
      }
    }
})

export const { showShortcutSearchModal, hideShortcutSearchModal } = ShortcutSearchModal.actions;


export default ShortcutSearchModal.reducer