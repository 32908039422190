import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import { useNavigate, useLocation, Link } from 'react-router-dom'
import Logo from '../images/allpos_white.svg'
import axios from '../../AxiosConfig'
import { showAppToast } from '../../features/utilities/appToastSlice'

const OnBoard = ({
    showAppToast
}) => {
    const [businessName, setBusinessName] = useState('')
    const [contactNumber, setContactNumber] = useState(null)
    const [processingLoader, setProcessingLoader] = useState(false)
    const [lng, setLng] = useState(null)
    const [lat, setLat] = useState(null)
    const [category, setCategory] = useState(false)
    let navigate = useNavigate()
    const search = useLocation().search
    const businessCategory = new URLSearchParams(search).get('businessCategory')
    const createStore = (e) => {
        e.preventDefault()
        setProcessingLoader(true)
        const postData = {
            "BusinessName": businessName,
            "ContactNumber": contactNumber,
            "Business_Category": businessCategory,
            "BusinessLocation": suggestionText
        }
        axios.put('/Businesses/getStarted', postData).then(res => {
            if (res.data.message === 'Success') {
                setProcessingLoader(false)
                navigate('/')
                setBusinessName('')
                setContactNumber(null)
                showAppToast({ toastMessage:'Store Created', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center', isVisible: true })
            } else {
                setProcessingLoader(false)
                showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000, isVisible: true})
            }
        })
    }
    const accessesToken = 'pk.eyJ1Ijoic2t5cXRlY2giLCJhIjoiY2xoOHFkeXl2MDAxdzNkbGd0cHc3YWxpYiJ9.ty5aLADWsiwGLYn1GZ9qTA';
    const [suggestionText, setSuggetionText] = useState('');
    const [suggestionList, setSuggestionList] = useState([]);
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
        function (position) {
            setLng(position.coords.longitude);
            setLat(position.coords.latitude);
        },
        function (error) {
            if (error.code === error.PERMISSION_DENIED) {
            axios.get('https://api.webweb.ai/website/address/noIp')
                .then(response => {
                setLng(response.data.data[0].lon);
                setLat(response.data.data[0].lat);
                })
                .catch(error => {
                })
            } else {
            }
        }
        );
    }, [])
    useEffect(() => {
        if (suggestionText.trim().length > 0) {
        const tempDataList = suggestionList.filter(suggestion => suggestion.text === suggestionText);
            if (tempDataList.length > 0) {
                setLng(tempDataList[0].center[0]);
                setLat(tempDataList[0].center[1]);
            }
            axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${suggestionText}.json?access_token=${accessesToken}`).then(res => {
                if (res.data) {
                setSuggestionList(res.data.features);
                } else {
                }
            })
        } else {
            setSuggestionList([]);
        }
    }, [suggestionText])
    useEffect(() => {
        if (lat !== null && lng !== null) {
            axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${accessesToken}`).then(res => {
                if (res.data) {
                const placeText = res.data.features.filter(feature => feature.id.includes('place'));
                if (suggestionText.length === 0) {
                    setSuggetionText(placeText[0].text)
                }
                } else {
                }
            })
        }
    }, [lng, lat])
    useEffect(() => {
        if (businessCategory) {
            setCategory(true)
        } else {
            setCategory(false)
        }
    }, [search])
    const updateBusinessCategory = (businessCategory) => {
        navigate('/onBoarding?businessCategory=' + businessCategory)
    }
    return (
        <div id="appCapsule" class="bg-primary" style={{height: '100vh'}}>
            <div class="login-form mt-1 container">
                <div class="section">
                    <img src={Logo} alt="image" class="form-image" />
                </div>
                <div class="section mt-1">
                    {category &&
                        <>
                            <h1 class="text-white">Store Onboarding</h1>
                            <h4 class="text-white">You are just one step away to start using POS for you business</h4>
                        </>
                    }
                    {!category &&
                        <>
                            <h1 class="text-white">Business Category</h1>
                            <h4 class="text-white">Select business category to get started</h4>
                        </>
                    }
                </div>
                <div class="section mt-1 mb-5">
                    {category &&
                        <form onSubmit={(e) => {createStore(e)}}>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="form-group boxed">
                                        <div class="input-wrapper">
                                            <input type="text" class="form-control" id="businessName" placeholder="Business Name" value={businessName} onChange={(e) => {setBusinessName(e.target.value)}} required />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12">
                                    <div class="form-group boxed">
                                        <div class="input-wrapper">
                                            <input type="number" min="0" class="form-control" id="contactNumber" placeholder="Contact Number" value={contactNumber} onChange={(e) => {setContactNumber(e.target.value)}} required />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12">
                                    <div class="form-group boxed">
                                        <input type="text" list='placesList' class="form-control w-100" name='city' autoComplete="city" placeholder="City" value={suggestionText} onChange={(e) => setSuggetionText(e.target.value)} />
                                        {/* <i class="input-icon">
                                            <ion-icon name="search-outline"></ion-icon>
                                        </i> */}
                                        {
                                            suggestionList.length > 0 &&
                                            <datalist id='placesList'>
                                            {
                                                suggestionList.map((item, i) => {
                                                return <option value={item.text} key={i}>{item.text}</option>
                                                })
                                            }
                                            </datalist>
                                        }
                                        </div>
                                </div>
                            </div>
                            {/* {
                                lng !== null && lat !== null && zoom !== null &&
                                <>{mapGet}</>
                            } */}

                            <div class="form-links mt-2">
                                <div>
                                    <Link to="/" class="text-white">Already Registered ?</Link>
                                </div>
                                <div>
                                    <Link to="/onBoarding" style={{color: '#e3c20e'}}>Switch Business Category</Link>
                                </div>
                            </div>

                            <div className="form-button-group container bg-primary" style={{paddingRight: '16px', paddingLeft: '16px'}}>
                                {!processingLoader &&
                                    <button className="btn btn-outline-light btn-block btn-lg" type="submit" style={{maxWidth: '500px'}}>
                                        Get Started
                                    </button>
                                }
                                {processingLoader &&
                                    <button className="btn btn-outline-light btn-block btn-lg" type="button" disabled style={{maxWidth: '500px'}}>
                                        <span className="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                                        Processing...
                                    </button>
                                }
                            </div>

                        </form>
                    }
                    {!category &&
                        <div class="row mt-4">
                            <div class="col-6 col-md-4 mb-1">
                                <button class="btn btn-block btn-outline-light" onClick={() => {updateBusinessCategory('cake-shop')}}>Cake Shop</button>
                            </div>
                            <div class="col-6 col-md-4 mb-1">
                                <button class="btn btn-block btn-outline-light" onClick={() => {updateBusinessCategory('shakes-and-mocktails')}}>Shakes & Mocktails</button>
                            </div>
                            <div class="col-6 col-md-4 mb-1">
                                <button class="btn btn-block btn-outline-light" onClick={() => {updateBusinessCategory('ice-cream-shop')}}>Ice Cream Shop</button>
                            </div>
                            <div class="col-6 col-md-4 mb-1">
                                <button class="btn btn-block btn-outline-light" onClick={() => {updateBusinessCategory('shawarma-shop')}}>Shawarma Shop</button>
                            </div>
                            <div class="col-6 col-md-4 mb-1">
                                <button class="btn btn-block btn-outline-light" onClick={() => {updateBusinessCategory('cafe')}}>Cafe</button>
                            </div>
                            <div class="col-6 col-md-4 mb-1">
                                <button class="btn btn-block btn-outline-light" onClick={() => {updateBusinessCategory('misal-shop')}}>Misal Shop</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {
    }
}
  
const mapDispatchToProps = {
    showAppToast
}

export default connect(mapStateToProps, mapDispatchToProps)(OnBoard)