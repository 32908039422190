import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    shopDetailModalVisible: false,
    salesData: null,
    shopDetailData: null
}

export const ShopDetailModalSlice = createSlice({
    name: 'shopDetail',
    initialState,
    reducers: {
      showShopDetailModal: (state, action) => {
        const {
            shopDetailModalVisible,
            salesData,
            shopDetailData
        } = action.payload
        state.shopDetailModalVisible = shopDetailModalVisible
        state.salesData = salesData
        state.shopDetailData = shopDetailData
      },
      hideShopDetailModal: (state) => {
        state.shopDetailModalVisible = false
      }
    }
})

export const { showShopDetailModal, hideShopDetailModal } = ShopDetailModalSlice.actions;


export default ShopDetailModalSlice.reducer