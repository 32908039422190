import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    notificationTopOffcanvasVisible: false,
}

export const NotificationTopOffcanvasSlice = createSlice({
    name: 'notificationTop',
    initialState,
    reducers: {
        showNotificationTopOffcanvas: (state, action) => {
        const {
            notificationTopOffcanvasVisible
        } = action.payload
        state.notificationTopOffcanvasVisible = notificationTopOffcanvasVisible
      },
      hideNotificationTopOffcanvas: (state) => {
        state.notificationTopOffcanvasVisible = false
      }
    }
})

export const { showNotificationTopOffcanvas, hideNotificationTopOffcanvas } = NotificationTopOffcanvasSlice.actions;


export default NotificationTopOffcanvasSlice.reducer