import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    quickAddVisible: false
}

export const quickAddOffcanvasSlice = createSlice({
    name: 'quickAdd',
    initialState,
    reducers: {
      showQuickAddOffcanvas: (state, action) => {
        const {
            quickAddVisible
        } = action.payload
        state.quickAddVisible = quickAddVisible
      },
      hideQuickAddOffcanvas: (state) => {
        state.quickAddVisible = false
      }
    }
})

export const { showQuickAddOffcanvas, hideQuickAddOffcanvas } = quickAddOffcanvasSlice.actions;


export default quickAddOffcanvasSlice.reducer