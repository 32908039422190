import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from "react-redux"
import Logo from "../../images/logo.svg"
import { setInvoiceID, setPaymentType, setBusinessData, setSyncCallback } from '../../../features/Data/dataSlice'
import { showOngoingOffcanvas } from '../../../features/Offcanvas/ongoingOffcanvasSlice'
import { hideMainMenuOffcanvas } from '../../../features/Offcanvas/mainMenuOffcanvasSlice'
import { triggerBusinessdDataCall } from '../../../features/utilities/generalSlice'
import { showSwitchBusinessModal } from '../../../features/Offcanvas/switchBusinessModalSlice'
import { showShopStatusModal } from '../../../features/Offcanvas/shopStatusModalSlice'
import { logout } from '../../../features/auth/authSlice'

const CheckoutOffcanvas = ({
    setInvoiceID,
    invoiceCount,
    showOngoingOffcanvas,
    mainMenuOffcanvasVisible,
    hideMainMenuOffcanvas,
    authToken,
    setBusinessData,
    triggerBusinessdDataCall,
    showSwitchBusinessModal,
    showShopStatusModal,
    businessData,
    logout,
    role,
    notificationBlinkType,
    currentBusinessIdData,
    setSyncCallback,
    syncActionType
}) => {
    console.log(currentBusinessIdData)
    const [headerTabName, setHeaderTabName] = useState('')
    const [queryString, setQueryString] = useState('')
    const [businessLogo, setBusinessLogo] = useState('')
    const [branchName, setBranchName] = useState('')
    const [businessName, setBusinessName] = useState('')
    const localStoragetToken = localStorage.getItem('token')
    const [branchStatus, setBranchStatus] = useState('')
    const tokenData = jwt_decode(localStoragetToken)
    let navigate = useNavigate()
    const search = window.location.pathname.split('/')
    const tempShopStatus = localStorage.getItem('shopStatus')
    useEffect(() => {
        setHeaderTabName(search[1])
        setQueryString(window.location.search)
    }, [search])
    const handleNewOrderSwitch = () => {
        setInvoiceID({invoiceID: null})
        setPaymentType({balanceAmount: 0})
        hideMainMenuOffcanvas()
        navigate('/newOrder')
    }
    const handleOrderSwitch = (ongoing) => {
        if (ongoing === null || ongoing === undefined || ongoing === 0) {
            hideMainMenuOffcanvas()
            navigate('/orders?tab=sales&category=all')
        }
    }
    const handleStateSwitch = (stateName) => {
        if (stateName === 'summary') {
            navigate('/summary')
            hideMainMenuOffcanvas()
        } else if (stateName === 'items') {
            navigate('/items')
            hideMainMenuOffcanvas()
        } else if (stateName === 'transactions') {
            navigate('/transactions?type=income&tcategory=all')
            hideMainMenuOffcanvas()
        } else if (stateName === 'manageItems') {
            navigate('/manageItems')
            hideMainMenuOffcanvas()
        } else if (stateName === 'storeConfig') {
            navigate('/storeConfig')
            hideMainMenuOffcanvas()
        } else if (stateName === 'customOrders') {
            navigate('/customOrders')
            hideMainMenuOffcanvas()
        } else if (stateName === 'adminDashboard') {
            navigate('/adminDashboard')
            hideMainMenuOffcanvas()
        } else if (stateName === 'items?tab=production') {
            navigate('/items?tab=production')
            hideMainMenuOffcanvas()
        }
    }
    useEffect(() => {
        const tokenData = jwt_decode(authToken)
        setTimeout(() => {
            triggerBusinessdDataCall({triggerFunction: false})
        }, 5000);
        if (businessData) {
            businessData.map((value, key) => {
                value.Branches.map((v,k) => {
                    if (value.ID === tokenData.userInfo.Business_ID && v.ID === tokenData.userInfo.DefaultLocationID) {
                        setBranchName(v.Name)
                        setBranchStatus(v.ShopStatus)
                        setBusinessName(value.Name)
                        setBusinessLogo(value.Logo)
                    }
                })
            })   
        }
    }, [mainMenuOffcanvasVisible])
    const handleBusinessSwitch = () => {
        showSwitchBusinessModal({switchBusinessModalVisible: true})
    }
    const handleShopStatusModal = () => {
        showShopStatusModal({shopStatusModalVisible: true})
        hideMainMenuOffcanvas()
    }

    const handleLogout = () => {
        setSyncCallback({syncCallback: true, syncActionType: 'logout'})
    }

    useEffect(() => {
        console.log(syncActionType)
        if (syncActionType == 'logout') {
            logout()
            setTimeout(() => {
                navigate('/')
                setSyncCallback({syncCallback: true, syncActionType: null})
            }, 1000)
        }
    }, [syncActionType])

    const handlePageSwitch = (switchTo) => {
        if (switchTo === 'addSingleStock') {
            navigate('/manageStock?action=addStock')
            hideMainMenuOffcanvas()
        } else if (switchTo === 'removeSingleStock') {
            navigate('/manageStock?action=removeStock')
            hideMainMenuOffcanvas()
        } else if (switchTo === 'storeConfig') {
            navigate('/storeConfig')
            hideMainMenuOffcanvas()
        } else if (switchTo === 'userLogin') {
            navigate('/userLogin')
            hideMainMenuOffcanvas()
        } else if (switchTo === 'stockReport') {
            navigate('/stockReport')
            hideMainMenuOffcanvas()
        }
    }
    const handleAddCustomOrder = (e) => {
        e.stopPropagation()
        hideMainMenuOffcanvas()
        if (currentBusinessIdData?.sidebarMenu?.customOrder?.visible) {
            navigate(currentBusinessIdData?.sidebarMenu?.customOrder?.path)
        } else {
            navigate('/customOrder')
        }
    }
    return (
        <div>
            <Offcanvas show={mainMenuOffcanvasVisible} placement={'start'} style={{transition: '0.5s'}}>
                {/* <Offcanvas.Header class="offcanvas-header" style={{paddingTop: '0px', paddingBottom: '0px'}} closeButton>
                    <Offcanvas.Title style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <img src={Logo} className="img-fluid" alt="Brand" width="90px" />
                    </Offcanvas.Title>
                </Offcanvas.Header> */}
                <Offcanvas.Body class="pt-0 offcanvas-body p-0">
                    <div class="row w-100">
                        <div class="col-9 col-md-9">
                            <div class="profileBox bg-primary" style={{position: 'inherit'}}>
                                {(businessLogo !== null && businessLogo !== undefined) &&
                                    <div class="image-wrapper py-2">
                                        <img src={businessLogo} alt="image" class="imaged rounded" style={{objectFit: 'cover', width: '34px', height: '34px'}} />
                                    </div>
                                }
                                <div class="in py-2">
                                    <strong style={{fontSize: '12px'}}>{businessName}</strong>
                                    {(tokenData?.userInfo?.RoleID !== '5') &&
                                        <div class="text-muted" style={{fontSize: '12px'}} onClick={handleBusinessSwitch}>
                                            <ion-icon name="location"></ion-icon>
                                            {branchName}&nbsp;<span class={(branchStatus === 'open') ? "badge bg-success fs-10" : "badge bg-danger fs-10"} style={{height: '15px'}}>{(branchStatus === 'open') ? 'Open' : 'Close'}</span>
                                        </div>
                                    }
                                    {(tokenData?.userInfo?.RoleID === '5') &&
                                        <div class="text-muted" style={{fontSize: '12px'}}>
                                            <ion-icon name="location"></ion-icon>
                                            {branchName}&nbsp;<span class={(branchStatus === 'open') ? "badge bg-success fs-10" : "badge bg-danger fs-10"} style={{height: '15px'}}>{(branchStatus === 'open') ? 'Open' : 'Close'}</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div class="col-3 col-md-3 d-flex align-items-center justify-content-end">
                            <button class="btn btn-sm btn-transparent p-0" onClick={() => {hideMainMenuOffcanvas()}}>
                                <ion-icon name="close" class="m-0 text-light"></ion-icon>
                            </button>
                        </div>
                    </div>
                    <div style={{overflow: 'auto', height: '85vh'}}>
                        <ul class="listview image-listview flush no-line">
                            {(role == 6) &&
                                <>
                                    {(currentBusinessIdData?.sidebarMenu?.hasOwnProperty('adminDashboard') && currentBusinessIdData?.sidebarMenu?.adminDashboard?.visible) &&
                                        <li>
                                            <a herf='javascript:void(0)' class={(headerTabName === 'adminDashboard') ? 'item webweb-bg-secondary pt-0 pb-0' : 'item pt-0 pb-0'} onClick={() => {handleStateSwitch('adminDashboard')}} style={{cursor: 'pointer'}}>
                                                <div class="icon-box bg-transparent">
                                                    <ion-icon name="speedometer-outline"></ion-icon>
                                                </div>
                                                <div class="in">
                                                    <div>Admin Dashboard</div>
                                                    <span class="text-muted"></span>
                                                </div>
                                            </a>
                                        </li>
                                    }
                                </>
                            }
                            {(currentBusinessIdData?.sidebarMenu?.hasOwnProperty('summary') && currentBusinessIdData?.sidebarMenu?.summary?.visible) &&
                                <li>
                                    <a herf='javascript:void(0)' class={(headerTabName === 'summary') ? 'item webweb-bg-secondary pt-0 pb-0' : 'item pt-0 pb-0'} onClick={() => {handleStateSwitch('summary')}} style={{cursor: 'pointer'}}>
                                        <div class="icon-box bg-transparent">
                                            <ion-icon name="storefront-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            <div>Summary</div>
                                            <span class="text-muted"></span>
                                        </div>
                                    </a>
                                </li>
                            }
                            {(currentBusinessIdData?.sidebarMenu?.hasOwnProperty('items') && currentBusinessIdData?.sidebarMenu?.items?.visible) &&
                                <li>
                                    <a href={'javascript:void(0)'} class={((headerTabName === 'items' || headerTabName === 'manageStock') && (!queryString.includes('production'))) ? 'item webweb-bg-secondary pt-0 pb-0 pe-2' : 'item pt-0 pb-0 pe-2'} onClick={() => {handleStateSwitch('items')}}>
                                        <div class="icon-box bg-transparent">
                                            <ion-icon name="cube-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            <div>Stocks</div>
                                            <span class="text-muted">
                                                {(notificationBlinkType == 'store' && (role == 5 || role == 10)) &&
                                                    <div class="spinner-grow text-danger" style={{height: '14px', width: '14px'}} role="status"></div>
                                                }
                                                {(notificationBlinkType == 'warehouse' && (role == 6 || role == 10)) &&
                                                    <div class="spinner-grow text-warning" style={{height: '14px', width: '14px'}} role="status"></div>
                                                }
                                            </span>
                                        </div>
                                    </a>
                                </li>
                            }
                            {(currentBusinessIdData?.sidebarMenu?.hasOwnProperty('orders') && currentBusinessIdData?.sidebarMenu?.orders?.visible) &&
                                <li>
                                    <a href="javascript:void(0)" onClick={() => {handleOrderSwitch(null)}} class={(headerTabName === 'orders') ? 'item webweb-bg-secondary pt-0 pb-0' : 'item pt-0 pb-0'}>
                                        <div class="icon-box bg-transparent">
                                            <ion-icon name="cart-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            <div>Orders</div>
                                            <span class="text-muted">
                                                {invoiceCount > 0 &&
                                                    <span class="badge bg-danger blink-ongoing-invoice">{invoiceCount}</span>
                                                }
                                            </span>
                                        </div>
                                    </a>
                                </li>
                            }
                            {(currentBusinessIdData?.sidebarMenu?.hasOwnProperty('transactions') && currentBusinessIdData?.sidebarMenu?.transactions?.visible) &&
                                <li>
                                    <a href={'javascript:void(0)'} class={(headerTabName === 'transactions') ? 'item webweb-bg-secondary pt-0 pb-0' : 'item pt-0 pb-0'} onClick={() => {handleStateSwitch('transactions')}}>
                                        <div class="icon-box bg-transparent">
                                            <ion-icon name="swap-vertical"></ion-icon>
                                        </div>
                                        <div class="in">
                                            <div>Finance</div>
                                            <span class="text-muted"></span>
                                        </div>
                                    </a>
                                </li>
                            }
                                {/* <li>
                                    <a href={'javascript:void(0)'} class={(headerTabName === 'items' && queryString.includes('production')) ? 'item webweb-bg-secondary pt-0 pb-0' : 'item pt-0 pb-0'} onClick={() => {handleStateSwitch('items?tab=production')}}>
                                        <div class="icon-box bg-transparent">
                                            <ion-icon name="file-tray-full-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            <div>Production</div>
                                            <span class="text-muted"></span>
                                        </div>
                                    </a>
                                </li> */}
                            {role != 6 && 
                                <>
                                    {(currentBusinessIdData?.sidebarMenu?.hasOwnProperty('newOrder') && currentBusinessIdData?.sidebarMenu?.newOrder?.visible) &&
                                        <li>
                                            <a href="javascript:void(0)" onClick={handleNewOrderSwitch} class={(headerTabName === 'newOrder') ? 'item webweb-bg-secondary pt-0 pb-0' : 'item pt-0 pb-0'}>
                                                <div class="icon-box bg-primary">
                                                    <ion-icon name="add-outline"></ion-icon>
                                                </div>
                                                <div class="in">
                                                    <div>New Order</div>
                                                    <span class="text-muted"></span>
                                                </div>
                                            </a>
                                        </li>
                                    }
                                </>
                            }
                        </ul>
                        <div class="listview-title m-0">
                            <span>Manage</span>
                        </div>
                        <ul class="listview image-listview flush no-line">
                            {(currentBusinessIdData?.sidebarMenu?.hasOwnProperty('customOrder') && currentBusinessIdData?.sidebarMenu?.customOrder?.visible) &&
                                <li>
                                    <a href="javascript:void(0)" class={(headerTabName === 'customOrders') ? 'item webweb-bg-secondary pt-0 pb-0 pe-2' : 'item pt-0 pb-0 pe-2'} style={{cursor: 'pointer'}} onClick={() => {handleStateSwitch('customOrders')}}>
                                        <div class="icon-box bg-transparent">
                                            <ion-icon name="cube-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            <div>Custom Orders</div>
                                            <span class="badge bg-success" onClick={(e) => {handleAddCustomOrder(e)}}>
                                                <ion-icon name="add-outline"></ion-icon> Add
                                            </span>
                                        </div>
                                    </a>
                                </li>
                            }
                            {(currentBusinessIdData?.sidebarMenu?.hasOwnProperty('manageItems') && currentBusinessIdData?.sidebarMenu?.manageItems?.visible) &&
                                <li>
                                    <a href="javascript:void(0)" class={(headerTabName === 'manageItems' || headerTabName === 'itemProfile') ? 'item webweb-bg-secondary pt-0 pb-0' : 'item pt-0 pb-0'} style={{cursor: 'pointer'}} onClick={() => {handleStateSwitch('manageItems')}}>
                                        <div class="icon-box bg-transparent">
                                            <ion-icon name="cube-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            <div>Items</div>
                                            <span class="text-muted"></span>
                                        </div>
                                    </a>
                                </li>
                            }
                        </ul>
                        {(currentBusinessIdData?.sidebarMenu?.hasOwnProperty('reports') && currentBusinessIdData?.sidebarMenu?.reports?.visible) &&
                            <div class="accordion-item">
                                <h2 class="accordion-header cursor-pointer">
                                    <div class="listview-title m-0 accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordion1">
                                        <span>Reports</span>
                                    </div>
                                </h2>
                                <div id="accordion1" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                    <div class="accordion-body p-0">
                                        <ul class="listview image-listview flush no-line">
                                            <li>
                                                <a href="javascript:void(0)" onClick={() => {handlePageSwitch('stockReport')}} class={(headerTabName === 'stockReport') ? 'webweb-bg-secondary item pt-0 pb-0' : 'item pt-0 pb-0'} style={{cursor: 'pointer'}}>
                                                    <div class="icon-box bg-transparent">
                                                        <ion-icon name="bar-chart-outline"></ion-icon>
                                                    </div>
                                                    <div class="in">
                                                        <div>Stock Report</div>
                                                        <span class="text-muted"></span>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)' class={'item pt-0 pb-0'} style={{cursor: 'pointer'}}>
                                                    <div class="icon-box bg-transparent">
                                                        <ion-icon name="pie-chart-outline"></ion-icon>
                                                    </div>
                                                    <div class="in">
                                                        <div>Sales Report</div>
                                                        <span class="text-muted"></span>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div class="sidebar-buttons" style={{height: '40px'}}>
                        <a href="javascript:void(0)" class="button" onClick={() => {handlePageSwitch('userLogin')}} style={{height: '40px'}}>
                            <ion-icon name="checkmark-circle-outline" class="fs-4"></ion-icon>
                        </a>
                        <a href="javascript:void(0)" class="button" onClick={() => {handlePageSwitch('storeConfig')}} style={{height: '40px'}}>
                            <ion-icon name="settings-outline" class="fs-4"></ion-icon>
                        </a>
                        <a href="javascript:void(0)" class="button" onClick={handleLogout} style={{height: '40px'}}>
                            <ion-icon name="log-out-outline" class="fs-4"></ion-icon>
                        </a>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

const mapStateToProps = ({ data, mainMenu, auth }) => {
    const {
        invoiceCount,
        businessData,
        notificationBlinkType,
        syncActionType
    } = data
    const {
        mainMenuOffcanvasVisible
    } = mainMenu
    const {
        authToken,
        role,
        currentBusinessIdData
    } = auth
    return {
        invoiceCount,
        mainMenuOffcanvasVisible,
        authToken,
        businessData,
        role,
        notificationBlinkType,
        currentBusinessIdData,
        syncActionType
    }
  }
  
  const mapDispatchToProps = {
    setInvoiceID,
    setPaymentType,
    showOngoingOffcanvas,
    hideMainMenuOffcanvas,
    setBusinessData,
    triggerBusinessdDataCall,
    showSwitchBusinessModal,
    showShopStatusModal,
    logout,
    setSyncCallback
  }

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutOffcanvas)