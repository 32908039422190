import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import jwt_decode from 'jwt-decode'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import axios from '../../AxiosConfig'
import moment from 'moment-timezone'
import NewOrderItem from './Components/NewOrderItem'
import { generalDataUpdate } from '../../features/utilities/generalSlice'
import ItemSizeOffcanvas from '../layout/Sidebar/ItemSizeOffcanvas'
import { itemsDataUpdate, setBranchID } from '../../features/Data/dataSlice'
import { hideAddRemoveStockModal } from '../../features/Offcanvas/addremoveStockModalSlice'
import { showInwardOutwardModal } from '../../features/Offcanvas/inwardOutwardModalSlice'


const AddRemoveStock = ({
  items,
  categories,
  topScrollValue,
  generalDataUpdate,
  checkoutItemList,
  authToken,
  stockItemList,
  stockActionType,
  categoryID,
  itemName,
  editStockData,
  itemsDataUpdate,
  businessData,
  buisness_ID,
  branchID,
  inwardOutwardDetailData,
  stockRequestID,
  stockActionButton,
  role,
  hideAddRemoveStockModal,
  showInwardOutwardModal,
  setBranchID
}) => {
  console.log(stockActionButton, inwardOutwardDetailData)
  const [actionType, setActionType] = useState('')
  const [stockActionInput, showStockActionInput] = useState('vendor')
  const [BgClass, setbgClass] = useState('')
  const [columnsData, setColumnsData] = useState([])
  const [columns, setColumns] = useState([])
  const [filteredCategory, setFilteredCategory] = useState('')
  const [allCategoryFlag, setAllCategoryFlag] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const [branchList, setBranchList] = useState([])
  const [disableStockAddButton, setDisableStockAddButton] = useState(false)
  const [onlyDate, setOnlyDate]  = useState()
  const [selectedBranchName, setSelectedBranchName]= useState('')
  const [selectedToName, setSelectedToName] = useState('')
  const [toBranchID, setToBranchID] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')

  const itemList = []
  const stateName = window.location.pathname.split('/')
  const search = useLocation().search
  const action = new URLSearchParams(search).get('action')
  // const requestActionType = new URLSearchParams(search).get('type')
  const requestActionType = stockActionType
  const targetTimezone = 'Asia/Kolkata'
  const newDate = moment().tz(targetTimezone).format('YYYY-MM-DD HH:mm:ss')
  const onlyDateTemp = moment().tz(targetTimezone).format('YYYY-MM-DD')
  const tokenData = jwt_decode(authToken)
  useEffect(() => {
    setOnlyDate(onlyDateTemp)
  }, [])
  useEffect(() => {
    setActionType(action)
  }, [search, stateName])
  const stockAction = new URLSearchParams(search).get('action')
  useEffect(() => {
    if (stateName[1] === 'manageStock') {
      if (stockAction === 'addStock') {
        setbgClass('text-warning')
      } else if (stockAction === 'removeStock') {
        setbgClass('text-danger')
      } else if (stockAction === 'transferOut') {
        setbgClass('text-pink')
      } else if (stockAction === 'request') {
        setbgClass('text-secondary')
      }
    } else {
      setbgClass('text-primary')
    }
  }, [search])
  let navigate = useNavigate()
  const buttonStyle = {
    buttonText: 'Place Order',
    buttonColor: 'success'
  }
  useEffect(() => {
    generalDataUpdate({ buttonStyle: buttonStyle })
  }, [])
  useEffect(() => {
    if (categories === null || categories === undefined || categories === '') {
      navigate('/load')
    }
  }, [categories])
  const handleStock = stockAction => {
    showStockActionInput(stockAction)
  }
  useEffect(() => {
    if (items) {
      let filtered
      if (categoryID !== 'all' && categoryID !== undefined) {
        setAllCategoryFlag(false)
        filtered = items.filter(item => categoryID === item.Item_CategoryID)
      } else {
        setAllCategoryFlag(true)
        filtered = items
      }
      if (itemName?.trim().length > 0)
        filtered = filtered.filter(
          item =>
            item?.ItemTitle?.toLowerCase()?.includes(
              itemName?.toLowerCase()?.trim()
            ) ||
            item?.AlternateText?.includes(itemName?.trim()) ||
            item?.Shortcode?.includes(itemName?.trim())
        )
      setFilteredCategory(filtered)
    }
  }, [categoryID, itemName, items])
  useEffect(() => {
    let tempColumns = []
    let rowData = []
    if (items === null || items === undefined || items === '') {
      navigate('/load')
    } else {
      if (filteredCategory) {
        filteredCategory.map((v, k) => {
          if (v.Stockable === 't') {
            let tempRowData = {
              id: v.ID,
              itemName: v.ItemTitle,
              total: 0,
              requestedStock: 0,
              salesQty: v.SalesQty,
              categoryID: v.Item_CategoryID,
              categoryName: v.Category
            }

            if (v.Sizes.length > 0) {
              v.Sizes.map((v1, k1) => {
                if (!tempColumns.includes(v1.SizeCategory)) {
                  tempColumns.push(v1.SizeCategory)
                }
                tempRowData[v1.SizeCategory] = v1.SalesQty
                tempRowData[v1.SizeCategory + 'ID'] = v1.ID
                tempRowData.total += parseInt(v1.SalesQty)
              })
            }
            rowData.push(tempRowData)
          }
        })
        for (let i = 0; i < editStockData.length; i++) {
          if (editStockData[i].Item_ID === editStockData[i].GroupItemID) {
            for (let j = 0; j < rowData.length; j++) {
              if (rowData[j].id == editStockData[i].GroupItemID) {
                rowData[j].requestedStock = parseInt(editStockData[i].quantity)
                break
              }
            }
          } else {
            for (let j = 0; j < rowData.length; j++) {
              if (rowData[j].id == editStockData[i].GroupItemID) {
                Object.keys(rowData[j]).map((k, v) => {
                  if (editStockData[i]['Item_ID'] == rowData[j][k]) {
                    let newKey = k.substring(0, k.length - 2)
                    newKey = newKey + 'RequestedStock'
                    rowData[j][newKey] = parseInt(editStockData[i].quantity)
                  }
                })
              }
            }
          }
        }
        setColumns(tempColumns)
        setColumnsData(rowData)
      }
    }
  }, [items, filteredCategory])
  console.log(inwardOutwardDetailData?.LocationID, tokenData.userInfo.DefaultLocationID)
  const updateReuqestedStock = (value, ID, sizeKey) => {
    let tempColumnsData = JSON.parse(JSON.stringify(columnsData))
    tempColumnsData.map((v, k) => {
      if (ID == v.id) {
        if (sizeKey == null) {
          tempColumnsData[k].requestedStock = parseInt(value)
          setColumnsData(tempColumnsData)
        } else {
          Object.keys(tempColumnsData[k]).map((k1, v1) => {
            if (sizeKey == k1) {
              let tempSizeStockData = JSON.parse(
                JSON.stringify(tempColumnsData[k])
              )
              tempSizeStockData[sizeKey] = parseInt(value)
              tempColumnsData[k] = tempSizeStockData
              setColumnsData(tempColumnsData)
            }
          })
        }
      }
    })
  }
  let stockObj = {
    Items: [],
    TotalQty: 0,
    Subtotal: 0,
    TotalTax: 0,
    Total: 0,
    DateTime: null,
    Currency: 'INR',
    Phone: '',
    Date: null,
    UserName: '',
    Business_ID: tokenData.userInfo.Business_ID,
    Location_ID: (branchID) ? branchID : tokenData.userInfo.DefaultLocationID,
    StockType: '',
    Customer_ID: 0
  }
  const submitForm = (submitType) => {
    setDisableStockAddButton(true)
    let tempBulkStockArr = []
    var form = document.querySelectorAll('#myForm input')
    var formData = {}
    for (var i = 0; i < form.length; i++) {
      var element = form[i]
      if (element.hasAttribute('data-id')) {
        var attributeName = element.getAttribute('data-id')
        var value = element.value
        formData[attributeName] = value
      }
      filteredCategory.map((v, k) => {
        if (v.Sizes.length > 0) {
          v.Sizes.map((v1, k1) => {
            if (v1.ID === attributeName) {
              tempBulkStockArr.push({
                Item_ID: attributeName,
                Quantity: value
              })
            }
          })
        } else {
          if (v.ID === attributeName) {
            tempBulkStockArr.push({
              Item_ID: attributeName,
              Quantity: value
            })
          }
        }
      })
    }
    let AddStockArr = []
    filteredCategory.map((v, k) => {
      tempBulkStockArr.map((v1, k1) => {
        if (v.Sizes.length > 0) {
          v.Sizes.map((v2, k2) => {
            if (v2.ID === v1.Item_ID) {
              if (stockActionType == 'removeStock' || stockActionButton == 'removeStock' || stockActionButton == 'wastage') {
                if (parseInt(v1.Quantity) <= parseInt(v2.SalesQty)) {
                  AddStockArr.push({
                    quantity: v1.Quantity,
                    MRP: v2.MRP,
                    Cost: v2.Cost,
                    ItemTitle: v2.ItemTitle,
                    Item_ID: v2.ID,
                    Taxes: v2.Taxes,
                    Status: false,
                    Image: v2.ImageURL,
                    AlternateText: v2.AlternateText,
                    GroupItemID: v.ID
                  })
                }
              } else {
                if (v1.Quantity > 0) {
                  AddStockArr.push({
                    quantity: v1.Quantity,
                    MRP: v2.MRP,
                    Cost: v2.Cost,
                    ItemTitle: v2.ItemTitle,
                    Item_ID: v2.ID,
                    Taxes: v2.Taxes,
                    Status: false,
                    Image: v2.ImageURL,
                    AlternateText: v2.AlternateText,
                    GroupItemID: v.ID
                  })
                }
              }
            }
          })
        } else {
          if (v.ID === v1.Item_ID) {
            if (v1.Quantity > 0) {
              AddStockArr.push({
                quantity: v1.Quantity,
                MRP: v.MRP,
                Cost: v.Cost,
                ItemTitle: v.ItemTitle,
                Item_ID: v.ID,
                Taxes: v.Taxes,
                Status: false,
                Image: v.ImageURL,
                AlternateText: v.AlternateText,
                GroupItemID: v.ID
              })
            }
          }
        }
      })
    })
    stockObj.Items = AddStockArr
    stockObj.DateTime = newDate
    stockObj.Date = onlyDate
    stockObj.StockRequestID = stockRequestID

    stockObj.StockType =
      editStockData.length > 0
        ? 'inTransit'
        : stockActionType
        ? stockActionType
        : 'addStock'
        if (submitType == 'modify') {
          stockObj.StockType = 'modifyRequest'
          stockObj.StockRequestID = stockRequestID
          stockObj.Location_ID = inwardOutwardDetailData?.Location_ID
        }
        if (submitType == 'modified') {
          stockObj.StockType = 'modifyRequest'
          stockObj.Location_ID = inwardOutwardDetailData?.Location_ID
        }
        if (submitType == 'production') {
          stockObj.StockType = 'production'
        }
        if (submitType == 'sendStock') {
          stockObj.StockType = 'sendStock'
          if (inwardOutwardDetailData) {
            stockObj.Location_ID = inwardOutwardDetailData?.Location_ID
          } else {
            if (typeof branchID === 'object') {
              stockObj.Location_ID = tokenData.userInfo.DefaultLocationID
            } else {
              stockObj.Location_ID = branchID
            }
          }
        }
        if (submitType == 'addStock') {
          stockObj.StockType = 'addStock'
          if (typeof branchID === 'object') {
            stockObj.Location_ID = tokenData.userInfo.DefaultLocationID
          } else {
            stockObj.Location_ID = branchID
          }
        }
        if (submitType == 'removeStock') {
          stockObj.StockType = 'removeStock'
          if (typeof branchID === 'object') {
            stockObj.Location_ID = tokenData.userInfo.DefaultLocationID
          } else {
            stockObj.Location_ID = branchID
          }
        }
        if (submitType == 'wastage') {
          stockObj.StockType = 'wastage'
          if (typeof branchID === 'object') {
            stockObj.Location_ID = tokenData.userInfo.DefaultLocationID
          } else {
            stockObj.Location_ID = branchID
          }
        }
        if (submitType == 'transferIn' || submitType == 'transferInTransit') {
          stockObj.StockType = submitType
          stockObj.Location_ID = inwardOutwardDetailData?.Location_ID
          stockObj.To_Location_ID = inwardOutwardDetailData?.To_Location_ID
        }
        if (stockObj.StockType == 'request') {
            stockObj.Location_ID = tokenData.userInfo.DefaultLocationID
        }
        if (submitType == 'transferStock') {
          stockObj.StockType = 'transferOut'
          if (typeof branchID === 'object') {
            stockObj.Location_ID = tokenData.userInfo.DefaultLocationID
          } else {
            stockObj.Location_ID = branchID
          }
          if (toBranchID) {
            stockObj.To_Location_ID = toBranchID
          } else {
            // alert('Please Select TO shop')
            setErrorMessage('Please Select TO shop')
            setDisableStockAddButton(false)
          }
        }
    if (submitType != 'modified') {
        if (stockObj.Items.length == 0) {
          setDisableStockAddButton(false)
          alert('Invalid Stock')
        }
        if (submitType == 'transferStock') {
          if(stockObj.To_Location_ID){
            if (stockObj.Items.length > 0) {
              axios.put('/Stock', stockObj).then(res => {
                if (res.data.message === 'Success') {
                  showInwardOutwardModal({inwardOutwardDetailData: null})
                  axios.get('/Items/business').then(res => {
                    if (res.data.message === 'Success') {
                      setDisableStockAddButton(false)
                      itemsDataUpdate({ items: res.data.data })
                      hideAddRemoveStockModal()
                      navigate('/items')
                    } else {
                    }
                  })
                } else {
                }
              })
            }
          } else {
            alert('Please Select TO shop')
            setErrorMessage('Please Select TO shop')
            setDisableStockAddButton(false)
          }
        } else {
          if (stockObj.Items.length > 0) {
            axios.put('/Stock', stockObj).then(res => {
              if (res.data.message === 'Success') {
                showInwardOutwardModal({inwardOutwardDetailData: null})
                axios.get('/Items/business').then(res => {
                  if (res.data.message === 'Success') {
                    setDisableStockAddButton(false)
                    itemsDataUpdate({ items: res.data.data })
                    hideAddRemoveStockModal()
                    navigate('/items')
                  } else {
                  }
                })
              } else {
              }
            })
          }
        }
    }
    if (submitType == 'modified') {
        if (stockObj.Items.length == 0) {
          setDisableStockAddButton(false)
          alert('Invalid Stock')
        }
        if (stockObj.Items.length > 0) {
          axios.post('/Stock/' + inwardOutwardDetailData?.ID, stockObj).then(res => {
            if (res.data.message === 'Success') {
              setDisableStockAddButton(false)
              // itemsDataUpdate({ items: res.data.data })
              showInwardOutwardModal({inwardOutwardDetailData: null})
              hideAddRemoveStockModal()
              navigate('/items')
            } else {
            }
          })
        }
    }
  }

  useEffect(() => {
  if (typeof branchID != 'object') {
    axios.get('/Items/business/' + branchID).then(res => {
      if (res.data.message === 'Success') {
        itemsDataUpdate({ items: res.data.data })
      } else {
      }
    })
  }
  }, [branchID])

  const searchInputRef = useRef(null)
  const [searchCount, setSearchCount] = useState(0)

  let searchText = ''
  const handleInputChange = () => {
    // Trigger find functionality when input changes
    searchText = searchInputRef.current.value
    setSearchValue(searchText)
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      const count = window.find(searchText)
      setSearchCount(count)
      // Prevent default behavior (e.g., form submission)
      event.preventDefault()
      // Trigger find functionality for next occurrence
      window.find(searchInputRef.current.value, false, false, true)
      setSearchCount(window.getSelection().anchorOffset + 1)
    } else if (event.key === 'ArrowUp') {
      // Move to the previous occurrence
      window.find(
        searchInputRef.current.value,
        false,
        false,
        true,
        false,
        false,
        true
      )
      setSearchCount(window.getSelection().anchorOffset + 1)
    } else if (event.key === 'ArrowDown') {
      // Move to the next occurrence
      window.find(searchInputRef.current.value, false, false, true)
      setSearchCount(window.getSelection().anchorOffset + 1)
    }
  }

  const categoryCounts = columnsData.reduce((acc, item) => {
    acc[item.categoryName] = (acc[item.categoryName] || 0) + 1;
    return acc;
  }, {});

  let previousCategory = '';

  useEffect(() => {
    const hash = window.location.hash.substring(1); // Get the id from the URL hash
    if (hash) {
        const element = document.getElementById(hash);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
  }, []);

  useEffect(() => {
    if (businessData) {
        businessData[0].Branches.map((v,k) => {
            if (v.ID == tokenData.userInfo.DefaultLocationID) {
                setSelectedBranchName(v.Name)           
            }
        })
    }
  }, [])
  const handleFromLocation = (branchData) => {
    if (branchData === 'all') {
    } else {
        setBranchID({branchID: branchData.ID})
        setSelectedBranchName(branchData.Name)
    }
  }
  const handleToLocation = (branchData) => {
        console.log(branchData.ID)
        setToBranchID(branchData.ID)
        setSelectedToName(branchData.Name)
  }
  useEffect(() => {
    if (businessData && buisness_ID) {
      setBranchList(businessData && businessData.filter((itm) =>  itm.ID === buisness_ID)[0]?.Branches)
    }
    if (inwardOutwardDetailData) {
      setSelectedToName(businessData && businessData[0].Branches.filter((itm) =>  itm.ID === inwardOutwardDetailData?.To_Location_ID)[0]?.Name)
    }
  }, [])
  return (
    <div>
      {/* <div class="extraHeader p-0" style={{height: '36px', zIndex: 10}}>
        <ul class="nav nav-tabs lined" role="tablist">
            <li class="nav-item">
                <a class="nav-link" style={{height: '36px'}} data-bs-toggle="tab" href="#inventoryStock" onClick={() => {navigate('/items')}} role="tab">
                    Stock
                </a>
            </li>
            <li class="nav-item">
                <Link to="/addRemoveStock" class="nav-link active" style={{height: '36px'}} href="#" role="tab">
                    Add / Remove / Request
                </Link>
            </li>
        </ul>
      </div> */}
      {/* <div id="appCapsule" class="pb-0" style={{paddingTop: '96px'}}> */}
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12 position-relative">
            <NewOrderItem
              itemCardDetail={items}
              searchWithButton
              itemCategories
              itemView
              pageName={'AddRemoveStock'}
              cardBg={''}
              categories={categories}
              countInRow={3}
            >
            </NewOrderItem>
              <div class="search-form ms-1 me-1 position-absolute" style={{top: '5px', left: 0}}>
                  <div class="form-group searchbox">
                  <input
                          type="text"
                          class="form-control"
                          ref={searchInputRef}
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDown}
                          placeholder="Search..."
                          style={{ borderRadius: '50px', width: '35%'}}
                          value={searchValue}
                      />
                  <i class="input-icon">
                      <ion-icon name="search-outline"></ion-icon>
                  </i>
                  </div>
              </div>
          </div>
          <div
            class="col-12 col-md-12 col-lg-12"
            style={{ marginBottom: '60px' }}
          >
            <div class="section full mt-4">
              {((stockActionButton == 'request' && role == 5 && (inwardOutwardDetailData == '' ||inwardOutwardDetailData == null || inwardOutwardDetailData == undefined))) &&
                <div class="d-flex align-items-center mb-1">
                  <h3 class="text-nowrap me-1 mb-0">Request Stock for </h3>
                  <input
                    type="date"
                    class="form-control form-control-sm ps-1 pe-1 w-auto"
                    id=""
                    value={onlyDate}
                    onChange={(e) => {setOnlyDate(e.target.value)}}
                    autocomplete="off"
                  />
                </div>
              }
              {(((stockActionButton == 'transferStock' || stockActionButton == 'transferOut' || stockActionButton == 'transferInTransit'))) &&
                <div class="d-flex mb-2">
                  <div class="d-flex align-items-center">
                    <h3 class="mb-0 me-1">From Shop</h3>
                    <div class="dropdown me-1">
                        <button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown">
                            <ion-icon name={"location-outline"}></ion-icon>
                            {selectedBranchName}
                        </button>
                        <div class="dropdown-menu pb-0">
                            {branchList && branchList.map((v,k) => {
                                return <a class="dropdown-item pt-0" href="javascript:void(0)" onClick={() => {handleFromLocation(v)}}> {v.Name}</a>
                            })}
                        </div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <h3 class="mb-0 me-1">To</h3>
                    <div class="dropdown me-1">
                        <button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown">
                            <ion-icon name={"location-outline"}></ion-icon>
                            {selectedToName}
                        </button>
                        <div class="dropdown-menu pb-0">
                            {branchList && branchList.map((v,k) => {
                                return <a class="dropdown-item pt-0" href="javascript:void(0)" onClick={() => {handleToLocation(v)}}> {v.Name}</a>
                            })}
                        </div>
                    </div>
                    <span class="text-danger fs-10">{errorMessage}</span>
                  </div>
                </div>
              }
              <div class="wide-block p-0">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead style={{ borderBottom: '2px solid black' }}>
                      <tr>
                        <th scope="col" class="fw-bold fs-6"></th>
                        <th scope="col" class="fw-bold fs-6">Item Title</th>
                        {/* <th scope="col" class="fw-bold fs-6">In Stock</th> */}
                        <th scope="col" class="fw-bold fs-6">Add</th>
                        {columns.map((v, k) => {
                          return (
                            <th scope="col" class="text-end fw-bold fs-6 text-nowrap">
                              {v}
                            </th>
                          )
                        })}
                      </tr>
                    </thead>
                    {/* <form onSubmit={() => {submitForm()}}> */}
                    <tbody id="myForm">
                      {columnsData.map((v, k) => {
                        const isNewCategory = previousCategory !== v.categoryName;
                        const isLastItemOfCategory = k === columnsData.length - 1 || columnsData[k + 1].categoryName !== v.categoryName;
                        if (isNewCategory) {
                            previousCategory = v.categoryName;
                        }
                        return (
                          <>
                            <tr style={{borderBottom: (isLastItemOfCategory) ? '1px solid' : '' }}>
                              {/* {k == 0 ?
                                <td class="ps-2 align-middle" rowSpan={3}>
                                  <div class="d-flex align-item-center fs-6 fw-bold">
                                    {v.categoryName}
                                  </div>
                                </td>
                              :
                                <>
                                  {v.categoryID == columnsData[k-1].categoryID ?
                                    <td class="ps-2 align-middle">
                                      <div class="d-flex align-item-center fs-6 fw-bold">
                                        {v.categoryName}
                                      </div>
                                    </td>
                                  :
                                    <td class="ps-2 align-middle">
                                      <div class="d-flex align-item-center fs-6 fw-bold">
                                        {v.categoryName}
                                      </div>
                                    </td>
                                  }
                                </>
                              } */}
                              {isNewCategory && (
                                  <td class="ps-2 align-middle text-center" id={v.categoryName} rowSpan={categoryCounts[v.categoryName]} style={{borderRight: '1px solid',borderBottom: '1px solid', backgroundColor: 'white'}}>
                                    <div class="fs-6 fw-bold text-nowrap">
                                      {v.categoryName}
                                    </div>
                                  </td>
                              )}
                              <td class="ps-2 align-middle">
                                <div class="d-flex align-item-center fs-6 fw-bold text-nowrap">
                                  {v.itemName}
                                </div>
                              </td>
                              {/* <td class="pb-0">
                                <div class="d-flex align-item-center">
                                  {v.total}
                                </div>
                              </td> */}
                              {!columns.some(key => v.hasOwnProperty(key)) ? (
                                <td class="p-0">
                                  <div class="form-group boxed">
                                    <div class="input-wrapper">
                                      <input
                                        type="number"
                                        class="form-control ps-1 pe-1 fs-5"
                                        placeholder="0"
                                        value={v.requestedStock}
                                        onChange={e => {
                                          updateReuqestedStock(
                                            e.target.value,
                                            v.id
                                          )
                                        }}
                                        id=""
                                        min="0"
                                        style={{ width: '100%' }}
                                        autocomplete="off"
                                        data-id={v.id}
                                        max={(stockActionType == 'removeStock' || stockActionType == 'wastage') ? v.salesQty : ''}
                                      />
                                      <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                      </i>
                                      <span
                                        class={(v.salesQty > 0) ? "badge bg-primary p-0" : "badge bg-danger p-0"}
                                        style={{
                                          height: '18px',
                                          width: '28px',
                                          position: 'absolute',
                                          fontSize: '12px',
                                          top: 0,
                                          right: 0,
                                          // transform: 'translate(113px, 0px)'
                                        }}
                                      >
                                        {v.salesQty}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              ) : (
                                <td class="pb-0 fw-bold">_</td>
                              )}
                              {columns.map((v1, k1) => {
                                return (
                                  <>
                                    {columns.some(key =>
                                      v.hasOwnProperty(key)
                                    ) ? (
                                      <td class="p-0">
                                        <div class="form-group boxed pe-1">
                                          <div
                                            class="input-wrapper"
                                            style={{ position: 'relative' }}
                                          >
                                            <input
                                              type="number"
                                              class="form-control ps-1 pe-1 fs-5"
                                              id=""
                                              min="0"
                                              max={(stockActionType == 'removeStock') ? v[v1] : ''}
                                              onChange={e => {
                                                updateReuqestedStock(
                                                  e.target.value,
                                                  v.id,
                                                  v1 + 'RequestedStock'
                                                )
                                              }}
                                              value={v[v1 + 'RequestedStock']}
                                              placeholder="0"
                                              style={{
                                                width: '100%'
                                              }}
                                              autocomplete="off"
                                              data-id={v[v1 + 'ID']}
                                            />
                                            <i class="clear-input">
                                              <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            {v[v1] > 0 && (
                                              <span
                                                class="badge bg-primary p-0"
                                                style={{
                                                  height: '18px',
                                                  width: '28px',
                                                  position: 'absolute',
                                                  fontSize: '12px',
                                                  top: 0,
                                                  right: 0
                                                }}
                                              >
                                                {v[v1]}
                                              </span>
                                            )}
                                            {v[v1] <= 0 && (
                                              <span
                                                class="badge bg-danger p-0"
                                                style={{
                                                  height: '18px',
                                                  width: '28px',
                                                  position: 'absolute',
                                                  fontSize: '12px',
                                                  top: 0,
                                                  right: 0
                                                }}
                                              >
                                                {v[v1]}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                    ) : (
                                      <td class="pb-0 fw-bold text-end">_</td>
                                    )}
                                  </>
                                )
                              })}
                            </tr>
                          </>
                        )
                      })}
                      {inwardOutwardDetailData?.CurrentStatus == inwardOutwardDetailData?.StockType &&
                        <>
                          {editStockData?.length <= 0 && (
                            <div class="fab-button text bottom-center responsive-bottom-fab">
                              {stockActionButton === undefined && (
                                <button
                                  class="fab fs-6 pe-2 ps-2 btn btn-primary"
                                  onClick={() => {
                                    submitForm((role == 5) ? 'request' : 'sendStock')
                                  }}
                                  style={{
                                    height: '40px',
                                    fontFamily: "'Inter', sans-serif"
                                  }}
                                  disabled={disableStockAddButton}
                                >
                                  {/* {stockActionButton === 'addStock' && (
                                    <>
                                      <ion-icon
                                        name="bag-add-outline"
                                        class="fs-6"
                                      ></ion-icon>
                                      Add Stock
                                    </>
                                  )} */}
                                  {/* {stockActionButton === 'removeStock' && (
                                    <>
                                      <ion-icon
                                        name="bag-remove-outline"
                                        class="fs-6"
                                      ></ion-icon>
                                      Remove Stock
                                    </>
                                  )}
                                  {stockActionButton === 'transferOut' && (
                                    <>
                                      <ion-icon
                                        name="swap-horizontal-outline"
                                        class="fs-6"
                                      ></ion-icon>
                                      Transfered
                                    </>
                                  )}
                                  {stockActionButton === 'request' && (
                                    <>
                                      <ion-icon
                                        name="bag-check-outline"
                                        class="fs-6"
                                      ></ion-icon>
                                      Request
                                    </>
                                  )} */}
                                  
                                    <>
                                      {role == 5 &&
                                        <>
                                          <ion-icon
                                            name="bag-check-outline"
                                            class="fs-6"
                                          ></ion-icon>
                                          Request Stock
                                        </>
                                      }
                                      {(role == 6 || role == 10) &&
                                        <>
                                          <ion-icon
                                            name="arrow-redo-outline"
                                            class="fs-6"
                                          ></ion-icon>
                                          Send Stock
                                        </>
                                      }
                                    </>
                                </button>
                              )}
                              {stockActionButton && (
                                <>
                                  {(stockActionButton === 'removeStock' || stockActionButton === 'request') &&
                                    <button
                                      class="fab fs-6 pe-2 ps-2 btn btn-success"
                                      onClick={() => {
                                        submitForm((stockActionButton == 'removeStock') ? 'removeStock' : '')
                                      }}
                                      style={{
                                        height: '40px',
                                        fontFamily: "'Inter', sans-serif"
                                      }}
                                      disabled={disableStockAddButton}
                                    >
                                      {stockActionButton === 'removeStock' && (
                                        <>
                                          <ion-icon
                                            name="bag-remove-outline"
                                            class="fs-6"
                                          ></ion-icon>
                                          Remove Stock
                                        </>
                                      )}
                                      {stockActionButton === 'request' && (
                                        <>
                                          <ion-icon
                                            name="bag-check-outline"
                                            class="fs-6"
                                          ></ion-icon>
                                          Request Stock
                                        </>
                                      )}
                                      {stockActionButton === 'addStock' &&
                                        <>
                                          <ion-icon
                                            name="bag-add-outline"
                                            class="fs-6"
                                          ></ion-icon>
                                          Add Stock
                                        </>
                                      }
                                    </button>
                                  }
                                  {(stockActionButton === 'addStock') &&
                                    <button
                                      class="fab fs-6 pe-2 ps-2 btn btn-success"
                                      onClick={() => {
                                        submitForm('sendStock')
                                      }}
                                      style={{
                                        height: '40px',
                                        fontFamily: "'Inter', sans-serif"
                                      }}
                                      disabled={disableStockAddButton}
                                    >
                                      <>
                                        <ion-icon
                                          name="bag-add-outline"
                                          class="fs-6"
                                        ></ion-icon>
                                        Add Stock
                                      </>
                                    </button>
                                  }
                                  {(stockActionButton === 'wastage') &&
                                    <button
                                      class="fab fs-6 pe-2 ps-2 btn btn-danger"
                                      onClick={() => {
                                        submitForm('wastage')
                                      }}
                                      style={{
                                        height: '40px',
                                        fontFamily: "'Inter', sans-serif"
                                      }}
                                      disabled={disableStockAddButton}
                                    >
                                      <>
                                        <ion-icon
                                          name="trash-outline"
                                          class="fs-6"
                                        ></ion-icon>
                                        Submit Wastage
                                      </>
                                    </button>
                                  }
                                  {(stockActionButton === 'transferStock') &&
                                    <button
                                      class="fab fs-6 pe-2 ps-2 btn btn-secondary"
                                      onClick={() => {
                                        submitForm('transferStock')
                                      }}
                                      style={{
                                        height: '40px',
                                        fontFamily: "'Inter', sans-serif"
                                      }}
                                      disabled={disableStockAddButton}
                                    >
                                      <>
                                        <ion-icon
                                          name="swap-horizontal-outline"
                                          class="fs-6"
                                        ></ion-icon>
                                        Transfer Stock
                                      </>
                                    </button>
                                  }
                                </>
                              )}
                            </div>
                          )}
                          {editStockData?.length > 0 && (
                            <>
                              {stockActionButton !== 'addStock' &&
                                <div class="fab-button text bottom-center responsive-bottom-fab d-flex">
                                  {stockActionButton != 'modified' &&
                                    <>
                                      {(stockActionButton === 'request') &&
                                        <>
                                        {(role == 6 || role == 10) &&
                                          <>
                                            <button
                                              class="fab fs-6 pe-2 ps-2 me-1 btn-secondary"
                                              onClick={() => {
                                                submitForm('modify')
                                              }}
                                              style={{
                                                height: '40px',
                                                fontFamily: "'Inter', sans-serif"
                                              }}
                                              disabled={disableStockAddButton}
                                            >
                                              <ion-icon name="create-outline" class="fs-6"></ion-icon>
                                              Modify
                                            </button>
                                            <button
                                              class="fab fs-6 pe-2 ps-2 btn-primary"
                                              onClick={() => {
                                                submitForm('sendStock')
                                              }}
                                              style={{
                                                height: '40px',
                                                fontFamily: "'Inter', sans-serif"
                                              }}
                                              disabled={disableStockAddButton}
                                            >
                                              <ion-icon name="arrow-redo-outline" class="fs-6"></ion-icon>
                                              Send Stock
                                            </button>
                                          </>
                                        }
                                          {/* <button
                                            class="fab fs-6 pe-2 ps-2 btn-success"
                                            onClick={() => {
                                              submitForm('production')
                                            }}
                                            style={{
                                              height: '40px',
                                              fontFamily: "'Inter', sans-serif"
                                            }}
                                            disabled={disableStockAddButton}
                                          >
                                            <ion-icon name="albums-outline" class="fs-6"></ion-icon>
                                            Production
                                          </button> */}
                                        </>
                                      }
                                      {(stockActionButton === 'modifyRequest') &&
                                        <>
                                        {(role == 6 || role == 10) &&
                                          <>
                                            <button
                                              class="fab fs-6 pe-2 ps-2 me-1 btn-secondary"
                                              onClick={() => {
                                                submitForm('modified')
                                              }}
                                              style={{
                                                height: '40px',
                                                fontFamily: "'Inter', sans-serif"
                                              }}
                                              disabled={disableStockAddButton}
                                            >
                                              <ion-icon name="create-outline" class="fs-6"></ion-icon>
                                              Modify
                                            </button>
                                            <button
                                              class="fab fs-6 pe-2 ps-2 me-1 btn-primary"
                                              onClick={() => {
                                                submitForm('sendStock')
                                              }}
                                              style={{
                                                height: '40px',
                                                fontFamily: "'Inter', sans-serif"
                                              }}
                                              disabled={disableStockAddButton}
                                            >
                                              <ion-icon name="arrow-redo-outline" class="fs-6"></ion-icon>
                                              Send Stock
                                            </button>
                                          </>
                                        }
                                          {/* <button
                                            class="fab fs-6 pe-2 ps-2 btn-success"
                                            onClick={() => {
                                              submitForm('production')
                                            }}
                                            style={{
                                              height: '40px',
                                              fontFamily: "'Inter', sans-serif"
                                            }}
                                            disabled={disableStockAddButton}
                                          >
                                            <ion-icon name="albums-outline" class="fs-6"></ion-icon>
                                            Production
                                          </button> */}
                                        </>
                                      }
                                      {(stockActionButton === 'inTransit' || stockActionButton === 'transferInTransit') &&
                                        <>
                                          {role == 5 &&
                                              <button
                                                class="fab fs-6 pe-2 ps-2 me-1 btn-success"
                                                onClick={() => {
                                                  submitForm((stockActionButton == 'transferInTransit') ? 'transferIn' : 'addStock')
                                                }}
                                                style={{
                                                  height: '40px',
                                                  fontFamily: "'Inter', sans-serif"
                                                }}
                                                disabled={disableStockAddButton}
                                              >
                                                <ion-icon name="checkmark-outline" class="fs-6"></ion-icon>
                                                Accept Stock
                                              </button>
                                          }
                                        </>
                                      }
                                      {(((stockActionButton === 'transferOut') && (parseInt(inwardOutwardDetailData?.Location_ID) == parseInt(tokenData.userInfo.DefaultLocationID)))) &&
                                        <>
                                          {role == 5 &&
                                              <button
                                                class="fab fs-6 pe-2 ps-2 me-1 btn-success"
                                                onClick={() => {
                                                  submitForm('transferInTransit')
                                                }}
                                                style={{
                                                  height: '40px',
                                                  fontFamily: "'Inter', sans-serif"
                                                }}
                                                disabled={disableStockAddButton}
                                              >
                                                <ion-icon name="arrow-redo-outline" class="fs-6"></ion-icon>
                                                Transfer Stock
                                              </button>
                                          }
                                        </>
                                      }
                                    </>
                                  }
                                  {/* {stockActionButton == 'modified' &&
                                    <button
                                      class="fab fs-6 pe-2 ps-2"
                                      onClick={() => {
                                        submitForm('modified')
                                      }}
                                      style={{
                                        height: '40px',
                                        fontFamily: "'Inter', sans-serif"
                                      }}
                                    >
                                      <ion-icon name="checkmark-done-outline" class="fs-6"></ion-icon>
                                      Modify
                                    </button>
                                  } */}
                                </div>
                              }
                            </>
                          )}
                        </>
                      }
                    </tbody>
                    {/* </form> */}
                  </table>
                </div>
              </div>
            </div>
          </div>
          <ItemSizeOffcanvas></ItemSizeOffcanvas>
        </div>
      {/* </div> */}
    </div>
  )
}

const mapStateToProps = ({ data, checkoutItems, auth, stock, inwardOutward }) => {
  const {
    items,
    categories,
    topScrollValue,
    stockActionType,
    categoryID,
    itemName,
    businessData,
    branchID,
    stockActionButton
  } = data
  const { checkoutItemList } = checkoutItems
  const { authToken, buisness_ID, role } = auth
  const { stockItemList, editStockData, stockRequestID } = stock
  const { inwardOutwardDetailData } = inwardOutward
  return {
    items,
    categories,
    topScrollValue,
    checkoutItemList,
    authToken,
    stockItemList,
    stockActionType,
    categoryID,
    itemName,
    editStockData,
    businessData,
    buisness_ID,
    branchID,
    inwardOutwardDetailData,
    stockRequestID,
    stockActionButton,
    role
  }
}

const mapDispatchToProps = {
  generalDataUpdate,
  itemsDataUpdate,
  hideAddRemoveStockModal,
  showInwardOutwardModal,
  setBranchID
}

export default connect(mapStateToProps, mapDispatchToProps)(AddRemoveStock)
