import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    imagePdfModalVisible: false
}

export const ImagePdfModalSlice = createSlice({
    name: 'imagePdf',
    initialState,
    reducers: {
        showImagePdfModal: (state, action) => {
        const {
            imagePdfModalVisible
        } = action.payload
        state.imagePdfModalVisible = imagePdfModalVisible
      },
      hideImagePdfModal: (state) => {
        state.imagePdfModalVisible = false
      }
    }
})

export const { showImagePdfModal, hideImagePdfModal } = ImagePdfModalSlice.actions;


export default ImagePdfModalSlice.reducer