import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import { setTempMRP, setTempCost } from '../../../features/Data/dataSlice'

const PricingComponent = ({
    tempMRP,
    setTempMRP,
    setTempCost,
    tempCost
}) => {
    const [MRP, setMRP] = useState(0)
    const [Cost, setCost] = useState(0)

    useEffect(() => {
        setMRP((tempMRP) ? tempMRP : 0)
        setCost((tempCost) ? tempCost : 0)
    }, [tempMRP, tempCost])

    const updateMRP = (mrp) => {
        setMRP(mrp)
        setTempMRP({tempMRP: mrp})
    }

    const updateCost = (cost) => {
        setCost(cost)
        setTempCost({tempCost: cost})
    } 
    return (
        <div class="row">
            <div class="col-6 col-md-6">
                <div class="form-group boxed">
                    <div class="input-wrapper">
                        <label class="form-label" for="mrp">MRP</label>
                        <input type="number" class="form-control" id="mrp" value={MRP} onChange={(e) => {updateMRP(e.target.value)}} placeholder="MRP" autocomplete="off" />
                        <i class="clear-input">
                            <ion-icon name="close-circle"></ion-icon>
                        </i>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-6">
                <div class="form-group boxed">
                    <div class="input-wrapper">
                        <label class="form-label" for="cost">Cost</label>
                        <input type="number" class="form-control" value={Cost} onChange={(e) => {updateCost(e.target.value)}} id="cost" placeholder="Cost" autocomplete="off" />
                        <i class="clear-input">
                            <ion-icon name="close-circle"></ion-icon>
                        </i>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12">
                <div class="form-group boxed">
                    <div class="input-wrapper">
                        <label class="form-label text-nowrap" for="mrp">MRP For</label>
                        <div class="row">
                            <div class="col-6 col-md-6 mb-1">
                                <label class="form-label text-nowrap" for="mrp">Counter Sale</label>
                                <input type="number" class="form-control ps-1 pe-1" id="mrp" placeholder="MRP" autocomplete="off" />
                            </div>
                            <div class="col-6 col-md-6 mb-1">
                                <label class="form-label text-nowrap" for="mrp">Dine In</label>
                                <input type="number" class="form-control ps-1 pe-1" id="mrp" placeholder="MRP" autocomplete="off" />
                            </div>
                            <div class="col-6 col-md-6 mb-1">
                                <label class="form-label text-nowrap" for="mrp">Pick Up</label>
                                <input type="number" class="form-control ps-1 pe-1" id="mrp" placeholder="MRP" autocomplete="off" />
                            </div>
                            <div class="col-6 col-md-6 mb-1">
                                <label class="form-label text-nowrap" for="mrp">Zomato / Swiggy</label>
                                <input type="number" class="form-control ps-1 pe-1" id="mrp" placeholder="MRP" autocomplete="off" />
                            </div>
                        </div>
                        
                        <i class="clear-input">
                            <ion-icon name="close-circle"></ion-icon>
                        </i>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12">
                <div class="form-group boxed">
                    <div class="input-wrapper">
                        <label class="form-label text-nowrap" for="cost">Cost For</label>
                        <div class="row">
                            <div class="col-6 col-md-6 mb-1">
                                <label class="form-label text-nowrap" for="cost">Counter Sale</label>
                                <input type="number" class="form-control ps-1 pe-1" id="cost" placeholder="Cost" autocomplete="off" />
                            </div>
                            <div class="col-6 col-md-6 mb-1">
                                <label class="form-label text-nowrap" for="cost">Dine In</label>
                                <input type="number" class="form-control ps-1 pe-1" id="cost" placeholder="Cost" autocomplete="off" />
                            </div>
                            <div class="col-6 col-md-6 mb-1">
                                <label class="form-label text-nowrap" for="cost">Pick Up</label>
                                <input type="number" class="form-control ps-1 pe-1" id="cost" placeholder="Cost" autocomplete="off" />
                            </div>
                            <div class="col-6 col-md-6 mb-1">
                                <label class="form-label text-nowrap" for="cost">Zomato / Swiggy</label>
                                <input type="number" class="form-control ps-1 pe-1" id="cost" placeholder="Cost" autocomplete="off" />
                            </div>
                        </div>
                        
                        <i class="clear-input">
                            <ion-icon name="close-circle"></ion-icon>
                        </i>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ data }) => {
    const {
        tempMRP,
        tempCost
    } = data
    return {
        tempMRP,
        tempCost
    }
}
  
const mapDispatchToProps = {
    setTempMRP,
    setTempCost
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingComponent)
