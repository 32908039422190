import React from 'react'
import { connect } from "react-redux"
import SearchWithButtons from './SearchWithButtons'
import ItemCategories from './ItemCategories'
import ItemsGridView from './ItemsGridView'
import ItemsListView from './ItemsListView'
import ItemsButtonView from './ItemsButtonView'
import ItemCategoriesGrid from './ItemCategoriesGrid'
import ItemCategoriesList from './ItemCategoriesList'
import FilterWithScroll from './FilterWithScroll'

const NewOrderItem = ({
    gridView,
    itemCardDetail,
    searchWithButton,
    itemCategories,
    itemView,
    pageName,
    cardBg,
    categories,
    countInRow,
    view,
    categoryName
}) => {
    return (
        <div class="p-0">
            {searchWithButton &&
                <div class="section full">
                    <div class="pt-1">
                        <SearchWithButtons pageName={pageName}></SearchWithButtons>
                        {/* <FilterWithScroll pageName={pageName}></FilterWithScroll> */}
                    </div>
                </div>
            }
            {/* {itemCategories &&
                <div class={(view === 'grid' || view === undefined) ? 'section mt-1 mb-4' : 'section mt-1 mb-4 d-block d-sm-none'}>
                    <ItemCategories categories={categories}></ItemCategories>
                </div>
            } */}
            {pageName === "AddRemoveStock" &&
                <ItemCategories categories={categories} pageName={pageName}></ItemCategories>
            }
            {pageName !== "AddRemoveStock" &&
                <>
                    {itemView &&
                        <div class="section full mt-1">
                            {(view === 'grid' || view === undefined) &&
                                <div class="row">
                                    <div class="col-12 col-md-12 mb-2">
                                        <ItemCategories categories={categories} pageName={pageName}></ItemCategories>
                                        {/* <ItemCategoriesGrid categories={categories} viewType={'grid'}></ItemCategoriesGrid> */}
                                    </div>
                                    <div class="col-12 col-md-12 custom-item-scroll-grid">
                                        <ItemsGridView itemCardDetail={itemCardDetail} cardBg={cardBg} countInRow={countInRow} pageName={pageName}></ItemsGridView>
                                    </div>
                                </div>
                            }
                            {view === 'list' &&
                                <div class="row">
                                    <div class="col-4 col-md-3 border-end custom-item-scroll-list">
                                        <ItemCategoriesList categories={categories} viewType={'list'}></ItemCategoriesList>
                                    </div>
                                    <div class="col-8 col-md-9 custom-item-scroll-list">
                                        <ItemsListView itemCardDetail={itemCardDetail} cardBg={cardBg} pageName={pageName}></ItemsListView>
                                    </div>
                                </div>  
                            }
                            {view === 'button' &&
                                <>
                                    <div class="row">
                                        {(categoryName === '' || categoryName === null || categoryName === undefined) &&
                                            <div class="col-12 col-md-12">
                                                <ItemCategoriesGrid categories={categories} viewType={'button'}></ItemCategoriesGrid>
                                            </div>
                                        }
                                        {(categoryName !== '' && categoryName !== null && categoryName !== undefined) &&
                                            <div class="col-12 col-md-12">
                                                <ItemsButtonView itemCardDetail={itemCardDetail} cardBg={cardBg} pageName={pageName}></ItemsButtonView>
                                            </div>
                                        }
                                    </div>
                                    {/* <div class="d-block d-sm-none">
                                        <ItemsButtonView itemCardDetail={itemCardDetail} cardBg={cardBg} pageName={pageName}></ItemsButtonView>
                                    </div> */}
                                </>
                            }
                        </div>
                    }
                </>
            }
        </div>
    )
}

const mapStateToProps = ({ general, data }) => {
    const {
        view
    } = general
    const {
        categoryName
    } = data
    return {
        view,
        categoryName
    }
  }
  
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(NewOrderItem)