import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    orderDetailModalVisible: false,
    orderDetaildata: null
}

export const OrderDetailModalSlice = createSlice({
    name: 'orderDetail',
    initialState,
    reducers: {
      showOrderDetailModal: (state, action) => {
        const {
            orderDetailModalVisible,
            orderDetaildata
        } = action.payload
        state.orderDetailModalVisible = orderDetailModalVisible
        state.orderDetaildata = orderDetaildata
      },
      hideOrderDetailModal: (state) => {
        state.orderDetailModalVisible = false
      }
    }
})

export const { showOrderDetailModal, hideOrderDetailModal } = OrderDetailModalSlice.actions;


export default OrderDetailModalSlice.reducer