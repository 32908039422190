import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    shopSummarySnapShotModalVisible: false,
    snapShotData: null
}

export const SnapShotSlice = createSlice({
    name: 'snapShot',
    initialState,
    reducers: {
        showSnapShotModal: (state, action) => {
            const {
                shopSummarySnapShotModalVisible,
                snapShotData
            } = action.payload
            state.shopSummarySnapShotModalVisible = shopSummarySnapShotModalVisible
            state.snapShotData = snapShotData
        },
        hideSnapShotModal: (state) => {
            state.shopSummarySnapShotModalVisible = false
        }
    }
})

export const { showSnapShotModal, hideSnapShotModal } = SnapShotSlice.actions;


export default SnapShotSlice.reducer