import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { useNavigate } from 'react-router'
import jwt_decode from 'jwt-decode'
import axios from '../../../AxiosConfig'
import { setBusinessData } from '../../../features/Data/dataSlice'
import moment from 'moment-timezone'
import ItemsListView from '../../Pages/Components/ItemsListView'
import { hideShopStatusModal } from '../../../features/Offcanvas/shopStatusModalSlice'
import CategorySummaryTable from '../../Pages/BitComponents/CategorySummaryTable'
import OrderTypeSummaryTable from '../../Pages/BitComponents/OrderTypeSummaryTable'
import PaymentMethodSummaryTable from '../../Pages/BitComponents/PaymentMethodSummaryTable'
import CategorySizeSummaryTable from '../../Pages/BitComponents/CategorySizeSummaryTable'
import Items from '../../Pages/Items'

const ShopStatusModal = ({
    authToken,
    setBusinessData,
    items,
    shopStatusModalVisible,
    hideShopStatusModal,
    liveShopStatus,
    businessData,
    branchID,
    configObj,
    currentBusinessIdData
}) => {
    const [branchName, setBranchName] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [businessLogo, setBusinessLogo] = useState('')
    const [summaryData, setSummaryData] = useState(null)
    // const [activeTab, setActiveTab] = useState('summary')
    const [todaysDate, setTodaysDate] = useState(new Date().toISOString().substr(0, 10))
    const targetTimezone = 'Asia/Kolkata'
    const currentTime = moment().tz(targetTimezone).format('HH:mm:ss')
    const [localTime, setLocalTime] = useState(currentTime)
    const [tempTodaysDate, setTempTodaysDate] = useState(moment().tz(targetTimezone).format('DD-MM-YYYY'))
    const localStoragetToken = localStorage.getItem('token')
    const tokenData = jwt_decode(localStoragetToken)
    const [userName, setUserName] = useState('')
    const [shopCash, setShopCash] = useState(null)
    const [shopRemark, setShopRemark] = useState('')
    const [nameAlert, showNameAlert] = useState(false)
    const [cashAlert, showCashAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [currentShopStatus, setCurrentShopStatus] = useState('')
    const [denominationArr, setDenominationArr] = useState([{
        'amount': 500,
        'count': 0,
        'total': 0
    },
    {
        'amount': 200,
        'count': 0,
        'total': 0
    },
    {
        'amount': 100,
        'count': 0,
        'total': 0
    },
    {
        'amount': 50,
        'count': 0,
        'total': 0
    },
    {
        'amount': 20,
        'count': 0,
        'total': 0
    },
    {
        'amount': 10,
        'count': 0,
        'total': 0
    },
    {
        'amount': 5,
        'count': 0,
        'total': 0
    },
    {
        'amount': 2,
        'count': 0,
        'total': 0
    },
    {
        'amount': 1,
        'count': 0,
        'total': 0
    }])
    const [grandTotal, setGrandTotal] = useState(0)
    const [sizeWiseData, setSizeWiseData] = useState([])
    const [categoryWiseData, setCategoryWiseData] = useState([])
    const [filteredCategory, setFilteredCategory] = useState('')
    const [finalTotalCount, setFinalTotalCount] = useState({'Other': 0})
    const [currentStockColumnsData, setCurrentStockColumnsData] = useState([])
    const [currentStockColumns, setCurrentStockColumns] = useState([])
    let previousCategory = '';
    const GetOrderdate = () => {
        const currentDate = new Date()
        const hour = currentDate.getHours();
        if (hour < 3) {
            const previousDay = new Date(currentDate.setDate(currentDate.getDate()));
            return previousDay.toISOString().split('T')[0];
        } else {
            return currentDate.toISOString().split('T')[0];
        }
    }
    useEffect(() => {
        const tokenData = jwt_decode(authToken)
        if (businessData) {
            businessData.map((value, key) => {
                value.Branches.map((v,k) => {
                    if (value.ID === tokenData.userInfo.Business_ID && v.ID === tokenData.userInfo.DefaultLocationID) {
                        setBranchName(v.Name)
                        setBusinessName(value.Name)
                        setBusinessLogo(value.Logo)
                    }
                })
            })   
        }
    }, [businessData, authToken])
    let navigate = useNavigate()
    let tempShopStatus = localStorage.getItem('shopStatus') 
    useEffect(() => {
        axios.get('/Reports/dashboardSummary').then(res => {
            if (res.data.message === 'Success') {
                setSummaryData(res.data.data)
            } else {
            }
        }).catch(err => {
        });
    }, [])

    const handleShopStatus = (e, shopStatus) => {
        let tempSnapShot = []
        if (items) {
            items.map((v,k) => {
                if (v.Sizes.length > 0) {
                    v.Sizes.map((v1,k1) => {
                        if (v1.Stockable === 't') {
                            if (parseInt(v1.SalesQty) > 0) {
                                const tempObj = {
                                    'ID': v1.ID,
                                    'Stock': v1.SalesQty,
                                    'Name': v1.ItemTitle
                                }
                                tempSnapShot.push(tempObj)
                            }
                        }
                    })
                } else {
                    if (v.Stockable === 't') {
                        if (parseInt(v.SalesQty) > 0) {
                            const tempObj = {
                                'ID': v.ID,
                                'Stock': v.SalesQty,
                                'Name': v.ItemTitle
                            }
                            tempSnapShot.push(tempObj)
                        }
                    }
                }
            })
        }
        e.preventDefault()
        // if (userName !== '' || shopCash !== null) {
            let data = {
                "Summary":{
                    'summaryData': summaryData,
                    'sizeWiseData': sizeWiseData,
                    'categoryWiseData': categoryWiseData
                },
                "Cash": shopCash,
                "Remark": shopRemark,
                "Name": userName,
                "LocalTimeStamp": moment().tz(targetTimezone).format('HH:mm:ss'),
                "ClosingStock": tempSnapShot,
                "Status": shopStatus,
                "Date": todaysDate,
                "Denomination": denominationArr
            }
            console.log(data)
            axios.put('/OpeningClosing', data).then(res => {
                if (res.data.message === 'Success') {
                    if (typeof(res.data.data) === 'string') {
                        setAlertMessage(res.data.data)
                    } else {
                        hideShopStatusModal()
                    }
                    axios.get('/OpeningClosing/getShopStatus/' + new Date().toISOString().substr(0, 10)).then(res => {
                        if (res.data.message === 'Success') {
                            setCurrentShopStatus(res.data.data)
                            localStorage.setItem('shopStatus', res.data.data)
                            if (res.data.data === '' || res.data.data === 'Close') {
                                // localStorage.clear()
                                // window.location.reload()
                                localStorage.setItem('shopStatus', 'Close')
                                hideShopStatusModal()
                                // logout()
                                // setTimeout(() => {
                                //     navigate('/')
                                // }, 1000)
                            } else {
                                localStorage.setItem('shopStatus', 'Open')
                                hideShopStatusModal()
                            }
                        } else {
                            // showAppToast({ toastMessage: 'Something went wrong', background: 'danger', icon: 'close', position: 'center', timeout: 1000 })
                        }
                    }).catch(err => {
                        // showAppToast({ toastMessage: 'Something went wrong', background: 'danger', icon: 'close', position: 'center', timeout: 1000 })
                    })
                } else {
                    // showAppToast({ toastMessage: 'Something went wrong', background: 'danger', icon: 'close', position: 'center', timeout: 1000 })
                }
            }).catch(err => {
                // showAppToast({ toastMessage: 'Something went wrong', background: 'danger', icon: 'close', position: 'center', timeout: 1000 })
            });
        // } else {
        //     if (userName === '') {
        //         showNameAlert(true)
        //     } else if (shopCash === null) {
        //         showCashAlert(true)
        //     }
        // }   
    }

    useEffect(() => {
        if (liveShopStatus === 'Open') {
            setAlertMessage('Shop Already Opened')
        } else if (liveShopStatus === 'Close') {
            setAlertMessage('Shop Already Closed')
        }
    }, [liveShopStatus])

    const handleName = (userName) => {
        setUserName(userName)
        if (userName.length !== 0) {
            showNameAlert(false)
        }
    }

    const handleCash = (shopCash) => {
        setShopCash(shopCash)
        if (shopCash.length !== 0) {
            showCashAlert(false)
        } else {
            setShopCash(null)
        }
    }

    const handleRemark = (shopRemark) => {
        setShopRemark(shopRemark)
    }

    const handleContinue = () => {
        setCurrentShopStatus(liveShopStatus)
    }

    useEffect(() => {
        if (currentShopStatus === 'Close') {
            localStorage.setItem('shopStatus', 'Close')
        } else if (currentShopStatus === 'Open') {
            hideShopStatusModal()
            localStorage.setItem('shopStatus', 'Open')
        }
    }, [currentShopStatus])

    let tempGrandTotal = 0
    const handleDenomination = (amount, count, index) => {
        let tempDenominationObj = JSON.parse(JSON.stringify(denominationArr))
        tempDenominationObj[index].count = parseInt(count)
        tempDenominationObj[index].total = parseInt(amount)*parseInt(count)
        tempDenominationObj.map((v,k) => {
            tempGrandTotal += v.total
        })
        setGrandTotal(tempGrandTotal)
        setDenominationArr(tempDenominationObj)
    }

    useEffect(() => {
        axios.get('/Reports/viewDailyCounters/' + GetOrderdate() + '/' + GetOrderdate() + '/' + branchID).then(res => {
            if (res.data.message === 'Success') {
                setSizeWiseData(res.data.data)
            }
        })
        axios.get('/Reports/getSaleCategoryWise/' + GetOrderdate() + '/' + GetOrderdate() + '/' + branchID).then(res => {
            if (res.data.message === 'Success') {
                setCategoryWiseData(res.data.data)
            }
        })
    }, [branchID])
    useEffect(() => {
        let tempColumns = [{
            id: 1,
            name: "Item Name",
            selector: (row) => row.itemName,
            sortable: true,
            reorder: true,
            cell: row => <span style={{ fontWeight: 'bold' }}>{row.itemName}</span>,
        } ,{
            id: 2,
            name: "Other",
            selector: (row) => row.total,
            sortable: true,
            reorder: true
        }]
        let tempColumnsData = []
        let sizeType = []
        if (filteredCategory) {
            let totalCount = {'Other': 0}
            filteredCategory.map((v,k) => {
                if (configObj?.Config?.inStock) {
                    if (v.TotalSaleQty > 0) {
                        let tempRowData = {
                            id: v.ID,
                            'Item Name': v.ItemTitle,
                            'Other': '--',
                            categoryID: v.Item_CategoryID,
                            categoryName: v.Category
                        }
                        if (v.Sizes.length > 0) {
                            v.Sizes.map((v1,k1) => {
                                if (!totalCount.hasOwnProperty(v1.SizeCategory)) {
                                    totalCount[v1.SizeCategory] = parseInt(v1.SalesQty)
                                } else {
                                    totalCount[v1.SizeCategory] += parseInt(v1.SalesQty)
                                }
                                if (!sizeType.includes(v1.SizeCategory)) {
                                    let tempNewColumn = {
                                        id: k + 3,
                                        name: v1.SizeCategory,
                                        selector: (row) => row[v1.SizeCategory],
                                        sortable: true,
                                        reorder: true
                                    }
                                    tempColumns.push(tempNewColumn)
                                    sizeType.push(v1.SizeCategory)
                                }
                                tempRowData[v1.SizeCategory] = v1.SalesQty 
                                tempRowData['Other'] = '--' 
                            })
                        } else {
                            totalCount['Other'] += parseInt(v.SalesQty)
                            tempRowData['Other'] = v.SalesQty 
                        }
                        tempColumnsData.push(tempRowData)
                    }
                } else {
                    let tempRowData = {
                        id: v.ID,
                        'Item Name': v.ItemTitle,
                        'Other': '--',
                        categoryID: v.Item_CategoryID,
                        categoryName: v.Category
                    }
                    if (v.Sizes.length > 0) {
                        v.Sizes.map((v1,k1) => {
                            if (!sizeType.includes(v1.SizeCategory)) {
                                let tempNewColumn = {
                                    id: k + 3,
                                    name: v1.SizeCategory,
                                    selector: (row) => row[v1.SizeCategory],
                                    sortable: true,
                                    reorder: true
                                }
                                tempColumns.push(tempNewColumn)
                                sizeType.push(v1.SizeCategory)
                            }
                            tempRowData[v1.SizeCategory] = v1.SalesQty 
                            tempRowData['Other'] = '--' 
                        })
                    } else {
                        tempRowData['Other'] = v.SalesQty 
                    }
                    tempColumnsData.push(tempRowData)
                }
            })
            setFinalTotalCount(totalCount)
        }
        setCurrentStockColumns(tempColumns)
        setCurrentStockColumnsData(tempColumnsData)
        if (items) {
            setFilteredCategory(items)
        }
    }, [shopStatusModalVisible])
    const categoryCounts = currentStockColumnsData.reduce((acc, item) => {
        acc[item.categoryName] = (acc[item.categoryName] || 0) + 1;
        return acc;
    }, {});
    return (
        <div>
            <Modal show={shopStatusModalVisible} centered={true} size="lg" backdrop="static" onHide={() => {hideShopStatusModal()}}>
                <Modal.Header closeButton style={{padding: '8px', paddingTop: '0px', paddingBottom: '0px', display: 'flex', justifyContent: 'center'}}>
                    <div class="row w-100">
                        <ul class="listview image-listview flush">
                            <li>
                                <div class="item p-0 ps-1">
                                    {(businessLogo !== null && businessLogo !== undefined) &&
                                        <img src={businessLogo} alt="image" class="image rounded-0" />
                                    }
                                    {(businessLogo === null || businessLogo === undefined) &&
                                        <ion-icon name="storefront-outline" class="me-1" style={{fontSize: '32px'}}></ion-icon>
                                    }
                                    <div class="in">
                                        <div style={{lineHeight: 1.2}}>
                                            {businessName}
                                            <footer><ion-icon name="location-outline" class="me-0"></ion-icon>&nbsp;{branchName}</footer>
                                        </div>
                                    </div>
                                    {/* <span class="text-muted">
                                        <a href="javascript:void(0)">
                                            <ion-icon name="close-outline" class="fs-2"></ion-icon>
                                        </a>
                                    </span> */}
                                </div>
                            </li>
                        </ul>
                    </div>
                </Modal.Header>
                <Modal.Body class="modal-body ps-0 pe-0 pt-0" style={{position: 'relative'}}>
                    {/* <div style={{display: 'none'}}>
                        <Items></Items>
                    </div> */}
                    {tempShopStatus == 'Open' &&
                        <ul class="nav nav-tabs lined" role="tablist" style={{position: 'absolute', top: 0, left: 0, right: 0, borderBottom: '1px solid gainsboro'}}>
                            <li class="nav-item">
                                <a class="nav-link active" data-bs-toggle="tab" href="#openingClosing" role="tab" style={{height: '34px'}}>
                                    {tempShopStatus == 'Close' &&
                                        <span>Opening</span>
                                    }
                                    {tempShopStatus == 'Open' &&
                                        <span>Closing</span>
                                    }
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#summary" role="tab" style={{height: '34px'}}>
                                    Summary
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#stock" role="tab" style={{height: '34px'}}>
                                    Stock
                                </a>
                            </li>
                        </ul>
                    }
                    {!alertMessage &&
                        <div class="section" style={{marginTop: (tempShopStatus == 'Open') ? '50px' : ''}}>
                            <div class="tab-content mt-2">
                                <div class="tab-pane fade show active" id="openingClosing" role="tabpanel">
                                    <div class="row">
                                        <div class="col-4 col-md-4">
                                            <div class="card border shadow-none">
                                                <div class="card-body p-0 pb-1 pt-1 d-flex justify-content-center">
                                                    <div class="row ps-1">
                                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center justify-content-center">
                                                            <p class="card-text m-0 text-secondary fs-10">Today's Date</p>
                                                        </div>
                                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                                            <h3 class="m-0 fs-6">{tempTodaysDate}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4 col-md-4">
                                            <div class="card border shadow-none">
                                                <div class="card-body p-0 pb-1 pt-1 d-flex justify-content-center">
                                                    <div class="row ps-1">
                                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center justify-content-center">
                                                            <p class="card-text m-0 text-secondary fs-10">Shop Status</p>
                                                        </div>
                                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                                            {tempShopStatus === 'Close' &&
                                                                <h3 class="m-0 fs-6 text-danger">CLOSED</h3>
                                                            }
                                                            {tempShopStatus === 'Open' &&
                                                                <h3 class="m-0 fs-6 text-success">OPENED</h3>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4 col-md-4">
                                            <div class="card border shadow-none">
                                                <div class="card-body p-0 pb-1 pt-1 d-flex justify-content-center">
                                                    <div class="row ps-1">
                                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center justify-content-center">
                                                            <p class="card-text m-0 text-secondary fs-10">Time</p>
                                                        </div>
                                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                                            <h3 class="m-0 fs-6">{moment().tz(targetTimezone).format('HH:mm:ss')}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <form>
                                        <div class="row">
                                            <div class="col-6 col-md-6 col-lg-6">
                                                <div class="form-group boxed">
                                                    <div class="input-wrapper">
                                                        <label class="form-label" for="name">Name</label>
                                                        {/* <input type="text" class="form-control pe-1" id="name" placeholder="Name" value={userName} autocomplete="off" required onChange={(e) => {handleName(e.target.value)}} />
                                                        <i class="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i> */}
                                                        

                                                        <div class="form-group searchbox">
                                                            <input type="text" class="form-control pe-1 ps-4" id="name" placeholder="Name" value={userName} autocomplete="off" required onChange={(e) => {handleName(e.target.value)}} />
                                                            <i class="input-icon h-100">
                                                                <ion-icon name="person-outline" class="fs-6 text-dark"></ion-icon>
                                                            </i>
                                                            {nameAlert &&
                                                                <span class="text-danger fs-10">Enter Name</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-6 col-lg-6">
                                                <div class="form-group boxed">
                                                    <div class="input-wrapper">
                                                        {tempShopStatus === 'Close' &&
                                                            <label class="form-label" for="closingOpening">Opening Cash</label>
                                                        }
                                                        {tempShopStatus === 'Open' &&
                                                            <label class="form-label" for="closingOpening">Closing Cash</label>
                                                        }
                                                        {/* <input type="number" min="0" class="form-control pe-1" id="closingOpening" value={shopCash} placeholder={(tempShopStatus === 'Close') ? "Opening Cash" : "Closing Cash"} onChange={(e) => {handleCash(e.target.value)}} autocomplete="off" required />
                                                        <i class="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i> */}
                                                        
                                                        <div class="form-group searchbox">
                                                            <input type="number" min="0" class="form-control pe-1 ps-4" id="closingOpening" value={shopCash} placeholder={(tempShopStatus === 'Close') ? "Opening Cash" : "Closing Cash"} onChange={(e) => {handleCash(e.target.value)}} autocomplete="off" required />
                                                            <i class="input-icon h-100">
                                                                <ion-icon name="cash-outline" class="fs-6 text-dark"></ion-icon>
                                                            </i>
                                                            {cashAlert &&
                                                                <span class="text-danger fs-10">Enter Cash</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {currentBusinessIdData?.dinomination &&
                                                <div class="col-12 col-md-12 col-lg-12 mt-2 mb-2">
                                                    <div class="table-responsive border" style={{borderRadius: '7px'}}>
                                                        <table class="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" class="fw-bold fs-5">Amount</th>
                                                                    <th scope="col" class="fw-bold fs-5"></th>
                                                                    <th scope="col" class="fw-bold fs-5 text-end">Count</th>
                                                                    <th scope="col" class="fw-bold fs-5 text-end">Total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td class="fw-bold fs-5 align-middle">₹500</td>
                                                                    <td class="align-middle">X</td>
                                                                    <td class="p-0 text-end">
                                                                        <div class="form-group boxed">
                                                                            <div class="input-wrapper d-flex justify-content-end">
                                                                                <input type="number" class="form-control text-end pe-0 fs-5" style={{height: '38px', width: '120px'}} id="number" placeholder="" onChange={(e) => {handleDenomination('500', e.target.value, 0)}} value={denominationArr[0].count} autocomplete="off" min="0" />
                                                                                <i class="clear-input">
                                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                                </i>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="fw-bold text-end fs-5 align-middle">₹{denominationArr[0].total}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="fw-bold fs-5 align-middle">₹200</td>
                                                                    <td class="align-middle">X</td>
                                                                    <td class="p-0 text-end">
                                                                        <div class="form-group boxed">
                                                                            <div class="input-wrapper d-flex justify-content-end">
                                                                                <input type="number" class="form-control text-end pe-0 fs-5" style={{height: '38px', width: '120px'}} id="number" placeholder="" onChange={(e) => {handleDenomination('200', e.target.value, 1)}} value={denominationArr[1].count} autocomplete="off" min="0" />
                                                                                <i class="clear-input">
                                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                                </i>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="fw-bold text-end fs-5 align-middle">₹{denominationArr[1].total}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="fw-bold fs-5 align-middle">₹100</td>
                                                                    <td class="align-middle">X</td>
                                                                    <td class="p-0 text-end">
                                                                        <div class="form-group boxed">
                                                                            <div class="input-wrapper d-flex justify-content-end">
                                                                                <input type="number" class="form-control text-end pe-0 fs-5" style={{height: '38px', width: '120px'}} id="number" placeholder="" onChange={(e) => {handleDenomination('100', e.target.value, 2)}} value={denominationArr[2].count} autocomplete="off" min="0" />
                                                                                <i class="clear-input">
                                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                                </i>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="fw-bold text-end fs-5 align-middle">₹{denominationArr[2].total}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="fw-bold fs-5 align-middle">₹50</td>
                                                                    <td class="align-middle">X</td>
                                                                    <td class="p-0 text-end">
                                                                        <div class="form-group boxed">
                                                                            <div class="input-wrapper d-flex justify-content-end">
                                                                                <input type="number" class="form-control text-end pe-0 fs-5" style={{height: '38px', width: '120px'}} id="number" placeholder="" onChange={(e) => {handleDenomination('100', e.target.value, 3)}} value={denominationArr[3].count} autocomplete="off" min="0" />
                                                                                <i class="clear-input">
                                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                                </i>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="fw-bold text-end fs-5 align-middle">₹{denominationArr[3].total}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="fw-bold fs-5 align-middle">₹20</td>
                                                                    <td class="align-middle">X</td>
                                                                    <td class="p-0 text-end">
                                                                        <div class="form-group boxed">
                                                                            <div class="input-wrapper d-flex justify-content-end">
                                                                                <input type="number" class="form-control text-end pe-0 fs-5" style={{height: '38px', width: '120px'}} id="number" placeholder="" onChange={(e) => {handleDenomination('100', e.target.value, 4)}} value={denominationArr[4].count} autocomplete="off" min="0" />
                                                                                <i class="clear-input">
                                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                                </i>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="fw-bold text-end fs-5 align-middle">₹{denominationArr[4].total}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="fw-bold fs-5 align-middle">₹10</td>
                                                                    <td class="align-middle">X</td>
                                                                    <td class="p-0 text-end">
                                                                        <div class="form-group boxed">
                                                                            <div class="input-wrapper d-flex justify-content-end">
                                                                                <input type="number" class="form-control text-end pe-0 fs-5" style={{height: '38px', width: '120px'}} id="number" placeholder="" onChange={(e) => {handleDenomination('100', e.target.value, 5)}} value={denominationArr[5].count} autocomplete="off" min="0" />
                                                                                <i class="clear-input">
                                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                                </i>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="fw-bold text-end fs-5 align-middle">₹{denominationArr[5].total}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="fw-bold fs-5 align-middle">₹5</td>
                                                                    <td class="align-middle">X</td>
                                                                    <td class="p-0 text-end">
                                                                        <div class="form-group boxed">
                                                                            <div class="input-wrapper d-flex justify-content-end">
                                                                                <input type="number" class="form-control text-end pe-0 fs-5" style={{height: '38px', width: '120px'}} id="number" placeholder="" onChange={(e) => {handleDenomination('100', e.target.value, 6)}} value={denominationArr[6].count} autocomplete="off" min="0" />
                                                                                <i class="clear-input">
                                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                                </i>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="fw-bold text-end fs-5 align-middle">₹{denominationArr[6].total}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="fw-bold fs-5 align-middle">₹2</td>
                                                                    <td class="align-middle">X</td>
                                                                    <td class="p-0 text-end">
                                                                        <div class="form-group boxed">
                                                                            <div class="input-wrapper d-flex justify-content-end">
                                                                                <input type="number" class="form-control text-end pe-0 fs-5" style={{height: '38px', width: '120px'}} id="number" placeholder="" onChange={(e) => {handleDenomination('100', e.target.value, 7)}} value={denominationArr[7].count} autocomplete="off" min="0" />
                                                                                <i class="clear-input">
                                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                                </i>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="fw-bold text-end fs-5 align-middle">₹{denominationArr[7].total}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="fw-bold fs-5 align-middle">₹1</td>
                                                                    <td class="align-middle">X</td>
                                                                    <td class="p-0 text-end">
                                                                        <div class="form-group boxed">
                                                                            <div class="input-wrapper d-flex justify-content-end">
                                                                                <input type="number" class="form-control text-end pe-0 fs-5" style={{height: '38px', width: '120px'}} id="number" placeholder="" onChange={(e) => {handleDenomination('100', e.target.value, 8)}} value={denominationArr[8].count} autocomplete="off" min="0" />
                                                                                <i class="clear-input">
                                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                                </i>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                <td class="fw-bold text-end fs-5 align-middle">₹{denominationArr[8].total}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="fw-bold fs-5 align-middle" colSpan={3}>Grand Total</td>
                                                                    <td class="fw-bold text-end fs-5 align-middle">₹{grandTotal}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            }
                                            <div class="col-12 col-md-12 col-lg-12">
                                                <div class="form-group boxed pt-0">
                                                    <div class="input-wrapper">
                                                        <label class="form-label" for="remark5">Remark</label>
                                                        <textarea class="form-control pe-1" id="remark5" placeholder="Remark" value={shopRemark} onChange={(e) => {handleRemark(e.target.value)}} autocomplete="off"></textarea>
                                                        <i class="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="" style={{position: 'absolute', top: -41, right: 35}}>
                                            {tempShopStatus === 'Close' &&
                                                <button type="submit" class="btn btn-success btn-sm text-nowrap" onClick={(e) => {handleShopStatus(e, 't')}}><ion-icon name="log-in-outline"></ion-icon>&nbsp;OPEN SHOP</button>
                                            }
                                            {tempShopStatus === 'Open' &&
                                                <button type="submit" class="btn btn-danger btn-sm text-nowrap" onClick={(e) => {handleShopStatus(e, 'f')}}><ion-icon name="log-out-outline"></ion-icon>&nbsp;CLOSE SHOP</button>
                                            }
                                        </div>
                                    </form>
                                </div>
                                <div class="tab-pane fade" id="summary" role="tabpanel">
                                    <div class="section full mt-1">
                                        <div class="row mb-1">
                                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-2 pb-1 pt-1">
                                                        <div class="row ps-1">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center justify-content-between">
                                                                <p class="card-text m-0 text-secondary fs-8">Total Sale</p>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <h3 class="m-0 text-success fs-3">₹{summaryData?.Orders?.TotalSale}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-2 pb-1 pt-1">
                                                        <div class="row ps-1">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                                <p class="card-text m-0 text-secondary fs-8">Total Expense</p>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <h3 class="m-0 text-danger fs-3">₹{summaryData?.Transactions?.TotalExpense}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-2 pb-1 pt-1">
                                                        <div class="row ps-1">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                                <p class="card-text m-0 text-secondary fs-8">Customer Credit</p>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.BalanceAmount}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-2 pb-1 pt-1">
                                                        <div class="row ps-1">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                                <p class="card-text m-0 text-secondary fs-8">Total Orders</p>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <h3 class="m-0 text-primary fs-3">{summaryData?.Orders?.TotalOrder}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-2 pb-1 pt-1">
                                                        <div class="row ps-1">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                                <p class="card-text m-0 text-secondary fs-8">Custom Orders</p>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <h3 class="m-0 text-primary fs-3">{summaryData?.Orders?.CustomOrderCount}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-2 pb-1 pt-1">
                                                        <div class="row ps-1">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                                <p class="card-text m-0 text-secondary fs-8">Total Wastage</p>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <h3 class="m-0 text-primary fs-3">₹0</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-md-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-0">
                                                        <CategorySummaryTable sizeWiseData={sizeWiseData}></CategorySummaryTable>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-0">
                                                        <OrderTypeSummaryTable salesData={summaryData}></OrderTypeSummaryTable>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-0">
                                                        <PaymentMethodSummaryTable paymentData={summaryData}></PaymentMethodSummaryTable>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-12 mb-2">
                                                <div class="card">
                                                    <div class="card-body p-0">
                                                        <CategorySizeSummaryTable categoryWiseData={categoryWiseData}></CategorySizeSummaryTable>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="stock" role="tabpanel">
                                    <div class="table-responsive w-100">
                                        <table class="table table-striped border">
                                            <thead>
                                                <tr style={{borderBottom: 'solid 2px'}}>
                                                    <th scope="col" class="fw-bold fs-6"></th>
                                                    {currentStockColumns.map((v,k) => {
                                                        return <>
                                                            <th scope="col" class={(v.name == 'Item Name') ? "fs-6 fw-bold text-nowrap" : "text-end fs-6 fw-bold text-nowrap"}>
                                                                {(v.name !== 'Item Name') ?
                                                                    <span class="d-flex flex-column text-center">
                                                                        <span class="badge bg-primary w-100 mb-1">
                                                                            {finalTotalCount[v.name] &&
                                                                                <>
                                                                                    <ion-icon name="cart-outline" class="me-1"></ion-icon>{finalTotalCount[v.name]}
                                                                                </>
                                                                            }
                                                                        </span>
                                                                        {v.name}
                                                                    </span>
                                                                :
                                                                    <>
                                                                        {v.name}
                                                                    </>
                                                                }
                                                            </th>
                                                        </>
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentStockColumnsData.map((v,k) => {
                                                    const isNewCategory = previousCategory !== v.categoryName;
                                                    const isLastItemOfCategory = k === currentStockColumnsData.length - 1 || currentStockColumnsData[k + 1].categoryName !== v.categoryName;
                                                    if (isNewCategory) {
                                                        previousCategory = v.categoryName;
                                                    }
                                                    return (<tr style={{borderBottom: (isLastItemOfCategory) ? '1px solid' : '' }}>
                                                        {isNewCategory && (
                                                            <td class="ps-2 align-middle text-center" id={v.categoryName} rowSpan={categoryCounts[v.categoryName]} style={{borderRight: '1px solid',borderBottom: '1px solid', backgroundColor: 'white'}}>
                                                                <div class="fs-6 fw-bold">
                                                                {v.categoryName}
                                                                </div>
                                                            </td>
                                                        )}
                                                        {currentStockColumns.map((v1,k1) => {
                                                            return <>
                                                                <td scope="row" class={(v1.name != 'Item Name') ? "pt-1 pb-1 align-middle text-end pe-1 ps-1" : "pt-1 pb-1 align-middle pe-1 ps-1"}>
                                                                    <>
                                                                        <span class="fs-6 fw-bold">
                                                                            {(v[v1.name]) ? v[v1.name] : '--'}
                                                                        </span>
                                                                    </>
                                                                </td>
                                                            </>
                                                        })}
                                                    </tr>)
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {alertMessage &&
                        <div class="section d-flex justify-content-center align-items-center flex-column">
                            <h2 class="mb-2 text-danger">{alertMessage}</h2>
                            <button class="btn btn-primary" onClick={handleContinue}>Continue</button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ auth, data, shopStatus }) => {
    const {
        authToken,
        currentBusinessIdData
    } = auth
    const {
        items,
        businessData,
        branchID,
        configObj
    } = data
    const {
        shopStatusModalVisible,
        liveShopStatus
    } = shopStatus
    return {
        authToken,
        items,
        shopStatusModalVisible,
        liveShopStatus,
        businessData,
        branchID,
        configObj,
        currentBusinessIdData
    }
  }
  
  const mapDispatchToProps = {
    setBusinessData,
    hideShopStatusModal
  }

export default connect(mapStateToProps, mapDispatchToProps)(ShopStatusModal)