import React, { useEffect } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from "react-redux"
import { hideNotificationTopOffcanvas } from '../../../features/Offcanvas/notficationTopOffcanvasSlice'

const NotificationTop = ({
    notificationTopOffcanvasVisible,
    hideNotificationTopOffcanvas
}) => {
    useEffect(() => {
        if (notificationTopOffcanvasVisible) {
            setTimeout(() => {
                hideNotificationTopOffcanvas()
            }, 2000);
        }
    }, [notificationTopOffcanvasVisible])
    return (
        <div>
            <Offcanvas show={notificationTopOffcanvasVisible} placement={'top'} backdrop={false} style={{transition: '0.4s', background: 'transparent'}}>
                <Offcanvas.Body class="offcanvas-body">
                    <div id="notification-12" class="notification-box responsive-notification" style={{position: 'sticky'}}>
                        <div class="notification-dialog ios-style bg-success" style={{width: '300px'}}>
                            <div class="notification-header">
                                <div class="in">
                                    <ion-icon name="checkmark-circle-outline" class="fs-5" style={{paddingBottom: '2px', paddingRight: '2px'}}></ion-icon>
                                    <strong>Success</strong>
                                </div>
                                <div class="right" onClick={() => {hideNotificationTopOffcanvas()}}>
                                    <a href="javascript:void(0)" class="close-button">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </a>
                                </div>
                            </div>
                            <div class="notification-content">
                                <div class="in">
                                    <h3 class="subtitle">Order Placed Successfully</h3>
                                    {/* <div class="text">
                                        Lorem ipsum dolor sit amet.
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

const mapStateToProps = ({ notificationTop }) => {
    const {
        notificationTopOffcanvasVisible
    } = notificationTop
    return {
        notificationTopOffcanvasVisible
    }
}
  
const mapDispatchToProps = {
    hideNotificationTopOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationTop)