import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { showTransactionDetailModal } from '../../../features/Offcanvas/transactionDetailModalSlice'
import { showConfirmationModal } from '../../../features/Offcanvas/confirmationModalSlice'
import Moment from 'react-moment'

const TransactionGridView = ({
    list,
    type,
    showTransactionDetailModal,
    userValue,
    showConfirmationModal
}) => {
    const [loader, setLoader] = useState(true)
    useEffect(() => {
        setTimeout(() => {
            setLoader(false)
        }, 3000)
    }, [])
    const handleTransactionDetailView = (data) => {
        showTransactionDetailModal({transactionDetailModalVisible: true, transactionDetaildata: data})
    }

    const handleDeleteTransactions = (e, orderID, message) => {
        e.stopPropagation()
        showConfirmationModal({confirmationModalVisible: true, ID: orderID, message: message, deleteType: 'Transaction'})
    }
    return (
        <div class="pt-1">
            <div class="row">
            {loader &&
                <div class="col-12 col-md-12 d-flex align-items-center justify-content-center" style={{height: '210px'}}>
                    <button class="btn btn-light btn-lg" type="button" disabled>
                        <span class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>
            }
            {!loader &&
                <>
                {list.map(value => {
                    return <>
                        {(type === 'income' && value.Transaction_Category_ID === '1') &&
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2 position-relative">
                                <div class="position-absolute" style={{top: '15px', right: '20px', zIndex: 1}}>
                                    <div class="dropdown d-flex justify-content-end" style={{cursor: 'pointer'}}>
                                        <div class="dropdown-toggle" data-bs-toggle="dropdown">
                                            <ion-icon name="ellipsis-vertical-outline" class="fs-6"></ion-icon>
                                        </div>
                                        <div class="dropdown-menu p-0">
                                            {/* <a class="dropdown-item p-1 dropdown-sm" href="javascript:void(0)" onClick={() => {handleTransactionDetailView(value)}}>View Detail</a> */}
                                            <a class="dropdown-item p-1 dropdown-sm webweb-bg-secondary" href="javascript:void(0)">Share&nbsp;<span class="badge bg-secondary">Coming Soon</span></a>
                                            <a class="dropdown-item p-1 dropdown-sm webweb-bg-secondary" href="javascript:void(0)">Print&nbsp;<span class="badge bg-secondary">Coming Soon</span></a>
                                            <a class="dropdown-item p-1 dropdown-sm webweb-bg-secondary" href="javascript:void(0)">Duplicate&nbsp;<span class="badge bg-secondary">Coming Soon</span></a>
                                            <a class="dropdown-item p-1 dropdown-sm webweb-bg-secondary" href="javascript:void(0)" onClick={(e) => {handleDeleteTransactions(e, value.ID, 'Are you sure you want to delete this Transaction ?')}}>Delete Transaction</a>
                                        </div>
                                    </div>
                                </div>
                                <a href="javascript:void(0)" onClick={() => {handleTransactionDetailView(value)}}>
                                    <div class={`card border-success`}>
                                        <div class="card-body p-1">
                                            <div class="row">
                                                <div class="col-6 col-md-6 mb-2">
                                                    <p class="card-text mb-0 d-flex align-items-center" style={{fontSize: '10px'}}> #TRN{value.ID}</p>
                                                    <div class="d-flex align-items-start flex-column">
                                                        <div class="d-flex">
                                                            <h3 class={(value.Transaction_Type === 'credit') ? 'text-nowrap mb-0 text-success' : 'text-nowrap mb-0 text-danger'}>₹{value.Amount}</h3>&nbsp;
                                                        </div>
                                                    </div> 
                                                    <p class="card-text mb-0 d-flex align-items-center" style={{fontSize: '10px'}}> #ORD{value.Item_Order_ID}</p> 
                                                </div>
                                                <div class="col-6 col-md-6 d-flex justify-content-end">
                                                    
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6 col-md-6">
                                                    <h5 class="mb-0 fw-bold">{value.OrderType}</h5>
                                                </div>
                                                <div class="col-6 col-md-6 text-end">
                                                    <h5 class="mb-0 fw-bold">{value.PaymentMethod}</h5>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6 col-md-6 d-flex align-items-start">
                                                    <p class="card-text" style={{fontSize: '10px'}}><ion-icon name="time-outline"></ion-icon>&nbsp;<Moment fromNow>{value.Date}</Moment>&nbsp;<ion-icon name="information-circle-outline"></ion-icon></p>
                                                </div>
                                                {value.Customer_ID &&
                                                    <div class="col-6 col-md-6 text-end">
                                                        <span class="fs-10 text-nowrap d-flex justify-content-end">
                                                            <div class="bg-transparent rounded-circle">
                                                                <ion-icon name="person-circle-outline" class="fs-10"></ion-icon>
                                                            </div>&nbsp;
                                                                {userValue.map(v => {
                                                                    return <>
                                                                        {value.Customer_ID === v.ID &&
                                                                            <>
                                                                                <a href={'tel:' + v.ContactNumber}>{v.ContactNumber}</a>
                                                                            </>
                                                                        }
                                                                    </>
                                                                })}
                                                            {/* {value.Business_ID} */}
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        }
                        {(type === 'expense' && value.Transaction_Category_ID !== '1') &&
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
                                <a href="javascript:void(0)" onClick={() => {handleTransactionDetailView(value)}}>
                                    <div class={`card border-danger`}>
                                        <div class="card-body p-1">
                                            <div class="row">
                                                <div class="col-6 col-md-6 mb-2">
                                                    <p class="card-text mb-0 d-flex align-items-center" style={{fontSize: '10px'}}> #TRN{value.ID}</p>
                                                    <div class="d-flex align-items-start flex-column">
                                                        <div class="d-flex">
                                                            <h3 class={(value.Transaction_Type === 'credit') ? 'text-nowrap mb-0 text-success' : 'text-nowrap mb-0 text-danger'}>₹{value.Amount}</h3>&nbsp;
                                                        </div>
                                                    </div> 
                                                    <p class="card-text mb-0 d-flex align-items-center" style={{fontSize: '10px'}}> #ORD{value.Item_Order_ID}</p> 
                                                </div>
                                                <div class="col-6 col-md-6 d-flex justify-content-end">
                                                    <div>
                                                        <div class="dropdown d-flex justify-content-end" style={{cursor: 'pointer'}}>
                                                            <div class="dropdown-toggle" data-bs-toggle="dropdown">
                                                                <ion-icon name="ellipsis-vertical-outline" class="fs-6"></ion-icon>
                                                            </div>
                                                            <div class="dropdown-menu p-0">
                                                                {/* <a class="dropdown-item p-1 dropdown-sm" href="javascript:void(0)">View Detail</a> */}
                                                                <a class="dropdown-item p-1 dropdown-sm webweb-bg-secondary" href="javascript:void(0)">Share&nbsp;<span class="badge bg-secondary">Coming Soon</span></a>
                                                                <a class="dropdown-item p-1 dropdown-sm webweb-bg-secondary" href="javascript:void(0)">Print&nbsp;<span class="badge bg-secondary">Coming Soon</span></a>
                                                                <a class="dropdown-item p-1 dropdown-sm webweb-bg-secondary" href="javascript:void(0)">Duplicate&nbsp;<span class="badge bg-secondary">Coming Soon</span></a>
                                                                <a class="dropdown-item p-1 dropdown-sm webweb-bg-secondary" href="javascript:void(0)" onClick={(e) => {handleDeleteTransactions(e, value.ID, 'Are you sure you want to delete this Transaction ?')}}>Delete Transaction&nbsp;<span class="badge bg-secondary">Coming Soon</span></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6 col-md-6">
                                                    <h5 class="mb-0 fw-bold">{value.OrderType}</h5>
                                                </div>
                                                <div class="col-6 col-md-6 text-end">
                                                    <h5 class="mb-0 fw-bold">{value.PaymentMethod}</h5>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6 col-md-6 d-flex align-items-start">
                                                    <p class="card-text" style={{fontSize: '10px'}}><ion-icon name="time-outline"></ion-icon>&nbsp;<Moment fromNow>{value.Date}</Moment>&nbsp;<ion-icon name="information-circle-outline"></ion-icon></p>
                                                </div>
                                                {value.Customer_ID &&
                                                    <div class="col-6 col-md-6 text-end">
                                                        <span class="fs-10 text-nowrap d-flex justify-content-end">
                                                            <div class="bg-transparent rounded-circle">
                                                                <ion-icon name="person-circle-outline" class="fs-10"></ion-icon>
                                                            </div>&nbsp;
                                                                {userValue.map(v => {
                                                                    return <>
                                                                        {value.Customer_ID === v.ID &&
                                                                            <>
                                                                                <a href={'tel:' + v.ContactNumber}>{v.ContactNumber}</a>
                                                                            </>
                                                                        }
                                                                    </>
                                                                })}
                                                            {/* {value.Business_ID} */}
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        }
                    </>
                })}
                </>
            }
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {
    }
}
  
const mapDispatchToProps = {
    showTransactionDetailModal,
    showConfirmationModal
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionGridView)
