import React, { useState, useEffect } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import Logo from '../images/logo.svg'
import { useParams } from 'react-router'
import axios from '../../AxiosConfig'

const MenuCard = () => {
    const { restID } = useParams()
    const [menuCardDetail, setMenuCardDetail] = useState(null);
    const [menuCardDetailBackup, setMenuCardDetailBackup] = useState(null);
    const [error, setError] = useState(false);

    const [searchText, setSearchText] = useState('')
    useEffect(() => {
        axios.get('/menuCard/' + restID)
            .then(res => {
                if (res.data.message === 'Success') {
                    setMenuCardDetail(res.data.data);
                    setMenuCardDetailBackup(res.data.data);
                    // console.log(res.data.data)
                } else {
                    //   showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
                }
            }).catch((error) => {
                setError(true);
            })
    }, [restID]);

    const filteredItems = (array, text) => {
        const items = array.filter((item1) => item1.ItemTitle.toLowerCase().includes(text.toLowerCase()))
        return items
    }


    useEffect(() => {
        if (searchText.trim().length > 0) {
            console.log(menuCardDetailBackup);
            let filteredArray = Object.keys(menuCardDetailBackup?.Items)?.map((value, key) => {
                const returnedValue = filteredItems(menuCardDetailBackup.Items[value], searchText);
                let values;
                if (returnedValue.length > 0) {
                    values = { [value]: returnedValue }
                } else {
                    values = null
                }
                return values;
            }
            )

            filteredArray = filteredArray.filter((item) => item !== null);
            const objectOfItems = {};
            filteredArray.forEach(element => {
                Object.keys(element).map(key => {
                    objectOfItems[key] = element[key]
                })
            });

            const tempmenuCardDetailBackup = JSON.parse(JSON.stringify(menuCardDetailBackup));
            tempmenuCardDetailBackup.Items = objectOfItems;
            setMenuCardDetail(tempmenuCardDetailBackup)
            console.log(tempmenuCardDetailBackup);
        } else {
            setMenuCardDetail(JSON.parse(JSON.stringify(menuCardDetailBackup)));
        }
    }, [searchText])

    return (
        <div id="appCapsule" style={{ paddingBottom: '0px', paddingTop: '40px' }}>
            {
                menuCardDetail !== null && menuCardDetail !== undefined &&
                <div class="section full">
                    <div class="appHeader" style={{ minHeight: '40px' }}>
                        <div class="container">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="pageTitle text-bold">
                                    <img
                                        alt="Logo"
                                        src={Logo}
                                        class="restaurant-pic"
                                        width="52px"
                                    />
                                </div>
                                <div>
                                    <a href="#!" class="headerButton d-flex align-items-center justify-content-center">
                                        <ion-icon name="person-circle-outline" class="fs-1 text-muted"></ion-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                menuCardDetail !== null && menuCardDetail !== undefined &&
                <div className="offer-section bg-dark p-2 position-relative">
                    <div className="container">
                        <div className="row">
                            <div className="text-col col-md-9 col-sm-12">
                                <div class="row">
                                    <div class="col-3 col-md-3 d-flex justify-content-center align-items-center flex-column gap-1">
                                        <div style={{ width: '90%', borderRadius: '100%', background: '#f9f9f9', display: 'flex', alignItems: 'center', justifyContent: 'center', aspectRatio: '1' }}>
                                            <img
                                                alt=""
                                                src={menuCardDetail?.BusinessDetails?.BusinessLogo}
                                                class="restaurant-pic w-100"
                                                style={{ objectFit: 'contain', aspectRatio: '1' }}
                                            />
                                        </div>
                                        <div class="rating-wrap d-flex align-items-center justify-content-center gap-1">
                                            <span>4.5</span> <ion-icon name="star-outline" class="text-warning" style={{ marginRight: '4px' }}></ion-icon>
                                            {/* <ul class="rating-stars list-unstyled mb-0">
                                                <li>
                                                    <ion-icon name="star-outline" class="text-warning" style={{marginRight: '4px'}}></ion-icon>
                                                    <ion-icon name="star-outline" class="text-warning" style={{marginRight: '4px'}}></ion-icon>
                                                    <ion-icon name="star-outline" class="text-warning" style={{marginRight: '4px'}}></ion-icon>
                                                    <ion-icon name="star-outline" class="text-warning" style={{marginRight: '4px'}}></ion-icon>
                                                    <ion-icon name="star-outline"></ion-icon>
                                                </li>
                                            </ul> */}
                                        </div>
                                    </div>
                                    <div class="col-9 col-md-9">
                                        <div>
                                            <h2 className="text-white font-weight-bold mb-0">
                                                {menuCardDetail?.BusinessDetails?.Name}
                                            </h2>
                                            <small class="text-white m-0"><ion-icon name="location-outline"></ion-icon> {menuCardDetail?.BusinessDetails?.Address}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="image-col col-md-3">
                                <div style={{width: '75px', height: '75px', borderRadius: '100%', background: '#f9f9f9', position: 'absolute', top: '0px',zIndex: 1000,transform: 'translate(240px, -40px)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <img
                                        alt=""
                                        src={menuCardDetail?.BusinessDetails?.BusinessLogo}
                                        class="restaurant-pic"
                                        width="50px"
                                    />
                                </div>
                                
                            </div> */}
                        </div>

                    </div>
                </div>
            }

            {
                error &&
                <div style={{ width: '100%', height: 'calc(100vh - 112px)' }} className='d-flex flex-column justify-content-center align-items-center'>
                    <ion-icon name="storefront-outline" style={{ width: '60px', height: '60px' }}></ion-icon>
                    <h1 className='mt-3'><b>Store Not Found!!</b></h1>
                </div>
            }
            {/* <div class="section full my-4">
                <div className="container">
                    <div>
                        <Splide
                            options={{
                                type: "loop",
                                gap: 10,
                                arrows: true,
                                pagination: false,
                                perPage: 5,
                                
                                breakpoints: {
                                    640: {
                                        perPage: 3,
                                    },
                                    400: {
                                        perPage: 1,
                                        padding: '20%',
                                    },
                                }

                            }}
                        >
                            <SplideSlide>


                                <div class="card product-card">
                                    <div class="card-body">
                                        <img src="assets/img/sample/photo/product1.jpg" class="image" alt="product image" />
                                        <h2 class="title">The osahan Restaurant</h2>
                                        <p class="text">North • Hamburgers • Pure veg</p>
                                        <div class="price"> $500 FOR TWO</div>
                                    </div>
                                </div>


                            </SplideSlide>
                            <SplideSlide>


                                <div class="card product-card">
                                    <div class="card-body">
                                        <img src="assets/img/sample/photo/product1.jpg" class="image" alt="product image" />
                                        <h2 class="title">The osahan Restaurant</h2>
                                        <p class="text">North • Hamburgers • Pure veg</p>
                                        <div class="price"> $500 FOR TWO</div>
                                    </div>
                                </div>


                            </SplideSlide>
                            <SplideSlide>


                                <div class="card product-card">
                                    <div class="card-body">
                                        <img src="assets/img/sample/photo/product1.jpg" class="image" alt="product image" />
                                        <h2 class="title">The osahan Restaurant</h2>
                                        <p class="text">North • Hamburgers • Pure veg</p>
                                        <div class="price"> $500 FOR TWO</div>
                                    </div>
                                </div>


                            </SplideSlide>
                            <SplideSlide>

                                <div class="card product-card">
                                    <div class="card-body">
                                        <img src="assets/img/sample/photo/product1.jpg" class="image" alt="product image" />
                                        <h2 class="title">The osahan Restaurant</h2>
                                        <p class="text">North • Hamburgers • Pure veg</p>
                                        <div class="price"> $500 FOR TWO</div>
                                    </div>
                                </div>

                            </SplideSlide>
                        </Splide>
                    </div>
                </div>
            </div> */}
            {
                menuCardDetail !== null && menuCardDetail !== undefined &&
                <>
                    <div className='container mt-2'>
                        <div class="card p-0">
                            <form class="search-form">
                                <div class="form-group searchbox">
                                    <input type="text" class="form-control" placeholder="Search items" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                                    <i class="input-icon">
                                        <ion-icon name="search-outline" role="img" class="md hydrated" aria-label="search outline"></ion-icon>
                                    </i>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="menu-section my-4">
                        <div className="container">
                            <div className="row w-100 m-0">
                                <div className="col-12 menu-section col-md-12 px-3 px-md-1">
                                    <div class="card">
                                        <div class="card-body p-0">
                                            {(menuCardDetail !== null && menuCardDetail !== undefined) &&
                                                <>
                                                    {Object.keys(menuCardDetail?.Items).map((value, key) => {
                                                        return <>
                                                            <div id={value + key} class="listview-title justify-content-start webweb-bg-secondary">
                                                                <span class="fw-bolder fs-6">{value}</span>&nbsp;
                                                                <small class="text-secondary fs-8">{menuCardDetail?.Items[value].length} Items</small>
                                                            </div>
                                                            <ul class="listview image-listview">
                                                                {menuCardDetail?.Items[value]?.map((v, k) => {
                                                                    return <li>
                                                                        <div class="item">
                                                                            {(v.ImageURL === '' || v.ImageURL === null || v.ImageURL === undefined) &&
                                                                                <div class="imageWrapper">
                                                                                    <img src={'https://us.123rf.com/450wm/viktorijareut/viktorijareut1503/viktorijareut150300119/37649514-grey-tray-kitchenware-trey-isolated-tray-vector-closed-tray.jpg?ver=6'} alt="image" class="imaged me-2" style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                                                                </div>
                                                                            }
                                                                            {v.ImageURL &&
                                                                                <div class="imageWrapper">
                                                                                    <img src={v.ImageURL} alt="image" class="imaged me-2" style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                                                                </div>
                                                                            }
                                                                            <div class="in">
                                                                                <div>
                                                                                    {v.ItemTitle}
                                                                                    <footer class="fw-bolder fs-6">₹{v.MRP}</footer>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                })}
                                                            </ul>
                                                        </>
                                                    })}

                                                    {
                                                        Object.keys(menuCardDetail?.Items).length === 0 &&
                                                        <div class="card py-4 d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '50vh' }}>
                                                            <ion-icon name="cafe-outline" style={{ width: '50px', height: '50px' }}></ion-icon>
                                                            <h4 className='mt-3'><b>No items found!</b></h4>
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="resto-block col-md-4">
                                    <ul class="listview image-listview">
                                        <li>
                                            <a href="#!" class="item">
                                            <img src="assets/img/sample/avatar/avatar1.jpg" alt="image" class="image"/>
                                                <div class="in">
                                                    <div>
                                                    Spice Hut Indian Restaurant
                                                        <footer><ion-icon name="location-outline"></ion-icon> 2036 2ND AVE, NEW YORK, NY 10029</footer>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#!" class="item">
                                                <ion-icon name="stop-circle-outline" class="mx-2"></ion-icon>
                                                <div class="in">
                                                    <div style={{width: "50%"}}>
                                                        Chicken Tikka Sub
                                                    </div>
                                                    <div class="stepper stepper-sm" style={{ height: '18px', width: '68px', minWidth: '60px' }}>
                                                        <a href="#!" class="stepper-button stepper-down" style={{ height: '20px', width: '24px', fonSize: '17px' }}>-</a>
                                                        <input type="text" class="form-control" value="1" disabled style={{ height: '18px', width: '32px', fonSize: '12px' }} />
                                                        <a href="#!" class="stepper-button stepper-up" style={{ height: '20px', width: '24px', fonSize: '17px' }}>+</a>
                                                    </div>
                                                    <footer style={{marginLeft: "7px"}}>$600</footer>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#!" class="item">
                                                <ion-icon name="stop-circle-outline" class="mx-2"></ion-icon>
                                                <div class="in">
                                                    <div style={{width: "50%"}}>
                                                    Methi Chicken Dry
                                                    </div>
                                                    <div class="stepper stepper-sm" style={{ height: '18px', width: '68px', minWidth: '60px' }}>
                                                        <a href="#!" class="stepper-button stepper-down" style={{ height: '20px', width: '24px', fonSize: '17px' }}>-</a>
                                                        <input type="text" class="form-control" value="1" disabled style={{ height: '18px', width: '32px', fonSize: '12px' }} />
                                                        <a href="#!" class="stepper-button stepper-up" style={{ height: '20px', width: '24px', fonSize: '17px' }}>+</a>
                                                    </div>
                                                    <footer style={{marginLeft: "7px"}}>$600</footer>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#!" class="item">
                                                <ion-icon name="stop-circle-outline" class="mx-2"></ion-icon>
                                                <div class="in">
                                                    <div style={{width: "50%"}}>
                                                    Reshmi Kebab
                                                    </div>
                                                    <div class="stepper stepper-sm" style={{ height: '18px', width: '68px', minWidth: '60px' }}>
                                                        <a href="#!" class="stepper-button stepper-down" style={{ height: '20px', width: '24px', fonSize: '17px' }}>-</a>
                                                        <input type="text" class="form-control" value="1" disabled style={{ height: '18px', width: '32px', fonSize: '12px' }} />
                                                        <a href="#!" class="stepper-button stepper-up" style={{ height: '20px', width: '24px', fonSize: '17px' }}>+</a>
                                                    </div>
                                                    <footer style={{marginLeft: "7px"}}>$600</footer>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#!" class="item">
                                                <ion-icon name="stop-circle-outline" class="mx-2"></ion-icon>
                                                <div class="in">
                                                    <div style={{width: "50%"}}>
                                                    Lemon Cheese Dry
                                                    </div>
                                                    <div class="stepper stepper-sm" style={{ height: '18px', width: '68px', minWidth: '60px' }}>
                                                        <a href="#!" class="stepper-button stepper-down" style={{ height: '20px', width: '24px', fonSize: '17px' }}>-</a>
                                                        <input type="text" class="form-control" value="1" disabled style={{ height: '18px', width: '32px', fonSize: '12px' }} />
                                                        <a href="#!" class="stepper-button stepper-up" style={{ height: '20px', width: '24px', fonSize: '17px' }}>+</a>
                                                    </div>
                                                    <footer style={{marginLeft: "7px"}}>$600</footer>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#!" class="item">
                                                <ion-icon name="stop-circle-outline" class="mx-2"></ion-icon>
                                                <div class="in">
                                                    <div style={{width: "50%"}}>
                                                    Rara Paneer
                                                    </div>
                                                    <div class="stepper stepper-sm" style={{ height: '18px', width: '68px', minWidth: '60px' }}>
                                                        <a href="#!" class="stepper-button stepper-down" style={{ height: '20px', width: '24px', fonSize: '17px' }}>-</a>
                                                        <input type="text" class="form-control" value="1" disabled style={{ height: '18px', width: '32px', fonSize: '12px' }} />
                                                        <a href="#!" class="stepper-button stepper-up" style={{ height: '20px', width: '24px', fonSize: '17px' }}>+</a>
                                                    </div>
                                                    <footer style={{marginLeft: "7px"}}>$600</footer>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="apply-promo-btn d-inline-flex mt-4">
                                        <input type="text" class="form-control" id="name5" placeholder="Enter Promo Code"
                                            autocomplete="off"/><button type="button" class="btn btn-danger square">% Apply</button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </>
            }


            <div class="section full mt-2">
                <div class="appBottomMenu bg-dark d-flex justify-content-center align-items-center" style={{ position: 'relative' }}>
                    <h4 class="mb-0 text-light">Powered By <span class="text-warning">SkyQ Tech</span></h4>
                </div>
            </div>
            {/* <div class="fab-button animate bottom-right dropdown" style={{bottom: '20px', right: '20px'}}>
                <a href="#" class="fab bg-dark d-flex flex-column" data-bs-toggle="dropdown">
                    <ion-icon name="book-outline"></ion-icon>
                    <span class="fs-10">Menu</span>
                </a>
                <div class="dropdown-menu bg-success">
                    <div>
                        <ul>
                            <li>Chicken momo</li>
                            <li>Chicken momo</li>
                            <li>Chicken momo</li>
                            <li>Chicken momo</li>
                            <li>Chicken momo</li>
                            <li>Chicken momo</li>
                            <li>Chicken momo</li>
                        </ul>
                    </div>
                </div>
            </div> */}
            {
                menuCardDetail !== null && menuCardDetail !== undefined &&
                <div class="position-fixed dropdown" style={{ right: '25px', bottom: '35px', zIndex: 1000 }}>
                    <a href="javscript:void(0)" class="btn btn-dark rounded-circle d-flex align-items-center justify-content-center flex-column dropdown-toggle" data-bs-toggle="dropdown" style={{ height: '60px', width: '60px' }}>
                        <ion-icon name="book-outline" class="me-0"></ion-icon>
                        <span class="fs-10">Menu</span>
                    </a>
                    <div class="dropdown-menu bg-dark p-0 custom-dropdown-alignment">
                        {(menuCardDetail !== undefined && menuCardDetail !== null) &&
                            <>
                                {Object.keys(menuCardDetail?.Items).map((v, k) => {
                                    return <a class="dropdown-item text-light d-flex justify-content-between" href={'#' + v + k}>
                                        <div>{v}</div>
                                        <div>{menuCardDetail?.Items[v].length}</div>
                                    </a>
                                })}
                            </>
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default MenuCard
