import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    addSetupModalVisible: false,
    type: '',
    setupData: null,
    actionType: '',
    arrKey: null,
    tempSetupDataImage: ''
}

export const AddSetupModalSlice = createSlice({
    name: 'addSetup',
    initialState,
    reducers: {
      showAddSetupModal: (state, action) => {
        const {
            addSetupModalVisible,
            type,
            setupData,
            actionType,
            arrKey
        } = action.payload
        state.addSetupModalVisible = addSetupModalVisible
        state.type = type
        state.setupData = setupData
        state.actionType = actionType
        state.arrKey = arrKey
      },
      hideAddSetupModal: (state) => {
        state.addSetupModalVisible = false
      },
      setTempSetupDataImage: (state, action) => {
        const {
            tempSetupDataImage
        } = action.payload
        state.tempSetupDataImage = tempSetupDataImage
      },
    }
})

export const { showAddSetupModal, hideAddSetupModal, setTempSetupDataImage } = AddSetupModalSlice.actions;


export default AddSetupModalSlice.reducer