import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../AxiosConfig'
import config from '../../config'
import jwt_decode from 'jwt-decode'

export const requestOtp = createAsyncThunk(
  'auth/requestOtp',
  async (params) => {
    const { mobileNumber } = params
    const response = await axios.get(config.apiUrl + '/users/login?mobile='+ mobileNumber)
    const { data } = response
    return data
  }
)

export const validateOtp = createAsyncThunk(
  'auth/validateOtp',
  async (params) => {
    const { mobileNumber, otp } = params
    const response = await axios.get(config.apiUrl + '/users/login?mobile='+ mobileNumber +'&otp=' + otp)
    const { data } = response
    return data
  }
)

const initialState = {
  authToken: null,
  buisness_ID: null,
  hideSendOtpButton: false,
  showVerifyOtpForm: false,
  showLoginForm: true,
  otpRequestSuccess: false,
  otpRequestError: false,
  otpValidateSuccess: false,
  otpValidateError: false,
  role: null,
  currentBusinessIdData : null,
  buildType: 'webapk'
  // apk/webapk
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state, action) => {
      localStorage.clear()
      state.hideSendOtpButton = false
      state.showVerifyOtpForm = false
      state.showLoginForm = true
      state.otpRequestSuccess = false
      state.otpRequestError = false
      state.otpValidateSuccess = false
      state.otpValidateError = false
      state.authToken = null
      state.role = null
    },
    backToLogin: (state) => {
      state.hideSendOtpButton = false
      state.showVerifyOtpForm = false
      state.showLoginForm = true
      state.otpRequestSuccess = false
      state.otpRequestError = false
      state.otpValidateSuccess = false
      state.otpValidateError = false
      state.role = null
      state.buisness_ID = null
      state.location_ID = null
      localStorage.clear()
    },
    setToken: (state, action) => {
      state.authToken = action.payload.authToken
      const tokenData = jwt_decode(action.payload.authToken);
      state.buisness_ID = tokenData.userInfo.Business_ID;
      state.role = tokenData.userInfo.RoleID;
      state.location_ID = tokenData.userInfo.DefaultLocationID
    },
    setCurrentBusinessIdData: (state, action) => {
      const {
        currentBusinessIdData
      } = action.payload
      state.currentBusinessIdData = currentBusinessIdData 
    },
    setBuildType: (state, action) => {
      const {
        buildType
      } = action.payload
      state.buildType = buildType 
    },
  },
  extraReducers: {
    [requestOtp.fulfilled]: (state, action) => {
      const {message, data}  = action.payload
      if (message === 'Success') {
        if (data === 'OTP Sent') {
          state.hideSendOtpButton = true
          state.showVerifyOtpForm = true
          state.otpRequestSuccess = true
        } else {
          state.otpRequestError = true
        }
      } else {
        state.hideSendOtpButton = false
        state.showVerifyOtpForm = false
      }
    },
    // [requestOtp.pending]: (state, action) => {
    //   state.someValue += action.payload
    // },
    // [requestOtp.rejected]: (state, action) => {
    //   state.someValue += action.payload
    // },
    [validateOtp.fulfilled]: (state, action) => {
      const { data } = action.payload
      if (data !== 'Incorrect OTP') {
        localStorage.setItem('token', data)
        state.authToken = data
        state.otpValidateSuccess = true
      } else {
        state.otpValidateError = true
        state.authToken = null
      }
    },
    // [validateOtp.pending]: (state, action) => {
    //   state.someValue += action.payload
    // },
    // [validateOtp.rejected]: (state, action) => {
    //   state.someValue += action.payload
    // }
  }
})

export const { logout, backToLogin, setToken, setCurrentBusinessIdData, setBuildType } = authSlice.actions;


export default authSlice.reducer
