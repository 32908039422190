import React, { useState, useEffect } from 'react';

const CategorySummaryTable = ({
    sizeWiseData
}) => {
    const [totalSum, setTotalSum] = useState(0)
    useEffect(() => {
        let totalSum = 0;
        sizeWiseData?.map((v,k) => {
            totalSum += parseInt(v.Sum)
        })
        setTotalSum((totalSum) ? totalSum : 0)
    }, [sizeWiseData])
    return (
        <div class="border p-0">
            <>
                {(sizeWiseData?.length === 0) &&
                    <h4 class="text-danger fw-bold text-center mt-1">No Data Found</h4>
                }
                {(sizeWiseData?.length > 0) &&
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" class="border-end text-nowrap fw-bold">Category</th>
                                    <th scope="col" class="border-end text-nowrap fw-bold text-end">₹{totalSum}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sizeWiseData?.map((v,k) => {
                                    return <tr>
                                        <td class="border-end text-nowrap fw-bold">
                                            {v.Title}
                                            <span class="badge bg-primary fs-10 ms-1" style={{height: '14px'}}>{v.Count}</span>
                                        </td>
                                        <td class="border-end text-nowrap text-end">₹{v.Sum}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                }
            </>
        </div>
    );
};

export default CategorySummaryTable;