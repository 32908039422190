import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    paymentOffcanvasVisible: false
}

export const PrinterSettingModalSlice = createSlice({
    name: 'printerSetting',
    initialState,
    reducers: {
        showPrinterSettingModal: (state, action) => {
        const {
            printerSettingModalVisible
        } = action.payload
        state.printerSettingModalVisible = printerSettingModalVisible
      },
      hidePrinterSettingModal: (state) => {
        state.printerSettingModalVisible = false
      }
    }
})

export const { showPrinterSettingModal, hidePrinterSettingModal } = PrinterSettingModalSlice.actions;


export default PrinterSettingModalSlice.reducer