import React from 'react'
import { connect } from "react-redux"
import Toast from 'react-bootstrap/Toast'
import { hideConfirmationModal } from '../../features/Offcanvas/confirmationModalSlice'
import axios from '../../AxiosConfig'
import { handleRenderOrders, handleRenderTransactions } from '../../features/Data/dataSlice'

const ConfirmationModal = ({
    confirmationModalVisible,
    hideConfirmationModal,
    ID,
    message,
    handleRenderOrders,
    deleteType,
    handleRenderTransactions
}) => {
    const handleDelete = (ID) => {
        if (deleteType === 'Orders') {
            axios.delete('/Order/' + deleteType + '/'+ ID).then(res => {
                if (res.data.message === 'Success') {
                handleRenderOrders({renderOrders: true})
                hideConfirmationModal()
                } else {
                // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
                }
            })
        } else if (deleteType === 'Invoices') {
            axios.delete('/Invoices/'+ ID).then(res => {
                if (res.data.message === 'Success') {
                handleRenderOrders({renderOrders: true})
                hideConfirmationModal()
                } else {
                // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
                }
            })
        } else if (deleteType === 'Transaction') {
            axios.delete('/Transactions/'+ ID).then(res => {
                if (res.data.message === 'Success') {
                handleRenderTransactions({renderTransactions: true})
                hideConfirmationModal()
                } else {
                // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
                }
            })
        } else if (deleteType === 'kot') {
            axios.delete('/Order/'+ ID).then(res => {
                if (res.data.message === 'Success') {
                handleRenderOrders({renderOrders: true})
                hideConfirmationModal()
                } else {
                // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
                }
            })
        }
        
    }
    return (
        <div>
            <Toast show={confirmationModalVisible} autohide delay={2000} bg={'white'} className={"toast-box toast-center border-danger"} style={{display: 'inherit', zIndex: 9999}}>
                <Toast.Body className="in">
                <div>
                    <ion-icon name={'trash-bin-outline'} className="fs-6" style={{height: '48px', width: '48px', marginBottom: '0px', color: '#ec4433'}}></ion-icon>
                    <h2 class="text-danger">Cancel</h2>
                </div>
                <div className="text text-secondary">
                    {message}
                    <div class="d-flex justify-content-center align-items-center">
                        <button class="me-1 btn btn-secondary text-light d-flex align-items-center" onClick={() => {hideConfirmationModal()}}><ion-icon name="close-circle-outline" class="mb-0" style={{height: '18px', width: '18px'}}></ion-icon>&nbsp;Close</button>
                        <button class="me-1 btn btn-danger d-flex align-items-center" onClick={() => {handleDelete(ID)}}><ion-icon name="trash-bin-outline" class="mb-0" style={{height: '18px', width: '18px'}}></ion-icon>&nbsp;Cancel</button>
                    </div>
                </div>
                </Toast.Body>
            </Toast>
        </div>
    )
}

const mapStateToProps = ({ confirmation }) => {
    const {
        confirmationModalVisible,
        ID,
        message,
        deleteType
    } = confirmation
    return {
        confirmationModalVisible,
        ID,
        message,
        deleteType
    }
  }
  
const mapDispatchToProps = {
    hideConfirmationModal,
    handleRenderOrders,
    handleRenderTransactions
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal)
