import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { showUserListOffcanvas } from '../../features/Offcanvas/userListOffcanvasSlice'
import { useNavigate } from 'react-router-dom'
import UserBottomOffcanvas from '../layout/Sidebar/UserBottomOffcanvas'
import html2canvas from 'html2canvas'
import axios from '../../AxiosConfig'
import Face from './Components/Face'

const UserCheckInOut = ({
    showUserListOffcanvas,
    userData
}) => {
    let navigate = useNavigate()
    let userInOutData = {
        'Image':'',
        'User_ID':'',
        'CheckInTime':'',
        'CheckOutTime':''
    }
    const [imagePreview, setImagePreview] = useState(false)
    const [locationName, setLocationName] = useState('')
    const [locationLoaderActive, setLocationLoaderActive] = useState(false)
    const [imagePath, setImagePath] = useState('')
    const [tabType, setTabType] = useState('in')
    const [disabledButton, setDisabledButton] = useState(true)
    const handleUserList = () => {
        showUserListOffcanvas({userListOffcanvasVisible: true})
    }

    useEffect(() => {
        let All_mediaDevices=navigator.mediaDevices
        if (imagePreview === false || imagePreview === null || imagePreview === undefined) {

            if (!All_mediaDevices || !All_mediaDevices.getUserMedia) {
                return;
            }
            All_mediaDevices.getUserMedia({
                audio: true,
                video: true
            })
            .then(function(vidStream) {
                var video = document.getElementById('videoCam');
                if ("srcObject" in video) {
                    video.srcObject = vidStream;
                } else {
                    video.src = window.URL.createObjectURL(vidStream);
                }
                video.onloadedmetadata = function(e) {
                    video.play();
                };
            })
            .catch(function(e) {
                console.log(e.name + ": " + e.message);
            });
        } else {
            All_mediaDevices.getUserMedia({
                audio: false,
                video: false
            })
            .then(function(vidStream) {
                var video = document.getElementById('videoCam');
                if ("srcObject" in video) {
                    video.srcObject = vidStream;
                } else {
                    video.src = window.URL.createObjectURL(vidStream);
                }
                video.onloadedmetadata = function(e) {
                    video.play();
                };
            })
            .catch(function(e) {
                console.log(e.name + ": " + e.message);
            });
        }
    }, [imagePreview])

    
    const takeshot = (captureStatus) => {
        if (captureStatus === 'capture') {
            setImagePreview(true)
            let div =
                document.getElementById('videoCam');
            html2canvas(div).then(
                function (canvas) {
                    document
                    .getElementById('output')
                    .appendChild(canvas);
                    canvas.toBlob((blob) => {
                        const file = new File([blob], 'image.png', { type: blob.type });
                        const formData = new FormData();
                        formData.append('inputFile', file);
                        axios.post('/applications/upload/Users', formData)
                        .then((response) => {
                            setImagePath(response.data.data)
                        })
                        .catch((error) => console.log(error));
                    });
            })
        } else if (captureStatus === 'recapture') {
            setImagePreview(false)
        }
    }
    
    const prevPage = () => {
        setImagePreview(true)
        navigate('/')
    }

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            // x.innerHTML = "Geolocation is not supported by this browser.";
        }
        function showPosition(position) {
            const lat = position.coords.latitude
            const lng = position.coords.longitude
            setLocationLoaderActive(true)
            axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/'+lng+','+lat+'.json?access_token=pk.eyJ1Ijoic2t5cXRlY2giLCJhIjoiY2xoOHFkeXl2MDAxdzNkbGd0cHc3YWxpYiJ9.ty5aLADWsiwGLYn1GZ9qTA')
            .then((response) => {
                setLocationLoaderActive(false)
                setLocationName(response.data.features[3].place_name)
            })
            .catch((error) => console.log(error));
        }
    }, [])

    const handleTabSwitch = (inOut) => {
        setTabType(inOut)
    }

    // const handleValidatePin = (pin) => {
    //     axios.get(`https://api.allpos.in/users/validatePin/${userData?.ID ? userData?.ID : null}/` + pin).then(res => {
    //         if (res.data.message === 'Success') {
    //           setDisabledButton(res.data.data)
    //         } else {
    //           // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
    //         }
    //     })
    // }

    const checkInOut = () => {
        userInOutData = {
            'Image':imagePath ? imagePath : '',
            'User_ID':userData?.ID,
            'CheckInTime': (tabType === 'in') ? new Date() : '',
            'CheckOutTime':(tabType === 'out') ? new Date() : '',
            'Type': tabType
        }
        axios.put('/Attendance', userInOutData).then(res => {
            if (res.data.message === 'Success') {
              navigate('/newOrder')
            } else {
              // showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
            }
        })
    }
    return (
        <div>
            <div>
                <div class="row px-3 py-3">
                    <div class="col-2 col-md-4 text-start d-flex align-items-center">
                        <a href="javascript:void(0)" onClick={prevPage} class="headerButton goBack fs-2">
                            <ion-icon name="chevron-back-outline"></ion-icon>
                        </a>
                    </div>
                    <div class="col-10 col-md-8 d-flex justify-content-end d-flex align-items-center">
                        <a href="javascript:void(0)" class="headerButton text-muted">
                            {locationLoaderActive === false &&
                                <>
                                    {(locationName !== '' && locationName !== null && locationName !== undefined) &&
                                        <>
                                            <ion-icon name="location-outline"></ion-icon> {locationName}
                                        </>
                                    }
                                </>
                            }
                            {locationLoaderActive === true &&
                                <div class="spinner-border text-primary" style={{height: '1rem', width: '1rem', borderWidth: '0.20em' }} role="status"></div>
                            }
                        </a>
                    </div>
                </div>
                <div class="row d-flex justify-content-center">
                    <ul class="nav nav-tabs capsuled w-50 p-0" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active text-success" data-bs-toggle="tab" href="#in" role="tab" onClick={() => {handleTabSwitch('in')}}>
                                <ion-icon name="log-in-outline" class="m-0 me-1 fs-4"></ion-icon> In
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-danger" data-bs-toggle="tab" href="#out" role="tab" onClick={() => {handleTabSwitch('out')}}>
                                <ion-icon name="log-out-outline" class="m-0 me-1 fs-4"></ion-icon> Out
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="login-form mt-3 pe-2 ps-2">
                    <div class="section d-flex justify-content-center flex-column align-items-center">
                        {/* {imagePreview === false &&
                            <video id="videoCam" class="mb-2" style={{height: '250px', width: '250px', objectFit: 'cover', borderRadius: '100%'}}></video>
                        }
                        {imagePreview === true &&
                            <div id="output" class="checkInOut mb-2" style={{height: '250px', width: '250px', objectFit: 'cover', borderRadius: '100%'}}></div>
                        }
                        {imagePreview === true &&
                            <button class="btn btn-primary" onClick={() => {takeshot('recapture')}}>
                                <ion-icon name="camera-outline"></ion-icon>&nbsp;Recapture
                            </button>
                        }
                        {imagePreview === false &&
                            <button class="btn btn-primary" onClick={() => {takeshot('capture')}}>
                                <ion-icon name="camera-outline"></ion-icon>&nbsp;Capture
                            </button>
                        } */}
                        <Face></Face>
                    </div>
                    {/* <div class="section mt-3">
                        <h2 class="d-flex align-items-end justify-content-center mb-0" style={{cursor: 'pointer'}}>
                            {userData?.FullName}
                        </h2>
                        <div onClick={handleUserList} class="fs-6" style={{textDecoration: 'underline', cursor: 'pointer'}}>Select User</div>
                    </div> */}
                    <div class="section mt-3 mb-5">
                        {/* <span class="fs-6">Enter Pin</span> */}
                        <form>

                            {/* <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <input type="text" class="form-control verify-input" id="smscode" placeholder="••••••" maxlength="6" onChange={(e) => {handleValidatePin(e.target.value)}} />
                                </div>
                            </div> */}

                            <div class="form-button-group">
                                <button type="button" class="btn btn-primary btn-block btn-lg" disabled={(disabledButton === 'true') ? false : true} onClick={checkInOut}>Check IN / OUT</button>
                            </div>

                        </form>
                            

                    </div>
                </div>
            </div>
            <UserBottomOffcanvas inOut={tabType}></UserBottomOffcanvas>
        </div>
    )
}

const mapStateToProps = ({ data }) => {
    const {
        userData
    } = data
    return {
        userData
    }
}
  
const mapDispatchToProps = {
    showUserListOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCheckInOut)