import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { useNavigate } from 'react-router'
import NewOrderItem from './Components/NewOrderItem'
import CheckoutComponent from './Components/CheckoutComponent'
import CheckoutOffcanvas from '../layout/Sidebar/CheckoutOffcanvas'
import ItemSizeOffcanvas from '../layout/Sidebar/ItemSizeOffcanvas'
import PaymentOffcanvas from '../layout/Sidebar/PaymentOffcanvas'
import QuantityOffcanvas from '../layout/Sidebar/QuantityOffcanvas'
import { generalDataUpdate } from '../../features/utilities/generalSlice'

const OrdersDetail = ({
    generalDataUpdate,
    categories,
    items,
    activeTabName
}) => {
    let navigate = useNavigate()
    const [tabName, setTabName] = useState('')
    const [buttonStyleObj, setButtonStyleObj] = useState(null)
    const [topScrollValueTemp, setTopScrollValueTemp] = useState(null)
    const [cardBg, setCardBg] = useState('transparent')
    const itemCardDetail = items

    const itemList = [{
        "qty": "2",
        "itemName":"Mango Juice",
        "price":"80"
    },{
        "qty": "5",
        "itemName":"Chocolate Shake",
        "price":"100"
    },{
        "qty": "1",
        "itemName":"Burger",
        "price":"40"
    },{
        "qty": "1",
        "itemName":"Pizza",
        "price":"280"
    },{
        "qty": "1",
        "itemName":"Extra Onion",
        "price":"10"
    }]

    useEffect(() => {
        if (activeTabName) {
            setTabName(activeTabName)
        }
    }, [activeTabName])

    let buttonStyle = null
    let topScrollValue = null
    let cardBgColor = ''
    useEffect(() => {
        if (tabName === 'sales') {
            buttonStyle = {
                "buttonText": "Sales",
                "buttonColor":"primary"
            }
        } else if (tabName === 'purchase') {
            buttonStyle = {
                "buttonText": "Purchase",
                "buttonColor":"warning"
            }
        } else if (tabName === 'wastage') {
            buttonStyle = {
                "buttonText": "Discard",
                "buttonColor":"danger"
            }
        }

        if (tabName === 'sales') {
            cardBgColor = '#e4e4ff'
        } else if (tabName === 'purchase') {
            cardBgColor = '#ffefd9'
        } else if (tabName === 'wastage') {
            cardBgColor = '#ffd0d0'
        }

        if (tabName === 'sales') {
            topScrollValue = [{
                "icon": "storefront-outline",
                "name":"Counter Sale"
            },{
                "icon": "restaurant-outline",
                "name":"Dine In"
            },{
                "icon": "bag-handle-outline",
                "name":"Pick Up"
            },{
                "icon": "phone-portrait-outline",
                "name":"Zomato / Swiggy"
            }]
        } else if (tabName === "purchase") {
            topScrollValue = [{
                "icon": "card-outline",
                "name":"Purchase"
            },{
                "icon": "newspaper-outline",
                "name":"Prepare"
            }, {
                "icon": "newspaper-outline",
                "name":"Trash"
            }]
        } else if (tabName === "wastage" ) {
            topScrollValue = [{
                "icon": "trash-bin-outline",
                "name":"Wastage"
            },{
                "icon": "trash-outline",
                "name":"Removal"
            },{
                "icon": "swap-horizontal-outline",
                "name":"Transfers"
            }]
        }
    }, [tabName])

    useEffect(() => {
        generalDataUpdate({buttonStyle: buttonStyle})
        setButtonStyleObj(buttonStyle)
        setCardBg(cardBgColor)
        setTopScrollValueTemp(topScrollValue)
    }, [tabName])

    useEffect(() => {
        if (categories === null || categories === undefined || items === null || items === undefined) {
            navigate('/load')
        }
    }, [categories, items])

    return (
        <div id="appCapsule">
            <div class="section full">
                <div class="row">
                    <div class="col-12 col-md-8 col-lg-9">
                        <NewOrderItem itemCardDetail={itemCardDetail} searchWithButton itemCategories itemView pageName={'OrderDetail'} cardBg={cardBg} categories={categories} countInRow={3}></NewOrderItem>
                    </div>
                    <div className="col-md-4 col-lg-3 d-none d-md-block border shadowed" style={{position: 'fixed', top: '56px', right: 0, bottom: 0}}>
                        <CheckoutComponent topScroll={(topScrollValueTemp !== null) ? topScrollValueTemp : topScrollValueTemp} mobileNo itemList={itemList} checkoutButton={(buttonStyleObj !== null) ? buttonStyleObj : buttonStyleObj} pageName={tabName}></CheckoutComponent>
                        <CheckoutOffcanvas topScroll={(topScrollValueTemp !== null) ? topScrollValueTemp : topScrollValueTemp} mobileNo itemList={itemList} checkoutButton={(buttonStyleObj !== null) ? buttonStyleObj : buttonStyleObj} pageName={tabName}></CheckoutOffcanvas>
                        <ItemSizeOffcanvas></ItemSizeOffcanvas>
                        <PaymentOffcanvas></PaymentOffcanvas>
                        <QuantityOffcanvas></QuantityOffcanvas>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ data }) => {
    const {
        categories,
        items,
        activeTabName
    } = data
    return {
        categories,
        items,
        activeTabName
    }
  }
  
const mapDispatchToProps = {
    generalDataUpdate
}

export default connect(mapStateToProps,mapDispatchToProps)(OrdersDetail)
