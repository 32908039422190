import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    customOrderPaymentModalVisible: false,
    orderPaymentData: null,
    orderID: null
}

export const CustomOrderPaymentModalSlice = createSlice({
    name: 'customOrder',
    initialState,
    reducers: {
      showCustomOrderPaymentModal: (state, action) => {
        const {
            customOrderPaymentModalVisible,
            orderPaymentData,
            orderID
        } = action.payload
        state.customOrderPaymentModalVisible = customOrderPaymentModalVisible
        state.orderPaymentData = orderPaymentData
        state.orderID = orderID
      },
      hideCustomOrderPaymentModal: (state) => {
        state.customOrderPaymentModalVisible = false
      }
    }
})

export const { showCustomOrderPaymentModal, hideCustomOrderPaymentModal } = CustomOrderPaymentModalSlice.actions;


export default CustomOrderPaymentModalSlice.reducer