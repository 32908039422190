import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import AddRemoveStock from '../../Pages/AddRemoveStock';
import { hideAddRemoveStockModal } from '../../../features/Offcanvas/addremoveStockModalSlice';
import { setAllBranchID } from '../../../features/Data/dataSlice';

const AddRemoveStockModal = ({
    addRemoveStockModalVisible,
    hideAddRemoveStockModal,
    stockActionType,
    setAllBranchID,
    allBranchID
}) => {
    const [screenWidth, setScreenWidth] = useState(0)
    const handleCloseAddRemoveStockModal = () => {
        console.log(allBranchID)
        setAllBranchID({allBranchID: parseInt(allBranchID) + 1})
        hideAddRemoveStockModal()
    }
    useEffect(() => {
        if (addRemoveStockModalVisible) {
            setScreenWidth(window.screen.width)
        }
    }, [addRemoveStockModalVisible])
    return (
        <div>
            <Modal
                show={addRemoveStockModalVisible}
                backdrop={'static'}
                size="xl"
                centered
                onHide={() => {handleCloseAddRemoveStockModal()}}
            >
                <div class="d-flex justify-content-center align-items-center cursor-pointer" onClick={() => {handleCloseAddRemoveStockModal()}} style={{position: 'absolute', top: 0, right: (screenWidth < 600) ? '0px' : '-45px', background: '#ffffff8a', borderRadius: '100%', height: '30px', width: '30px', zIndex: 10}}>
                    <ion-icon name="close-outline" class="fs-1"></ion-icon>
                </div>
                {/* <Modal.Header closeButton>
                    <h2 class="mb-0" style={{textTransform: 'capitalize'}}>{stockActionType}</h2>
                </Modal.Header> */}
                <Modal.Body>
                    <AddRemoveStock></AddRemoveStock>
                </Modal.Body>
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ addRemoveStock, data }) => {
    const {
        addRemoveStockModalVisible,
        stockActionType
    } = addRemoveStock
    const {
        allBranchID
    } = data
    return {
        addRemoveStockModalVisible,
        stockActionType,
        allBranchID
    }
}
  
const mapDispatchToProps = {
    hideAddRemoveStockModal,
    setAllBranchID
}

export default connect(mapStateToProps, mapDispatchToProps)(AddRemoveStockModal);