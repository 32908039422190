import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import Moment from 'react-moment'
import { hideSnapShotModal } from '../../../features/Offcanvas/SnapShotSlice'

const SnapShotModal = ({
    shopSummarySnapShotModalVisible,
    snapShotData,
    hideSnapShotModal
}) => {
    console.log(snapShotData)
    const [stockTotal, setStockTotal] = useState(0)
    useEffect(() => {
        if (snapShotData && snapShotData.Summary.length > 0) {
            let stockTotalSum = 0
            snapShotData.Summary.map((v,k) => {
                stockTotalSum += parseInt(v.Stock)
            })
            setStockTotal(stockTotalSum)
        }
    }, [snapShotData])
    return (
        <div>
            <Modal show={shopSummarySnapShotModalVisible} backdrop size={'xl'} centered onHide={() => {hideSnapShotModal()}}>
                <Modal.Header closeButton>
                    <Modal.Title style={{lineHeight: 0.3}}>
                        <h3 class={(snapShotData?.Status == 't') ? "text-success mb-0" : "text-danger mb-0"}>{(snapShotData?.Status == 't') ? 'Opened' : 'Closed'}</h3>
                        <span class="text-secondary fs-10"><Moment format="hh:mm:ss A">{snapShotData?.DateTime}</Moment></span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body p-0">
                    <div class="section full mb-2">
                        <div class="p-0">
                            {(snapShotData?.Summary.length > 0) &&
                                <div class="table-responsive">
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="border-end fw-bold">Name</th>
                                                <th scope="col" class="text-end fw-bold">Stock</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {snapShotData?.Summary.map((v,k) => {
                                                return <tr>
                                                    <td class="border-end">{v.Name}</td>
                                                    <td class="text-end">{v.Stock}</td>
                                                </tr>
                                            })}
                                            <tr>
                                                <td class="border-end fw-bold">Total</td>
                                                <td class="text-end fw-bold">{stockTotal}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            }
                            {(snapShotData?.Summary.length == 0) &&
                                <h2 class="text-danger text-center mt-1">No stock found</h2>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ snapShot }) => {
    const {
        shopSummarySnapShotModalVisible,
        snapShotData
    } = snapShot
    return {
        shopSummarySnapShotModalVisible,
        snapShotData
    }
  }
  
  const mapDispatchToProps = {
    hideSnapShotModal
  }

export default connect(mapStateToProps, mapDispatchToProps)(SnapShotModal)