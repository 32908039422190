import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Veg from '../images/icon/veg.png'
import NonVeg from '../images/icon/non-veg.png'
import jwt_decode from 'jwt-decode'
import { showItemProfileOffcanvas } from '../../features/Offcanvas/itemProfileOffcanvasSlice'
import { setItemProfileDetails } from '../../features/Offcanvas/itemProfileOffcanvasSlice'
import { showAddItemOptionOffcanvas } from '../../features/Offcanvas/addStockOptionOffcanvasSlice'
import AddItemOptionOffcanvas from '../layout/Sidebar/AddItemOptionOffcanvas'
import FileUploadModal from '../layout/Sidebar/FileUploadModal'
import SearchWithButtons from './Components/SearchWithButtons'

const ManageItems = ({
    categories,
    items,
    showItemProfileOffcanvas,
    setItemProfileDetails,
    authToken,
    showAddItemOptionOffcanvas,
    categoryID,
    itemName
}) => {
    const tokenData = jwt_decode(authToken)
    const [filteredCategory, setFilteredCategory] = useState(null)
    const [allCategoryFlag, setAllCategoryFlag] = useState(true)
    let navigate = useNavigate()
    const handleAddQuickAdd = (e, detail) => {
        e.stopPropagation();
        showItemProfileOffcanvas({itemProfileVisible: true})
        setItemProfileDetails({itemProfileData: detail})
    }
    useEffect(() => {
        if (items === null || items === undefined || items === '') {
            navigate('/load')
        }
    },[items])
    useEffect(() => {
        if (items) {
            let filtered;
            if (categoryID !== 'all' && categoryID !== undefined) {
                setAllCategoryFlag(false)
                filtered = items.filter((item) => categoryID === item.Item_CategoryID);
            } else {
                setAllCategoryFlag(true)
                filtered = items
            }
            if(itemName?.trim().length > 0) filtered = filtered.filter((item) => (item?.ItemTitle?.toLowerCase()?.includes(itemName?.toLowerCase()?.trim()) || item?.AlternateText?.includes(itemName?.trim()) || item?.Shortcode?.includes(itemName?.trim())))
            setFilteredCategory(filtered)
        }
    }, [categoryID, itemName, items])
    const handleAddItem = () => {
        let defObj = {
            "OfflineInvoiceNumber": '',
            "Orders":[{
                "Items":[],
                "TotalQty":0,
                "Subtotal":0,
                "TotalTax":{
                    "cgst": 0,
                    "sgst":0
                },
                "Total":0,
                "Type":'',
                "DateTime":null,
                "OrderNumber":'',
                "OrderStatus": 0
            }],
            "Currency":"INR",
            "Phone":null,
            "Date": null,
            "UserName":"",
            "BalanceAmount":0,
            "PaymentMethod":"",
            "OrderType": '',
            "Amount": 0,
            "TransactionDate": null,
            "Customer_ID": "",
            "Status": -2,
            "TotalDiscount": 0
        }
        setItemProfileDetails({itemProfileData: defObj})
        navigate('/itemProfile')
        // showAddItemOptionOffcanvas({addItemOptionVisible: true})
    }
    return (
        <div id="appCapsule">
            <div class="section full">
                <div class="pt-1">
                    <SearchWithButtons pageName={'ManageItems'}></SearchWithButtons>
                </div>
            </div>
            <div class="section full mt-1">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-12">
                        <div class="p-0 mb-4">
                            <div class="section full mt-1">
                                {(categories !== null && categories !== undefined) &&
                                    <>
                                        {categories.map(v => {
                                            return <>
                                                <div class="listview-title sticky-title" style={{marginRight: '-8px', marginLeft: '-8px'}}>
                                                    <strong>{v.Name}</strong>
                                                </div>
                                                <ul class="listview image-listview flush bg-transparent">
                                                    {filteredCategory !== null &&
                                                        <>
                                                            {filteredCategory.map((value, key) => {
                                                                let count = 0
                                                                value.Sizes.map((v,k) => {
                                                                    count += parseInt(v.SalesQty)
                                                                })
                                                                return <>
                                                                    {v.ID === value.Item_CategoryID &&
                                                                        <li onClick={(e) => {handleAddQuickAdd(e, value)}}>
                                                                            <a href="javascript:void(0)" class="item ps-0">
                                                                            <div style={{height: '50px',width: '10px',color: 'transparent',marginRight: '2px', backgroundColor: value.Color}}>.</div>
                                                                                <div class="imageWrapper me-1">
                                                                                    {(value.ImageURL !== undefined && value.ImageURL !== null && value.ImageURL !== '') &&
                                                                                        <img src={value.ImageURL && value.ImageURL} alt="image" class="imaged" style={{height: '50px', width: '50px', objectFit: 'cover'}} />
                                                                                    }
                                                                                    {(value.ImageURL === '') &&
                                                                                        <img src="https://us.123rf.com/450wm/viktorijareut/viktorijareut1503/viktorijareut150300119/37649514-grey-tray-kitchenware-trey-isolated-tray-vector-closed-tray.jpg?ver=6" alt="image" class="imaged" style={{height: '50px', width: '50px', objectFit: 'cover'}} />
                                                                                    }
                                                                                </div>
                                                                                <div class="in">
                                                                                    <div>
                                                                                        <header>{value.Shortcode}</header>
                                                                                        {value.FoodType === 'veg' &&
                                                                                            <img src={Veg} width="14px" style={{paddingBottom: '2px', marginRight: '2px'}} />
                                                                                        }
                                                                                        {value.FoodType === 'non-veg' &&
                                                                                            <img src={NonVeg} width="14px" style={{paddingBottom: '2px', marginRight: '2px'}} />
                                                                                        }
                                                                                        {value.ItemTitle}
                                                                                        {(value.AlternateText !== '' && value.AlternateText !== null && value.AlternateText !== undefined) &&
                                                                                            <small> ({value.AlternateText})
                                                                                            </small>
                                                                                        }
                                                                                        {value.Sizes.length === 0 &&
                                                                                            <footer>₹{value.MRP}</footer>
                                                                                        }
                                                                                        {value.Sizes.length !== 0 &&
                                                                                            <footer>{value.Sizes.length} Size(s)</footer>
                                                                                        }
                                                                                    </div>
                                                                                    <span class="text-muted d-flex align-items-center">
                                                                                        {value.Stockable === 't' &&
                                                                                            <>  
                                                                                                {value.Sizes.length === 0 &&
                                                                                                    <span class="badge bg-primary fs-10 me-1" style={{height: '12px'}}><ion-icon name="cart-outline" class="fs-10" style={{paddingRight: '3px'}}></ion-icon> {value.SalesQty}</span>
                                                                                                }
                                                                                                {value.Sizes.length > 0 &&
                                                                                                    <span class="badge bg-primary fs-10 me-1" style={{height: '12px'}}><ion-icon name="cart-outline" class="fs-10" style={{paddingRight: '3px'}}></ion-icon> {count}</span>
                                                                                                }
                                                                                            </>
                                                                                        }
                                                                                        {(value?.QuickAdd === null || value?.QuickAdd === undefined || value?.QuickAdd === 'f' || value?.QuickAdd === '') &&
                                                                                            <i class="fa fa-star text-secondary me-1 fs-5" aria-hidden="true"></i>
                                                                                        }
                                                                                        {value?.QuickAdd === 't' &&
                                                                                            <i class="fa fa-star text-warning me-1 fs-5" aria-hidden="true"></i>
                                                                                        }
                                                                                        Edit
                                                                                    </span>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                    }
                                                                </>
                                                            })}
                                                        </>
                                                    }
                                                </ul>
                                            </>
                                        })}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {tokenData?.userInfo?.RoleID !== '5' &&
                    <div class="fab-button text bottom-center responsive-bottom-fab">
                        <button class="fab fs-6 pe-2 ps-2" style={{height: '40px', fontFamily: "'Inter', sans-serif"}} onClick={() => {handleAddItem()}}>
                            <ion-icon name="add-outline" class="fs-6"></ion-icon>
                            New Item
                        </button>
                    </div>
                }
            </div>
            <AddItemOptionOffcanvas></AddItemOptionOffcanvas>
            <FileUploadModal></FileUploadModal>
        </div>
    )
}

const mapStateToProps = ({ data, auth }) => {
    const {
        categories,
        items,
        categoryID,
        itemName
    } = data
    const {
        authToken
    } = auth
    return {
        categories,
        items,
        authToken,
        categoryID,
        itemName
    }
  }
  
  const mapDispatchToProps = {
    showItemProfileOffcanvas,
    setItemProfileDetails,
    showAddItemOptionOffcanvas
  }

export default connect(mapStateToProps, mapDispatchToProps)(ManageItems)
