import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import CheckoutMobileNo from './BitComponents/CheckoutMobileNo'
import moment from 'moment-timezone'
import axios from '../../AxiosConfig'
import { showAppToast } from '../../features/utilities/appToastSlice'
import { setEditCustomOrderData } from '../../features/Data/dataSlice'

const CustomOrderEdit = ({
    businessData,
    authToken,
    items,
    showAppToast,
    userData,
    editCustomOrderData,
    setEditCustomOrderData
}) => {
    const [branchData, setBranchData] = useState(null)
    const [businessTempData, setBusinessTempData] = useState(null)
    const [selectedOption, setSelectedOption] = useState(null)
    const [tempCurrentDate, setTempCurrentDate] = useState(null)
    const [disableSubmitButton, setDisableSubmitButton] = useState(false)
    const [orderNumber, setOrderNumber] = useState(null)
    const [customSizeInput, showCustomSizeInput] = useState(false)
    const [imagePreview, setImagePreview] = useState("")
    // USESTATE FOR FORM FIELDS START
    const [billNo, setBillNo] = useState(null)
    const [orderDate, setOrderDate] = useState(null)
    const [deliveryDate, setDeliveryDate] = useState(null)
    const [eventType, setEventType] = useState("birthday")
    const [paymentMethod, setPaymentMethod] = useState("Cash")
    const [remark, setRemark] = useState('')
    const [eventText, setEventText] = useState(null)
    const [deliveryTime, setDeliveryTime] = useState("12:00")
    const [selectedCakeType, setSelectedCakeType] = useState({
        "label": "Normal",
        "value": "normal"
    })
    const [itemSize, setItemSize] = useState(null)
    // USESTATE FOR FORM FIELDS END
    const [titleName, setTitleName] = useState(null)
    const [tempCustomOrderObj, setTempCustomOrderObj] = useState(null)
    let navigate = useNavigate()
    const calculateYear = (number) => {
        let num = number.toString()
        if (num.length === 1) {
            num = '00' + num
        } else if (num.length === 2) {
            num = '0' + num
        }
        return num
    }
    useEffect(() => {
        const tokenData = jwt_decode(authToken)
        if (businessData) {
          businessData.map((value, key) => {
            value.Branches.map((v,k) => {
                if (value.ID === tokenData.userInfo.Business_ID && v.ID === tokenData.userInfo.DefaultLocationID) {
                    setBranchData(v)
                    setBusinessTempData(value)
                }
            })
          }) 
        }
    }, [businessData, authToken])
    // const options = [
    //     {
    //       label: "Cake",
    //       options: [
    //         { label: "vanilla", value: "Vanilla" },
    //         { label: "chocolate", value: "Chocolate" }
    //       ]
    //     },
    //     {
    //         label: "Pastry",
    //         options: [
    //           { label: "strawbarry", value: "Strawbarry" },
    //           { label: "mango", value: "Mango" },
    //           { label: "chocolate", value: "ChocolatePastry" }
    //         ]
    //     }
    // ];
    useEffect(() => {
        let options = []
        if (items) {
            items.map((v,k) => {
                options.push({
                    "label": v.ItemTitle,
                    "value": v.ID,
                    "data": v
                })
            })
            setTitleName(options)
            const currentDate = new Date().toISOString().split('T')[0]
            setTempCurrentDate(currentDate)
            setOrderDate(currentDate)
            setDeliveryDate(currentDate)
        } else {
            navigate('/load')
        }
    }, [items])
    const cakeType = [
        { label: "Normal", value: "normal" },
        { label: "Photo Cake", value: "photoCake" },
        { label: "Tower", value: "tower" },
        { label: "Doll", value: "doll" },
        { label: "Square", value: "square" },
        { label: "Round", value: "round" },
        { label: "Heart", value: "heart" },
        { label: "Other Design", value: "otherDesign" },
        { label: "Less Cream", value: "lessCream" },
        { label: "Foundant", value: "foundant" },
        { label: "Home Delivery", value: "homeDelivery" }
    ]
    function updateObj (data, dataType) {
        if (editCustomOrderData) {
            const tempCustomOrderObj2 = JSON.parse(JSON.stringify(editCustomOrderData))
            // console.log(data)

            if (dataType === 'billNo' ) {
                setBillNo(data)
                tempCustomOrderObj2.billNo = data
            } else if (dataType === 'orderDate') {
                setOrderDate(data)
                tempCustomOrderObj2.OrderDate = data
            } else if (dataType === 'deliveryDate') {
                setDeliveryDate(data)
                tempCustomOrderObj2.DeliveryDate = data
                tempCustomOrderObj2.OrderDetails.DeliveryDate = data
            } else if (dataType === 'eventType') {
                setEventType(data)
                tempCustomOrderObj2.EventType = data
                tempCustomOrderObj2.OrderDetails.EventType = data
            } else if (dataType === 'eventText') {
                setEventText(data)
                tempCustomOrderObj2.EventText = data
                tempCustomOrderObj2.OrderDetails.EventText = data
            } else if (dataType === 'deliveryTime') {
                setDeliveryTime(data)
                tempCustomOrderObj2.DeliveryTime = data
                tempCustomOrderObj2.OrderDetails.DeliveryTime = data
            } else if (dataType === 'cakeType') {
                setSelectedCakeType(data)
                tempCustomOrderObj2.CakeType = data
                tempCustomOrderObj2.OrderDetails.CakeType = data
            } else if (dataType === 'paymentMethod') {
                setPaymentMethod(data)
                tempCustomOrderObj2.PaymentMethod = data
            } else if (dataType === 'remark') {
                setRemark(data)
                tempCustomOrderObj2.Remark = data
                tempCustomOrderObj2.OrderDetails.Remark = data
            }  else if (dataType === 'phone') {
                // setPhone(data)
                tempCustomOrderObj2.Phone = data
                tempCustomOrderObj2.OrderDetails.Phone = data
            }
            setEditCustomOrderData({editCustomOrderData: tempCustomOrderObj2})
            setTempCustomOrderObj(tempCustomOrderObj2)   
        }
    }

    async function updateObjReturn(obj, data, key) {
        let newObj = JSON.parse(JSON.stringify(obj))
        if (key === 'phone') {
            newObj.Phone = data
            newObj.OrderDetails.Phone = data
        } else if (key === 'eventText') {
            setEventText(data)
            newObj.EventText = data
            newObj.OrderDetails.EventText = data
        } else if (key === 'eventType') {
            setEventType(data)
            newObj.EventType = data
            newObj.OrderDetails.EventType = data
        } else if (key === 'orderDate') {
            setOrderDate(data)
            newObj.OrderDate = data
            newObj.Date = data
        } else if (key === 'deliveryDate') {
            setDeliveryDate(data)
            newObj.OrderDetails.DeliveryDate = data
        } else if (key === 'deliveryTime') {
            setDeliveryTime(data)
            newObj.OrderDetails.DeliveryTime = data
        } else if (key === 'orderNumber') {
            newObj.OrderNumber = data
            newObj.OfflineInvoiceNumber = "IN" + data
        } else if (key === 'dateTime') {
            newObj.DateTime = data
            newObj.TransactionDate = data
        } else if (key === 'advance') {
            newObj.Advance = data
        } else if (key === 'balanceAmount') {
            newObj.BalanceAmount = data
            newObj.OrderDetails.BalanceAmount = data
            newObj.Balance = data
        } else if (key === 'amount') {
            newObj.Amount = data
            newObj.TotalDiscount = 0
            newObj.PaymentMethod = "Cash"
            newObj.OrderType = "Custom"
            newObj.Status = 1
        } else if (key === 'cakeType') {
            setSelectedCakeType(data)
            newObj.CakeType = data
        }
        // console.log(newObj)
        return newObj
    }

    useEffect(async () => {
        // console.log(editCustomOrderData)
        let phoneObj = await updateObjReturn(editCustomOrderData, editCustomOrderData?.OrderDetails?.Phone, 'phone')
        let nameOnCakeObj = await updateObjReturn(phoneObj, editCustomOrderData?.OrderDetails?.EventText, 'eventText')
        let eventTypeObj = await updateObjReturn(nameOnCakeObj, editCustomOrderData?.OrderDetails?.EventType, 'eventType')
        let orderDateObj = await updateObjReturn(eventTypeObj, editCustomOrderData?.OrderDate, 'orderDate')
        let deliveryDateObj = await updateObjReturn(orderDateObj, editCustomOrderData?.OrderDetails?.DeliveryDate, 'deliveryDate')
        let deliveryTimeObj = await updateObjReturn(deliveryDateObj, editCustomOrderData?.OrderDetails?.DeliveryTime, 'deliveryTime')
        let orderNumberObj = await updateObjReturn(deliveryTimeObj, orderNumber, 'orderNumber')
        let dateTimeObj = await updateObjReturn(orderNumberObj, newDate, 'dateTime')
        let advanceObj = await updateObjReturn(dateTimeObj, (editCustomOrderData?.Subtotal - parseInt(editCustomOrderData?.BalanceAmount)), 'advance')
        let balanceAmountObj = await updateObjReturn(advanceObj, (parseInt(editCustomOrderData?.BalanceAmount)), 'balanceAmount')
        let amountObj = await updateObjReturn(balanceAmountObj, parseInt(editCustomOrderData?.Subtotal), 'amount')
        let cakyTypeObj = await updateObjReturn(amountObj, editCustomOrderData?.OrderDetails?.CakeType, 'cakeType')
        // console.log(cakyTypeObj)
        setTempCustomOrderObj(cakyTypeObj)
        setEditCustomOrderData({editCustomOrderData: cakyTypeObj})
        // setTempCustomOrderObj(nameOnCakeObj)
        // setEventText(editCustomOrderData?.NameOnCake)
        // updateObj(editCustomOrderData?.OrderDate, 'orderDate')
        // setOrderDate(editCustomOrderData?.OrderDate)
        // updateObj(editCustomOrderData?.DeliveryDate, 'deliveryDate')
        // setDeliveryDate(editCustomOrderData?.DeliveryDate)
        // updateObj(editCustomOrderData?.DeliveryTime, 'deliveryTime')
        // setDeliveryTime(editCustomOrderData?.DeliveryTime)
        // updateObj(editCustomOrderData?.CakeType, 'cakeType')
        // setImagePreview(editCustomOrderData?.ReferenceImage)
    }, [orderNumber])

    // ON CHANGE EVENT START
    const updateBillNo = (billNo) => {
        updateObj(billNo, 'billNo')
    }
    const updatePhone = (phone) => {
        updateObj(phone, 'phone')
    }
    const updateOrderDate = (orderDate) => {
        updateObj(orderDate, 'orderDate')
    }
    const updateDeliveryDate = (deliveryDate) => {
        updateObj(deliveryDate, 'deliveryDate')
    }
    const updateEventType = (eventType) => {
        updateObj(eventType, 'eventType')
    }
    const updateEventText = (eventText) => {
        updateObj(eventText, 'eventText')
    }
    const updateDeliveryTime = (deliveryTime) => {
        updateObj(deliveryTime, 'deliveryTime')
    }
    const handleCakeTypeChange = (cakeType) => {
        updateObj(cakeType, 'cakeType')
    }
    const updatePaymentMethod = (paymentMethod) => {
        updateObj(paymentMethod, 'paymentMethod')
    }
    const updateRemark = (remark) => {
        updateObj(remark, 'remark')
    }
    // ON CHANGE EVEN END

    useEffect(() => {
        let d = new Date();
        const year = d.getFullYear();
        const start = new Date(d.getFullYear(), 0, 0);
        const diff = d - start;
        const oneDay = 1000 * 60 * 60 * 24;
        const day = Math.floor(diff / oneDay);
        var seconds = (d.getSeconds() < 10 ? '0' : '') + d.getSeconds();
        var minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
        var hour = (d.getHours() < 10 ? '0' : '') + d.getHours();
        setOrderNumber(year + calculateYear(day) + 'AA' + 'K01' + hour + minutes + seconds)
    }, [editCustomOrderData])
    let customOrderItem = []
    let customOrderObj =  {}
    const targetTimezone = 'Asia/Kolkata'
    const newDate = moment().tz(targetTimezone).format('YYYY-MM-DD HH:mm:ss')
    const onlyDate = moment().tz(targetTimezone).format('YYYY-MM-DD')
    const tempBillNo = localStorage.getItem('billNo')
    function resetForm() {
        let d = new Date();
        const year = d.getFullYear();
        const start = new Date(d.getFullYear(), 0, 0);
        const diff = d - start;
        const oneDay = 1000 * 60 * 60 * 24;
        const day = Math.floor(diff / oneDay);
        var seconds = (d.getSeconds() < 10 ? '0' : '') + d.getSeconds();
        var minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
        var hour = (d.getHours() < 10 ? '0' : '') + d.getHours();
        setOrderNumber(year + calculateYear(day) + 'AA' + 'K01' + hour + minutes + seconds)
        const tempOrderNumber = year + calculateYear(day) + 'AA' + 'K01' + hour + minutes + seconds
        for (let i = 0; i < 2; i++) {
            customOrderItem.push({
                "quantity":0,
                "MRP":0,
                "Cost":0,
                "ItemTitle":null,
                "Item_ID":null,
                "Taxes":0,
                "Status":false,
                "Image": null,
                "AlternateText": null,
                "Sizes": [],
                "CustomSizeFlag": false
            }) 
        }
        customOrderObj = {
            "OfflineInvoiceNumber": "IN" + tempOrderNumber,
            "Items":customOrderItem,
            "TotalQty":0,
            "Subtotal":0,
            "TotalTax": 0,
            "Total":0,
            "EventType":"birthday",
            "DateTime":newDate,
            "OrderNumber":tempOrderNumber,
            "Currency":"INR",
            "Phone":userData?.ContactNumber,
            "Date": onlyDate,
            "UserName":"",
            "BalanceAmount":0,
            "PaymentMethod":"Cash",
            "OrderType": "Custom",
            "Amount": 0,
            "TransactionDate": newDate,
            // "Customer_ID": "",
            "Status": 1,
            "TotalDiscount": 0,
            "billNo": parseInt(tempBillNo) + 1,
            "OrderDate": onlyDate,
            "DeliveryDate": onlyDate,
            "DeliveryTime": "12:00",
            "CakeType":[{ label: "Normal", value: "normal" }],
            "Remark": "",
            "OrderDetails":{
                "BalanceAmount":0,
                "EventType":"birthday",
                "EventText":"",
                "DeliveryDate": onlyDate,
                "DeliveryTime": "12:00",
                "CakeType":[{ label: "Normal", value: "normal" }],
                "Remark": ""
            }
        }
        setTempCustomOrderObj(customOrderObj)
    }
    useEffect(() => {
        if (editCustomOrderData == '' || editCustomOrderData == null || editCustomOrderData == undefined) {
            resetForm()
        }
    }, [editCustomOrderData])
    const handleTitleChange = (value, index) => {
        setSelectedOption(value)
        showCustomSizeInput(false)
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        if (value.data.Sizes.length > 0) {
            tempCustomOrderObj2.Items[index].Item_ID = value.data.Item_ID
        } else {
            tempCustomOrderObj2.Items[index].Item_ID = value.value
            tempCustomOrderObj2.Items[index].AlternateText = value.data.AlternateText
            tempCustomOrderObj2.Items[index].MRP = value.data.MRP
            tempCustomOrderObj2.Items[index].Cost = value.data.Cost
        }
        tempCustomOrderObj2.Items[index].ItemTitle = value.label
        tempCustomOrderObj2.Items[index].Sizes = value.data.Sizes
        tempCustomOrderObj2.Items[index].Image = value.data.ImageURL
        tempCustomOrderObj2.Items[index].quantity = 1
        let count = 0
        let tempSubtotal = 0
        tempCustomOrderObj2.Items.map((v,k) => {
            if (v.quantity && v.MRP) {
                tempSubtotal += v.quantity * v.MRP
            }
            count ++
        })
        tempCustomOrderObj2.Subtotal = tempSubtotal
        tempCustomOrderObj2.Total = tempSubtotal
        tempCustomOrderObj2.Amount = tempSubtotal
        tempCustomOrderObj2.Advance = tempSubtotal
        setEditCustomOrderData({editCustomOrderData: tempCustomOrderObj2})
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const updateItemSizeCustom = (value, index, parentData) => {
        if (parentData.ItemSize === 'custom') {
            const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
            tempCustomOrderObj2.Items[index].CustomSIze = value
            tempCustomOrderObj2.Items[index].Item_ID = selectedOption.value
            setEditCustomOrderData({editCustomOrderData: tempCustomOrderObj2})
            setTempCustomOrderObj(tempCustomOrderObj2)    
        } else {
            const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
            tempCustomOrderObj2.Items[index].CustomSIze = value
            setEditCustomOrderData({editCustomOrderData: tempCustomOrderObj2})
            setTempCustomOrderObj(tempCustomOrderObj2)
        }
    }
    const updateItemQty = (value, index) => {
        setItemSize(value)
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.Items[index].quantity = value
        let count = 0
        let tempSubtotal = 0
        tempCustomOrderObj2.Items.map((v,k) => {
            if (v.quantity && v.MRP) {
                tempSubtotal += v.quantity * v.MRP
            }
            count ++
        })
        tempCustomOrderObj2.Subtotal = tempSubtotal
        tempCustomOrderObj2.Total = tempSubtotal
        tempCustomOrderObj2.Amount = tempSubtotal
        tempCustomOrderObj2.Advance = tempSubtotal
        setEditCustomOrderData({editCustomOrderData: tempCustomOrderObj2})
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const updateItemMRP = (value, index) => {
        setItemSize(value)
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.Items[index].MRP = value
        let count = 0
        let tempSubtotal = 0
        tempCustomOrderObj2.Items.map((v,k) => {
            if (v.quantity && v.MRP) {
                tempSubtotal += v.quantity * v.MRP
            }
            count ++
        })
        tempCustomOrderObj2.Subtotal = tempSubtotal
        tempCustomOrderObj2.Total = tempSubtotal
        tempCustomOrderObj2.Amount = tempSubtotal
        tempCustomOrderObj2.Advance = tempSubtotal
        setEditCustomOrderData({editCustomOrderData: tempCustomOrderObj2})
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const updateAdvance = (value, index) => {
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.Advance = value
        tempCustomOrderObj2.Balance = parseInt(tempCustomOrderObj2.Subtotal) - parseInt(tempCustomOrderObj2.Advance)
        tempCustomOrderObj2.BalanceAmount = parseInt(tempCustomOrderObj2.Subtotal) - parseInt(tempCustomOrderObj2.Advance)
        tempCustomOrderObj2.OrderDetails.BalanceAmount = parseInt(tempCustomOrderObj2.Subtotal) - parseInt(tempCustomOrderObj2.Advance)
        setEditCustomOrderData({editCustomOrderData: tempCustomOrderObj2})
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const handleAddItem = (e) => {
        e.stopPropagation()
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.Items.push({
            "quantity":0,
            "MRP":0,
            "Cost":0,
            "ItemTitle":null,
            "Item_ID":null,
            "Taxes":0,
            "Status":false,
            "Image": null,
            "AlternateText": null,
            "Sizes": []
        })
        setEditCustomOrderData({editCustomOrderData: tempCustomOrderObj2})
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const uploadRefImage = (filePath) => {
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        const formData = new FormData();
        formData.append('inputFile', filePath)
        axios.post('/applications/upload/items', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(res => {
            setImagePreview(res.data.data)
            tempCustomOrderObj2.ReferenceImage = res.data.data
            setEditCustomOrderData({editCustomOrderData: tempCustomOrderObj2})
            setTempCustomOrderObj(tempCustomOrderObj2)
        }).catch(err => {
        })
    }

    function getDifferentKeys(obj1, obj2) {
        const diff = {};
    
        // Iterate through the keys of the first object
        for (const key in obj1) {
            if (obj1.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
                diff[key] = obj1[key];
            }
        }
    
        return diff;
    }
    // const handleCustomOrderSubmit = (e) => {
    //     e.preventDefault()
        
    //     const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
    //     let totalQuantity = 0
    //     tempCustomOrderObj2.Items.map((v,k) => {
    //         totalQuantity += parseInt(v.quantity)
    //     })
    //     tempCustomOrderObj2.TotalQty = totalQuantity
    //     setTempCustomOrderObj(tempCustomOrderObj2)
    //     // const result = getDifferentKeys(tempCustomOrderObj2, editCustomOrderData);
    //     // setDisableSubmitButton(true)
    //     // axios.post('/Order/CustomOrder', tempCustomOrderObj2).then(res => {
    //     //     if (res.data.message === 'Success') {
    //     //         resetForm()
    //     //         navigate('/customOrders')
    //     //         setDisableSubmitButton(false)
    //     //         showAppToast({ toastMessage:'ORDER PLACED', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center', isVisible: true })
    //     //     } else {
    //     //         setDisableSubmitButton(false)
    //     //         showAppToast({ toastMessage:'Something went wrong', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center', isVisible: true })
    //     //     }
    //     // })
    // }
    const handleCustomOrderSubmit = (e) => {
        e.preventDefault()
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        let totalQuantity = 0
        tempCustomOrderObj2.Items.map((v,k) => {
            totalQuantity += parseInt(v.quantity)
        })
        tempCustomOrderObj2.TotalQty = totalQuantity
        // console.log(tempCustomOrderObj2)
        setTempCustomOrderObj(tempCustomOrderObj2)
        setDisableSubmitButton(true)
        axios.post('/Order/CustomOrder', tempCustomOrderObj2).then(res => {
            if (res.data.message === 'Success') {
                resetForm()
                navigate('/customOrders')
                setDisableSubmitButton(false)
                // showAppToast({ toastMessage:'ORDER PLACED', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center', isVisible: true })
            } else {
                setDisableSubmitButton(false)
                // showAppToast({ toastMessage:'Something went wrong', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center', isVisible: true })
            }
        })
    }
    return (
        <div id="appCapsule" style={{background: 'orange',marginLeft: '-8px', marginRight: '-8px', paddingRight: '8px', paddingLeft: '8px'}}>
            <div class="section full mt-1">
                <form onSubmit={(e) => {handleCustomOrderSubmit(e)}}>
                    <div class="invoice p-0 bg-transparent" style={{boxShadow: 'none'}}>
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center flex-column">
                                <h3 class="mb-0 text-center">{businessTempData?.Name}</h3>
                                <small class="text-center">({branchData?.Name})</small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-6 col-md-6">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" style={{fontSize: '14px'}} for="billNo">Bill No</label>
                                                    <input type="number" class="form-control form-control-sm p-0 ps-1" id="billNo" placeholder="Bill No" value={tempCustomOrderObj?.billNo} autocomplete="off" required onChange={(e) => {updateBillNo(e.target.value)}} style={{minHeight: '36px', height: '36px'}} />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        {/* <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="customerName">Mobile No</label>
                                                </div>
                                            </div>
                                            <CheckoutMobileNo></CheckoutMobileNo>
                                        </p> */}
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" style={{fontSize: '14px'}} for="phone">Phone</label>
                                                    <input type="number" class="form-control form-control-sm p-0 ps-1" id="phone" placeholder="Phone" value={tempCustomOrderObj?.Phone} autocomplete="off" max-length="10" min="0" required onChange={(e) => {updatePhone(e.target.value)}} style={{minHeight: '36px', height: '36px'}} />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" style={{fontSize: '14px'}} for="deliveryDate">Order Date</label>
                                                    <input type="date" class="form-control form-control-sm p-0 ps-1" id="date" placeholder="Date" value={orderDate} autocomplete="off" onChange={(e) => {updateOrderDate(e.target.value)}} style={{minHeight: '36px', height: '36px'}} required />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <p class="mb-1">
                                            <div class="form-group boxed">
                                                <div class="input-wrapper">
                                                    <label class="form-label" style={{fontSize: '14px'}} for="deliveryDate">Delivery Date</label>
                                                    <div class="d-flex">
                                                        <input type="date" class="form-control form-control-sm p-0 ps-1 me-1" id="date" placeholder="Date" autocomplete="off" onChange={(e) => {updateDeliveryDate(e.target.value)}} value={deliveryDate} style={{minHeight: '36px', height: '36px'}} />
                                                        <i class="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-6 col-md-6">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" style={{fontSize: '14px'}} for="event">Select Event</label>
                                                    <select class="form-control form-select-sm form-select" id="event" value={eventType} onChange={(e) => {updateEventType(e.target.value)}} style={{minHeight: '36px', height: '36px'}} required>
                                                        <option value="">Select event</option>
                                                        <option value="birthday">Happy Birthday</option>
                                                        <option value="annivarsary">Happy Annivarsary</option>
                                                        <option value="others">Others</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="eventText" style={{textTransform: 'capitalize', fontSize: '14px'}}> Text on Cake</label>
                                                    <input type="text" class="form-control form-control-sm p-0 ps-1" id="eventText" placeholder={'Text on Cake'} onChange={(e) => {updateEventText(e.target.value)}} value={eventText} autocomplete="off" style={{minHeight: '36px', height: '36px'}} />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <p class="mb-1">
                                            <div class="form-group boxed">
                                                <div class="input-wrapper">
                                                    <label class="form-label" style={{fontSize: '14px'}} for="deliveryDate">Delivery Time</label>
                                                    <div class="d-flex">
                                                        <input type="time" class="form-control form-control-sm p-0 ps-1" id="time" placeholder="time" autocomplete="off" value={deliveryTime} onChange={(e) => {updateDeliveryTime(e.target.value)}} style={{minHeight: '36px', height: '36px'}} />
                                                        <i class="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <p class="mb-0">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" style={{fontSize: '14px'}} for="cakeType">Cake Type</label>
                                                </div>
                                            </div>
                                            <Select
                                                value={selectedCakeType}
                                                onChange={handleCakeTypeChange}
                                                options={cakeType}
                                                autoFocus={false}
                                                isMulti
                                                required
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="invoice-detail mt-2">
                            <div>
                                <div class="table-responsive" style={{overflow: 'visible'}}>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <td class="fs-6 text-dark">ITEM</td>
                                                <td class="text-start text-dark fs-6">SIZE(S)</td>
                                                <td class="fs-6 text-dark">QTY</td>
                                                <td class="fs-6 text-dark">RATE</td>
                                                <td class="fs-6 text-dark">SUBTOTAL</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tempCustomOrderObj?.Items.map((v,k) => {
                                                return <tr key={k}>
                                                    <td>
                                                        <div style={{minWidth: '200px', fontSize: '18px', fontWeight: 'normal'}}>
                                                            <Select
                                                                value={{
                                                                    "label": v?.ItemTitle,
                                                                    "value": v?.Item_ID
                                                                }}
                                                                onChange={(e) => {handleTitleChange(e, k)}}
                                                                options={titleName}
                                                                autoFocus={false}
                                                                key={k}
                                                            />
                                                        </div>
                                                    </td>
                                                    {/* {v?.Sizes?.length > 0 && */}
                                                        <td key={k}>
                                                            {/* {!v.CustomSizeFlag &&
                                                                <div class="form-group boxed ms-0 pb-0 pt-0">
                                                                    <div class="input-wrapper d-flex justify-content-start">
                                                                        <select class="form-control form-control-sm form-select text-center" value={v?.SelectedSize?.Item_ID} id="time" onChange={(e) => {updateItemSize(e.target.value, k)}} style={{minHeight: '38px', height: '38px', minWidth: '50px',width: '150px', fontSize: '0.875rem',paddingRight: '30px'}}>
                                                                            <option class="text-start" value="">Select a size</option>
                                                                            {v?.Sizes?.map((v1,k1) => {
                                                                                return <option class="text-start" value={v1.Item_ID}>{v1.ItemTitle}</option>
                                                                            })}
                                                                            <option class="text-start" value="custom">Custom</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            } */}
                                                            {/* {v.CustomSizeFlag && */}
                                                                <div class="form-group boxed ms-0 pb-0 pt-0">
                                                                    <div class="input-wrapper">
                                                                        <div style={{position: 'relative'}}>
                                                                            <input type="number" class="form-control form-control-sm p-0 ps-1 text-start" id="size" placeholder="Size" step="0.1" onChange={(e) => {updateItemSizeCustom(e.target.value, k, v)}} value={v.CustomSIze} autocomplete="off" min="0" autoFocus style={{minHeight: '38px', height: '38px', width: '100%'}} />
                                                                            <i class="clear-input">
                                                                                <ion-icon name="close-circle"></ion-icon>
                                                                            </i>
                                                                            {/* <div style={{position: 'absolute', right: '5px', bottom: '4px'}} onClick={() => {handleBackToDropdown(k)}}>
                                                                                <ion-icon name="arrow-back-outline" class="fs-4 text-secondary"></ion-icon>
                                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            {/* } */}
                                                        </td>
                                                    {/* } */}
                                                    {/* {(v?.Sizes?.length === 0 || v?.Sizes === null || v?.Sizes === undefined) &&
                                                        <td>
                                                            <div class="form-group boxed ms-0 pb-0 pt-0">
                                                                <div class="input-wrapper d-flex justify-content-start">
                                                                    <input type="number" class="form-control form-control-sm p-0 ps-1 text-start" id="size" placeholder="Sizes" onChange={(e) => {updateItemSizeCustom(e.target.value, k, v)}} value={v.CustomSize} autocomplete="off" min="0" style={{minHeight: '38px', height: '38px', width: '150px'}} />
                                                                    <i class="clear-input">
                                                                        <ion-icon name="close-circle"></ion-icon>
                                                                    </i>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    } */}
                                                    <td>
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper d-flex justify-content-center">
                                                                <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" id="qty" placeholder="Qty" onChange={(e) => {updateItemQty(e.target.value, k)}} value={v.quantity} autocomplete="off" min="0" style={{minHeight: '38px', height: '38px', width: '100%'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="pe-0">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-group" style={{flexWrap: 'nowrap'}}>
                                                                <span class="input-group-text ps-1 pe-0 bg-white" id="basic-addon1" style={{height: '38px', border: '1px solid lightgray', borderRadius: '6px 0px 0px 6px'}}>₹</span>
                                                                <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" onChange={(e) => {updateItemMRP(e.target.value, k)}} value={v.MRP} placeholder="Rate" aria-label="Rate" min="0" aria-describedby="basic-addon1" style={{minHeight: '38px', height: '38px', width: '100%', borderLeft: 'none'}} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="ps-0 fs-6 text-dark align-middle">₹{parseInt(v.quantity) * parseInt(v.MRP)}</td>
                                                </tr>
                                            })}
                                            <tr>
                                                <td class="text-start" colspan="5">
                                                    <button class="btn btn-success ps-1 pe-1" type="button" onClick={(e) => {handleAddItem(e)}}><ion-icon name="add-outline" disabled></ion-icon> Add Item</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="invoice-total mt-0 border-0">
                            <div class="row">
                                <div class="col-12 col-md-9 order-2 order-md-1">
                                    <div class="row">
                                        <div class="col-12 col-md-6">
                                            <div class="form-group boxed">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="remark" style={{fontSize: '14px'}}>Description</label>
                                                    <textarea id="remark" rows="4" class="form-control" value={tempCustomOrderObj?.OrderDetails?.Remark} onChange={(e) => {updateRemark(e.target.value)}}></textarea>
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="row">
                                                <div class="col-12 col-md-12">
                                                    <div class="form-group boxed">
                                                        <div class="input-wrapper">
                                                            <label class="form-label" for="image" style={{fontSize: '14px'}}>Upload Image</label>
                                                            <input type="file" class="form-control form-control-sm p-0 ps-1 text-start" id="image" placeholder="Image" autocomplete="off" onChange={(e) => {uploadRefImage(e.target.files[0])}} style={{minHeight: '38px', height: '38px'}} />
                                                            <i class="clear-input">
                                                                <ion-icon name="close-circle"></ion-icon>
                                                            </i>
                                                        </div>
                                                    </div>
                                                    {imagePreview &&
                                                        <img src={imagePreview} width="210px" />
                                                    }
                                                </div>
                                                <div class="col-6 col-md-6">
                                                    <div class="form-group boxed pb-0">
                                                        <div class="input-wrapper">
                                                            <label class="form-label" for="paymentMethod" style={{fontSize: '14px'}}>Payment Method</label>
                                                            <select class="form-control form-select-sm form-select" id="paymentMethod" value={paymentMethod} onChange={(e) => {updatePaymentMethod(e.target.value)}} style={{minHeight: '36px', height: '36px'}} required>
                                                                <option value="">Select payment method</option>
                                                                <option value="Cash">Cash</option>
                                                                <option value="Card">Card</option>
                                                                <option value="UPI">UPI</option>
                                                                <option value="Payment-Gateway">Payment Gateway</option>
                                                                <option value="Cheque">Cheque</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-md-6">
                                                    <div class="form-group boxed">
                                                        <div class="input-wrapper">
                                                            <label class="form-label" for="advance" style={{fontSize: '14px'}}>Advance</label>
                                                        </div>
                                                        <div class="input-group">
                                                            <span class="input-group-text ps-1 pe-0 bg-white" id="basic-addon1" style={{height: '38px', border: '1px solid lightgray', borderRadius: '6px 0px 0px 6px'}}>₹</span>
                                                            <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" placeholder="Advance" value={tempCustomOrderObj?.Advance} onChange={(e) => {updateAdvance(e.target.value)}}aria-label="advance" aria-describedby="basic-addon1" required style={{minHeight: '38px', height: '38px', borderLeft: 'none'}} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-12 col-md-3 order-1 order-md-2">
                                    <ul class="listview transparent simple-listview flush">
                                        <li style={{fontSize: '18px'}}>Subtotal <span class="hightext" style={{fontSize: '18px'}}>₹{tempCustomOrderObj?.Subtotal}</span></li>
                                        <li style={{fontSize: '18px'}}>
                                            CGST
                                            <span class="hightext" style={{fontSize: '18px'}}>
                                                ₹{(tempCustomOrderObj?.Cgst) ? tempCustomOrderObj?.Cgst : 0}
                                                {/* <div class="form-group boxed ms-0 pb-0 pt-0">
                                                    <div class="input-group">
                                                        <span class="input-group-text ps-1 pe-0 bg-white" id="basic-addon1" style={{height: '25px', border: '1px solid lightgray', borderRadius: '6px 0px 0px 6px', fontSize: '14px'}}>₹</span>
                                                        <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" onChange={(e) => {updateCgst(e.target.value)}} value={tempCustomOrderObj?.Cgst} placeholder="CGST" aria-label="cgst" aria-describedby="basic-addon1" required style={{minHeight: '25px', height: '25px', width: '60px', borderLeft: 'none'}} />
                                                    </div>
                                                </div> */}
                                            </span>
                                        </li>
                                        <li style={{fontSize: '18px'}}>
                                            SGST
                                            <span class="hightext" style={{fontSize: '18px'}}>
                                                ₹{(tempCustomOrderObj?.Sgst) ? tempCustomOrderObj?.Sgst : 0}
                                                {/* <div class="form-group boxed ms-0 pb-0 pt-0">
                                                    <div class="input-group">
                                                        <span class="input-group-text ps-1 pe-0 bg-white" id="basic-addon1" style={{height: '25px', border: '1px solid lightgray', borderRadius: '6px 0px 0px 6px', fontSize: '18px'}}>₹</span>
                                                        <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" onChange={(e) => {updateSgst(e.target.value)}} value={tempCustomOrderObj?.Sgst} placeholder="SGST" aria-label="Sgst" aria-describedby="basic-addon1" required style={{minHeight: '25px', height: '25px', width: '60px', borderLeft: 'none'}} />
                                                    </div>
                                                </div> */}
                                            </span>
                                        </li>
                                        <li style={{fontSize: '18px'}}>Total<span class="totaltext text-primary" style={{fontSize: '18px'}}>₹{tempCustomOrderObj?.Subtotal}</span></li>
                                        <li style={{fontSize: '18px'}}>Balance<span class="hightext" style={{fontSize: '18px'}}>₹{tempCustomOrderObj?.Balance}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* <div class="row mt-3">
                            <div class="col-md-12 d-flex justify-content-end">
                                <button class="btn btn-success" disabled={disableSubmitButton}>Place Order</button>
                            </div>
                        </div> */}
                        <div class="fab-button text bottom-center responsive-bottom-fab">
                            <button class="fab btn-success fs-6 pe-2 ps-2" style={{height: '40px', fontFamily: "'Inter', sans-serif"}} disabled={disableSubmitButton}>
                                Update Order
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

const mapStateToProps = ({ data, auth, addUser }) => {
    const {
      businessData,
      items,
      editCustomOrderData
    } = data
    const {
      authToken
    } = auth
    const {
        userData
    } = addUser
    return {
      authToken,
      businessData,
      items,
      userData,
      editCustomOrderData
    }
  }
  
  const mapDispatchToProps = {
    setEditCustomOrderData
  }

export default connect(mapStateToProps, mapDispatchToProps)(CustomOrderEdit)
