import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { connect } from "react-redux"
import { showItemSizeOffcanvas } from '../../../features/Offcanvas/itemSizeOffcanvasSlice'
import { updateCart, updateQuantity, updatePrice } from '../../../features/state/checkoutItemsSlice'
import { setCategoryName, cartDraftIndex, cartOrderIndex, cartDraftData, itemsDataUpdate } from '../../../features/Data/dataSlice'
import { showShopStatusModal } from '../../../features/Offcanvas/shopStatusModalSlice'
import { updateStock, updateStockQuantity, updateStockPrice } from '../../../features/Data/stockSlice'
import { showAppToast } from '../../../features/utilities/appToastSlice'
import Veg from '../../images/icon/veg.png'
import NonVeg from '../../images/icon/non-veg.png'

const ItemButton = ({
    showItemSizeOffcanvas,
    itemCardDetail,
    cardBg,
    checkoutItemList,
    updateCart,
    updateQuantity,
    updatePrice,
    price,
    pageName,
    alternateTextVisible,
    colorVisible,
    setCategoryName,
    cartDraftIndex,
    cartOrderIndex,
    draftIndex,
    odIndex,
    draftData,
    cartDraftData,
    showShopStatusModal,
    items,
    itemsDataUpdate,
    stockItemList,
    updateStock,
    updateStockQuantity,
    updateStockPrice,
    stockPrice,
    showAppToast
}) => {
    const [activeItem, setActiveItem] = useState([])
    const [stateName, setStateName] = useState('')
    const [tempTotalSalesQty, setTempTotalSalesQty] = useState(0)
    const page = window.location.pathname.split('/')
    useEffect(() => {
        setStateName(page[1])
    }, [page])
    let navigate = useNavigate()
    const handleItemSize = (e, itemName, groupID) => {
        e.stopPropagation()
        showItemSizeOffcanvas({itemSizeOffcanvasVisible: true, itemName: itemName, groupItemID: groupID})
    }

    const handleItemDetailProfile = () => {
        navigate('/itemProfile')
    }

    useEffect(() => {
        if (pageName === 'newOrder') {
            updatePrice({
                subtotal : 0,
                cgst : 0,
                sgst : 0})
            updateCart([])   
        }
    }, [pageName])

    const calculateStock = (itemList, item) => {
        itemList.map((v,k) => {
            if (v.ID === item.ID) {
                v.SalesQty = parseInt(v.SalesQty) - 1 
            }
        })
        itemsDataUpdate({items: itemList})
    }

    const addToCheckoutItems = (item, pageName) => {
        let tempItemList = JSON.parse(JSON.stringify(items))
        if (pageName === 'NewOrder') {
            const currentShopStatus = localStorage.getItem('shopStatus')
            if (currentShopStatus === 'Close') {
                showShopStatusModal({shopStatusModalVisible: true, liveShopStatus: ''})
            } else {
                if (item.Sizes.length === 0) {
                    if (parseInt(item.SalesQty) === 0 && item.AllowNegativeStock === 'f') {
                        showAppToast({ toastMessage:'OUT OF STOCK', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center', isVisible: true })
                    } else {
                        let tempItemObj = {
                            "quantity": 1,
                            "MRP":item.MRP,
                            "Cost":item.Cost,
                            "ItemTitle":item.ItemTitle,
                            "Item_ID":item.ID,
                            "Taxes":item.Taxes,
                            "Image": item.ImageURL,
                            "AlternateText": item.AlternateText,
                            "Status": false,
                            "FoodType": item.FoodType
                        }
                        let tempOrderObj = {
                            "DateTime": null,
                            "Items": [],
                            "OrderNumber": "",
                            "Subtotal": 0,
                            "Total":0,
                            "TotalQty": 0,
                            "TotalTax": {"cgst": 0, "sgst": 0},
                            "Type":"",
                            "OrderStatus": 0
                        }
                        let tempObj = JSON.parse(JSON.stringify(draftData))
                        let addNewItem = false
                        if (draftIndex !== null && draftIndex !== undefined && draftIndex !== '') {
                            if (tempObj[draftIndex].Status === 0) {
                                let addNewOrder = false
                                for (let i = 0; i < tempObj[draftIndex].Orders.length; i++) {
                                    if (tempObj[draftIndex].Orders[i].OrderStatus === 1) {
                                        addNewOrder = true
                                    }
                                }
                                if (addNewOrder) {
                                    tempObj[draftIndex].Orders.push(tempOrderObj)
                                    cartOrderIndex({odIndex: tempObj[draftIndex].Orders.length - 1})
                                    odIndex = tempObj[draftIndex].Orders.length - 1
                                }
                            }
                            if (tempObj[draftIndex].Orders[odIndex].Items.length === 0) {
                                addNewItem = true
                                tempObj[draftIndex].TotalQuantity = 0
                            } else {
                                for (let i = 0; i < tempObj[draftIndex].Orders[odIndex].Items.length; i++) {
                                    if (tempObj[draftIndex].Orders[odIndex].Items[i].Item_ID === item.ID) {
                                        tempObj[draftIndex].Orders[odIndex].Items[i].quantity = tempObj[draftIndex].Orders[odIndex].Items[i].quantity + 1
                                        addNewItem = false
                                        break 
                                    } else {
                                        addNewItem = true                    
                                    }
                                }
                            }
                            if (addNewItem) {
                                tempObj[draftIndex].Orders[odIndex].Items.push(tempItemObj)
                            }
                            tempObj[draftIndex].TotalQuantity = tempObj[draftIndex].TotalQuantity + 1
                            tempObj[draftIndex].Amount = tempObj[draftIndex].Amount + parseInt(item.MRP)
                            tempObj[draftIndex].BalanceAmount = tempObj[draftIndex].BalanceAmount + parseInt(item.MRP)
                            tempObj[draftIndex].Status = -1
                            tempObj[draftIndex].OrderType = (tempObj[draftIndex].OrderType === '') ? 'Counter Sale' : tempObj[draftIndex].OrderType
                            addNewItem = false
                        } else {
                            let draftIndexSet = false
                            for (let i = 0; i < draftData.length; i++) {
                                if (draftData[i].Status === -2) {
                                    draftIndexSet = true
                                    tempObj[i].Status = -1
                                    tempObj[i].OrderType = 'Counter Sale'
                                    tempObj[i].TotalQuantity = 1
                                    tempObj[i].Orders[0].Items.push(tempItemObj)
                                    cartDraftIndex({draftIndex: i})
                                    cartOrderIndex({odIndex: 0})
                                    tempObj[i].Amount = tempObj[i].Amount + parseInt(item.MRP)
                                    tempObj[i].BalanceAmount = tempObj[i].BalanceAmount + parseInt(item.MRP)
                                    break
                                }
                            }
                            if (!draftIndexSet) {
                                let defObj = {
                                    "OfflineInvoiceNumber": '',
                                    "Orders":[{
                                        "Items":[],
                                        "TotalQty":0,
                                        "Subtotal":0,
                                        "TotalTax":{
                                            "cgst": 0,
                                            "sgst":0
                                        },
                                        "Total":0,
                                        "Type":'',
                                        "DateTime":null,
                                        "OrderNumber":'',
                                        "OrderStatus": 0
                                    }],
                                    "Currency":"INR",
                                    "Phone":null,
                                    "Date": null,
                                    "UserName":"",
                                    "BalanceAmount":0,
                                    "PaymentMethod":"",
                                    "OrderType": '',
                                    "Amount": 0,
                                    "TransactionDate": null,
                                    "Customer_ID": "",
                                    "Status": -2
                                }
                                tempObj = JSON.parse(JSON.stringify(draftData))
                                tempObj.push(defObj)
                                tempObj[draftData.length].Status = -1
                                tempObj[draftData.length].OrderType = 'Counter Sale'
                                tempObj[draftData.length].TotalQuantity = 1
                                tempObj[draftData.length].Orders[0].Items.push(tempItemObj)
                                cartDraftIndex({draftIndex: draftData.length})
                                cartOrderIndex({odIndex: 0})
                                tempObj[draftData.length].Amount = tempObj[draftData.length].Amount + parseInt(item.MRP)
                                tempObj[draftData.length].BalanceAmount = tempObj[draftData.length].BalanceAmount + parseInt(item.MRP)
                            }
                        }
                        cartDraftData({draftData: tempObj})
                        if (pageName === 'NewOrder' || pageName === 'ManageStock') {
                                let itemList = [...checkoutItemList]
                                // let checkoutItemIndex = itemList.findIndex(e=>e.item.ID === item.ID)
                                let checkoutItemIndex = -1
                                for (let i = 0; i < itemList.length; i++) {
                                    if (parseInt(itemList[i].item.ID) === parseInt(item.ID) && itemList[i].OrderIndex === odIndex) {
                                        checkoutItemIndex = i
                                        break
                                    }
                                }
                                if(checkoutItemIndex===-1){
                                    itemList.push({
                                        item : item, 
                                        quantity : 1,
                                        OrderStatus : 0,
                                        OrderIndex: (odIndex === null ? 0 : odIndex)
                                    })
                                    updateCart(itemList)
                                } else {
                                    updateQuantity({checkoutItemIndex:checkoutItemIndex,quantity:Number(itemList[checkoutItemIndex]?.quantity)+1})
                                    itemList.splice(checkoutItemIndex,1,{item : item, quantity : itemList[checkoutItemIndex].quantity+1, OrderStatus: 0, OrderIndex: (odIndex === null ? 0 : odIndex)})
                                    updateCart(itemList)
                                }
                                updatePrice({
                                    subtotal : Number(price.subtotal)+Number(item.MRP),
                                    cgst : 0,
                                    sgst : 0})
                        } 
                        calculateStock(tempItemList, item)
                    }
                } else {
                    showItemSizeOffcanvas({itemSizeOffcanvasVisible: true, itemValue: item})
                }
            }
        } else if (pageName === 'ManageStock') {
            if (item.Sizes.length === 0) {
                setActiveItem(item.ID)
                let itemList = [...stockItemList]
                let stockItemIndex = -1
                for (let i = 0; i < itemList.length; i++) {
                    if (parseInt(itemList[i].item.ID) === parseInt(item.ID)) {
                        stockItemIndex = i
                        break
                    }
                }
                if(stockItemIndex===-1){
                    itemList.push({
                        item : item, 
                        quantity : 1,
                        OrderStatus : 0,
                        OrderIndex: (odIndex === null ? 0 : odIndex)
                    })
                    updateStock(itemList)
                } else {
                    updateStockQuantity({stockItemIndex:stockItemIndex,quantity:Number(itemList[stockItemIndex]?.quantity)+1})
                    itemList.splice(stockItemIndex,1,{item : item, quantity : itemList[stockItemIndex].quantity+1, OrderStatus: 0, OrderIndex: (odIndex === null ? 0 : odIndex)})
                    updateStock(itemList)
                }
                updateStockPrice({
                    subtotal : Number(stockPrice.subtotal)+Number(item.MRP),
                    cgst : 0,
                    sgst : 0})
            } else {
                showItemSizeOffcanvas({itemSizeOffcanvasVisible: true, itemValue: item})
            }
        }
    }

    let activeItemID = []
    useEffect(() => {
        if (stateName === 'newOrder') {
            if (checkoutItemList.length > 0) {
                checkoutItemList.map((v,k) => {
                    activeItemID.push(v.item.ID)
                    setActiveItem(activeItemID)
                })   
            } else {
                setActiveItem([])
            }
        } else if (stateName === 'manageStock') {
            if (stockItemList.length > 0) {
                stockItemList.map((v,k) => {
                    activeItemID.push(v.item.ID)
                    setActiveItem(activeItemID)
                })   
            } else {
                setActiveItem([])
            }
        }
    }, [checkoutItemList, stockItemList, stateName])
    useEffect(() => {
        if (itemCardDetail.Sizes.length > 0) {
            let totalSalesQty = 0
            itemCardDetail.Sizes.map((v,k) => {
                totalSalesQty = parseInt(totalSalesQty) + parseInt(v.SalesQty)
            })
            setTempTotalSalesQty(totalSalesQty)
        }
    }, [itemCardDetail])
    return (
        <div class="btn btn-outline-secondary btn-lg p-1 w-100" onClick={(pageName === 'NewOrder' || pageName === 'OrderDetail' || pageName === 'ManageStock') ? ()=>addToCheckoutItems(itemCardDetail, pageName) : handleItemDetailProfile} style={{backgroundColor: (itemCardDetail?.Color && colorVisible) ? itemCardDetail?.Color : 'transparent', border: 'solid 2px', borderColor: (activeItem.includes(itemCardDetail.ID)) ? '#24265d' : 'gainsboro', minHeight: '70px'}}>
            <div class="row" style={{position: 'relative'}}>
                <div class="col-8 col-md-8 d-flex align-items-center">
                    <h2 class="title mb-0 text-start" style={{fontSize: '13px'}}>
                        <a href="javascript:void(0)" class="text-dark text-nowrap">
                            <div style={{fontSize: '13px'}}>
                                <img src={(itemCardDetail.FoodType === 'veg') ? Veg : NonVeg } width="14px" style={{paddingBottom: '2px', marginRight: '2px'}} />
                                {(alternateTextVisible === 'englishText') &&
                                    <>
                                        {itemCardDetail.ItemTitle && itemCardDetail.ItemTitle}
                                    </>
                                }
                                {(alternateTextVisible === 'alternateText') &&
                                    <>
                                        {itemCardDetail?.AlternateText}
                                    </>
                                }
                                {(alternateTextVisible === 'shortcode') &&
                                    <>
                                        {itemCardDetail?.Shortcode}
                                    </>
                                }
                            </div>
                        </a>
                    </h2>
                </div>
                <div class="col-4 col-md-4">
                    <div class="row">
                        {itemCardDetail.Sizes.length > 0 &&
                            <>
                                <small class="text-nowrap fs-10">{itemCardDetail.Sizes.length} Size(s)</small>
                            </>
                        }
                        {stateName === 'newOrder' &&
                            <>
                                {itemCardDetail.Sizes.length === 0 &&
                                    <>
                                        {itemCardDetail.Stockable === 't' &&
                                            <>
                                                {itemCardDetail.SalesQty &&
                                                    <div class="col-12 col-md-12 d-flex justify-content-end">
                                                        <div class="badge bg-primary" style={{fontSize: '8px', height: '14px'}}>
                                                            <ion-icon name="cart-outline" class="m-0" style={{fontSize: '8px', paddingBottom: '2px'}}></ion-icon>{itemCardDetail.SalesQty}
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                        {itemCardDetail.Sizes.length === 0 &&
                            <div class="col-12 col-md-12 d-flex justify-content-end">
                                <button class="btn btn-text btn-sm d-flex align-items-center p-0 m-0 fw-bold" style={{height: '18px', fontSize: '12px'}}> 
                                    ₹{itemCardDetail.MRP}
                                </button>
                            </div>
                        }
                        {stateName === 'manageStock' &&
                            <>
                                {(itemCardDetail.Sizes.length === 0) &&
                                    <>
                                        <div class="col-12 col-md-12 d-flex justify-content-end">
                                            <div class="badge bg-primary" style={{fontSize: '8px', height: '14px'}}>
                                                <ion-icon name="cart-outline" class="m-0" style={{fontSize: '8px', paddingBottom: '2px'}}></ion-icon>{itemCardDetail.SalesQty}
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
                {stateName === 'newOrder' &&
                    <>
                        {itemCardDetail.Sizes.length === 0 &&
                            <>
                                {itemCardDetail.Stockable === 't' &&
                                    <div class="col-12 col-md-12 d-flex justify-content-end">
                                        {((itemCardDetail.SalesQty === 0 || itemCardDetail.SalesQty === '0') && itemCardDetail.AllowNegativeStock === 'f') &&
                                            <div class="d-flex align-items-center justify-content-center" style={{position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, backgroundColor: 'rgba(255,255,255,0.5)', zIndex: 1}}>
                                                <span class="badge bg-danger">OUT OF STOCK</span>
                                            </div>
                                        }
                                    </div>
                                }
                            </>
                        }
                        {itemCardDetail.Sizes.length > 0 &&
                            <div class="col-12 col-md-12 d-flex justify-content-end">
                                {tempTotalSalesQty === 0 &&
                                    <div class="d-flex align-items-center justify-content-center" style={{position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, backgroundColor: 'rgba(255,255,255,0.5)', zIndex: 1}}>
                                        <span class="badge bg-danger">OUT OF STOCK</span>
                                    </div>
                                }
                            </div>
                        }
                    </>
                }
            </div>
        </div>
        
    )
}

const mapStateToProps = ({checkoutItems, general, data, stock }) => {
    const {
        checkoutItemList,
        price
    } = checkoutItems
    const {
        alternateTextVisible,
        colorVisible
    } = general
    const{
        draftIndex,
        odIndex,
        draftData,
        items
    } = data
    const {
        stockItemList,
        stockPrice
    } = stock
    return {
        checkoutItemList,
        price,
        alternateTextVisible,
        colorVisible,
        draftIndex,
        odIndex,
        draftData,
        items,
        stockItemList,
        stockPrice
    }
  }
  
const mapDispatchToProps = {
    showItemSizeOffcanvas,
    updateCart,
    updateQuantity,
    updatePrice,
    setCategoryName,
    cartDraftIndex,
    cartOrderIndex,
    cartDraftData,
    showShopStatusModal,
    itemsDataUpdate,
    updateStock,
    updateStockQuantity,
    updateStockPrice,
    showAppToast
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemButton)
