import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    switchBusinessModalVisible: false
}

export const switchBusinessModalSlice = createSlice({
    name: 'switchBusiness',
    initialState,
    reducers: {
      showSwitchBusinessModal: (state, action) => {
        const {
            switchBusinessModalVisible
        } = action.payload
        state.switchBusinessModalVisible = switchBusinessModalVisible
      },
      hideSwitchBusinessModal: (state) => {
        state.switchBusinessModalVisible = false
      }
    }
})

export const { showSwitchBusinessModal, hideSwitchBusinessModal } = switchBusinessModalSlice.actions;


export default switchBusinessModalSlice.reducer