import React from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from '../../../AxiosConfig'
import { connect } from "react-redux"
import { hideCustomConfirmation } from '../../../features/Offcanvas/confirmationModalSlice'
import { setCustomOrderRender } from '../../../features/Data/dataSlice'

const CustomConfirmationModal = ({
    hideCustomConfirmation,
    customConfirmationModalVisible,
    invoiceID,
    setCustomOrderRender,
    customOrderRender
}) => {
    const handleDeleteCustomOrder = () => {
        axios.delete('/Invoices/' + invoiceID).then(res => {
            if (res.data.message === 'Success') {
                setCustomOrderRender({customOrderRender: customOrderRender + 1})
                hideCustomConfirmation()
            }
        })
    }
    return (
        <div>
            <Modal show={customConfirmationModalVisible} centered backdrop={'static'}>
                <Modal.Header style={{padding: '15px 10px 15px 10px'}}>
                    <div class="row w-100 position-relative">
                        <div class="col-md-12 col-12 pe-3">
                            Delete
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body class="pt-0 p-2 modal-body">
                    <div class="section full mt-1">
                        <h3 class="mb-2">Are you sure you want to delete this order ?</h3>
                        <div class="d-flex justify-content-end">
                            <button class="btn btn-secondary me-1" onClick={() => {hideCustomConfirmation()}}>Cancel</button>
                            <button class="btn btn-danger" onClick={handleDeleteCustomOrder}>Delete</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ confirmation, customOrder }) => {
    const {
        customConfirmationModalVisible,
        invoiceID
    } = confirmation
    const {
        customOrderRender
    } = customOrder
    return {
        customConfirmationModalVisible,
        invoiceID,
        customOrderRender
    }
  }
  
  const mapDispatchToProps = {
    hideCustomConfirmation,
    setCustomOrderRender
  }

export default connect(mapStateToProps, mapDispatchToProps)(CustomConfirmationModal)