import React, { useState, useEffect } from 'react'
import OrderDetailModal from '../layout/Sidebar/OrderDetailModal'
import axios from '../../AxiosConfig'
import config from '../../config'
import { connect } from 'react-redux'
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment'
import { useNavigate, useLocation, Link, Navigate } from 'react-router-dom'
import { showOrderDetailModal } from '../../features/Offcanvas/orderDetailModalSlice'
import SearchWithButtons from './Components/SearchWithButtons'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import OrderGridView from './BitComponents/OrderGridView'
import OrderListView from './BitComponents/OrderListView'
import { activeTabUpdatedName, localStorageFlag, handleRenderOrders,setCustomOrderRender } from '../../features/Data/dataSlice'
import { generalDataUpdate } from '../../features/utilities/generalSlice'
import OngoingOrders from './BitComponents/OngoingOrders'
import IncomingOrders from './BitComponents/IncomingOrders'
import CustomOrdersPaymentModal from '../layout/Sidebar/CustomOrdersPaymentModal'
import { showCustomOrderPaymentModal } from '../../features/Offcanvas/customOrderPaymentModalSlice'
import { showCustomConfirmation } from '../../features/Offcanvas/confirmationModalSlice'
import CustomConfirmationModal from '../layout/Sidebar/CustomConfirmationModal'
import { setEditCustomOrderData } from '../../features/Data/dataSlice'
import CustomOrderDetailModal from '../layout/Sidebar/CustomOrderDetailModal'
import { showCustomOrderDetailModal } from '../../features/Offcanvas/customOrderDetailModalSlice'

const CustomOrders = ({
    // view,
    // localStorageUpdated,
    // localStorageFlag,
    // handleRenderOrders,
    // renderOrders,
    // dayOrderFilter,
    // generalDataUpdate,
    // renderOngoingOrder,
    // activeTabUpdatedName,
    businessData,
    buisness_ID,
    role,
    authToken,
    location_ID,
    showCustomOrderPaymentModal,
    setCustomOrderRender,
    customOrderRender,
    showCustomConfirmation,
    setEditCustomOrderData,
    editCustomOrderData,
    currentBusinessIdData,
    showCustomOrderDetailModal
}) => {
    let navigate = useNavigate()
    const [BackupOrderList, setBackupOrderList] = useState(null);
    const [OrderList, setOrderList] = useState(null);
    const [OrderListHeader, setOrderListHeader] = useState(null);
    const [orderID, setOrderID] = useState(null)
    const [orderData, setOrderData] = useState(null)

    const [searchableText, setSearchableText] = useState('');


    const [OrderDate, setOrderDate] = useState(new Date().toISOString().split('T')[0]);
    const [DeliveryDate, setDeliveryDate] = useState();
    const [DeliveredDate, setDeliveredDate] = useState();
    const [PhoneFilter, setPhoneFilter] = useState('')
    const [NameFilter, setNameFilter] = useState('')

    const [AciveRadio, setActiveRadio] = useState('Pending');

    const [ShopList, setShopList] = useState(null);
    const [selectedShop, setSelectedShop] = useState(null);

    const [customOrderTotalAdvance, setCustomOrderTotalAdvance] = useState(0)
    const [customOrderTotalBalance, setCustomOrderTotalBalance] = useState(0)

    function changeRadio(changeTo) {
        setActiveRadio(changeTo)
    }

    useEffect(() => {
        if(businessData && buisness_ID) {
            const temp = businessData.filter((itm) =>  itm.ID === buisness_ID)[0].Branches;
            const temp2 = [{
                "ID": "All",
                "Name": "All",
                "Business_ID": "All"
            }, ...temp]
            setShopList(temp2)
        }
    }, [businessData, buisness_ID])

    useEffect(() => {
        if (role != 6) {
            setSelectedShop(location_ID)
        } else {
            setSelectedShop('All')
        }
    }, [location_ID])

    function changeSelectedShop(e) {
        setSelectedShop(e.target.value);
    }
    
    useEffect(() => {
            const SendData = {
                "LocationID": selectedShop,
                "OrderDate": OrderDate,
                "Phone": PhoneFilter,
                "Name": NameFilter
            }
            if(OrderDate !== undefined && OrderDate !== null && OrderDate !== '') SendData.OrderDate = OrderDate;
            if(DeliveryDate !== undefined && DeliveryDate !== null && DeliveryDate !== '') SendData.DeliveryDate = DeliveryDate;
            if(PhoneFilter !== undefined && PhoneFilter !== null && PhoneFilter !== '') SendData.Phone = PhoneFilter;
            if(NameFilter !== undefined && NameFilter !== null && NameFilter !== '') SendData.Name = NameFilter;
            if(DeliveredDate !== undefined && DeliveredDate !== null && DeliveredDate !== '') SendData.DeliveredOn = DeliveredDate;
            if (selectedShop) {
                axios.post(config.apiUrl + '/Invoices/getCustomOrders', SendData).then(res=>{
                    if (res.data.message === 'Success') {
                    setOrderData(res.data.data)
                    let tempOrderData = JSON.parse(JSON.stringify(res.data.data))
                    tempOrderData.map((v,k) => {
                        if (v.hasOwnProperty('TotalDiscount')) {
                            tempOrderData[k].Orders[0].Discount = v.TotalDiscount
                        }
                        if (v.hasOwnProperty('TotalPaidAmount')) {
                            tempOrderData[k].Orders[0].Advance = v.TotalPaidAmount
                        }
                        if (v.hasOwnProperty('billNo')) {
                            tempOrderData[k].Orders[0].BillNo = v.billNo
                        }
                    })
                    console.log(tempOrderData)
                    setEditCustomOrderData({editCustomOrderData: tempOrderData})
                    let totalAdvance = 0
                    let totalBalance = 0
                    const GetColumnsToLoad = res.data.data.map((itm, i) => {
                        if (itm?.Orders[0]?.Status != -1) {
                            totalAdvance += itm.PaidAmount
                            totalBalance += itm.BalanceAmount
                        }
                        let tempVar = null
                        if (buisness_ID == 9) {
                            tempVar = {
                                'OrderDate' : moment(itm.OrderDate).format('DD MMM YY'),
                                'GrandTotal' : itm.GrandTotal,
                                'Subtotal' : itm.Subtotal,
                                'TotalDiscount' : itm.TotalDiscount,
                                'TotalPaidAmount' : itm.TotalPaidAmount,
                                'TotalTax' : itm.TotalTax,
                                'BillNo' : itm.billNo,
                                'Name' : itm.Orders[0]?.OrderDetails?.Name,
                                'Phone' : itm.Orders[0]?.OrderDetails?.Phone,
                                'Age' : itm.Orders[0]?.OrderDetails?.Age,
                                'OrderDetails': itm.Orders[0]?.OrderDetails,
                                // 'DeliveryDate' : itm.Orders[0]?.OrderDetails?.DeliveryDate,
                                // 'DeliveryTime' : itm.Orders[0]?.OrderDetails?.DeliveryTime,
                                // 'CakeType' : itm.Orders[0]?.OrderDetails?.CakeType[0].value,
                                'Description' : itm.Orders[0]?.Items.filter((w) => w.Item_ID !== null)?.map((v) => v.CustomSIze).toString(),
                                'Type' : itm.Orders[0]?.Items.filter((w) => w.Item_ID !== null)?.map((v) => v.ItemTitle).toString(),
                                // 'NameOnCake' : itm.Orders[0]?.OrderDetails?.EventText,
                                'Remark' : itm.Orders[0]?.OrderDetails?.Remark,
                                'Advance' : itm.PaidAmount,
                                'Balance' : itm.BalanceAmount,
                                'Status': itm.Orders[0]?.Status,
                                'Items': itm.Orders[0]?.Items,
                                'ReferenceImage': itm.Orders[0]?.OrderDetails.ReferenceImage,
                                'InvoiceID': itm.ID,
                                'OrderID': itm.Orders[0]?.ID,
                                'VendorDate': (itm.Orders[0]?.OrderDetails?.VendorDate) ? moment(itm.Orders[0]?.OrderDetails?.VendorDate).format('DD MMM YY') : ''
                            };
                        } else {
                            tempVar = {
                                'OrderDate' : itm.OrderDate,
                                'GrandTotal' : itm.GrandTotal,
                                'Subtotal' : itm.Subtotal,
                                'TotalDiscount' : itm.TotalDiscount,
                                'TotalPaidAmount' : itm.TotalPaidAmount,
                                'TotalTax' : itm.TotalTax,
                                'BillNo' : itm.billNo,
                                'Phone' : itm.Orders[0]?.OrderDetails?.Phone,
                                'DeliveryDate' : itm.Orders[0]?.OrderDetails?.DeliveryDate,
                                'DeliveryTime' : itm.Orders[0]?.OrderDetails?.DeliveryTime,
                                'CakeType' : itm.Orders[0]?.OrderDetails?.CakeType[0].value,
                                'Weight' : itm.Orders[0]?.Items.filter((w) => w.Item_ID !== null)?.map((v) => v.CustomSIze).toString(),
                                'Flavour' : itm.Orders[0]?.Items.filter((w) => w.Item_ID !== null)?.map((v) => v.ItemTitle).toString(),
                                'NameOnCake' : itm.Orders[0]?.OrderDetails?.EventText,
                                'Remark' : itm.Orders[0]?.OrderDetails?.Remark,
                                'Advance' : itm.PaidAmount,
                                'Balance' : itm.BalanceAmount,
                                'Status': itm.Orders[0]?.Status,
                                'Items': itm.Orders[0]?.Items,
                                'ReferenceImage': itm.Orders[0]?.OrderDetails.ReferenceImage,
                                'InvoiceID': itm.ID 
                            };
                        }
                        return tempVar
                    })
                    setCustomOrderTotalAdvance(totalAdvance)
                    setCustomOrderTotalBalance(totalBalance)
                    if (buisness_ID == 9) {
                        setOrderListHeader(['OrderDate', 'Name', 'VendorDate'])
                    } else {
                        setOrderListHeader(['OrderDate', 'GrandTotal', 'Subtotal', 'TotalDiscount', 'TotalPaidAmount', 'TotalTax', 'BillNo', 'Phone', 'DeliveryDate', 
                            'DeliveryTime', 'CakeType', 'Weight', 'Flavour', 'NameOnCake', 'Remark', 'Advance', 'Balance'])
                    }
                    setOrderList([...GetColumnsToLoad]);
                    setBackupOrderList([...GetColumnsToLoad]);
                    }
                })
            }
    }, [OrderDate, DeliveryDate, DeliveredDate, AciveRadio, selectedShop, customOrderRender, PhoneFilter, NameFilter])

    const consoleData = () => {
        const pagedata = OrderList;
        
        const options = { 
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true, 
          showTitle: true,
          title: 'Custome Order List',
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true,
          filename: 'Custome Order List',
        };
    
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(pagedata);
    }

    useEffect(() => {
        if(searchableText.trim().length > 0) {
            const FilterList = BackupOrderList.filter((itm) => 
                itm.OrderDate.toString().includes(searchableText.toString()) ||
                itm.GrandTotal.toString().includes(searchableText.toString()) ||
                itm.Subtotal.toString().includes(searchableText.toString()) ||
                itm.TotalDiscount.toString().includes(searchableText.toString()) ||
                itm.TotalPaidAmount.toString().includes(searchableText.toString()) ||
                itm.TotalTax.toString().includes(searchableText.toString()) ||
                itm.BillNo.toString().includes(searchableText.toString()) ||
                itm.Phone.toString().includes(searchableText.toString()) ||
                itm.DeliveryDate.toString().includes(searchableText.toString()) ||
                itm.DeliveryTime.toString().includes(searchableText.toString()) ||
                itm.CakeType.toString().includes(searchableText.toString()) ||
                itm.Weight.toString().includes(searchableText.toString()) ||
                itm.Flavour.toString().includes(searchableText.toString()) ||
                itm.NameOnCake.toString().includes(searchableText.toString()) ||
                itm.Advance.toString().includes(searchableText.toString()) ||
                itm.Balance.toString().includes(searchableText.toString())
            )
            setOrderList(FilterList);
        } else {
            setOrderList(BackupOrderList)
        }
    }, [searchableText]);

    const handleClearOrderDate = () => {
        setOrderDate('')
    }

    const handleClearDelivery = () => {
        setDeliveryDate('')
    }

    const handleClearPhone = () => {
        setPhoneFilter('')
    }

    const handleClearName = () => {
        setNameFilter('')
    }

    const handleClearDeliveredOn = () => {
        setDeliveredDate('')
    }

    const openPaymentModal = (e, data, index) => {
        e.stopPropagation()
        showCustomOrderPaymentModal({customOrderPaymentModalVisible: true, orderPaymentData: data, orderID: orderData[index].Orders[0].ID})
    }

    const editCustomOrder = (data, index) => {
        console.log(editCustomOrderData[index].Orders[0])
        setEditCustomOrderData({editCustomOrderData: editCustomOrderData[index].Orders[0]})
        {buisness_ID != 9 &&
            navigate('/editCustomOrder')
        }
        {buisness_ID == 9 &&
            navigate('/editOpticCustomOrder')
        }
    }
    
    const handleDeleteCustomOrder = (e, index) => {
        e.stopPropagation()
        showCustomConfirmation({customConfirmationModalVisible: true, invoiceID: orderData[index].ID})
    }

    const handleUndoCustomOrder = (invoiceID) => {
        axios.post('/Invoices/undoCustomOrder/' + invoiceID).then(res => {
            if (res.data.message === 'Success') {
                setCustomOrderRender({customOrderRender: customOrderRender + 1})
            }
        })
    }

    const showDetailModal = (data) => {
        showCustomOrderDetailModal({customOrderDetailModalVisible: true, customOrderDetailData: data})
    }
    return (
        <>
            <div id="appCapsule" class="pb-0" style={{height: '100vh'}}>
                <div class="tab-content mt-1 h-100">
                    <div class="tab-pane fade show active h-100" id="completed" role="tabpanel">
                        <div class="section full mb-1 pb-0 border-bottom">
                            <div class="pt-0">
                                <div class="row">
                                    <div class="col-12 col-md-4 mb-1">
                                        {/* <div class="search-form me-1">
                                            <div class="form-group searchbox">
                                                <input type="text" class="form-control" value={searchableText} onChange={(e) => setSearchableText(e.target.value)} placeholder={"Search"} style={{borderRadius: '50px'}} />
                                                <i class="input-icon">
                                                    <ion-icon name="search-outline"></ion-icon>
                                                </i>
                                            </div>     
                                        </div> */}
                                    </div>
                                </div>
                                <div class="row mt-0 mb-1">
                                    <div class="col-4 col-md-3">
                                        <div class="form-group boxed p-0 me-1">
                                            <div class="input-wrapper">
                                                <label class="form-label mb-0" for="orderDate">Order</label>
                                                <input type="date" value={OrderDate} onChange={(e) => setOrderDate(e.target.value)} class="form-control form-control-sm pe-2 bg-transparent" id="orderDate" placeholder="Enter your name" autocomplete="off" style={{height: '24px'}} />
                                                <i class="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                            <div class="d-flex align-items-center justify-content-end">
                                                <span class="badge bg-secondary fs-10 d-flex align-items-center cursor-pointer" onClick={handleClearOrderDate} style={{height: '15px', marginTop: '4px'}}><ion-icon name="close-circle" style={{paddingBottom: '2px'}}></ion-icon> Clear</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="col-4 col-md-4">
                                        <div class="form-group boxed p-0 me-1">
                                            <div class="input-wrapper">
                                                <label class="form-label mb-0" for="deiveryDate">Delivery</label>
                                                <input type="date" value={DeliveryDate} onChange={(e) => setDeliveryDate(e.target.value)} class="form-control form-control-sm pe-2 bg-transparent" id="deiveryDate" placeholder="Enter your name" autocomplete="off" style={{height: '24px'}} />
                                                <i class="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                            <div class="d-flex align-items-center justify-content-end">
                                                <span class="badge bg-secondary fs-10 d-flex align-items-center cursor-pointer" onClick={handleClearDelivery} style={{height: '15px', marginTop: '4px'}}><ion-icon name="close-circle" style={{paddingBottom: '2px'}}></ion-icon> Clear</span>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div class="col-4 col-md-4">
                                        <div class="form-group boxed p-0 me-1">
                                            <div class="input-wrapper">
                                                <label class="form-label mb-0" for="byPhone">By Phone</label>
                                                <input type="number" value={PhoneFilter} onChange={(e) => setPhoneFilter(e.target.value)} class="form-control form-control-sm pe-2 bg-transparent" min="0" id="byPhone" placeholder="Search by Phone" autocomplete="off" style={{height: '24px'}} />
                                                <i class="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                            <div class="d-flex align-items-center justify-content-end">
                                                <span class="badge bg-secondary fs-10 d-flex align-items-center cursor-pointer" onClick={handleClearPhone} style={{height: '15px', marginTop: '4px'}}><ion-icon name="close-circle" style={{paddingBottom: '2px'}}></ion-icon> Clear</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 col-md-4">
                                        <div class="form-group boxed p-0 me-1">
                                            <div class="input-wrapper">
                                                <label class="form-label mb-0" for="byName">By Name</label>
                                                <input type="text" value={NameFilter} onChange={(e) => setNameFilter(e.target.value)} class="form-control form-control-sm pe-2 bg-transparent" id="byName" placeholder="Search by Name" autocomplete="off" style={{height: '24px'}} />
                                                <i class="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                            <div class="d-flex align-items-center justify-content-end">
                                                <span class="badge bg-secondary fs-10 d-flex align-items-center cursor-pointer" onClick={handleClearName} style={{height: '15px', marginTop: '4px'}}><ion-icon name="close-circle" style={{paddingBottom: '2px'}}></ion-icon> Clear</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="col-4 col-md-3">
                                        <div class="form-group boxed p-0 me-1">
                                            <div class="input-wrapper">
                                                <label class="form-label mb-0" for="deiveredOnDate">Delivered On</label>
                                                <input type="date" value={DeliveredDate} onChange={(e) => setDeliveredDate(e.target.value)} class="form-control form-control-sm pe-2 bg-transparent" id="deiveredOnDate" placeholder="Enter your name" autocomplete="off" style={{height: '24px'}} />
                                                <i class="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                            <div class="d-flex align-items-center justify-content-end">
                                                <span class="badge bg-secondary fs-10 d-flex align-items-center cursor-pointer" onClick={handleClearDeliveredOn} style={{height: '15px', marginTop: '4px'}}><ion-icon name="close-circle" style={{paddingBottom: '2px'}}></ion-icon> Clear</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 col-md-3">
                                        <div class="form-check form-check-inline d-flex align-items-center">
                                            {role == 6 &&
                                                <>
                                                    <label class="form-label text-nowrap me-1 mb-0 text-dark" for="selectShop">Select Shop</label>
                                                    {
                                                        ShopList &&
                                                        <select class="form-control" id="selectShop" onChange={(e) => changeSelectedShop(e)}>
                                                            {
                                                                ShopList.map((itm) => {
                                                                    return <option value={itm.ID}>{itm.Name}</option>
                                                                })
                                                            }
                                                        </select>
                                                    }
                                                </>
                                            }
                                            {role != 6 &&
                                                <label class="form-label text-nowrap me-1 mb-0 text-dark w-100" for="selectShop">
                                                    {ShopList?.map((itm) => {
                                                        return <>
                                                            {itm.ID == location_ID &&
                                                            <div class="form-group boxed p-0 me-1">
                                                                <div class="input-wrapper">
                                                                    <label class="form-label mb-0" for="deiveredOnDate">Selected Shop</label>
                                                                    <input type="text" value={itm.Name} class="form-control form-control-sm pe-2 bg-transparent" id="deiveredOnDate" placeholder="Enter your name" autocomplete="off" disabled style={{height: '24px'}} />
                                                                    <i class="clear-input">
                                                                        <ion-icon name="close-circle"></ion-icon>
                                                                    </i>
                                                                </div>
                                                            </div>
                                                            }
                                                        </>
                                                    })}
                                                </label>
                                            }
                                        </div>
                                    </div> */}
                                    <div class="col-12 col-md-12 mt-2">
                                        
                                        <div class="d-flex justify-content-center justify-content-sm-end">
                                            <div className="d-flex justify-content-end align-items-center gap-2 me-0">
                                                <button disabled className='btn btn-warning btn-sm'>Print</button>
                                                <button disabled={OrderList === null || OrderList?.length === 0} onClick={() => consoleData()} className='btn btn-primary btn-sm'>Export as CSV</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {OrderList && OrderList.length > 0 && 
                            <div class="section full h-100">
                                <div class="table-responsive h-100">
                                    <table className='table table-striped w-100 table' id="TableFormData">
                                        <thead>
                                            {
                                                OrderListHeader.map((itm, i) => {
                                                    return <th key={i} class="fw-bold">
                                                        {(itm === 'Advance') &&
                                                            <span class="text-center">
                                                                <span class="badge bg-primary" style={{height: '16px'}}>
                                                                    ₹{customOrderTotalAdvance}
                                                                </span>
                                                                <br />
                                                            </span>
                                                        }
                                                        {(itm === 'Balance') &&
                                                            <span class="text-center">
                                                                <span class="badge bg-success" style={{height: '16px'}}>
                                                                    ₹{customOrderTotalBalance}
                                                                </span>
                                                                <br />
                                                            </span>
                                                        }
                                                        {itm}
                                                    </th>
                                                    
                                                })
                                            }
                                            <th class="fw-bold">Action</th>
                                        </thead>
                                        <tbody>

                                            {
                                                OrderList.map((itm, i) => {
                                                    return <tr key={i} style={{background: (itm.Balance > 0 && itm.Balance < itm.Subtotal && itm.Status != '-1') ? ('#90ee9061') : ((OrderList[i].Status == -1) ? '#ffc2bc' : ''), cursor: 'pointer'}} onClick={() => {showDetailModal(itm)}}>
                                                    {
                                                            OrderListHeader.map((itms, j) => {
                                                                return <th key={j} class={(buisness_ID == 9) ? "text-nowrap fs-5 align-middle" : "text-nowrap align-middle"}>
                                                                    {itm[itms]}
                                                                </th>
                                                            })
                                                        }
                                                        <th class="text-nowrap">
                                                            {(OrderList[i].Status == 0) &&
                                                                <>
                                                                    <button class="btn btn-success btn-sm text-nowrap me-1" onClick={(e) => {openPaymentModal(e, OrderList[i], i)}}> Deliver</button>
                                                                    <button class="btn btn-danger btn-sm text-nowrap me-1" onClick={(e) => {handleDeleteCustomOrder(e, i)}}> Cancel</button>
                                                                    {/* <button class="btn btn-primary btn-sm text-nowrap me-1" onClick={() => {handleCloneCustomOrder(i)}}> Clone</button> */}
                                                                </>
                                                            }
                                                            {role == 6 &&
                                                                <>
                                                                    {(OrderList[i].Status == 1) &&
                                                                        <>
                                                                            <button class="btn btn-secondary btn-sm text-nowrap me-1" disabled> Delivered</button>
                                                                            <button class="btn btn-primary btn-sm text-nowrap me-1" onClick={() => {handleUndoCustomOrder(OrderList[i].InvoiceID)}}> Undo</button>
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                            {(OrderList[i].Status == -1) &&
                                                                <button class="btn btn-danger btn-sm text-nowrap me-1" disabled> Cancelled</button>
                                                            }
                                                            {/* {buisness_ID != 9 && */}
                                                                {/* <button class="btn btn-secondary btn-sm text-nowrap me-1" onClick={() => {editCustomOrder(OrderList[i], i)}}> Clone</button> */}
                                                                <button class="btn btn-secondary btn-sm text-nowrap me-1" onClick={() => {editCustomOrder(OrderList[i], i)}}> Edit</button>
                                                            {/* } */}
                                                        </th>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        {OrderList === null &&
                            <h2 class="text-danger d-flex align-items-center justify-content-center" style={{height: '250px'}}>Loading...</h2>
                        }
                        {OrderList && OrderList.length === 0 && 
                            <h2 class="text-danger d-flex align-items-center justify-content-center" style={{height: '250px'}}>No Orders Found</h2>
                        }
                    </div>
                </div>
                <OrderDetailModal></OrderDetailModal>
                <CustomOrdersPaymentModal></CustomOrdersPaymentModal>
                <CustomConfirmationModal></CustomConfirmationModal>
                <CustomOrderDetailModal></CustomOrderDetailModal>
                <div class="fab-button animate bottom-right" style={{bottom: '72px', right: '40px'}}>
                    <Link to={(currentBusinessIdData?.sidebarMenu?.customOrder?.visible) ? currentBusinessIdData?.sidebarMenu?.customOrder?.path : "/customOrder"} class="fab">
                        <ion-icon name="add-outline"></ion-icon>
                    </Link>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = ({ general, data, auth }) => {
    const {
    } = general
    const {
        buisness_ID,
        role,
        authToken,
        location_ID,
        currentBusinessIdData
    } = auth
    const {
        businessData,
        customOrderRender,
        editCustomOrderData
    } = data
    return {
        businessData,
        buisness_ID,
        role,
        authToken,
        location_ID,
        customOrderRender,
        editCustomOrderData,
        currentBusinessIdData
    }
  }
  
  const mapDispatchToProps = {
    showCustomOrderPaymentModal,
    setCustomOrderRender,
    showCustomConfirmation,
    setEditCustomOrderData,
    showCustomOrderDetailModal
  }

export default connect(mapStateToProps, mapDispatchToProps)(CustomOrders)