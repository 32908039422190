import React from 'react';

const PrintLoader = () => {
    return (
        <div class="d-flex align-items-center justify-content-center flex-column" style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 999, background: '#33333333'}}>
            <div class="spinner-border text-primary" role="status"></div>
            <span class="fs-4 mt-2 d-flex"><ion-icon name="print-outline" style={{marginRight: '5px'}}></ion-icon> Printing...</span>
        </div>
    );
};

export default PrintLoader;