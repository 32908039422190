import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { Link } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import DataTable from "react-data-table-component"
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { useNavigate, useLocation } from 'react-router-dom'
import { generalDataUpdate } from '../../features/utilities/generalSlice'
import { showItemProfileOffcanvas, setItemProfileDetails } from '../../features/Offcanvas/itemProfileOffcanvasSlice'
import Moment from 'react-moment' 
import axios from '../../AxiosConfig'
import Veg from '../images/icon/veg.png'
import NonVeg from '../images/icon/non-veg.png'
import InwardOutwardDetailModal from '../layout/Sidebar/InwardOutwardDetailModal'
import { showInwardOutwardModal } from '../../features/Offcanvas/inwardOutwardModalSlice'
import AddStockOptionOffcanvas from '../layout/Sidebar/AddStockOptionOffcanvas'
import { showAddStockOptionOffcanvas } from '../../features/Offcanvas/addStockOptionOffcanvasSlice'
import SearchWithButtons from './Components/SearchWithButtons'
import { setNotificationBlink, setOrderDayFilter, setBranchID, setProductionStockList, setStockActionType, setStockActionButton, itemsDataUpdate } from '../../features/Data/dataSlice'
import moment from 'moment'
import ItemCategories from './Components/ItemCategories'
import { showAddRemoveStockModal } from '../../features/Offcanvas/addremoveStockModalSlice'
import { updateEditStock } from '../../features/Data/stockSlice'

const Items = ({
    generalDataUpdate,
    categories,
    items,
    categoryID,
    itemName,
    showItemProfileOffcanvas,
    itemProfileVisible,
    authToken,
    setItemProfileDetails,
    showInwardOutwardModal,
    showAddStockOptionOffcanvas,
    configObj,
    setNotificationBlink,
    role,
    setOrderDayFilter,
    dayOrderFilter,
    businessData,
    buisness_ID,
    setBranchID,
    branchID,
    setProductionStockList,
    showAddRemoveStockModal,
    inwardOutwardDetailData,
    updateEditStock,
    setStockActionType,
    setStockActionButton,
    itemsDataUpdate
}) => {
    const [filteredCategory, setFilteredCategory] = useState('')
    const [allCategoryFlag, setAllCategoryFlag] = useState(true)
    const [itemSearch, setItemSearch] = useState('')
    const [stockableValue, setStockableValue] = useState('f')
    const [categoryList, setCategoryList] = useState(null)
    const [currentStockColumnsData, setCurrentStockColumnsData] = useState([])
    const [currentStockColumns, setCurrentStockColumns] = useState([])
    const [productionStockColumnsData, setProductionStockColumnsData] = useState([])
    const [productionStockColumns, setProductionStockColumns] = useState([])
    const [inwardOutwardList, setInwardOutwardList] = useState(null)
    const [datePicker, setDatePicker] = useState(null)
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [businessLocationId, setBusinessLocationId] = useState(null)
    const [selectedBranchName, setSelectedBranchName]= useState('')
    const [branchList, setBranchList] = useState()
    const [productionList, setProductionList] = useState(null)
    const [productionTableData, setProductionTableData] = useState(null)
    const [stockRequestTimeline, setStockRequestTimeline] = useState(null)
    const [finalTotalCount, setFinalTotalCount] = useState({'Other': 0})
    const search = useLocation().search
    const activeTabName = new URLSearchParams(search).get('tab')
    
    let navigate = useNavigate()
    const tokenData = jwt_decode(authToken)

    const buttonStyle = {
        "buttonText": "Items",
        "buttonColor":"warning"
    }

    useEffect(() => {
        generalDataUpdate({buttonStyle: buttonStyle})
    }, [])

    useEffect(() => {
        if (categories === null || categories === undefined || items === null || items === undefined) {
            navigate('/load')
        }
    }, [categories, items])

    useEffect(() => {
        if (items) {
            if (categoryID !== 'all') {
                setAllCategoryFlag(false)
                setFilteredCategory(items.filter((item) => categoryID === item.Item_CategoryID))
                setItemSearch(items.filter((item) => itemName.includes(item.Title)))
            } else {
                setAllCategoryFlag(true)
                setFilteredCategory(items)
                setItemSearch(items.filter((item) => itemName.includes(item.Title)))
            }
        }
    }, [categoryID])

    const handleItemDetailProfile = () => {
        navigate('/itemProfile')
    }

    const handleStockability = (stockable) => {
        if (stockable === true) {
            setStockableValue('t')
        } else {
            setStockableValue('f')
        }
    }

    const handleAddQuickAdd = (e, detail) => {
        e.stopPropagation();
        showItemProfileOffcanvas({itemProfileVisible: true})
        setItemProfileDetails({itemProfileData: detail})
    }
    const switchManageStock = () => {
        // showAddStockOptionOffcanvas({addStockOptionVisible: true})
        navigate('/addRemoveStock')
    }

    useEffect(() => {
        if (branchID) {
            let dateFilterFrom = new Date().toISOString().slice(0, 10)
            let dateFilterTo = new Date().toISOString().slice(0, 10)
            if (dayOrderFilter === 'Today') {
                dateFilterFrom = new Date().toISOString().slice(0, 10)
                dateFilterTo = new Date().toISOString().slice(0, 10)
            } else if (dayOrderFilter === 'Yesterday') {
                dateFilterFrom = (new Date(new Date().setDate(new Date().getDate()-1)).toISOString().slice(0, 10))
                dateFilterTo = (new Date(new Date().setDate(new Date().getDate()-1)).toISOString().slice(0, 10))
            } else if (dayOrderFilter === 'Last7') {
                dateFilterFrom = (new Date(new Date().setDate(new Date().getDate()-7)).toISOString().slice(0, 10))
                dateFilterTo = new Date().toISOString().slice(0, 10)
            } else if (dayOrderFilter === 'Last30') {
                dateFilterFrom = (new Date(new Date().setDate(new Date().getDate()-30)).toISOString().slice(0, 10))
                dateFilterTo = new Date().toISOString().slice(0, 10)
            } else if (dayOrderFilter === 'All') {
                dateFilterFrom = 'All'
                dateFilterTo = 'All'
            } else if (dayOrderFilter.includes('-')) {
                dateFilterFrom = dayOrderFilter
                dateFilterTo = dayOrderFilter
            }
            if (activeTabName == 'manageStock') {
                axios.get('/Stock/getList?date=' + dateFilterFrom + '&location='+ branchID).then(res => {
                    if (res.data.message === 'Success') {
                        setInwardOutwardList(res.data.data)
                        if (res.data.data[0]?.StockType === 'request') {
                            setNotificationBlink({notificationBlinkType: 'warehouse'})
                        } else if (res.data.data[0]?.StockType === 'inTransit') {
                            setNotificationBlink({notificationBlinkType: 'store'})
                        }
                    }
                })
            }
            if (activeTabName == 'production') {
                const data = {
                    "LocationID": branchID,
                    "Date": dateFilterFrom
                }
                axios.post('/Stock/getProductionList', data).then(res => {
                    if (res.data.message === 'Success') {
                        setProductionList(res.data.data)
                    }
                })
                axios.get('/Stock/getListForAdmin?date=' + dateFilterFrom + '&location=' + branchID).then(res => {
                    if (res.data.message === 'Success') {
                        setStockRequestTimeline(res.data.data)
                    }
                })
            }
        }
    }, [dayOrderFilter, branchID])

    const handleInwardOutwardDetail = (data, type) => {
        console.log(data, type)
        showInwardOutwardModal({inwardOutwardDetailData: data})
        setStockActionButton({stockActionButton: (data.StockType) ? data.StockType : type})
        setStockActionType({stockActionType: (data.StockType) ? data.StockType : type})
        showAddRemoveStockModal({addRemoveStockModalVisible: true})
        setBranchID({branchID: (data.StockType == 'transferInTransit' || data.StockType == 'transferIn') ? data.To_Location_ID : data.Location_ID})
        if ((type != 'addStock' && type != 'request' && type != 'removeStock' && type != 'transferStock')) {
            updateEditStock({editStockData: data.Items, stockRequestID: (data.StockRequestID) ? data.StockRequestID : data.ID})
        } else {
            updateEditStock({editStockData: [], stockRequestID: null})
        }
    }
    useEffect(() => {
        if (items) {
            let filtered;
            if (categoryID !== 'all' && categoryID !== undefined) {
                setAllCategoryFlag(false)
                filtered = items.filter((item) => categoryID === item.Item_CategoryID);
            } else {
                setAllCategoryFlag(true)
                filtered = items
            }
            if(itemName?.trim().length > 0) filtered = filtered.filter((item) => (item?.ItemTitle?.toLowerCase()?.includes(itemName?.toLowerCase()?.trim()) || item?.AlternateText?.includes(itemName?.trim()) || item?.Shortcode?.includes(itemName?.trim())))
            setFilteredCategory(filtered)
        }
    }, [categoryID, itemName, items])

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold', // override the row height
            },
        },
        rows: {
            style: {
                '&:nth-child(odd)': {
                    backgroundColor: '#e1e1e1',
                },
                '&:nth-child(even)': {
                    backgroundColor: '#ffffff',
                },
                minHeight: '43px'
            },
        },
    }
    // let productionTableData = {}
    function dataList(stateName) {
        if (stateName != 'production') {
            let tempColumns = [{
                id: 1,
                name: "Item Name",
                selector: (row) => row.itemName,
                sortable: true,
                reorder: true,
                cell: row => <span style={{ fontWeight: 'bold' }}>{row.itemName}</span>,
              } ,{
                id: 2,
                name: "Other",
                selector: (row) => row.total,
                sortable: true,
                reorder: true
              }]
              let tempColumnsData = []
              let sizeType = []
                if (items === null || items === undefined || items === '') {
                    navigate('/load')
                } else {
                    if (filteredCategory) {
                        let totalCount = {'Other': 0}
                        // let totalCount = {}
                        filteredCategory.map((v,k) => {
                            // if (v.Stockable === 't') {
                                if (configObj?.Config?.inStock) {
                                    // if (v.TotalSaleQty > 0) {
                                        let tempRowData = {
                                            id: v.ID,
                                            'Item Name': v.ItemTitle,
                                            'Other': '--',
                                            categoryID: v.Item_CategoryID,
                                            categoryName: v.Category
                                        }
                                        if (v.Sizes.length > 0) {
                                            v.Sizes.map((v1,k1) => {
                                                if (!totalCount.hasOwnProperty(v1.SizeCategory)) {
                                                    totalCount[v1.SizeCategory] = parseInt(v1.SalesQty)
                                                } else {
                                                    totalCount[v1.SizeCategory] += parseInt(v1.SalesQty)
                                                }
                                                if (!sizeType.includes(v1.SizeCategory)) {
                                                    let tempNewColumn = {
                                                        id: k + 3,
                                                        name: v1.SizeCategory,
                                                        selector: (row) => row[v1.SizeCategory],
                                                        sortable: true,
                                                        reorder: true
                                                    }
                                                    tempColumns.push(tempNewColumn)
                                                    sizeType.push(v1.SizeCategory)
                                                }
                                                tempRowData[v1.SizeCategory] = v1.SalesQty 
                                                tempRowData['Other'] = '--' 
                                            })
                                        } else {
                                            totalCount['Other'] += parseInt(v.SalesQty)
                                            tempRowData['Other'] = v.SalesQty 
                                        }
                                        tempColumnsData.push(tempRowData)
                                    // }
                                } else {
                                    let tempRowData = {
                                        id: v.ID,
                                        'Item Name': v.ItemTitle,
                                        'Other': '--',
                                        categoryID: v.Item_CategoryID,
                                        categoryName: v.Category
                                    }
                                    if (v.Sizes.length > 0) {
                                        v.Sizes.map((v1,k1) => {
                                            if (!sizeType.includes(v1.SizeCategory)) {
                                                let tempNewColumn = {
                                                    id: k + 3,
                                                    name: v1.SizeCategory,
                                                    selector: (row) => row[v1.SizeCategory],
                                                    sortable: true,
                                                    reorder: true
                                                }
                                                tempColumns.push(tempNewColumn)
                                                sizeType.push(v1.SizeCategory)
                                            }
                                            tempRowData[v1.SizeCategory] = v1.SalesQty 
                                            tempRowData['Other'] = '--' 
                                        })
                                    } else {
                                        tempRowData['Other'] = v.SalesQty 
                                    }
                                    tempColumnsData.push(tempRowData)
                                }
                                
                            // }
                        })
                        setFinalTotalCount(totalCount)
                    }
                    setCurrentStockColumns(tempColumns)
                    setCurrentStockColumnsData(tempColumnsData)
                }
        } else {
            let tempColumns = [{
                id: 1,
                name: "Item Name",
                selector: (row) => row.itemName,
                sortable: true,
                reorder: true,
                cell: row => <span style={{ fontWeight: 'bold' }}>{row.itemName}</span>,
              } ,{
                id: 2,
                name: "Total",
                selector: (row) => row.total,
                sortable: true,
                reorder: true,
                right: true
              }]
              let tempColumnsData = []
              let sizeType = []
                if (items === null || items === undefined || items === '') {
                    navigate('/load')
                } else {
                    if (productionList) {
                        productionList.map((v,k) => {
                            if (v.Stockable === 't') {
                                if (configObj?.Config?.inStock) {
                                    if (v.TotalSaleQty > 0) {
                                        let tempRowData = {
                                            id: v.ID,
                                            'Item Name': v.ItemTitle,
                                            Total: 0
                                        }
                                        if (v.Sizes.length > 0) {
                                            v.Sizes.map((v1,k1) => {
                                                if (!sizeType.includes(v1.SizeCategory)) {
                                                    let tempNewColumn = {
                                                        id: k + 3,
                                                        name: v1.SizeCategory,
                                                        selector: (row) => row[v1.SizeCategory],
                                                        sortable: true,
                                                        reorder: true,
                                                        right: true,
                                                        cell: row => <input type="number" style={{overflow: 'hidden', textAlign: 'end'}} value={parseInt(row[v1.SizeCategory])} />,
                                                    }
                                                    tempColumns.push(tempNewColumn)
                                                    sizeType.push(v1.SizeCategory)
                                                }
                                                tempRowData[v1.SizeCategory] = v1.SalesQty 
                                                tempRowData.Total += parseInt(v1.SalesQty)
                                                tempRowData[v1.SizeCategory + 'CurrentStock'] = v1.CurrentStock
                                            })
                                        } else {
                                            tempRowData.Total = v.SalesQty 
                                        }
                                        tempColumnsData.push(tempRowData)
                                    }
                                } else {
                                    let tempRowData = {
                                        id: v.ID,
                                        'Item Name': v.ItemTitle,
                                        Total: 0
                                    }
                                    if (v.Sizes.length > 0) {
                                        v.Sizes.map((v1,k1) => {
                                            if (!sizeType.includes(v1.SizeCategory)) {
                                                let tempNewColumn = {
                                                    id: k + 3,
                                                    name: v1.SizeCategory,
                                                    selector: (row) => row[v1.SizeCategory],
                                                    sortable: true,
                                                    reorder: true,
                                                    right: true,
                                                    cell: row => (
                                                        <div>
                                                        {(v.Sizes.length > 0) &&
                                                    <input type="number" style={{overflow: 'hidden', textAlign: 'end'}} value={parseInt(row[v1.SizeCategory])} />}</div>),
                                                }
                                                tempColumns.push(tempNewColumn)
                                                sizeType.push(v1.SizeCategory)
                                            }
                                            tempRowData[v1.SizeCategory] = v1.SalesQty 
                                            tempRowData.Total += parseInt(v1.SalesQty) 
                                            tempRowData[v1.SizeCategory + 'CurrentStock'] = v1.CurrentStock
                                        })
                                    } else {
                                        tempRowData.Total = v.SalesQty 
                                    }
                                    tempColumnsData.push(tempRowData)
                                }
                                
                            }
                        })
                    }
                    setProductionStockColumns(tempColumns)
                    setProductionStockColumnsData(tempColumnsData)
                    setProductionTableData({
                        tempColumns,
                        tempColumnsData,
                    });
                }
        }
    }
    useEffect(() => {
        dataList(activeTabName)
    }, [items, filteredCategory, productionList, activeTabName])
  
    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "ALL"
    };
    const handleOrderDateWise = (day) => {
        if (day === 'Custom') {
            setShowDatePicker(true)
        } else {
            setOrderDayFilter({dayOrderFilter: day})
            setDatePicker(null)
            setShowDatePicker(false)
        }
    }
    useEffect(() => {
        if (datePicker !== null && datePicker !== '' && datePicker !== undefined) {
            setOrderDayFilter({dayOrderFilter: datePicker})
        }
    }, [datePicker])
    const handleSwicthBranchLocation = (branchData) => {
        setBranchID({branchID: branchData.ID})
        setSelectedBranchName(branchData.Name)
    }
    useEffect(() => {
        if (businessData) {
            businessData[0].Branches.map((v,k) => {
                if (v.ID == tokenData.userInfo.DefaultLocationID) {
                    setSelectedBranchName(v.Name)     
                    setBranchID({branchID: v.ID})
                }
            })
        }
    }, [])
    useEffect(() => {
        if (businessData && buisness_ID) {
          setBranchList(businessData && businessData.filter((itm) =>  itm.ID === buisness_ID)[0].Branches)
        }
    }, [])
    const switchTab = (tabName) => {
        navigate('/items?tab=' + tabName)
    }
    const handlePrintProductionStock = (columns, data) => {
        setProductionStockList({productionColumns: columns, productionData: data})
        printProductionIframe(columns, data)
    }

    function printProductionIframe(productionColumn, productionData) {
        var iframe = document.getElementById('myIframe');
        // const tableBody = document.createElement('thead');
        let thDom = ''
        let trDom = ''
        productionColumn?.map((v, k) => {
            if (v.name !== 'Total') {
                thDom += `<th align="left" style="font-size: 12px;border-right: 1px solid #333333;border-bottom: 1px solid #333333;">${v.name}</th>`
            }
        });
        // const tr1 = document.createElement('tr');
        productionData?.map((v,k) => {
            let tempProductionData = JSON.parse(JSON.stringify(v))
            tempProductionData['Item Name'] = tempProductionData['Item Name']
            trDom += '<tr>'
            productionColumn.map((v1,k1) => {
                Object.keys(tempProductionData).forEach(key => {
                    if (v1.name !== 'Total') {
                        if (key == v1.name) {
                            trDom += '<td style="border-right: 1px solid #333333;border-bottom: 1px solid #333333;">' + tempProductionData[key] + '</td>'
                        }
                    }
                });
            })
            trDom += '</tr>'
        })
        
        const PrintProductionStock = '<div id="printProductionStock"><div><font face="verdana" size="2"><div><div id="receiptDetails" style="page-break-after: always;"><div style=" font-weight: bold; text-align: center; word-wrap: break-word;margin-bottom: 10px;">Production Stock</div><table style="width: 100%;border-top: solid 1px #333333;border-left: solid 1px #333333;"><thead><tr>' + thDom + '</tr></thead><tbody>' + trDom + '</tbody></table><div align="center" style="font-size: 10px;margin-top: 10px;">Powered by <b>allpos.com</b></div><br /></div></div></font></div></div>';
        
        iframe.contentWindow.document.body.innerHTML = PrintProductionStock


        iframe.onload = function() {
            iframe.contentWindow.print();
        };

        if (iframe.contentDocument.readyState === 'complete') {
            iframe.contentWindow.print();
        }
    }

    const handleProductionItemUpdate = (value, columnsData, columnsName, index) => {
        let tempProductionStockColumn = JSON.parse(JSON.stringify(productionStockColumnsData))
        tempProductionStockColumn[index][columnsName] = parseInt(value)
        setProductionStockColumnsData(tempProductionStockColumn)
    }

    const categoryCounts = currentStockColumnsData.reduce((acc, item) => {
        acc[item.categoryName] = (acc[item.categoryName] || 0) + 1;
        return acc;
    }, {});

    let previousCategory = '';

    useEffect(() => {
        const hash = window.location.hash.substring(1); // Get the id from the URL hash
        if (hash) {
            const element = document.getElementById(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);
    
    useEffect(() => {
        if (typeof branchID != 'object') {
          axios.get('/Items/business/' + branchID).then(res => {
            if (res.data.message === 'Success') {
              itemsDataUpdate({ items: res.data.data })
            } else {
            }
          })
        }
    }, [branchID])
    return (
        <div>
            <iframe id="myIframe" style={{display: 'none'}}></iframe> 
            {/* {(activeTabName !== 'production') && */}
                <div class="extraHeader p-0" style={{height: '36px', zIndex: 10}}>
                    <ul class="nav nav-tabs lined" role="tablist">
                        <li class="nav-item">
                            <Link to="/items" class={(activeTabName === '' || activeTabName === null || activeTabName === undefined) ? "nav-link active" : "nav-link"} style={{height: '36px'}}role="tab">
                                Current Stock
                            </Link>
                        </li>
                        <li class="nav-item">
                            <Link to="/items?tab=manageStock" class={(activeTabName === 'manageStock') ? "nav-link active" : "nav-link"} style={{height: '36px'}} role="tab">
                                Manage Stock
                            </Link>
                        </li>
                        {(role == 6 || role == 10) &&
                            <li class="nav-item">
                                <Link to="/items?tab=production" class={(activeTabName === 'production') ? "nav-link active" : "nav-link"} style={{height: '36px'}}role="tab">
                                    Production
                                </Link>
                            </li>
                        }
                        {/* <li class="nav-item">
                            <Link to="/addRemoveStock" class="nav-link" style={{height: '36px'}} href="#" role="tab">
                                Add / Remove / Request
                            </Link>
                        </li> */}
                    </ul>
                </div>
            {/* } */}
            <div id="appCapsule" class="extra-header-active" style={{paddingTop: '90px'}}> 
                <div class="tab-content mt-2 mx-0" style={{marginRight: '-8px', marginLeft: '-8px'}}>
                    <div class={(activeTabName === '' || activeTabName === null || activeTabName === undefined) ? "tab-pane fade show active" : "tab-pane fade"} id="currentStock" role="tabpanel"> 
                        <div class="row">
                            {/* <div class="col-12 col-md-8 mb-2"> */}
                                <div class="row mb-1">
                                    <div class="col-8 col-md-8">
                                        <div class="mb-1">
                                            <SearchWithButtons pageName={"Items"}></SearchWithButtons>
                                        </div>
                                    </div>
                                    <div class="col-4 col-md-4 my-0 mx-0 d-flex justify-content-end">
                                        {/* <>
                                            {showDatePicker === false &&
                                                <div class="dropdown d-flex me-1" style={{cursor: 'pointer'}}>
                                                    <div class="badge bg-primary dropdown-toggle fs-8" data-bs-toggle="dropdown">
                                                        <span style={{textTransform: 'uppercase'}}>
                                                            <ion-icon name="calendar-outline" class="m-0 fs-8" style={{width: '18px'}}></ion-icon>
                                                            {dayOrderFilter}
                                                        </span>
                                                        <ion-icon name="chevron-down-outline" class="fs-6"></ion-icon>
                                                    </div>
                                                    <div class="dropdown-menu p-0">
                                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Today')}}>Today</a>
                                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Yesterday')}}>Yesterday</a>
                                                        <div class="divider"></div>
                                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Custom')}}>Custom</a>
                                                    </div>
                                                </div>
                                            }
                                            {showDatePicker &&
                                                <div class="form-group boxed me-1">
                                                    <div class="input-wrapper">
                                                        <input
                                                            type="date"
                                                            class="form-control"
                                                            value={datePicker}
                                                            onChange={e => {
                                                                setDatePicker(e.target.value)
                                                            }}
                                                            id="datePicker"
                                                            placeholder="Date Picker"
                                                            autocomplete="off"
                                                            style={{fontSize: '14px', height: '32px'}}
                                                        />
                                                        <i class="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i>
                                                        <label class="form-label fs-10 d-flex align-items-center mt-1 cursor-pointer" onClick={() => {handleOrderDateWise('Today')}}>
                                                            <ion-icon name="chevron-back-outline"></ion-icon>Back
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                        </> */}
                                        <div class="dropdown me-1">
                                            <button class="btn btn-outline-secondary btn-sm dropdown-toggle fs-8" data-bs-toggle="dropdown" style={{textTransform: 'capitalize'}}>
                                                <ion-icon name={"location-outline"}></ion-icon>
                                                {selectedBranchName}
                                                <ion-icon name="chevron-down-outline" class="fs-6"></ion-icon>
                                            </button>
                                            <div class="dropdown-menu pb-0">
                                                {branchList && branchList.map((v,k) => {
                                                    return <a class="dropdown-item pt-0" href="javascript:void(0)" onClick={() => {handleSwicthBranchLocation(v)}}> {v.Name}</a>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-2">
                                    <ItemCategories categories={categories} pageName={"Items"}></ItemCategories>
                                </div>
                                
                                <div class="col-12 col-md-12">
                                    <div class="section mt-2">
                                        {/* <div>
                                            <h2>Current Stock</h2>
                                            <button class="btn btn-outline-primary btn-sm"><ion-icon name="print-outline" class="m-0 fs-8"></ion-icon>Print</button>
                                        </div> */}
                                        <div class="table-responsive w-100">
                                            <table class="table table-striped border">
                                                <thead>
                                                    <tr style={{borderBottom: 'solid 2px'}}>
                                                        <th scope="col" class="fw-bold fs-6"></th>
                                                        {currentStockColumns.map((v,k) => {
                                                            return <>
                                                                <th scope="col" class={(v.name == 'Item Name') ? "fs-6 fw-bold text-nowrap" : "text-end fs-6 fw-bold text-nowrap"}>
                                                                    {(v.name !== 'Item Name') ?
                                                                        <span class="d-flex flex-column text-center">
                                                                            <span class="badge bg-primary w-100 mb-1">
                                                                                {finalTotalCount[v.name] &&
                                                                                    <>
                                                                                        <ion-icon name="cart-outline" class="me-1"></ion-icon>{finalTotalCount[v.name]}
                                                                                    </>
                                                                                }
                                                                            </span>
                                                                            {v.name}
                                                                        </span>
                                                                    :
                                                                        <>
                                                                            {v.name}
                                                                        </>
                                                                    }
                                                                </th>
                                                            </>
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentStockColumnsData.map((v,k) => {
                                                        const isNewCategory = previousCategory !== v.categoryName;
                                                        const isLastItemOfCategory = k === currentStockColumnsData.length - 1 || currentStockColumnsData[k + 1].categoryName !== v.categoryName;
                                                        if (isNewCategory) {
                                                            previousCategory = v.categoryName;
                                                        }
                                                        return (<tr style={{borderBottom: (isLastItemOfCategory) ? '1px solid' : '' }}>
                                                            {isNewCategory && (
                                                                <td class="ps-2 align-middle text-center" id={v.categoryName} rowSpan={categoryCounts[v.categoryName]} style={{borderRight: '1px solid',borderBottom: '1px solid', backgroundColor: 'white'}}>
                                                                    <div class="fs-6 fw-bold">
                                                                    {v.categoryName}
                                                                    </div>
                                                                </td>
                                                            )}
                                                            {currentStockColumns.map((v1,k1) => {
                                                                return <>
                                                                    {/* {v1.name !== 'Total' && */}
                                                                        <td scope="row" class={(v1.name != 'Item Name') ? "pt-1 pb-1 align-middle text-end pe-1 ps-1" : "pt-1 pb-1 align-middle pe-1 ps-1"}>
                                                                            <>
                                                                            {/* {(v1.name != 'Item Name' && v1.name != 'Total') &&
                                                                                <div class="form-group boxed p-0" style={{position: 'relative'}}>
                                                                                    <div class="input-wrapper">
                                                                                        <input type="number" class="form-control pe-2" value={v[v1.name]} onChange={(e) => {handleProductionItemUpdate(e.target.value, v, v1.name, k)}} placeholder="0" autocomplete="off" min={0} />
                                                                                        <i class="clear-input">
                                                                                            <ion-icon name="close-circle"></ion-icon>
                                                                                        </i>
                                                                                    </div>
                                                                                    {((typeof branchID == 'string' || (branchID.length == 1 || branchID.length == 0))) &&
                                                                                        <span class="badge bg-primary" style={{height: '18px', position: 'absolute', top: 0, right: 0, width: '28px', fontSize: '12px'}}>{v[v1.name + 'CurrentStock']}</span>
                                                                                    }
                                                                                </div>
                                                                            } */}
                                                                            <span class="fs-6 fw-bold">
                                                                                {(v[v1.name]) ? v[v1.name] : '--'}
                                                                            </span>
                                                                            {/* {(v1.name == 'Other') &&
                                                                            } */}
                                                                            </>
                                                                        </td>
                                                                    {/* } */}
                                                                </>
                                                            })}
                                                        </tr>)
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* <DataTable
                                            columns={currentStockColumns}
                                            data={currentStockColumnsData}
                                            defaultSortFieldId={1}
                                            fixedHeader={true}
                                            fixedHeaderScrollHeight={'65vh'}
                                            customStyles={customStyles}
                                            // paginationPerPage={50}
                                            // paginationRowsPerPageOptions={[10, 50, 100, 150, 200]}
                                            // pagination
                                            // paginationComponentOptions={paginationComponentOptions}
                                        /> */}
                                    </div>
                                </div>
                                {/* <div class="col-12 col-md-4">
                                    <div class="section">
                                        <h2>Stock History</h2>
                                        {inwardOutwardList?.length > 0 &&
                                            <ul class="listview image-listview flush bg-transparent" style={{height: '65vh', overflow: 'scroll'}}>
                                                {inwardOutwardList?.map((v,k) => {
                                                    return (<>
                                                    <li>
                                                        <div class="item ps-1">
                                                            {v.StockType === 'addStock' &&
                                                                <div class="icon-box bg-warning">
                                                                    <ion-icon name="bag-add-outline"></ion-icon>
                                                                </div>
                                                            }
                                                            {v.StockType === 'removeStock' &&
                                                                <div class="icon-box bg-danger">
                                                                    <ion-icon name="bag-remove-outline"></ion-icon>
                                                                </div>
                                                            }
                                                            {v.StockType === 'transferOut' &&
                                                                <div class="icon-box" style={{backgroundColor: '#fa758d'}}>
                                                                    <ion-icon name="swap-horizontal-outline"></ion-icon>
                                                                </div>
                                                            }
                                                            {v.StockType === 'request' &&
                                                                <div class="icon-box bg-secondary">
                                                                    <ion-icon name="bag-check-outline"></ion-icon>
                                                                </div>
                                                            }
                                                            {v.StockType === 'inTransit' &&
                                                                <div class="icon-box bg-success">
                                                                    <ion-icon name="cube-outline"></ion-icon>
                                                                </div>
                                                            }
                                                            {v.StockType === 'modifyRequest' &&
                                                                <div class="icon-box bg-dark">
                                                                    <ion-icon name="checkmark-done-circle-outline"></ion-icon>
                                                                </div>
                                                            }
                                                            <div class="in">
                                                                <div>
                                                                    <header><Moment fromNow>{v.DateTime}</Moment></header>
                                                                    {v.StockType === 'addStock' &&
                                                                        <>
                                                                            Added
                                                                        </>
                                                                    }
                                                                    {v.StockType === 'removeStock' &&
                                                                        <>
                                                                            Removed
                                                                        </>
                                                                    }
                                                                    {v.StockType === 'transferOut' &&
                                                                        <>
                                                                            Transfered
                                                                        </>
                                                                    }
                                                                    {v.StockType === 'request' &&
                                                                        <>
                                                                            Requested
                                                                        </>
                                                                    }
                                                                    {v.StockType === 'inTransit' &&
                                                                        <>
                                                                            In Transit
                                                                        </>
                                                                    }
                                                                    {v.StockType === 'modifyRequest' &&
                                                                        <>
                                                                            Modified Request
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    {v?.Timeline.length > 0 &&
                                                        <>
                                                            {v?.Timeline?.map((v1,k1) => {
                                                                return <li class="ms-2" onClick={() => {handleInwardOutwardDetail(v1)}}>
                                                                    <a href="javascript:void(0)" class="item ps-1">
                                                                        {v1.StockType === 'addStock' &&
                                                                            <div class="icon-box bg-warning">
                                                                                <ion-icon name="bag-add-outline"></ion-icon>
                                                                            </div>
                                                                        }
                                                                        {v1.StockType === 'removeStock' &&
                                                                            <div class="icon-box bg-danger">
                                                                                <ion-icon name="bag-remove-outline"></ion-icon>
                                                                            </div>
                                                                        }
                                                                        {v1.StockType === 'transferOut' &&
                                                                            <div class="icon-box" style={{backgroundColor: '#fa758d'}}>
                                                                                <ion-icon name="swap-horizontal-outline"></ion-icon>
                                                                            </div>
                                                                        }
                                                                        {v1.StockType === 'request' &&
                                                                            <div class="icon-box bg-secondary">
                                                                                <ion-icon name="bag-check-outline"></ion-icon>
                                                                            </div>
                                                                        }
                                                                        {v1.StockType === 'inTransit' &&
                                                                            <div class="icon-box bg-success">
                                                                                <ion-icon name="cube-outline"></ion-icon>
                                                                            </div>
                                                                        }
                                                                        {v1.StockType === 'modifyRequest' &&
                                                                            <div class="icon-box bg-dark">
                                                                                <ion-icon name="checkmark-done-circle-outline"></ion-icon>
                                                                            </div>
                                                                        }
                                                                        <div class="in">
                                                                            <div>
                                                                                <header><Moment fromNow>{v1.DateTime}</Moment></header>
                                                                                {v1.StockType === 'addStock' &&
                                                                                    <>
                                                                                        Added
                                                                                    </>
                                                                                }
                                                                                {v1.StockType === 'removeStock' &&
                                                                                    <>
                                                                                        Removed
                                                                                    </>
                                                                                }
                                                                                {v1.StockType === 'transferOut' &&
                                                                                    <>
                                                                                        Transfered
                                                                                    </>
                                                                                }
                                                                                {v1.StockType === 'request' &&
                                                                                    <>
                                                                                        Requested
                                                                                    </>
                                                                                }
                                                                                {v1.StockType === 'inTransit' &&
                                                                                    <>
                                                                                        In Transit
                                                                                    </>
                                                                                }
                                                                                {v1.StockType === 'modifyRequest' &&
                                                                                    <>
                                                                                        Modified Request
                                                                                    </>
                                                                                }
                                                                                <footer class="mt-0">
                                                                                    <div class="text">
                                                                                        <span class="badge bg-secondary me-1" style={{height: '18px'}}>{v1.Items.length} Items</span>
                                                                                        <span class="badge bg-secondary me-1" style={{height: '18px'}}>{v1.TotalQty} Qty</span>
                                                                                    </div>
                                                                                    
                                                                                </footer>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                            })}
                                                        </>
                                                    }
                                                    </>)
                                                })}
                                            </ul>
                                        }
                                        {inwardOutwardList?.length === 0 &&
                                            <h1 class="text-danger d-flex align-items-center justify-content-center mt-5">No Items Added</h1>
                                        }
                                    </div>
                                    <InwardOutwardDetailModal></InwardOutwardDetailModal>
                                </div> */}
                            {/* </div> */}
                        </div>
                        {/* <Link to="/items?" class="fab-button text bottom-center responsive-bottom-fab">
                            <button
                                class="fab fs-6 pe-2 ps-2 bg-primary"
                                style={{
                                    height: '40px',
                                    fontFamily: "'Inter', sans-serif"
                                }}
                            >
                                Add Stock
                            </button>
                        </Link> */}
                    </div>
                    <div class={(activeTabName === 'manageStock') ? "tab-pane fade show active" : "tab-pane fade"} id="manageStock" role="tabpanel">
                        <div>
                            <SearchWithButtons></SearchWithButtons>
                        </div>
                        <div class="section full mt-2">
                            {showDatePicker === false &&
                                <div class="dropdown d-flex justify-content-end me-1" style={{cursor: 'pointer'}}>
                                    <div class="badge bg-primary dropdown-toggle fs-8" data-bs-toggle="dropdown">
                                        <span style={{textTransform: 'uppercase'}}>
                                            <ion-icon name="calendar-outline" class="m-0 fs-8" style={{width: '18px'}}></ion-icon>
                                            {dayOrderFilter}
                                        </span>
                                        <ion-icon name="chevron-down-outline" class="fs-6"></ion-icon>
                                    </div>
                                    <div class="dropdown-menu p-0">
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Today')}}>Today</a>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Yesterday')}}>Yesterday</a>
                                        <div class="divider"></div>
                                        <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleOrderDateWise('Custom')}}>Custom</a>
                                    </div>
                                </div>
                            }
                            {showDatePicker &&
                                <div class="form-group boxed me-1">
                                    <div class="input-wrapper">
                                        <input
                                            type="date"
                                            class="form-control"
                                            value={datePicker}
                                            onChange={e => {
                                                setDatePicker(e.target.value)
                                            }}
                                            id="datePicker"
                                            placeholder="Date Picker"
                                            autocomplete="off"
                                            style={{fontSize: '14px', height: '32px'}}
                                        />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <label class="form-label fs-10 d-flex align-items-center mt-1 cursor-pointer" onClick={() => {handleOrderDateWise('Today')}}>
                                            <ion-icon name="chevron-back-outline"></ion-icon>Back
                                        </label>
                                    </div>
                                </div>
                            }
                            {inwardOutwardList?.map((v,k) => {
                                    return <ul class="d-flex justify-content-between p-0 flex-column" style={{listStyleType: 'none'}}>
                                    <div class="d-flex align-items-center mb-1">
                                        <div class="fs-5 mb-0 ps-5">{v.LocationName}</div>
                                        {v.ToLocationName &&
                                            <>
                                                &nbsp;
                                                <ion-icon name="arrow-forward-outline" class="fs-5 text-secondary"></ion-icon> &nbsp;
                                                <div class="fs-5 mb-0 ps-0">{v.ToLocationName}</div>
                                            </>
                                        }
                                    </div>
                                    <div class="row w-100 pb-0" style={{borderBottom: '1px solid gainsboro', overflowX: 'scroll', flexFlow: 'nowrap'}}>
                                        {v?.Timeline?.map((v1,k1) => {
                                            return (<div class="col-4 col-md-2 d-flex justify-content-center position-relative" onClick={() => {handleInwardOutwardDetail(v1)}}>
                                                {(k1 == 0 && v.Timeline.length > 1) &&
                                                    <div class="position-absolute" style={{border: '1px solid lightgray', height: '1px', top: '24%', zIndex: 1, width: '50%', right: 0}}></div>
                                                }
                                                {((v.Timeline.length !== (k1 + 1)) && k1 != 0) &&
                                                    <div class="w-100 position-absolute" style={{border: '1px solid lightgray', height: '1px', top: '24%', zIndex: 1}}></div>
                                                }
                                                {v.StockType != 'inTransit' &&
                                                    <>
                                                        {((v.Timeline.length === (k1 + 1)) && k1 != 0) &&
                                                            <div class="position-absolute" style={{border: '1px solid lightgray', height: '1px', top: '24%', zIndex: 1, width: '50%', left: 0}}></div>
                                                        }
                                                    </>
                                                }
                                                {v.StockType == 'inTransit' &&
                                                    <>
                                                        {role == 5 ?
                                                            <>
                                                                {((v.Timeline.length === (k1 + 1)) && k1 != 0) &&
                                                                    <div class="position-absolute" style={{border: '1px solid lightgray', height: '1px', top: '24%', zIndex: 1, width: '100%', left: 0}}></div>
                                                                }
                                                            </>
                                                        :
                                                            <>
                                                                {((v.Timeline.length === (k1 + 1)) && k1 != 0) &&
                                                                    <div class="position-absolute" style={{border: '1px solid lightgray', height: '1px', top: '24%', zIndex: 1, width: '50%', left: 0}}></div>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                }
                                                <li class="d-flex justify-content-center flex-column cursor-pointer">
                                                    <div class="text-center">
                                                        {v1.StockType === 'request' &&
                                                            <div class="border rounded-circle m-auto border-primary bg-primary border-2 text-dark d-flex align-items-center justify-content-center" style={{width: '60px', height: '60px', zIndex: 2, position: 'sticky'}}>
                                                                <ion-icon name="bag-check-outline" class="fs-2 text-white"></ion-icon>
                                                            </div>
                                                        }
                                                        {v1.StockType === 'sendStock' &&
                                                            <div class="border rounded-circle m-auto border-primary bg-primary border-2 text-dark d-flex align-items-center justify-content-center" style={{width: '60px', height: '60px', zIndex: 2, position: 'sticky'}}>
                                                                <ion-icon name="bag-add-outline" class="fs-2 text-white"></ion-icon>
                                                            </div>
                                                        }
                                                        {v1.StockType === 'removeStock' &&
                                                            <div class="border rounded-circle m-auto border-danger bg-danger border-2 text-dark d-flex align-items-center justify-content-center" style={{width: '60px', height: '60px', zIndex: 2, position: 'sticky'}}>
                                                                <ion-icon name="bag-remove-outline" class="fs-2 text-white"></ion-icon>
                                                            </div>
                                                        }
                                                        {(v1.StockType === 'inTransit' || v1.StockType === 'transferInTransit') &&
                                                            <div class="border rounded-circle m-auto border-warning bg-warning border-2 text-dark d-flex align-items-center justify-content-center" style={{width: '60px', height: '60px', zIndex: 2, position: 'sticky'}}>
                                                                <ion-icon name="car-outline" class="fs-2 text-white"></ion-icon>
                                                            </div>
                                                        }
                                                        {v1.StockType === 'production' &&
                                                            <div class="border rounded-circle m-auto border-success bg-success border-2 text-dark d-flex align-items-center justify-content-center" style={{width: '60px', height: '60px', zIndex: 2, position: 'sticky'}}>
                                                                <ion-icon name="restaurant-outline" class="fs-2 text-white"></ion-icon>
                                                            </div>
                                                        }
                                                        {v1.StockType === 'modifyRequest' &&
                                                            <div class="border rounded-circle m-auto border-secondary bg-secondary border-2 text-dark d-flex align-items-center justify-content-center" style={{width: '60px', height: '60px', zIndex: 2, position: 'sticky'}}>
                                                                <ion-icon name="pencil-outline" class="fs-2 text-white"></ion-icon>
                                                            </div>
                                                        }
                                                        {v1.StockType === 'addStock' &&
                                                            <div class="border rounded-circle m-auto border-success bg-success border-2 text-dark d-flex align-items-center justify-content-center" style={{width: '60px', height: '60px', zIndex: 2, position: 'sticky'}}>
                                                                <ion-icon name="checkmark-done-outline" class="fs-2 text-white"></ion-icon>
                                                            </div>
                                                        }
                                                        {v1.StockType === 'wastage' &&
                                                            <div class="border rounded-circle m-auto border-danger bg-danger border-2 text-dark d-flex align-items-center justify-content-center" style={{width: '60px', height: '60px', zIndex: 2, position: 'sticky'}}>
                                                                <ion-icon name="trash-outline" class="fs-2 text-white"></ion-icon>
                                                            </div>
                                                        }
                                                        {v1.StockType === 'transferOut' &&
                                                            <div class="border rounded-circle m-auto border-secondary bg-secondary border-2 text-dark d-flex align-items-center justify-content-center" style={{width: '60px', height: '60px', zIndex: 2, position: 'sticky'}}>
                                                                <ion-icon name="arrow-redo-outline" class="fs-2 text-white"></ion-icon>
                                                            </div>
                                                        }
                                                        {v1.StockType === 'transferIn' &&
                                                            <div class="border rounded-circle m-auto border-success bg-success border-2 text-dark d-flex align-items-center justify-content-center" style={{width: '60px', height: '60px', zIndex: 2, position: 'sticky'}}>
                                                                <ion-icon name="arrow-undo-outline" class="fs-2 text-white"></ion-icon>
                                                            </div>
                                                        }
                                                        {(v1.StockType == 'inTransit' || v1.StockType === 'transferInTransit') ?
                                                            <div class="text-center fs-6 text-nowrap">On the way</div>
                                                        :
                                                        <>
                                                            {v1.StockType == 'addStock' ?
                                                                <div class="text-center fs-6 text-nowrap" style={{textTransform: 'capitalize'}}>Delivered</div>
                                                            :
                                                                <>
                                                                {v1.StockType == 'request' ?
                                                                    <div class="text-center fs-6 text-nowrap" style={{textTransform: 'capitalize'}}>Requested Stock</div>
                                                                :    
                                                                    <div class="text-center fs-6 text-nowrap" style={{textTransform: 'capitalize'}}>{v1.StockType}</div>
                                                                }
                                                                </>
                                                            }
                                                        </>
                                                        }
                                                        <div class="text-center fs-10" style={{marginTop: '-5px', height: '20px'}}><Moment fromNow>{v1.DateTime}</Moment></div>
                                                        <span class="badge bg-secondary" style={{height: '18px'}}><ion-icon name="cube-outline"></ion-icon> &nbsp;{v1.TotalQty}</span>
                                                    </div>
                                                </li>
                                            </div>
                                            )
                                        })}
                                        {role == 5 &&
                                            <>
                                                {(v.StockType == 'inTransit') &&
                                                    <div class="col-4 col-md-2 d-flex justify-content-center position-relative" onClick={() => {handleInwardOutwardDetail(v.Timeline[v.Timeline.length - 1])}}>
                                                        <div class="position-absolute" style={{border: '1px solid lightgray', height: '1px', top: '24%', zIndex: 1, width: '50%', left: 0}}></div>
                                                        <li class="cursor-pointer" style={{zIndex: 5}}>
                                                            <div class="text-center">
                                                                <button class="btn btn-success text-nowrap" style={{marginTop: '10%'}}><ion-icon name="checkmark-done-outline"></ion-icon> Accept Stock</button>
                                                            </div>
                                                        </li>
                                                    </div>
                                                }
                                                 {(v.StockType == 'inTransit' || v.StockType == 'transferInTransit') && (parseInt(v.ToLocationID) == parseInt(tokenData.userInfo.DefaultLocationID)) &&
                                                    <div class="col-4 col-md-2 d-flex justify-content-center position-relative" onClick={() => {handleInwardOutwardDetail(v.Timeline[v.Timeline.length - 1])}}>
                                                        <div class="position-absolute" style={{border: '1px solid lightgray', height: '1px', top: '24%', zIndex: 1, width: '70%', left: '-50%'}}></div>
                                                        <li class="cursor-pointer" style={{zIndex: 5}}>
                                                            <div class="text-center">
                                                                <button class="btn btn-success text-nowrap" style={{marginTop: '10%'}}><ion-icon name="checkmark-done-outline"></ion-icon> Accept Stock</button>
                                                            </div>
                                                        </li>
                                                    </div>
                                                }
                                                {(v.StockType == 'transferOut' && (parseInt(v.LocationID) == parseInt(tokenData.userInfo.DefaultLocationID))) &&
                                                    <div class="col-4 col-md-2 d-flex justify-content-center position-relative" onClick={() => {handleInwardOutwardDetail(v.Timeline[v.Timeline.length - 1])}}>
                                                        <div class="position-absolute" style={{border: '1px solid lightgray', height: '1px', top: '24%', zIndex: 1, width: '70%', left: '-50%'}}></div>
                                                        <li class="cursor-pointer" style={{zIndex: 5}}>
                                                            <div class="text-center">
                                                                <button class="btn btn-success text-nowrap" style={{marginTop: '10%'}}><ion-icon name="arrow-redo-outline"></ion-icon> <span class="d-none d-sm-block">Transfer Stock</span></button>
                                                            </div>
                                                        </li>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </ul>
                            })}
                            {inwardOutwardList?.length == 0 &&
                                <h1 class="text-danger text-center">No data found</h1>
                            }
                            {/* <div class="form-wizard-section">
                                <div class="row">
                                    <div class="col-md-4">
                                        <a href="component-form-wizard.html" class="button-item">
                                            <strong>1</strong>
                                            <p>Check</p>
                                        </a>
                                    </div>
                                    <div class="col-md-4">
                                        <a href="component-form-wizard.html" class="button-item">
                                            <strong>2</strong>
                                            <p>Enter Address</p>
                                        </a>
                                    </div>
                                </div>
                                <a href="component-form-wizard.html" class="button-item active">
                                    <strong>3</strong>
                                    <p>Payment</p>
                                </a>
                                <a href="component-form-wizard.html" class="button-item">
                                    <strong>
                                        <ion-icon name="checkmark-outline"></ion-icon>
                                    </strong>
                                    <p>Order Done</p>
                                </a>
                            </div> */}
                        </div>
                        <div class="fab-button text bottom-center responsive-bottom-fab d-flex" style={{zIndex: 5}}>
                            {(role == 6 || role == 10) &&
                                <>
                                    <button
                                        class={"fab fs-6 pe-2 ps-2 btn btn-secondary text-nowrap me-1"}
                                        style={{
                                            height: '40px',
                                            fontFamily: "'Inter', sans-serif"
                                        }}
                                        onClick={() => {handleInwardOutwardDetail('', 'transferStock')}}
                                    >
                                            <>
                                                <ion-icon name="swap-horizontal-outline" class="fs-4 me-0"></ion-icon>
                                                <span class="d-none d-sm-block">Transfer Stock</span>
                                            </>
                                    </button>
                                    <button
                                        class={"fab fs-6 pe-2 ps-2 btn btn-danger text-nowrap me-1"}
                                        style={{
                                            height: '40px',
                                            fontFamily: "'Inter', sans-serif"
                                        }}
                                        // onClick={(role == 6 || role == 10) ? (() => {handleShowStockHistory('add')}) : (() => {handleShowStockHistory('request')})}
                                        onClick={() => {handleInwardOutwardDetail('', 'removeStock')}}
                                    >
                                            <>
                                                <ion-icon name="close-outline" class="fs-4 me-0"></ion-icon>
                                                <span class="d-none d-sm-block">Remove Stock</span>
                                            </>
                                    </button>
                                </>
                            }
                            <button
                                class={"fab fs-6 pe-2 ps-2 btn btn-success text-nowrap"}
                                style={{
                                    height: '40px',
                                    fontFamily: "'Inter', sans-serif"
                                }}
                                // onClick={(role == 6 || role == 10) ? (() => {handleShowStockHistory('add')}) : (() => {handleShowStockHistory('request')})}
                                onClick={(role == 6 || role == 10) ? (() => {handleInwardOutwardDetail('', 'addStock')}) : (() => {handleInwardOutwardDetail('', 'request')})}
                            >
                                {(role == 6 || role == 10) &&
                                    <>
                                        <ion-icon name="checkmark-outline" class="fs-4 me-0"></ion-icon>
                                        <span class="d-none d-sm-block">Add Stock</span>
                                    </>
                                }
                                {(role == 5) &&
                                    <>
                                        <ion-icon name="bag-check-outline" class="fs-6"></ion-icon>
                                        Request Stock
                                    </>
                                }
                            </button>
                        </div>
                    </div>
                    {(role == 6 || role == 10) &&
                        <div class={(activeTabName === 'production') ? "tab-pane fade show active" : "tab-pane fade"} id="production" role="tabpanel"> 
                            <div class="mb-2 d-flex justify-content-between row">
                                <div className='col-12'>
                                    <SearchWithButtons pageName={"Items"}></SearchWithButtons>
                                </div>
                            </div>
                            <div class="section d-flex flex-column">
                                <div class="row">
                                    {productionStockColumnsData.length > 0 &&
                                        <div class="col-md-8">
                                            {(productionStockColumnsData.length > 0) && 
                                                <div class="text-end">
                                                    <button class="btn btn-outline-secondary btn-sm mb-1 me-1" disabled><ion-icon name="cloud-download-outline" class="m-0 fs-8 me-1"></ion-icon>Export as CSV</button>
                                                    <button class="btn btn-outline-primary btn-sm mb-1" onClick={() => {handlePrintProductionStock(productionStockColumns, productionStockColumnsData)}}><ion-icon name="print-outline" class="m-0 fs-8 me-1"></ion-icon>Print</button>
                                                </div>
                                            }
                                            <div class="table-responsive w-100">
                                                <table class="table table-striped border">
                                                    <thead>
                                                        <tr style={{borderBottom: 'solid 2px'}}>
                                                            {productionStockColumns.map((v,k) => {
                                                                return <>
                                                                    {v.name !== 'Total' &&
                                                                        <th scope="col" class={(v.name == 'Item Name') ? "fs-6 fw-bold" : "text-end fs-6 fw-bold"}>{v.name}</th>
                                                                    }
                                                                </>
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {productionStockColumnsData.map((v,k) => {
                                                            return (<tr>
                                                                {productionStockColumns.map((v1,k1) => {
                                                                    return <>
                                                                        {v1.name !== 'Total' &&
                                                                            <td scope="row" class={(v1.name != 'Item Name') ? "pt-1 pb-1 align-middle text-end pe-1 ps-1" : "pt-1 pb-1 align-middle pe-1 ps-1"}>
                                                                                <>
                                                                                {/* {(v1.name != 'Item Name' && v1.name != 'Total') &&
                                                                                    <div class="form-group boxed p-0" style={{position: 'relative'}}>
                                                                                        <div class="input-wrapper">
                                                                                            <input type="number" class="form-control pe-2" value={v[v1.name]} onChange={(e) => {handleProductionItemUpdate(e.target.value, v, v1.name, k)}} placeholder="0" autocomplete="off" min={0} />
                                                                                            <i class="clear-input">
                                                                                                <ion-icon name="close-circle"></ion-icon>
                                                                                            </i>
                                                                                        </div>
                                                                                        {((typeof branchID == 'string' || (branchID.length == 1 || branchID.length == 0))) &&
                                                                                            <span class="badge bg-primary" style={{height: '18px', position: 'absolute', top: 0, right: 0, width: '28px', fontSize: '12px'}}>{v[v1.name + 'CurrentStock']}</span>
                                                                                        }
                                                                                    </div>
                                                                                } */}
                                                                                {/* {(v1.name == 'Item Name') && */}
                                                                                    <span class="fs-6 fw-bold">
                                                                                        {v[v1.name]}
                                                                                    </span>
                                                                                {/* } */}
                                                                                </>
                                                                            </td>
                                                                        }
                                                                    </>
                                                                })}
                                                            </tr>)
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* <DataTable
                                                columns={productionStockColumns}
                                                data={productionStockColumnsData}
                                                defaultSortFieldId={1}
                                                fixedHeader={true}
                                                fixedHeaderScrollHeight={'80vh'}
                                                customStyles={customStyles}
                                                print={true}
                                            /> */}
                                        </div>
                                    }
                                    <div class="col-md-4">
                                        <div class="section">
                                            {(stockRequestTimeline?.length > 0) &&
                                                <ul class="listview image-listview flush bg-transparent" style={{height: '65vh', overflow: 'scroll'}}>
                                                    {stockRequestTimeline?.map((v1,k) => {
                                                        return <li class="ms-2" onClick={() => {handleInwardOutwardDetail(v1)}}>
                                                        <a href="javascript:void(0)" class="item ps-1">
                                                            {v1.StockType === 'addStock' &&
                                                                <div class="icon-box bg-warning">
                                                                    <ion-icon name="bag-add-outline"></ion-icon>
                                                                </div>
                                                            }
                                                            {v1.StockType === 'removeStock' &&
                                                                <div class="icon-box bg-danger">
                                                                    <ion-icon name="bag-remove-outline"></ion-icon>
                                                                </div>
                                                            }
                                                            {v1.StockType === 'transferOut' &&
                                                                <div class="icon-box" style={{backgroundColor: '#fa758d'}}>
                                                                    <ion-icon name="swap-horizontal-outline"></ion-icon>
                                                                </div>
                                                            }
                                                            {v1.StockType === 'request' &&
                                                                <div class="icon-box bg-secondary">
                                                                    <ion-icon name="bag-check-outline"></ion-icon>
                                                                </div>
                                                            }
                                                            {v1.StockType === 'inTransit' &&
                                                                <div class="icon-box bg-success">
                                                                    <ion-icon name="cube-outline"></ion-icon>
                                                                </div>
                                                            }
                                                            {v1.StockType === 'modifyRequest' &&
                                                                <div class="icon-box bg-dark">
                                                                    <ion-icon name="checkmark-done-circle-outline"></ion-icon>
                                                                </div>
                                                            }
                                                            <div class="in">
                                                                <div>
                                                                    <header><Moment fromNow>{v1.DateTime}</Moment></header>
                                                                    {v1.StockType === 'addStock' &&
                                                                        <>
                                                                            {v1.LocationName} - Added
                                                                        </>
                                                                    }
                                                                    {v1.StockType === 'removeStock' &&
                                                                        <>
                                                                            {v1.LocationName} - Removed
                                                                        </>
                                                                    }
                                                                    {v1.StockType === 'transferOut' &&
                                                                        <>
                                                                            {v1.LocationName} - Transfered
                                                                        </>
                                                                    }
                                                                    {v1.StockType === 'request' &&
                                                                        <>
                                                                            {v1.LocationName} - Requested
                                                                        </>
                                                                    }
                                                                    {v1.StockType === 'inTransit' &&
                                                                        <>
                                                                            {v1.LocationName} - In Transit
                                                                        </>
                                                                    }
                                                                    {v1.StockType === 'modifyRequest' &&
                                                                        <>
                                                                            {v1.LocationName} - Modified Request
                                                                        </>
                                                                    }
                                                                    <footer class="mt-0">
                                                                        <div class="text">
                                                                            <span class="badge bg-secondary me-1" style={{height: '18px'}}>{v1.Items.length} Items</span>
                                                                            <span class="badge bg-secondary me-1" style={{height: '18px'}}>{v1.TotalQty} Qty</span>
                                                                        </div>
                                                                        
                                                                    </footer>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    })}
                                                </ul>
                                            }
                                            {(stockRequestTimeline?.length === 0) &&
                                                <h2 class="text-danger d-flex align-items-center justify-content-center mt-1 text-center">No Data Found</h2>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* <PrintProductionStock></PrintProductionStock> */}
                            </div>    
                        </div>
                    }
                </div>
            </div>
            <AddStockOptionOffcanvas></AddStockOptionOffcanvas>
        </div>
    )
}

const mapStateToProps = ({ data, itemProfile, auth, inwardOutward }) => {
    const {
        categories,
        items,
        categoryID,
        itemName,
        configObj,
        dayOrderFilter,
        businessData,
        branchID
    } = data
    const {
        itemProfileVisible
    } = itemProfile
    const {
        authToken,
        role,
        buisness_ID
    } = auth
    const {
        inwardOutwardDetailData
    } = inwardOutward
    return {
        categories,
        items,
        categoryID,
        itemName,
        itemProfileVisible,
        authToken,
        configObj,
        role,
        dayOrderFilter,
        businessData,
        buisness_ID,
        branchID,
        inwardOutwardDetailData
    }
  }
  
const mapDispatchToProps = {
    generalDataUpdate,
    showItemProfileOffcanvas,
    setItemProfileDetails,
    showInwardOutwardModal,
    showAddStockOptionOffcanvas,
    setNotificationBlink,
    setOrderDayFilter,
    setBranchID,
    setProductionStockList,
    showAddRemoveStockModal,
    updateEditStock,
    setStockActionType,
    setStockActionButton,
    itemsDataUpdate
}

export default connect(mapStateToProps, mapDispatchToProps)(Items)
