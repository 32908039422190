import React, { useState, useEffect } from 'react';

const OrderTypeSummaryTable = ({
    salesData
}) => {
    const [orderTypeTotal, setOrderTypeTotal] = useState(0)
    useEffect(() => {
        let typeTotal;
        typeTotal = (salesData?.Orders?.OrderType?.CounterSale) + (salesData?.Orders?.OrderType?.DineIn) + (salesData?.Orders?.OrderType?.Parcel) + (salesData?.Orders?.OrderType?.Online) + (salesData?.Orders?.OrderType?.CustomOrderAdvance) + (salesData?.Orders?.OrderType?.CustomOrderBalance)
        setOrderTypeTotal(typeTotal)
    }, [salesData])
    return (
        <div class="border p-0">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" class="border-end text-nowrap fw-bold">Type</th>
                            <th scope="col" class="border-end text-nowrap fw-bold text-end">₹{(orderTypeTotal) ? orderTypeTotal : 0}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="border-end text-nowrap fw-bold">Counter Sale</td>
                            <td class="border-end text-nowrap text-end">₹{salesData?.Orders?.OrderType?.CounterSale}</td>
                        </tr>
                        <tr>
                            <td class="border-end text-nowrap fw-bold">DineIn</td>
                            <td class="border-end text-nowrap text-end">₹{salesData?.Orders?.OrderType?.DineIn}</td>
                        </tr>
                        <tr>
                            <td class="border-end text-nowrap fw-bold">Pick Up</td>
                            <td class="border-end text-nowrap text-end">₹{salesData?.Orders?.OrderType?.Parcel}</td>
                        </tr>
                        <tr>
                            <td class="border-end text-nowrap fw-bold">Zomato / Swiggy</td>
                            <td class="border-end text-nowrap text-end">₹{salesData?.Orders?.OrderType?.Online}</td>
                        </tr>
                        <tr>
                            <td class="border-end text-nowrap fw-bold">Custom Order Advance</td>
                            <td class="border-end text-nowrap text-end">₹{salesData?.Orders?.OrderType?.CustomOrderAdvance}</td>
                        </tr>
                        <tr>
                            <td class="border-end text-nowrap fw-bold">Custom Order Balance / Customers Dues</td>
                            <td class="border-end text-nowrap text-end">₹{(salesData?.Orders?.OrderType?.CustomOrderBalance) ? salesData?.Orders?.OrderType?.CustomOrderBalance : 0 } <span class="text-secondary">/ ₹{(salesData?.Orders?.OrderType?.TotalCustomOrderBalance) ? salesData?.Orders?.OrderType?.TotalCustomOrderBalance : 0 }</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OrderTypeSummaryTable;