import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    addCategoryOffcanvasVisible: false
}

export const AddCategoryOffcanvasSlice = createSlice({
    name: 'addCategory',
    initialState,
    reducers: {
      showAddCategoryOffcanvas: (state, action) => {
        const {
            addCategoryOffcanvasVisible
        } = action.payload
        state.addCategoryOffcanvasVisible = addCategoryOffcanvasVisible
      },
      hideAddCategoryOffcanvas: (state) => {
        state.addCategoryOffcanvasVisible = false
      }
    }
})

export const { showAddCategoryOffcanvas, hideAddCategoryOffcanvas } = AddCategoryOffcanvasSlice.actions;


export default AddCategoryOffcanvasSlice.reducer