import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    addRemoveStockModalVisible: false
}

export const AddRemoveStockModalSlice = createSlice({
    name: 'addRemomveStock',
    initialState,
    reducers: {
      showAddRemoveStockModal: (state, action) => {
        const {
            addRemoveStockModalVisible
        } = action.payload
        state.addRemoveStockModalVisible = addRemoveStockModalVisible
      },
      hideAddRemoveStockModal: (state) => {
        state.addRemoveStockModalVisible = false
      }
    }
})

export const { showAddRemoveStockModal, hideAddRemoveStockModal } = AddRemoveStockModalSlice.actions;


export default AddRemoveStockModalSlice.reducer