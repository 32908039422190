import React from 'react';
import { connect } from "react-redux";
import { Modal } from 'react-bootstrap';
import { hidePrinterSettingModal } from '../../../features/Offcanvas/printerSettingModalSlice';
import BluetoothList from '../../Pages/Components/BluetoothList';

const PrinterSettingModal = ({
    hidePrinterSettingModal,
    printerSettingModalVisible
}) => {
    return (
        <div>
            <Modal show={printerSettingModalVisible} centered onHide={() => {hidePrinterSettingModal()}}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Printer</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{padding: '0px'}}>
                    <BluetoothList></BluetoothList>
                </Modal.Body>
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ printerSetting }) => {
    const {
        printerSettingModalVisible
    } = printerSetting
    return {
        printerSettingModalVisible
    }
  }
  
  const mapDispatchToProps = {
    hidePrinterSettingModal
  }

export default connect(mapStateToProps, mapDispatchToProps)(PrinterSettingModal);