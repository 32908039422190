import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import { categoryIdUpdate, setCategoryName } from '../../../features/Data/dataSlice'

const ItemCategoriesGrid = ({
    categories,
    categoryIdUpdate,
    setCategoryName,
    categoryName,
    viewType,
    alternateTextVisible,
    colorVisible
}) => {
    const [ID, setID] = useState(null)
    useEffect(() => {
        if (ID === null && (categoryName === '' || categoryName === undefined || categoryName === null)) {
            categoryIdUpdate({categoryID: 'all'})
            setID(null)
        }
    }, [categoryName])
    const handleCategorySwitch = (categoryID, categoryName) => {
        categoryIdUpdate({categoryID: (categoryID === undefined || categoryID === null || categoryID === '') ? 1 : categoryID})
        setID(categoryID)
        setCategoryName({categoryName: categoryName})
    }
    return (
        <>
            {(categories !== null && categories !== undefined) &&
                <div class="row border-end">
                    {categories.map(value => {
                        return <div class={(viewType === 'button') ? 'col-4 col-md-4' : 'col-4 col-md-6'}>
                            <span class={`btn btn-block mb-1 ${(ID === value.ID) ? 'border-secondary' : ''} btn-sm`} style={{height: '70px', background: (viewType === 'button') ? 'transparent' : '#e7e7e7', border: '2px solid', borderColor: (colorVisible) ? value.Color :'gainsboro', fontSize: '14px'}} onClick={() => {handleCategorySwitch(value.ID, value.Name)}}>
                                {(alternateTextVisible === 'shortcode' || alternateTextVisible === 'englishText') &&
                                    <>
                                        {value.Name && value.Name}
                                    </>
                                }
                                {(alternateTextVisible === 'alternateText') &&
                                    <>
                                        {value.AlternateText && value.AlternateText}
                                    </>
                                }
                            </span>
                        </div>
                    })}
                </div>
            }
        </>
    )
}

const mapStateToProps = ({ data, general }) => {
    const {
        categories,
        categoryName
    } = data
    const {
        alternateTextVisible,
        colorVisible
    } = general
    return {
        categories,
        categoryName,
        alternateTextVisible,
        colorVisible
    }
  }
  
const mapDispatchToProps = {
    categoryIdUpdate,
    setCategoryName
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemCategoriesGrid)