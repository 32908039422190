import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { setToken, setCurrentBusinessIdData } from './features/auth/authSlice'
import { setPairedDeviceArr, setBluetoothStatus } from './features/Data/dataSlice'
import Authentication from './components/Pages/Authentication'
import Layout from './components/Pages/Layout'
import Load from './components/Pages/Load'
import AppInfo from './components/Pages/AppInfo'
import jwt_decode from 'jwt-decode'
import OnBoard from './components/Pages/OnBoard'
import Header from './components/layout/Header/Header'
import Footer from './components/layout/Footer/Footer'
import NewOrder from './components/Pages/NewOrder'
import Items from './components/Pages/Items'
import Summary from './components/Pages/Summary'
import Orders from './components/Pages/Orders'
import OrdersDetail from './components/Pages/OrdersDetail'
import Transactions from './components/Pages/Transactions'
import UserProfile from './components/Pages/UserProfile'
import ItemProfile from './components/Pages/ItemProfile'
import MenuCard from './components/Pages/MenuCard'
import BusinessProfile from './components/Pages/BusinessProfile'
import UserCheckInOut from './components/Pages/UserCheckInOut'
import axios from './AxiosConfig'
import { showShopStatusModal } from './features/Offcanvas/shopStatusModalSlice'
import StoreConfig from './components/Pages/StoreConfig'
import ManageStock from './components/Pages/ManageStock'
import Bill from './components/Pages/Bill'
import CustomOrder from './components/Pages/CustomOrder'
import AddRemoveStock from './components/Pages/AddRemoveStock'
import SalesReport from './components/Pages/SalesReport'
import ManageItems from './components/Pages/ManageItems'
import Setup from './components/Pages/BusinessSetup'
import Face from './components/Pages/Components/Face'
import Face2 from './components/Pages/Components/Face2'
import StockReport from './components/Pages/OpeningClosingStockReport'
import CustomOrders from './components/Pages/CustomOrders'
import AdminDashboard from './components/Pages/AdminDashboard'
import CustomOrderEdit from './components/Pages/CustomOrderEdit'
import OpticCustomOrder from './components/Pages/OpticCustomOrder'
import OpticCustomOrderEdit from './components/Pages/OpticCustomOrderEdit'

const App = ({
  authToken,
  setToken,
  showShopStatusModal,
  role,
  businessData,
  business_ID,
  setCurrentBusinessIdData,
  setPairedDeviceArr,
  setBluetoothStatus,
  buildType
}) => {
  useEffect(() => {
    window.addEventListener('beforeunload', function(event) {
      // Your code here
      event.preventDefault();
      return 'Are you sure you want to leave this page?';
    });
  }, [])
  if ((localStorage.getItem('Orders') === null && localStorage.getItem('Purchase') === null && localStorage.getItem('Wastage') === null) || (localStorage.getItem('Orders').length <= 2 && localStorage.getItem('Purchase').length <= 2 && localStorage.getItem('Wastage').length <= 2)) {
    JSON.stringify(localStorage.setItem('Orders', '[]'))
    JSON.stringify(localStorage.setItem('Purchase', '[]'))
    JSON.stringify(localStorage.setItem('Wastage', '[]'))    
    JSON.stringify(localStorage.setItem('Carts', '[]'))    
  }
  // localStorage.clear()
  const validateToken = () => {
    if (authToken && role) {
      axios.get('/OpeningClosing/getShopStatus/' + new Date().toISOString().substr(0, 10)).then(res => {
        if (res.data.message === 'Success') {
            localStorage.setItem('shopStatus', res.data.data)
            if (res.data.data === 'Close') {
              showShopStatusModal({shopStatusModalVisible: true})
            }
        } else {
            // showAppToast({ toastMessage: 'Something went wrong', background: 'danger', icon: 'close', position: 'center', timeout: 1000 })
        }
      }).catch(err => {
          // showAppToast({ toastMessage: 'Something went wrong', background: 'danger', icon: 'close', position: 'center', timeout: 1000 })
      });
      return true
    } else {
      const token = localStorage.getItem('token')
      const tokenData = (token) ? jwt_decode(token) : false
      if (tokenData && new Date(tokenData.exp * 1000) > new Date()) {
        if (tokenData.userInfo.hasOwnProperty('ID') && tokenData.userInfo.hasOwnProperty('RoleID')) {
          setToken({ authToken: token, role: tokenData.userInfo.RoleID })
          const businessID = tokenData.userInfo.Business_ID
          console.log(businessID)
          if (businessID == 9) {
            setCurrentBusinessIdData({currentBusinessIdData: {
              'sidebarMenu': {
                'summary': {
                  'path':'/summary',
                  'visible': true
                },
                'customOrder': {
                  'path':'/opticCustomOrder',
                  'visible': true
                }
              },
              'draftOrder': false,
              'dinomination': false,
              'defaultPage': '/summary',
              'categoryFilter': 'filter',
              'customOrderListDetail': true,
              'customOpticFooter': true,
              'defaultPage': '/customOrders'
            }})
          } else if (businessID == 7) {
            setCurrentBusinessIdData({currentBusinessIdData: {
              'sidebarMenu': {
                'adminDashboard': {
                  'path':'/adminDashboard',
                  'visible': true
                },
                'summary': {
                  'path':'/summary',
                  'visible': true
                },
                'items': {
                  'path':'/items',
                  'visible': true
                },
                'orders': {
                  'path':'/orders',
                  'visible': true
                },
                'transactions': {
                  'path':'/transactions',
                  'visible': true
                },
                'newOrder': {
                  'path':'/newOrder',
                  'visible': true
                },
                'customOrder': {
                  'path':'/customOrder',
                  'visible': true
                },
                'manageItems': {
                  'path':'/manageItems',
                  'visible': true
                },
                'reports': {
                  'path':'/reports',
                  'visible': true
                }
              },
              'draftOrder': true,
              'dinomination': true,
              'defaultPage': '/summary',
              'categoryFilter': 'scroll',
              'customOrderListDetail': false
            } })
          } else {
            setCurrentBusinessIdData({currentBusinessIdData: {
              'sidebarMenu': {
                'adminDashboard': {
                  'path':'/adminDashboard',
                  'visible': true
                },
                'summary': {
                  'path':'/summary',
                  'visible': true
                },
                'items': {
                  'path':'/items',
                  'visible': true
                },
                'orders': {
                  'path':'/orders',
                  'visible': true
                },
                'transactions': {
                  'path':'/transactions',
                  'visible': true
                },
                'newOrder': {
                  'path':'/newOrder',
                  'visible': true
                },
                'customOrder': {
                  'path':'/customOrder',
                  'visible': true
                },
                'manageItems': {
                  'path':'/manageItems',
                  'visible': true
                },
                'reports': {
                  'path':'/reports',
                  'visible': true
                }
              },
              'draftOrder': true,
              'dinomination': true,
              'defaultPage': '/summary',
              'categoryFilter': 'filter',
              'customOrderListDetail': false
            } })
          }
          return true
        } else {
          localStorage.clear()
          return false
        }
      } else {
        localStorage.clear()
        return false
      }
    }
  }
  const userLoggedIn = validateToken()
  const root = document.documentElement;
  root.style.setProperty('--primary-color', (businessData && businessData[0].ThemeColor) ? businessData[0].ThemeColor : '#24265d');

  console.log(userLoggedIn, buildType)
  if (userLoggedIn && buildType == 'apk') {
    setInterval(() => {
      window.BTPrinter.list(function(data){
          console.log("LIST Success", data);
          const tempDeviceArr = convertArrayToObjects(data)
          setPairedDeviceArr({pairedDeviceArr: tempDeviceArr})
          console.log(tempDeviceArr)
      },function(err){
          console.log("Error");
          console.log(err);
      });
    
      window.BTPrinter.status(function(data){
        console.log("BLUETOOTH STATUS", data);
        setBluetoothStatus({bluetoothStatus: data}) // bt status: true or false
      },function(err){
        console.log("Error");
        console.log(err)
      });
    }, 30000);
  }

  const convertArrayToObjects = (data) => {
    const devices = [];
    
    for (let i = 0; i < data.length; i += 3) {
        const device = {
          name: data[i],
          macAddress: data[i + 1],
          type: data[i + 2]
        };
        
        devices.push(device);
      }
      
      return devices;
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        {!userLoggedIn &&
          <Fragment>
            <Route path="*" element={<Auth />} />
            <Route path="onBoarding" element={<OnBoardView />} />
            <Route path="menuCard/:restID" element={<MenuCardView />} />
            <Route path="bill" element={<BillView />} />
            <Route path="userLogin" element={<UserLoginView />} />
            <Route path="userLogin1" element={<UserLogin1View />} />
          </Fragment>
        }
        {userLoggedIn &&
          <Fragment>
            <Route path="/" element={<SelectWebsiteList />} />
            <Route path="onBoarding" element={<OnBoardView />} />
            <Route path="appinfo" element={<AboutApp />} />
            <Route path="load" element={<SelectWebsiteList />} />
            <Route path="newOrder" element={<NewOrderView />} />
            <Route path="items" element={<ItemsView />} />
            <Route path="summary" element={<SummaryView />} />
            <Route path="orders" element={<OrdersView />} />
            <Route path="ordersDetail" element={<OrdersDetailView />} />
            <Route path="transactions" element={<TransactionsView />} />
            <Route path="userProfile" element={<UserProfileView />} />
            <Route path="itemProfile" element={<ItemProfileView />} />
            <Route path="businessProfile" element={<BusinessProfileView />} />
            <Route path="menuCard/:restID" element={<MenuCardView />} />
            <Route path="bill" element={<BillView />} />
            <Route path="userLogin" element={<UserLoginView />} />
            <Route path="userLogin1" element={<UserLogin1View />} />
            <Route path="storeConfig" element={<StoreConfigView />} />
            <Route path="manageStock" element={<ManageStockView />} />
            <Route path="customOrder" element={<CustomOrderView />} />
            <Route path="addRemoveStock" element={<AddRemoveStockView />} />
            <Route path="salesReport" element={<SalesReportView />} />
            <Route path="manageItems" element={<ManageItemsView />} />
            <Route path="setup" element={<SetupView />} />
            <Route path="stockReport" element={<StockReportView />} />
            <Route path="customOrders" element={<CustomOrdersView />} />
            <Route path="editCustomOrder" element={<EditCustomOrdersView />} />
            <Route path="adminDashboard" element={<AdminDashboardView />} />
            <Route path="opticCustomOrder" element={<OpticCustomOrderView />} />
            <Route path="editOpticCustomOrder" element={<OpticCustomOrderEditView />} />
            <Route path="*" element={<Auth />} />
          </Fragment>
        }
        </Routes>
      </BrowserRouter>
    </div>
  );
}

function Auth() {
  return (
    <div className="App pt-5">
      <Authentication></Authentication>
    </div>
  )
}

function SelectWebsiteList() {
  return (
    <div className="App">
      <Load></Load>
    </div>
  )
}

function AboutApp() {
  return (
    <div className="App">
      {/* <Layout> */}
      <Header></Header>
        <AppInfo></AppInfo>
      <Footer></Footer>
      {/* </Layout> */}
    </div>
  )
}

function OnBoardView() {
  return (
    <div className="App">
    {/* <Layout> */}
      <OnBoard></OnBoard>
    {/* </Layout> */}
    </div>
  )
}

function NewOrderView() {
  return (
    <div className="App">
    <Layout>
      <NewOrder></NewOrder>
    </Layout>
    </div>
  )
}

function ItemsView() {
  return (
    <div className="App">
    <Layout>
      <Items></Items>
    </Layout>
    </div>
  )
}

function SummaryView() {
  return (
    <div className="App">
    <Layout>
      <Summary></Summary>
    </Layout>
    </div>
  )
}

function OrdersView() {
  return (
    <div className="App">
    <Layout>
      <Orders></Orders>
    </Layout>
    </div>
  )
}

function OrdersDetailView() {
  return (
    <div className="App">
    <Layout>
      <OrdersDetail></OrdersDetail>
    </Layout>
    </div>
  )
}

function TransactionsView() {
  return (
    <div className="App">
    <Layout>
      <Transactions></Transactions>
    </Layout>
    </div>
  )
}

function UserProfileView() {
  return (
    <div className="App">
    <Layout>
      <UserProfile></UserProfile>
    </Layout>
    </div>
  )
}

function ItemProfileView() {
  return (
    <div className="App">
    <Layout>
      <ItemProfile></ItemProfile>
    </Layout>
    </div>
  )
}

function BusinessProfileView() {
  return (
    <div className="App">
    <Layout>
      <BusinessProfile></BusinessProfile>
    </Layout>
    </div>
  )
}

function MenuCardView() {
  return (
    <div className="App">
    {/* <Layout> */}
      <MenuCard></MenuCard>
    {/* </Layout> */}
    </div>
  )
}

function UserLoginView() {
  return (
    <div className="App">
    {/* <Layout> */}
      <UserCheckInOut></UserCheckInOut>
    {/* </Layout> */}
    </div>
  )
}

function UserLogin1View() {
  return (
    <div className="App">
    {/* <Layout> */}
      {/* <Face></Face> */}
      <Face2></Face2>
    {/* </Layout> */}
    </div>
  )
}

function StoreConfigView() {
  return (
    <div className="App">
      <Layout>
        <StoreConfig></StoreConfig>
      </Layout>
    </div>
  )
}

function ManageStockView() {
  return (
    <div className="App">
      <Layout>
        <ManageStock></ManageStock>
      </Layout>
    </div>
  )
}

function CustomOrderView() {
  return (
    <div className="App">
      <Layout>
        <CustomOrder></CustomOrder>
      </Layout>
    </div>
  )
}

function AddRemoveStockView() {
  return (
    <div className="App">
      <Layout>
        <AddRemoveStock></AddRemoveStock>
      </Layout>
    </div>
  )
}

function SalesReportView() {
  return (
    <div className="App">
      <Layout>
        <SalesReport></SalesReport>
      </Layout>
    </div>
  )
}

function ManageItemsView() {
  return (
    <div className="App">
      <Layout>
        <ManageItems></ManageItems>
      </Layout>
    </div>
  )
}

function SetupView() {
  return (
    <div className="App">
      <Layout>
        <Setup></Setup>
      </Layout>
    </div>
  )
}

function BillView() {
  return (
    <div className="App">
    {/* <Layout> */}
      <Bill></Bill>
    {/* </Layout> */}
    </div>
  )
}

function StockReportView() {
  return (
    <div className="App">
      <Layout>
        <StockReport></StockReport>
      </Layout>
    </div>
  )
}

function CustomOrdersView() {
  return (
    <div className="App">
      <Layout>
        <CustomOrders></CustomOrders>
      </Layout>
    </div>
  )
}

function EditCustomOrdersView() {
  return (
    <div className="App">
      <Layout>
        <CustomOrderEdit></CustomOrderEdit>
      </Layout>
    </div>
  )
}

function AdminDashboardView() {
  return (
    <div className="App">
      <Layout>
        <AdminDashboard></AdminDashboard>
      </Layout>
    </div>
  )
}

function OpticCustomOrderView() {
  return (
    <div className="App">
      <Layout>
        <OpticCustomOrder></OpticCustomOrder>
      </Layout>
    </div>
  )
}

function OpticCustomOrderEditView() {
  return (
    <div className="App">
      <Layout>
        <OpticCustomOrderEdit></OpticCustomOrderEdit>
      </Layout>
    </div>
  )
}

const mapStateToProps = ({ auth, data }) => {
  const {
    authToken,
    role,
    business_ID,
    buildType
  } = auth
  const {
    businessData
  } = data
  return {
    authToken,
    role,
    businessData,
    business_ID,
    buildType
  }
}

const mapDispatchToProps = {
  setToken,
  showShopStatusModal,
  setCurrentBusinessIdData,
  setPairedDeviceArr,
  setBluetoothStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(App)