import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    messageModalVisible: false,
    message: null
}

export const MessageModalSlice = createSlice({
    name: 'messageModal',
    initialState,
    reducers: {
      showMessageModal: (state, action) => {
        const {
            messageModalVisible,
            message
        } = action.payload
        state.messageModalVisible = messageModalVisible
        state.message = message
      },
      hideMessageModal: (state) => {
        state.messageModalVisible = false
      }
    }
})

export const { showMessageModal, hideMessageModal } = MessageModalSlice.actions;


export default MessageModalSlice.reducer