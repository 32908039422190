import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { useLocation } from 'react-router'
import axios from '../../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import { hideShopDetailModal } from '../../../features/Offcanvas/ShopDetailModalSlice'
import CategorySummaryTable from '../../Pages/BitComponents/CategorySummaryTable'
import OrderTypeSummaryTable from '../../Pages/BitComponents/OrderTypeSummaryTable'
import PaymentMethodSummaryTable from '../../Pages/BitComponents/PaymentMethodSummaryTable'

const ShopDetailModal = ({
    shopDetailModalVisible,
    hideShopDetailModal,
    salesData,
    businessData,
    authToken,
    shopDetailData
}) => {
    console.log(shopDetailData)
    const [sizeWiseData, setSizeWiseData] = useState(null)
    const [branchName, setBranchName] = useState('')
    const stateName = useLocation().pathname
    const GetOrderdate = () => {
        const currentDate = new Date()
        const hour = currentDate.getHours();
        if (hour < 3) {
            const previousDay = new Date(currentDate.setDate(currentDate.getDate()));
            return previousDay.toISOString().split('T')[0];
        } else {
            return currentDate.toISOString().split('T')[0];
        }
    }
    useEffect(() => {
        if (shopDetailData?.branchID) {
            axios.get('/Reports/viewDailyCounters/' + GetOrderdate() + '/' + GetOrderdate() + '/' + shopDetailData?.branchID).then(res => {
                if (res.data.message === 'Success') {
                    setSizeWiseData(res.data.data)
                }
            })
        }
    }, [shopDetailData])
    const shopStatus = localStorage.getItem('shopStatus')
    useEffect(() => {
        const tokenData = jwt_decode(authToken)
        if (businessData) {
            businessData.map((value, key) => {
                value.Branches.map((v,k) => {
                    if (value.ID === tokenData.userInfo.Business_ID && v.ID === tokenData.userInfo.DefaultLocationID) {
                        setBranchName(v.Name)
                    }
                })
            })   
        }
    }, [authToken, businessData])
    return (
        <div>
            <Modal show={shopDetailModalVisible} size={'xl'} centered onHide={() => {hideShopDetailModal()}}>
                <Modal.Header closeButton>
                    <Modal.Title style={{lineHeight: 0.3}}>
                        <h3 class="mb-0">{shopDetailData?.branchName}</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div class="section full mb-2">
                        <div class="row">
                            {stateName.includes('summary') &&
                                <div class="col-12 col-md-12 mb-2">
                                    <div class="border p-0">
                                        <div class="table-responsive">
                                            <table class="table table-striped">
                                                <tbody>
                                                    <tr>
                                                        <th scope="col" class="border-end text-nowrap fw-bold">MTD</th>
                                                        <th scope="col" class="border-end text-nowrap">YTD</th>
                                                    </tr>
                                                    <tr>
                                                        <td class="border-end text-nowrap fw-bold">
                                                            ₹0
                                                        </td>
                                                        <td class="border-end text-nowrap">
                                                            ₹0
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            }
                            {!stateName.includes('summary') &&
                                <>
                                    <div class="col-12 col-md-4 mb-2">
                                        <div class="card">
                                            <div class="card-body p-0">
                                                <CategorySummaryTable sizeWiseData={sizeWiseData}></CategorySummaryTable>
                                            </div>
                                        </div>
                                        {/* <div class="listview-title mt-0 ps-0">Category</div>
                                        <CategorySummaryTable sizeWiseData={sizeWiseData}></CategorySummaryTable> */}
                                    </div>
                                    <div class="col-12 col-md-4 mb-2">
                                        <div class="card">
                                            <div class="card-body p-0">
                                                <OrderTypeSummaryTable salesData={shopDetailData?.salesData}></OrderTypeSummaryTable>
                                            </div>
                                        </div>
                                        {/* <div class="listview-title mt-0 ps-0">Order Type</div>
                                        <OrderTypeSummaryTable salesData={salesData}></OrderTypeSummaryTable> */}
                                    </div>
                                    <div class="col-12 col-md-4 mb-2">
                                        <div class="card">
                                            <div class="card-body p-0">
                                                <PaymentMethodSummaryTable paymentData={shopDetailData?.salesData}></PaymentMethodSummaryTable>
                                            </div>
                                        </div>
                                        {/* <div class="listview-title mt-0 ps-0">Payment Method</div>
                                        <PaymentMethodSummaryTable paymentData={salesData}></PaymentMethodSummaryTable> */}
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ shopDetail, data, auth }) => {
    const {
        shopDetailModalVisible,
        salesData,
        shopDetailData
    } = shopDetail
    const {
        businessData
    } = data
    const {
        authToken
    } = auth
    return {
        shopDetailModalVisible,
        salesData,
        businessData,
        authToken,
        shopDetailData
    }
  }
  
  const mapDispatchToProps = {
    hideShopDetailModal
  }

export default connect(mapStateToProps, mapDispatchToProps)(ShopDetailModal)