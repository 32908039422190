import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { hideImagePdfModal } from '../../../features/Offcanvas/imagePdfModalSlice'
import { setOpticPrintBill } from '../../../features/Offcanvas/customOrderDetailModalSlice'

const ImagePdfModal = ({
    imagePdfModalVisible,
    hideImagePdfModal,
    customOrderDetailData,
    setOpticPrintBill
}) => {
    const handlePrintBillPdf = () => {
        setOpticPrintBill({opticPrintBill: true, opticPrintBillData: customOrderDetailData, printType: 'pdf'})
        hideImagePdfModal()
    }
    const handlePrintBillImage = () => {
        setOpticPrintBill({opticPrintBill: true, opticPrintBillData: customOrderDetailData, printType: 'image'})
        hideImagePdfModal()
    }
    const handlePrintBill = () => {
        setOpticPrintBill({opticPrintBill: true, opticPrintBillData: customOrderDetailData})
        hideImagePdfModal()
    }
    return (
        <div>
            <Modal show={imagePdfModalVisible} size={'sm'} centered={true} onHide={() => {hideImagePdfModal()}}>
                <Modal.Body class="pt-0 p-1">
                    <div class="modal-body p-0 mt-1 d-flex justify-content-center">
                        <div class="d-flex justify-content-center align-items-center flex-column me-3 cursor-pointer">
                            <div class="btn btn-outline-secondary btn-sm rounded" style={{height: '70px', width: '70px'}} onClick={() => {handlePrintBillImage()}}>
                                <ion-icon name="image-outline" class="fs-1 me-0"></ion-icon>
                            </div>
                            <h3 class="mt-1 mb-0 fw-normal text-center">Image</h3>
                        </div>
                        <div class="d-flex justify-content-center align-items-center flex-column cursor-pointer">
                            <div class="btn btn-outline-secondary btn-sm rounded" style={{height: '70px', width: '70px'}} onClick={() => {handlePrintBillPdf()}}>
                                <ion-icon name="document-attach-outline" class="fs-1 me-0"></ion-icon>
                            </div>
                            <h3 class="mt-1 mb-0 fw-normal text-center">PDF</h3>
                        </div>
                        {/* <div class="d-flex justify-content-center align-items-center flex-column cursor-pointer">
                            <div class="btn btn-outline-secondary btn-sm rounded" style={{height: '70px', width: '70px'}} onClick={() => {handlePrintBill()}}>
                                <ion-icon name="print-outline" class="fs-1 me-0"></ion-icon>
                            </div>
                            <h3 class="mt-1 mb-0 fw-normal text-center">Print</h3>
                        </div> */}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ imagePdf, customOrderDetail }) => {
    const {
        imagePdfModalVisible
    } = imagePdf
    const {
        customOrderDetailData
    } = customOrderDetail
    return {
        imagePdfModalVisible,
        customOrderDetailData
    }
  }
  
  const mapDispatchToProps = {
    hideImagePdfModal,
    setOpticPrintBill
  }

export default connect(mapStateToProps, mapDispatchToProps)(ImagePdfModal)