import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js'; // Import hls.js for handling streams
import * as faceapi from 'face-api.js'; // Import face-api.js for face recognition

const FaceRecognition = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const videoWidth = 640; // Set your video width
  const videoHeight = 360; // Set your video height

  // Load face-api.js models
  useEffect(() => {
    const loadModels = async () => {
      const MODEL_URL = process.env.PUBLIC_URL + '/models'; // Update this path to your models
      await Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
        faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
        faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
        faceapi.nets.ageGenderNet.loadFromUri(MODEL_URL),
      ]);
      setModelsLoaded(true);
    };
    loadModels();
  }, []);

  // HLS stream setup and playing
  useEffect(() => {
    const video = videoRef.current;
    
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource('http://localhost:2000/output/stream.m3u8'); // HLS stream URL
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play();
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = 'http://localhost:2000/output/stream.m3u8'; // Fallback for Safari and iOS
      video.addEventListener('loadedmetadata', () => {
        video.play();
      });
    }

    // Cleanup function
    return () => {
      if (video) {
        video.pause();
        video.src = '';
      }
    };
  }, []);

  // Handle video play event for face detection
  const handleVideoOnPlay = async () => {
    if (canvasRef.current && modelsLoaded) {
      canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(videoRef.current);
      const displaySize = { width: videoWidth, height: videoHeight };
      faceapi.matchDimensions(canvasRef.current, displaySize);
      
      setInterval(async () => {
        const detections = await faceapi.detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions())
          .withFaceLandmarks()
          .withFaceExpressions()
          .withAgeAndGender();

        const resizedDetections = faceapi.resizeResults(detections, displaySize);
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, videoWidth, videoHeight);

        // Draw detections and landmarks
        resizedDetections.forEach(detection => {
          const { landmarks } = detection;

          // Draw landmarks as dots
          landmarks._positions.forEach(point => {
            context.fillStyle = 'red'; // Color for the dots
            context.beginPath();
            context.arc(point.x, point.y, 3, 0, 2 * Math.PI); // Draw a circle (dot)
            context.fill();
          });

          // Draw bounding box and label
          const box = detection.detection.box;
          const drawBox = new faceapi.draw.DrawBox(box, {
            label: Math.round(detection.age) + " year old " + detection.gender,
            boxColor: 'blue',
            labelBackgroundColor: 'rgba(0, 0, 255, 0.5)'
          });
          drawBox.draw(canvas);

          // Draw other visual elements (expressions, etc.)
          faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
          faceapi.draw.drawFaceExpressions(canvas, resizedDetections);
        });
      }, 100); // Adjust the interval as necessary
    }
  };

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <video ref={videoRef} onPlay={handleVideoOnPlay} width={videoWidth} height={videoHeight} controls style={{ borderRadius: '10px' }} />
        <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0 }} />
      </div>
      {!modelsLoaded && <div>Loading models...</div>}
    </div>
  );
};

export default FaceRecognition;
