import { configureStore } from '@reduxjs/toolkit'
import appToastReducer from '../features/utilities/appToastSlice'
import authReducer from '../features/auth/authSlice'
import checkoutReducer from '../features/Offcanvas/checkoutOffcanvasSlice'
import ItemSizeReducer from '../features/Offcanvas/itemSizeOffcanvasSlice'
import PaymentReducer from '../features/Offcanvas/paymentOffcanvasSlice'
import QuantityReducer from '../features/Offcanvas/quantityOffcanvasSlice'
import GeneralReducer from '../features/utilities/generalSlice'
import DataReducer from '../features/Data/dataSlice'
import OrderDetailReducer from '../features/Offcanvas/orderDetailModalSlice'
import AddCategoryReducer from '../features/Offcanvas/addCategoryOffcanvasSlice'
import CheckoutItemsReducer from '../features/state/checkoutItemsSlice'
import ToastReducer from '../features/Offcanvas/toastSlice'
import TransactionDetailReducer from '../features/Offcanvas/transactionDetailModalSlice'
import AddTransactionReducer from '../features/Offcanvas/addTransactionModalSlice'
import AddUserModalSlice from '../features/Offcanvas/addUserSlice'
import CofirmationModalSlice from '../features/Offcanvas/confirmationModalSlice'
import CategoryModalSlice from '../features/Offcanvas/categoryFullModalSlice'
import quickAddOffcanvasSlice from '../features/Offcanvas/quickAddOffcanvasSlice'
import mainActionSheetSlice from '../features/Offcanvas/mainActionSheetSlice'
import messageModalSlice from '../features/Offcanvas/messageModalSlice'
import dineInOffcanvasSlice from '../features/Offcanvas/dineInTableOffcanvasSlice'
import switchBusinessModalReducer from '../features/Offcanvas/switchBusinessModalSlice'
import userListOffcanvasSlice from '../features/Offcanvas/userListOffcanvasSlice'
import ongoingOffcanvasSlice from '../features/Offcanvas/ongoingOffcanvasSlice'
import itemProfileOffcanvasSlice from '../features/Offcanvas/itemProfileOffcanvasSlice'
import mainMenuOffcanvasSlice from '../features/Offcanvas/mainMenuOffcanvasSlice'
import orderTypeModalSlice from '../features/Offcanvas/orderTypeModalSlice'
import imageEditModalSlice from '../features/Offcanvas/imageEditModalSlice'
import draftCartListModalSlice from '../features/Offcanvas/draftCartListModalSlice'
import discountModalSlice from '../features/Offcanvas/discountModalSlice'
import shortcutSearchModalSlice from '../features/Offcanvas/shortcutSearchModalSlice'
import shopStatusModalSlice from '../features/Offcanvas/shopStatusModalSlice'
import stockSlice from '../features/Data/stockSlice'
import inwardOutwardModalSlice from '../features/Offcanvas/inwardOutwardModalSlice'
import addStockOptionOffcanvasSlice from '../features/Offcanvas/addStockOptionOffcanvasSlice'
import notficationTopOffcanvasSlice from '../features/Offcanvas/notficationTopOffcanvasSlice'
import addSetupModalSlice from '../features/Offcanvas/addSetupModalSlice'
import CustomOrderPaymentModalReducer from '../features/Offcanvas/customOrderPaymentModalSlice'
import ShopDetailModalReducer from '../features/Offcanvas/ShopDetailModalSlice'
import SnapShotReducer from '../features/Offcanvas/SnapShotSlice'
import AddRemoveStockModalReducer from '../features/Offcanvas/addremoveStockModalSlice'
import CustomOrderDetailModalReducer from '../features/Offcanvas/customOrderDetailModalSlice'
import ImagePdfModalReducer from '../features/Offcanvas/imagePdfModalSlice'
import PrinterSettingModalReducer from '../features/Offcanvas/printerSettingModalSlice'

export const store = configureStore({
  reducer: {
    appToast: appToastReducer,
    auth: authReducer,
    checkout: checkoutReducer,
    itemSize: ItemSizeReducer,
    payment: PaymentReducer,
    quantity: QuantityReducer,
    general: GeneralReducer,
    data: DataReducer,
    orderDetail: OrderDetailReducer,
    addCategory: AddCategoryReducer,
    checkoutItems: CheckoutItemsReducer,
    toast: ToastReducer,
    transactionDetail: TransactionDetailReducer,
    addTransaction: AddTransactionReducer,
    addUser: AddUserModalSlice,
    confirmation: CofirmationModalSlice,
    category: CategoryModalSlice,
    quickAdd: quickAddOffcanvasSlice,
    mainActionSheet: mainActionSheetSlice,
    messageModal: messageModalSlice,
    dineIn: dineInOffcanvasSlice,
    switchBusiness: switchBusinessModalReducer,
    userList: userListOffcanvasSlice,
    ongoingOffcanvas: ongoingOffcanvasSlice,
    itemProfile: itemProfileOffcanvasSlice,
    mainMenu: mainMenuOffcanvasSlice,
    orderType: orderTypeModalSlice,
    imageEdit: imageEditModalSlice,
    draftCartList: draftCartListModalSlice,
    discount: discountModalSlice,
    shortcut: shortcutSearchModalSlice,
    shopStatus: shopStatusModalSlice,
    stock: stockSlice,
    inwardOutward: inwardOutwardModalSlice,
    addStockOption: addStockOptionOffcanvasSlice,
    notificationTop: notficationTopOffcanvasSlice,
    addSetup: addSetupModalSlice,
    customOrder: CustomOrderPaymentModalReducer,
    shopDetail: ShopDetailModalReducer,
    snapShot: SnapShotReducer,
    addRemoveStock: AddRemoveStockModalReducer,
    customOrderDetail: CustomOrderDetailModalReducer,
    imagePdf: ImagePdfModalReducer,
    printerSetting: PrinterSettingModalReducer
  },
});