import React, { useEffect } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from "react-redux"
import { hideConfirmActionOffcanvas } from '../../../features/Offcanvas/confirmationModalSlice'
import { showPaymentOffcanvas } from '../../../features/Offcanvas/paymentOffcanvasSlice'
import { setKotData, setPaymentType, setInvoiceID } from '../../../features/Data/dataSlice'

const ConfirmationOffcanvas = ({
    confirmActionVisible,
    hideConfirmActionOffcanvas,
    setKotData,
    offlineKotData,
    showPaymentOffcanvas,
    setPaymentType,
    setInvoiceID,
    orderIndex
}) => {
    const handlePrintKot = () => {
        setKotData({kotData: offlineKotData['Orders'][orderIndex], printFrom: 'NewOrder'})
        hideConfirmActionOffcanvas()
    }
    const handleCollectPayment = () => {
        setPaymentType({balanceAmount: offlineKotData?.BalanceAmount})
        setInvoiceID({invoiceID: offlineKotData?.OfflineInvoiceNumber})
        showPaymentOffcanvas({paymentOffcanvasVisible: true})
        hideConfirmActionOffcanvas()
    }
    useEffect(() => {
        if (confirmActionVisible === true || confirmActionVisible === 'true') {
            setTimeout(() => {
                hideConfirmActionOffcanvas()
            }, 10000);
        }
    }, [confirmActionVisible])
    return (
        <div>
            <Offcanvas show={confirmActionVisible} placement={'top'} style={{backgroundColor: 'transparent'}}>
                <Offcanvas.Body class="">
                    <div class="notification-box" style={{top: 0}}>
                        <div class="notification-dialog ios-style">
                            <div class="notification-header bg-white text-dark">
                                <div class="in">
                                    {/* <img src="assets/img/sample/avatar/avatar3.jpg" alt="image" class="imaged w24 rounded" /> */}
                                    <strong class="text-dark">Please Confirm</strong>
                                </div>
                                <div class="right">
                                    {/* <span class="text-dark">just now</span> */}
                                    <a href="javascript:void(0)" class="close-button text-dark" onClick={() => {hideConfirmActionOffcanvas()}}>
                                        <ion-icon name="close-circle"></ion-icon>
                                    </a>
                                </div>
                            </div>
                            <div class="notification-content bg-white">
                                <div class="in">
                                    {/* <h3 class="subtitle text-dark">Hello There</h3> */}
                                    <div class="text text-dark">
                                        Confirm Your Action to Proceed
                                    </div>
                                </div>
                            </div>
                            <div class="notification-footer" style={{borderTop: 'none'}}>
                                <div class="notification-button bg-secondary" onClick={handlePrintKot}>
                                    <ion-icon name="print-outline"></ion-icon>
                                    Print KOT
                                </div>
                                <div class="notification-button bg-success" onClick={handleCollectPayment}>
                                    <ion-icon name="cash-outline"></ion-icon>
                                    Collect Payment
                                </div>
                                {/* <div class="notification-button bg-secondary" onClick={() => {hideConfirmActionOffcanvas()}}>
                                    <ion-icon name="close-circle-outline"></ion-icon>
                                    Close
                                </div> */}
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

const mapStateToProps = ({ confirmation, data }) => {
    const {
        confirmActionVisible
    } = confirmation
    const {
        offlineKotData,
        orderIndex
    } = data
    return {
        confirmActionVisible,
        offlineKotData,
        orderIndex
    }
}
  
const mapDispatchToProps = {
    hideConfirmActionOffcanvas,
    setKotData,
    showPaymentOffcanvas,
    setPaymentType,
    setInvoiceID
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationOffcanvas)