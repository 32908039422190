import React from 'react'
import ErrorPage from '../images/error.png'
import { useLocation } from 'react-router-dom'
import Paid from '../images/icon/paidWithBg.png'
import Logo from '../images/logo.svg'

const Bill = () => {
    const search = useLocation().search
    const billNo = new URLSearchParams(search).get('billNo')
    return (
        <div id="appCapsule" class="p-0 pb-2 d-flex justify-content-center">
            <div class="responsive-bill" style={{display: (billNo) ? 'block' : 'none'}}>
                <div class="section full mt-3">
                    <div class="invoice">
                        <div class="invoiceBackgroundLogo">
                            <img src={Logo} />
                        </div>
                        <div class="invoice-page-header">
                            <div class="invoice-logo">
                                <img src={Logo} alt="logo" />
                            </div>
                            <div class="invoice-id">#INVOICE34039</div>
                        </div>
                        <div class="invoice-person mt-4">
                            <div class="invoice-to">
                                <h4>John Doe</h4>
                                <p>johndoe@domain.com</p>
                                <p>Liberty Street, N39823<br /> New York City</p>
                            </div>
                            <div class="invoice-from">
                                <h4>Mobilekit Inc.</h4>
                                <p>mobilekit@domain.com</p>
                                <p>Grand Canyon Street, G0392212<br />Arizona</p>
                            </div>
                        </div>
                        <div class="invoice-detail mt-4">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <td>DESCRIPTION</td>
                                            <td>RATE</td>
                                            <td>QTY</td>
                                            <td>SUBTOTAL</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Design Service</td>
                                            <td>$320.00</td>
                                            <td>1</td>
                                            <td>$320.00</td>
                                        </tr>
                                        <tr>
                                            <td>Marketing Service</td>
                                            <td>$240.00</td>
                                            <td>2</td>
                                            <td>$480.00</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="invoice-signature mt-4">
                            <div class="signature-block">
                                Signature Here
                            </div>
                        </div>

                        <div class="invoice-total mt-4">
                            <ul class="listview transparent simple-listview">
                                <li>Subtotal <span class="hightext">$800.00</span></li>
                                <li>Tax (10%)<span class="hightext">$80.00</span></li>
                                <li>Total<span class="totaltext text-primary">$880.00</span></li>
                            </ul>
                        </div>

                        <div class="invoice-bottom">
                            <img src={Paid} width="80px" style={{marginBottom: '-5px', transform: 'rotate(20deg)'}} />
                            <h4 style={{zIndex: '1', position: 'sticky'}}>Visit Again</h4>
                            <h4 style={{zIndex: '1', marginTop: '-12px', position: 'sticky'}}>Powered By <a href="https://allpos.com/" target="_blank"><img src={Logo} style={{width: '42px'}} /></a></h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="justify-content-center align-items-center" style={{height: '85vh', display: (billNo === null || billNo === undefined || billNo === '') ? 'flex' : 'none'}}>
                <img src={ErrorPage} style={{width: '60%'}} />
            </div>
        </div>
    )
}

export default Bill
