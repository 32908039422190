import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useNavigate } from 'react-router-dom'
import { connect } from "react-redux"
import { hideAddStockOptionOffcanvas } from '../../../features/Offcanvas/addStockOptionOffcanvasSlice'

const AddStockOptionOffcanvas = ({
    addStockOptionVisible,
    hideAddStockOptionOffcanvas
}) => {
    let navigate = useNavigate()
    const handleAddStock = (addType) => {
        if (addType === 'single') {
            navigate('/manageStock?action=addStock')
            hideAddStockOptionOffcanvas() 
        } else if (addType === 'bulk') {
            navigate('/addRemoveStock')
            hideAddStockOptionOffcanvas()
        }
    }
    return (
        <div>
            <Offcanvas show={addStockOptionVisible} placement={'bottom'} style={{height: 'auto', transition: '0.4s'}}>
                <Offcanvas.Header closeButton style={{minHeight: '36px'}} onHide={() => {hideAddStockOptionOffcanvas()}}>
                    <div class="d-flex justify-content-between w-100">
                        <Offcanvas.Title style={{display: 'flex', alignItems: 'center'}}>Stock Action Type</Offcanvas.Title>
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body class="offcanvas-body">
                    <div class="section">
                        <div class="d-flex">
                            <button class="btn btn-outline-secondary me-1" onClick={() => {handleAddStock('single')}}>Add Single Stock</button>
                            <button class="btn btn-outline-secondary" onClick={() => {handleAddStock('bulk')}}>Add Bulk Stock</button>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

const mapStateToProps = ({ addStockOption }) => {
    const {
        addStockOptionVisible
    } = addStockOption
    return {
        addStockOptionVisible
    }
}
  
const mapDispatchToProps = {
    hideAddStockOptionOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(AddStockOptionOffcanvas)