import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { hideAddUserModal } from '../../../features/Offcanvas/addUserSlice'

const AddUserModal = ({
    addUserVisible,
    hideAddUserModal,
    userData
}) => {
    const [customerData, setCustomerData] = useState(null)
    useEffect(() => {
        if (userData !== null) {
            setCustomerData(userData)
        } else {
            setCustomerData(userData)
        }
    }, [userData, addUserVisible])

    const handleUserData = (value, inputName) => {
        const temp = JSON.parse(JSON.stringify(customerData))
        temp[inputName] = value
        setCustomerData(temp)
    }
    const handleAddUser = (e) => {
        e.preventDefault()
    }
    return (
        <div>
            <Modal show={addUserVisible} centered={true}>
                <div class="rounded-circle d-flex align-items-center justify-content-center text-light" style={{height: '25px', width: '25px', background: 'rgba(0,0,0,0.3)', position: 'absolute', top: '-13px', right: '-13px', cursor: 'pointer'}} onClick={() => {hideAddUserModal()}}>
                    <ion-icon name="close-outline"></ion-icon>
                </div>
                <Modal.Body class="modal-body m-1 p-1 border">
                    <div class="section">
                        <form onSubmit={(e) => {handleAddUser(e)}}>
                            <div class="row">
                                <div class="col-6 col-md-6">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="name">Name <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control" id="name" required />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-md-6">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="contact">Contact No<span class="text-danger">*</span></label>
                                            <input type="number" class="form-control" id="contact" value={customerData && customerData.ContactNumber} onChange={(e) => {handleUserData(e.target.value, 'ContactNumber')}} required />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-md-6">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="city">City</label>
                                            <input type="text" class="form-control" id="city" />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-md-6">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="gender">Gender </label>
                                            <select class="form-control form-select" id="gender" required>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="other">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-md-6">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="dob">DOB</label>
                                            <input type="date" class="form-control" id="dob" />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-md-6">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="EmailID">Email ID</label>
                                            <input type="email" class="form-control" id="EmailID" />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="col-6 col-md-6">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="contactBook">Contact Book</label>
                                            <input class="form-control" list="contactBook" name="contactBook" id="contactBook" />
                                            <datalist id="contactBook">
                                                <option value="Edge" />
                                                <option value="Firefox" />
                                                <option value="Chrome" />
                                                <option value="Opera" />
                                                <option value="Safari" />
                                            </datalist>
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div> */}
                                <div class="col-12 col-md-12">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="otherDetail">Description</label>
                                            <textarea class="form-control" id="otherDetail"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12">
                                    <div class="d-flex justify-content-center">
                                        <button type="submit" class="btn btn-success d-flex align-items-center w-50"><ion-icon name="add-outline"></ion-icon>&nbsp;Add Customer</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ addUser }) => {
    const {
        addUserVisible,
        userData
    } = addUser
    return {
        addUserVisible,
        userData
    }
  }
  
  const mapDispatchToProps = {
    hideAddUserModal
  }

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal)