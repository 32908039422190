import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    imageEditModalVisible: false,
    imageEditData: null,
    fileUploadModalVisible: false
}

export const ImageEditModalSlice = createSlice({
    name: 'imageEdit',
    initialState,
    reducers: {
      showImageEditModal: (state, action) => {
        const {
            imageEditModalVisible,
            imageEditData
        } = action.payload
        state.imageEditModalVisible = imageEditModalVisible
        state.imageEditData = imageEditData
      },
      hideImageEditModal: (state) => {
        state.imageEditModalVisible = false
        state.imageEditData = false
      },
      showFileUploadModal: (state, action) => {
        const {
            fileUploadModalVisible
        } = action.payload
        state.fileUploadModalVisible = fileUploadModalVisible
      },
      hideFileUploadModal: (state) => {
        state.fileUploadModalVisible = false
      }
    }
})

export const { showImageEditModal, hideImageEditModal, showFileUploadModal, hideFileUploadModal } = ImageEditModalSlice.actions;


export default ImageEditModalSlice.reducer