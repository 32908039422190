import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import axios from '../../AxiosConfig'
import { connect } from 'react-redux'
import SearchWithButtons from './Components/SearchWithButtons'

const OpeningClosingStockReport = ({
    dayOrderFilter
}) => {
    const [reportData, setReportData] = useState(null)
    const [orderListCallback, setOrderListCallback] = useState(false)
    useEffect(() => {
        let dateFilterFrom = new Date().toISOString().slice(0, 10)
        let dateFilterTo = new Date().toISOString().slice(0, 10)
        if (dayOrderFilter === 'Today') {
        dateFilterFrom = new Date().toISOString().slice(0, 10)
        dateFilterTo = new Date().toISOString().slice(0, 10)
        } else if (dayOrderFilter === 'Yesterday') {
        dateFilterFrom = new Date(new Date().setDate(new Date().getDate() - 1))
            .toISOString()
            .slice(0, 10)
        dateFilterTo = new Date(new Date().setDate(new Date().getDate() - 1))
            .toISOString()
            .slice(0, 10)
        } else if (dayOrderFilter === 'Last7') {
        dateFilterFrom = new Date(new Date().setDate(new Date().getDate() - 7))
            .toISOString()
            .slice(0, 10)
        dateFilterTo = new Date().toISOString().slice(0, 10)
        } else if (dayOrderFilter === 'Last30') {
        dateFilterFrom = new Date(new Date().setDate(new Date().getDate() - 30))
            .toISOString()
            .slice(0, 10)
        dateFilterTo = new Date().toISOString().slice(0, 10)
        } else if (dayOrderFilter === 'All') {
        dateFilterFrom = 'All'
        dateFilterTo = 'All'
        } else if (dayOrderFilter.includes('-')) {
        dateFilterFrom = dayOrderFilter
        dateFilterTo = dayOrderFilter
        }

        axios
        .get(
            '/OpeningClosing/getShopStatusList/' +
            dateFilterFrom +
            '/' +
            dateFilterTo
        )
        .then(res => {
            if (res.data.message === 'Success') {
            console.log(res.data.data)
            setReportData(res.data.data)
            }
        })
    }, [dayOrderFilter])
  return (
    <div id="appCapsule">
        <div class="section full mb-1">
            <div class="pt-1">
                <SearchWithButtons pageName={'StockReport'}></SearchWithButtons>
            </div>
        </div>
      <div class="section full">
        <ul class="listview image-listview flush bg-transparent">
            {reportData?.map((v,k) => {
                return <li>
                <a href="javascript:void(0)" class="item ps-1">
                  <div class={(v.Status === 't') ? 'icon-box bg-success' : 'icon-box bg-danger'}>
                    <ion-icon name={(v.Status === 't') ? "checkmark-outline" : "close-outline"}></ion-icon>
                  </div>
                  <div class="in">
                    <div>
                      <header>
                        {v.Date}&nbsp;|&nbsp;
                        {v.LocalTimeStamp}
                        {/* <Moment fromNow>{v.LocalTimeStamp}</Moment> */}
                      </header>
                      <small class="text-muted">
                        <span class="text-dark">₹{v.Cash}</span>
                        &nbsp;
                        
                      </small>
                      <footer class="mt-0">
                        <div class="text">
                          <span
                            class="badge bg-secondary"
                            style={{ height: '18px' }}
                          >
                            Total Stock {v.TotalStock}
                          </span>
                        </div>
                      </footer>
                    </div>
                  </div>
                  <span class="text-muted text-nowrap">{v.Name}</span>
                </a>
              </li>
            })}
        </ul>
      </div>
    </div>
  )
}

const mapStateToProps = ({ data }) => {
    const {
        dayOrderFilter
    } = data
    return {
        dayOrderFilter
    }
  }
  
  const mapDispatchToProps = {
  }

export default connect(mapStateToProps, mapDispatchToProps)(OpeningClosingStockReport)
