import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import moment from 'moment-timezone'
import axios from '../../AxiosConfig'
import { setEditCustomOrderData } from '../../features/Data/dataSlice'

const OpticCustomOrderEdit = ({
    businessData,
    authToken,
    items,
    showAppToast,
    userData,
    editCustomOrderData,
    setEditCustomOrderData
}) => {
    const [branchData, setBranchData] = useState(null)
    const [businessTempData, setBusinessTempData] = useState(null)
    const [selectedOption, setSelectedOption] = useState(null)
    const [tempCurrentDate, setTempCurrentDate] = useState(null)
    const [disableSubmitButton, setDisableSubmitButton] = useState(false)
    const [orderNumber, setOrderNumber] = useState(null)
    const [customSizeInput, showCustomSizeInput] = useState(false)
    // USESTATE FOR FORM FIELDS START
    const [billNo, setBillNo] = useState(null)
    const [orderDate, setOrderDate] = useState(null)
    const [deliveryDate, setDeliveryDate] = useState(null)
    const [eventType, setEventType] = useState("birthday")
    const [paymentMethod, setPaymentMethod] = useState("Cash")
    const [remark, setRemark] = useState('')
    const [eventText, setEventText] = useState(null)
    const [deliveryTime, setDeliveryTime] = useState("12:00")
    const [selectedCakeType, setSelectedCakeType] = useState({
        "label": "Normal",
        "value": "normal"
    })
    const [itemSize, setItemSize] = useState(null)
    // USESTATE FOR FORM FIELDS END
    const [titleName, setTitleName] = useState(null)
    const [tempCustomOrderObj, setTempCustomOrderObj] = useState(null)
    let navigate = useNavigate()
    const calculateYear = (number) => {
        let num = number.toString()
        if (num.length === 1) {
            num = '00' + num
        } else if (num.length === 2) {
            num = '0' + num
        }
        return num
    }
    useEffect(() => {
        const tokenData = jwt_decode(authToken)
        if (businessData) {
          businessData.map((value, key) => {
            value.Branches.map((v,k) => {
                if (value.ID === tokenData.userInfo.Business_ID && v.ID === tokenData.userInfo.DefaultLocationID) {
                    setBranchData(v)
                    setBusinessTempData(value)
                }
            })
          }) 
        }
    }, [businessData, authToken])
    useEffect(() => {
        let options = []
        if (items) {
            items.map((v,k) => {
                options.push({
                    "label": v.ItemTitle,
                    "value": v.ID,
                    "data": v
                })
            })
            setTitleName(options)
            const currentDate = new Date().toISOString().split('T')[0]
            setTempCurrentDate(currentDate)
            setOrderDate(currentDate)
            setDeliveryDate(currentDate)
        } else {
            navigate('/load')
        }
    }, [items])
    const cakeType = [
        { label: "Normal", value: "normal" },
        { label: "Photo Cake", value: "photoCake" },
        { label: "Tower", value: "tower" },
        { label: "Doll", value: "doll" },
        { label: "Square", value: "square" },
        { label: "Round", value: "round" },
        { label: "Heart", value: "heart" },
        { label: "Other Design", value: "otherDesign" },
        { label: "Less Cream", value: "lessCream" },
        { label: "Foundant", value: "foundant" },
        { label: "Home Delivery", value: "homeDelivery" }
    ]
    function updateObj (data, dataType, extraDataType) {
        if (editCustomOrderData) {
            const tempCustomOrderObj2 = JSON.parse(JSON.stringify(editCustomOrderData))

            if (dataType === 'billNo' ) {
                // setBillNo(data)
                tempCustomOrderObj2.BillNo = data
            } else if (dataType === 'orderDate') {
                setOrderDate(data)
                tempCustomOrderObj2.OrderDate = data
            } else if (dataType === 'deliveryDate') {
                setDeliveryDate(data)
                tempCustomOrderObj2.DeliveryDate = data
                tempCustomOrderObj2.OrderDetails.DeliveryDate = data
            } else if (dataType === 'eventType') {
                setEventType(data)
                tempCustomOrderObj2.EventType = data
                tempCustomOrderObj2.OrderDetails.EventType = data
            } else if (dataType === 'eventText') {
                setEventText(data)
                tempCustomOrderObj2.EventText = data
                tempCustomOrderObj2.OrderDetails.EventText = data
            } else if (dataType === 'deliveryTime') {
                setDeliveryTime(data)
                tempCustomOrderObj2.DeliveryTime = data
                tempCustomOrderObj2.OrderDetails.DeliveryTime = data
            } else if (dataType === 'cakeType') {
                setSelectedCakeType(data)
                tempCustomOrderObj2.CakeType = data
                tempCustomOrderObj2.OrderDetails.CakeType = data
            } else if (dataType === 'paymentMethod') {
                setPaymentMethod(data)
                tempCustomOrderObj2.PaymentMethod = data
            } else if (dataType === 'remark') {
                setRemark(data)
                tempCustomOrderObj2.Remark = data
                tempCustomOrderObj2.OrderDetails.Remark = data
            }  else if (dataType === 'phone') {
                tempCustomOrderObj2.Phone = data
                tempCustomOrderObj2.OrderDetails.Phone = data
            } else if (dataType === 'name') {
                tempCustomOrderObj2.Name = data
                tempCustomOrderObj2.OrderDetails.Name = data
            } else if (dataType === 'age') {
                tempCustomOrderObj2.Age = data
                tempCustomOrderObj2.OrderDetails.Age = data
            } else if (dataType === 'address') {
                tempCustomOrderObj2.OrderDetails.Address = data
            } else if (dataType === 'email') {
                tempCustomOrderObj2.OrderDetails.Email = data
            } else if (extraDataType === 'rightTable') {
                if (!tempCustomOrderObj2.OrderDetails.hasOwnProperty('RightTable')) {
                    tempCustomOrderObj2.OrderDetails.RightTable = {}
                }
                if (tempCustomOrderObj2.OrderDetails.RightTable.hasOwnProperty(dataType)) {
                    tempCustomOrderObj2.OrderDetails.RightTable[dataType] = data
                } else {
                    tempCustomOrderObj2.OrderDetails.RightTable[dataType] = data
                }
            } else if (extraDataType === 'leftTable') {
                if (!tempCustomOrderObj2.OrderDetails.hasOwnProperty('LeftTable')) {
                    tempCustomOrderObj2.OrderDetails.LeftTable = {}
                }
                if (tempCustomOrderObj2.OrderDetails.LeftTable.hasOwnProperty(dataType)) {
                    tempCustomOrderObj2.OrderDetails.LeftTable[dataType] = data
                } else {
                    tempCustomOrderObj2.OrderDetails.LeftTable[dataType] = data
                }
            } else if (extraDataType === 'lensesRightTable') {
                if (!tempCustomOrderObj2.OrderDetails.hasOwnProperty('LensesRightTable')) {
                    tempCustomOrderObj2.OrderDetails.LensesRightTable = {}
                }
                if (tempCustomOrderObj2.OrderDetails.LensesRightTable.hasOwnProperty(dataType)) {
                    tempCustomOrderObj2.OrderDetails.LensesRightTable[dataType] = data
                } else {
                    tempCustomOrderObj2.OrderDetails.LensesRightTable[dataType] = data
                }
            } else if (extraDataType === 'lensesLeftTable') {
                if (!tempCustomOrderObj2.OrderDetails.hasOwnProperty('LensesLeftTable')) {
                    tempCustomOrderObj2.OrderDetails.LensesLeftTable = {}
                }
                if (tempCustomOrderObj2.OrderDetails.LensesLeftTable.hasOwnProperty(dataType)) {
                    tempCustomOrderObj2.OrderDetails.LensesLeftTable[dataType] = data
                } else {
                    tempCustomOrderObj2.OrderDetails.LensesLeftTable[dataType] = data
                }
            }
            setEditCustomOrderData({editCustomOrderData: tempCustomOrderObj2})
            setTempCustomOrderObj(tempCustomOrderObj2)   
        }
    }

    async function updateObjReturn(obj, data, key) {
        let newObj = JSON.parse(JSON.stringify(obj))
        if (key === 'phone') {
            newObj.Phone = data
            newObj.OrderDetails.Phone = data
        } else if (key === 'billNo') {
            newObj.BillNo = data
            newObj.OrderDetails.BillNo = data
        } else if (key === 'name') {
            newObj.Name = data
            newObj.OrderDetails.Name = data
        } else if (key === 'address') {
            newObj.OrderDetails.Address = data
        } else if (key === 'email') {
            newObj.OrderDetails.Email = data
        } else if (key === 'eventText') {
            setEventText(data)
            newObj.EventText = data
            newObj.OrderDetails.EventText = data
        } else if (key === 'eventType') {
            setEventType(data)
            newObj.EventType = data
            newObj.OrderDetails.EventType = data
        } else if (key === 'orderDate') {
            setOrderDate(data)
            newObj.OrderDate = data
            newObj.Date = data
        } else if (key === 'deliveryDate') {
            setDeliveryDate(data)
            newObj.OrderDetails.DeliveryDate = data
        } else if (key === 'deliveryTime') {
            setDeliveryTime(data)
            newObj.OrderDetails.DeliveryTime = data
        } else if (key === 'orderNumber') {
            newObj.OrderNumber = data
            newObj.OfflineInvoiceNumber = "IN" + data
        } else if (key === 'dateTime') {
            newObj.DateTime = data
            newObj.TransactionDate = data
        } else if (key === 'advance') {
            newObj.Advance = data
        } else if (key === 'balanceAmount') {
            newObj.BalanceAmount = data
            newObj.OrderDetails.BalanceAmount = data
            newObj.Balance = data
        } else if (key === 'discount') {
            newObj.TotalDiscount = data
        } else if (key === 'amount') {
            newObj.Amount = data
            newObj.PaymentMethod = "Cash"
            newObj.OrderType = "Custom"
            newObj.Status = 1
        } else if (key === 'cakeType') {
            setSelectedCakeType(data)
            newObj.CakeType = data
        }
        return newObj
    }

    useEffect(async () => {
        let phoneObj = await updateObjReturn(editCustomOrderData, editCustomOrderData?.OrderDetails?.Phone, 'phone')
        let nameObj = await updateObjReturn(phoneObj, editCustomOrderData?.OrderDetails?.Name, 'name')
        let nameOnCakeObj = await updateObjReturn(nameObj, editCustomOrderData?.OrderDetails?.EventText, 'eventText')
        let eventTypeObj = await updateObjReturn(nameOnCakeObj, editCustomOrderData?.OrderDetails?.EventType, 'eventType')
        let orderDateObj = await updateObjReturn(eventTypeObj, editCustomOrderData?.OrderDate, 'orderDate')
        let deliveryDateObj = await updateObjReturn(orderDateObj, editCustomOrderData?.OrderDetails?.DeliveryDate, 'deliveryDate')
        let deliveryTimeObj = await updateObjReturn(deliveryDateObj, editCustomOrderData?.OrderDetails?.DeliveryTime, 'deliveryTime')
        let orderNumberObj = await updateObjReturn(deliveryTimeObj, orderNumber, 'orderNumber')
        let dateTimeObj = await updateObjReturn(orderNumberObj, newDate, 'dateTime')
        let advanceObj = await updateObjReturn(dateTimeObj, editCustomOrderData?.Advance, 'advance')
        let balanceAmountObj = await updateObjReturn(advanceObj, (parseInt(editCustomOrderData?.BalanceAmount)), 'balanceAmount')
        let amountObj = await updateObjReturn(balanceAmountObj, parseInt(editCustomOrderData?.Subtotal), 'amount')
        let cakyTypeObj = await updateObjReturn(amountObj, editCustomOrderData?.OrderDetails?.CakeType, 'cakeType')
        let ageObj = await updateObjReturn(cakyTypeObj, editCustomOrderData?.OrderDetails?.Age, 'age')
        let addressObj = await updateObjReturn(ageObj, editCustomOrderData?.OrderDetails?.Address, 'address')
        let emailObj = await updateObjReturn(addressObj, editCustomOrderData?.OrderDetails?.Email, 'email')
        let discountObj = await updateObjReturn(emailObj, parseInt(editCustomOrderData?.Discount), 'discount')
        let billObj = await updateObjReturn(discountObj, parseInt(editCustomOrderData?.BillNo), 'billNo')
        setTempCustomOrderObj(billObj)
        setEditCustomOrderData({editCustomOrderData: billObj})
    }, [orderNumber])

    // ON CHANGE EVENT START
    const updateBillNo = (billNo) => {
        updateObj(billNo, 'billNo')
    }
    const updatePhone = (phone) => {
        updateObj(phone, 'phone')
    }
    const updateName = (name) => {
        updateObj(name, 'name')
    }
    const updateAge = (age) => {
        updateObj(age, 'age')
    }
    const updateAddress = (address) => {
        updateObj(address, 'address')
    }
    const updateEmail = (email) => {
        updateObj(email, 'email')
    }
    const updateOrderDate = (orderDate) => {
        updateObj(orderDate, 'orderDate')
    }
    const updateDeliveryDate = (deliveryDate) => {
        updateObj(deliveryDate, 'deliveryDate')
    }
    const updateEventType = (eventType) => {
        updateObj(eventType, 'eventType')
    }
    const updateEventText = (eventText) => {
        updateObj(eventText, 'eventText')
    }
    const updateDeliveryTime = (deliveryTime) => {
        updateObj(deliveryTime, 'deliveryTime')
    }
    const handleCakeTypeChange = (cakeType) => {
        updateObj(cakeType, 'cakeType')
    }
    const updatePaymentMethod = (paymentMethod) => {
        updateObj(paymentMethod, 'paymentMethod')
    }
    const updateRemark = (remark) => {
        updateObj(remark, 'remark')
    }
    const updateRightTable = (value, inputType) => {
        updateObj(value, inputType, 'rightTable')
    }
    const updateLeftTable = (value, inputType) => {
        updateObj(value, inputType, 'leftTable')
    }
    const updateLensesRightTable = (value, inputType) => {
        updateObj(value, inputType, 'lensesRightTable')
    }
    const updateLensesLeftTable = (value, inputType) => {
        updateObj(value, inputType, 'lensesLeftTable')
    }
    // ON CHANGE EVEN END
    const updateDiscount = (value, index) => {
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.TotalDiscount = (value) ? value : 0
        // tempCustomOrderObj2.Total = parseInt(tempCustomOrderObj2.Subtotal) - parseInt(tempCustomOrderObj2.TotalDiscount)
        // tempCustomOrderObj2.Advance = parseInt(tempCustomOrderObj2.Subtotal) - parseInt(tempCustomOrderObj2.TotalDiscount)
        tempCustomOrderObj2.BalanceAmount = (parseInt(tempCustomOrderObj2.Subtotal) - parseInt(tempCustomOrderObj2.Advance)) - parseInt(tempCustomOrderObj2.TotalDiscount)
        tempCustomOrderObj2.OrderDetails.BalanceAmount = (parseInt(tempCustomOrderObj2.Subtotal) - parseInt(tempCustomOrderObj2.Advance)) - parseInt(tempCustomOrderObj2.TotalDiscount)
        tempCustomOrderObj2.Balance = (parseInt(tempCustomOrderObj2.Subtotal) - parseInt(tempCustomOrderObj2.Advance)) - parseInt(tempCustomOrderObj2.TotalDiscount)
        setEditCustomOrderData({editCustomOrderData: tempCustomOrderObj2})
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    useEffect(() => {
        let d = new Date();
        const year = d.getFullYear();
        const start = new Date(d.getFullYear(), 0, 0);
        const diff = d - start;
        const oneDay = 1000 * 60 * 60 * 24;
        const day = Math.floor(diff / oneDay);
        var seconds = (d.getSeconds() < 10 ? '0' : '') + d.getSeconds();
        var minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
        var hour = (d.getHours() < 10 ? '0' : '') + d.getHours();
        setOrderNumber(year + calculateYear(day) + 'AA' + 'K01' + hour + minutes + seconds)
    }, [editCustomOrderData])
    let customOrderItem = []
    let customOrderObj =  {}
    const targetTimezone = 'Asia/Kolkata'
    const newDate = moment().tz(targetTimezone).format('YYYY-MM-DD HH:mm:ss')
    const onlyDate = moment().tz(targetTimezone).format('YYYY-MM-DD')
    const tempBillNo = localStorage.getItem('billNo')
    function resetForm() {
        let d = new Date();
        const year = d.getFullYear();
        const start = new Date(d.getFullYear(), 0, 0);
        const diff = d - start;
        const oneDay = 1000 * 60 * 60 * 24;
        const day = Math.floor(diff / oneDay);
        var seconds = (d.getSeconds() < 10 ? '0' : '') + d.getSeconds();
        var minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
        var hour = (d.getHours() < 10 ? '0' : '') + d.getHours();
        setOrderNumber(year + calculateYear(day) + 'AA' + 'K01' + hour + minutes + seconds)
        const tempOrderNumber = year + calculateYear(day) + 'AA' + 'K01' + hour + minutes + seconds
        for (let i = 0; i < 2; i++) {
            customOrderItem.push({
                "quantity":0,
                "MRP":0,
                "Cost":0,
                "ItemTitle":null,
                "Item_ID":null,
                "Taxes":0,
                "Status":false,
                "Image": null,
                "AlternateText": null,
                "Sizes": [],
                "CustomSizeFlag": false
            }) 
        }
        customOrderObj = {
            "OfflineInvoiceNumber": "IN" + tempOrderNumber,
            "Items":customOrderItem,
            "TotalQty":0,
            "Subtotal":0,
            "TotalTax": 0,
            "Total":0,
            "EventType":"birthday",
            "DateTime":newDate,
            "OrderNumber":tempOrderNumber,
            "Currency":"INR",
            "Phone":userData?.ContactNumber,
            "Date": onlyDate,
            "UserName":"",
            "BalanceAmount":0,
            "PaymentMethod":"Cash",
            "OrderType": "Custom",
            "Amount": 0,
            "TransactionDate": newDate,
            // "Customer_ID": "",
            "Status": 1,
            "TotalDiscount": 0,
            "billNo": parseInt(tempBillNo) + 1,
            "OrderDate": onlyDate,
            "DeliveryDate": onlyDate,
            "DeliveryTime": "12:00",
            "CakeType":[{ label: "Normal", value: "normal" }],
            "Remark": "",
            "OrderDetails":{
                "BalanceAmount":0,
                "EventType":"birthday",
                "EventText":"",
                "DeliveryDate": onlyDate,
                "DeliveryTime": "12:00",
                "CakeType":[{ label: "Normal", value: "normal" }],
                "Remark": ""
            }
        }
        setTempCustomOrderObj(customOrderObj)
    }
    useEffect(() => {
        if (editCustomOrderData == '' || editCustomOrderData == null || editCustomOrderData == undefined) {
            resetForm()
        }
    }, [editCustomOrderData])
    const handleTitleChange = (value, index) => {
        setSelectedOption(value)
        showCustomSizeInput(false)
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        if (value.data.Sizes.length > 0) {
            tempCustomOrderObj2.Items[index].Item_ID = value.data.Item_ID
        } else {
            tempCustomOrderObj2.Items[index].Item_ID = value.value
            tempCustomOrderObj2.Items[index].AlternateText = value.data.AlternateText
            tempCustomOrderObj2.Items[index].MRP = value.data.MRP
            tempCustomOrderObj2.Items[index].Cost = value.data.Cost
        }
        tempCustomOrderObj2.Items[index].ItemTitle = value.label
        tempCustomOrderObj2.Items[index].Sizes = value.data.Sizes
        tempCustomOrderObj2.Items[index].Image = value.data.ImageURL
        tempCustomOrderObj2.Items[index].quantity = 1
        let count = 0
        let tempSubtotal = 0
        tempCustomOrderObj2.Items.map((v,k) => {
            if (v.quantity && v.MRP) {
                tempSubtotal += v.quantity * v.MRP
            }
            count ++
        })
        tempCustomOrderObj2.Subtotal = tempSubtotal
        tempCustomOrderObj2.Total = tempSubtotal
        tempCustomOrderObj2.Amount = tempSubtotal
        tempCustomOrderObj2.Advance = tempSubtotal
        setEditCustomOrderData({editCustomOrderData: tempCustomOrderObj2})
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const updateItemSizeCustom = (value, index, parentData) => {
        if (parentData.ItemSize === 'custom') {
            const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
            tempCustomOrderObj2.Items[index].CustomSIze = value
            tempCustomOrderObj2.Items[index].Item_ID = selectedOption.value
            setEditCustomOrderData({editCustomOrderData: tempCustomOrderObj2})
            setTempCustomOrderObj(tempCustomOrderObj2)    
        } else {
            const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
            tempCustomOrderObj2.Items[index].CustomSIze = value
            setEditCustomOrderData({editCustomOrderData: tempCustomOrderObj2})
            setTempCustomOrderObj(tempCustomOrderObj2)
        }
    }
    const updateItemQty = (value, index) => {
        setItemSize(value)
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.Items[index].quantity = value
        let count = 0
        let tempSubtotal = 0
        tempCustomOrderObj2.Items.map((v,k) => {
            if (v.quantity && v.MRP) {
                tempSubtotal += v.quantity * v.MRP
            }
            count ++
        })
        tempCustomOrderObj2.Subtotal = tempSubtotal
        tempCustomOrderObj2.Total = tempSubtotal
        tempCustomOrderObj2.Amount = tempSubtotal
        tempCustomOrderObj2.Advance = tempSubtotal
        setEditCustomOrderData({editCustomOrderData: tempCustomOrderObj2})
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const updateItemMRP = (value, index) => {
        setItemSize(value)
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.Items[index].MRP = value
        let count = 0
        let tempSubtotal = 0
        tempCustomOrderObj2.Items.map((v,k) => {
            if (v.quantity && v.MRP) {
                tempSubtotal += v.quantity * v.MRP
            }
            count ++
        })
        tempCustomOrderObj2.Subtotal = tempSubtotal
        tempCustomOrderObj2.Total = tempSubtotal
        tempCustomOrderObj2.Amount = tempSubtotal
        tempCustomOrderObj2.Advance = tempSubtotal
        setEditCustomOrderData({editCustomOrderData: tempCustomOrderObj2})
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const updateAdvance = (value, index) => {
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.Advance = value
        tempCustomOrderObj2.Balance = (parseInt(tempCustomOrderObj2.Subtotal) - parseInt(tempCustomOrderObj2.Advance)) - parseInt(tempCustomOrderObj2.TotalDiscount)
        tempCustomOrderObj2.BalanceAmount = (parseInt(tempCustomOrderObj2.Subtotal) - parseInt(tempCustomOrderObj2.Advance)) - parseInt(tempCustomOrderObj2.TotalDiscount)
        tempCustomOrderObj2.OrderDetails.BalanceAmount = (parseInt(tempCustomOrderObj2.Subtotal) - parseInt(tempCustomOrderObj2.Advance)) - parseInt(tempCustomOrderObj2.TotalDiscount)
        setEditCustomOrderData({editCustomOrderData: tempCustomOrderObj2})
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const handleAddItem = (e) => {
        e.stopPropagation()
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.Items.push({
            "quantity":0,
            "MRP":0,
            "Cost":0,
            "ItemTitle":null,
            "Item_ID":null,
            "Taxes":0,
            "Status":false,
            "Image": null,
            "AlternateText": null,
            "Sizes": []
        })
        setEditCustomOrderData({editCustomOrderData: tempCustomOrderObj2})
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const uploadRefImage = (filePath) => {
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        const formData = new FormData();
        formData.append('inputFile', filePath)
        axios.post('/applications/upload/items', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(res => {
            tempCustomOrderObj2.OrderDetails.ReferenceImage.push(res.data.data)
            setEditCustomOrderData({editCustomOrderData: tempCustomOrderObj2})
            setTempCustomOrderObj(tempCustomOrderObj2)
        }).catch(err => {
        })
    }

    function getDifferentKeys(obj1, obj2) {
        const diff = {};
    
        // Iterate through the keys of the first object
        for (const key in obj1) {
            if (obj1.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
                diff[key] = obj1[key];
            }
        }
    
        return diff;
    }
    const handleCustomOrderSubmit = (e) => {
        e.preventDefault()
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        let totalQuantity = 0
        tempCustomOrderObj2.Items.map((v,k) => {
            totalQuantity += parseInt(v.quantity)
        })
        tempCustomOrderObj2.TotalQty = totalQuantity
        setTempCustomOrderObj(tempCustomOrderObj2)
        setDisableSubmitButton(true)
        axios.post('/Order/CustomOrder', tempCustomOrderObj2).then(res => {
            if (res.data.message === 'Success') {
                resetForm()
                navigate('/customOrders')
                setDisableSubmitButton(false)
            } else {
                setDisableSubmitButton(false)
            }
        })
    }
    const handleDeleteImage = (index) => {
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        if (index !== -1) {
            tempCustomOrderObj2?.OrderDetails?.ReferenceImage?.splice(index, 1);
        }
        setEditCustomOrderData({editCustomOrderData: tempCustomOrderObj2})
        setTempCustomOrderObj(tempCustomOrderObj2) 
    }
    console.log(editCustomOrderData)
    return (
        <div id="appCapsule" style={{marginLeft: '-8px', marginRight: '-8px', paddingRight: '8px', paddingLeft: '8px'}}>
            <div class="section full pt-1">
                <form onSubmit={(e) => {handleCustomOrderSubmit(e)}}>
                    <div class="invoice p-0 bg-transparent" style={{boxShadow: 'none'}}>
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center flex-column">
                                <h2 class="mb-0 text-center">{businessTempData?.Name}</h2>
                                <h4 class="text-center">({branchData?.Name})</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-6 col-md-3">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" style={{fontSize: '1rem'}} for="billNo">Bill No <span class="text-danger">*</span></label>
                                                    <input type="number" class="form-control form-control-sm p-0 ps-1" id="billNo" placeholder="Bill No" value={tempCustomOrderObj?.BillNo} autocomplete="off" required onChange={(e) => {updateBillNo(e.target.value)}} style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-md-3">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" style={{fontSize: '14px'}} for="deliveryDate">Order Date <span class="text-danger">*</span></label>
                                                    <input type="date" class="form-control form-control-sm p-0 ps-1" id="date" placeholder="Date" value={orderDate} autocomplete="off" onChange={(e) => {updateOrderDate(e.target.value)}} style={{minHeight: '44px', height: '44px', fontSize: '20px'}} required />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-md-3">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" style={{fontSize: '14px'}} for="phone">Phone <span class="text-danger">*</span></label>
                                                    <input type="number" class="form-control form-control-sm p-0 ps-1" id="phone" placeholder="Phone" value={tempCustomOrderObj?.Phone} autocomplete="off" max-length="10" min="0" required onChange={(e) => {updatePhone(e.target.value)}} style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-md-3">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="name" style={{textTransform: 'capitalize', fontSize: '14px'}}> Name <span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control form-control-sm p-0 ps-1" id="name" value={tempCustomOrderObj?.OrderDetails?.Name} required placeholder={'Enter Name'} onChange={(e) => {updateName(e.target.value)}} autocomplete="off" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-md-3">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="age" style={{textTransform: 'capitalize', fontSize: '14px'}}> Age</label>
                                                    <input type="number" class="form-control form-control-sm p-0 ps-1" id="age" min="0" value={tempCustomOrderObj?.OrderDetails?.Age} placeholder={'Age'} onChange={(e) => {updateAge(e.target.value)}} autocomplete="off" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-md-3">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="address" style={{textTransform: 'capitalize', fontSize: '14px'}}> Address</label>
                                                    <input type="text" class="form-control form-control-sm p-0 ps-1" id="address" placeholder={'Address'} onChange={(e) => {updateAddress(e.target.value)}} value={tempCustomOrderObj?.OrderDetails?.Address} autocomplete="off" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="email" style={{textTransform: 'capitalize', fontSize: '14px'}}> Email</label>
                                                    <input type="email" class="form-control form-control-sm p-0 ps-1" id="email" placeholder={'example@abc.com'} onChange={(e) => {updateEmail(e.target.value)}} value={tempCustomOrderObj?.OrderDetails?.Email} autocomplete="off" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <h2 class="text-center">Glass Prescription</h2>
                            <div class="w-100 d-flex" style={{overflowX: 'scroll'}}>
                                <div class="mb-2 w-50 me-2" style={{minWidth: '600px'}}>
                                    <h3 class="text-center" style={{fontSize: '18px'}}>Right</h3>
                                    <div class="border">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>SPH</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>CYL</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>AXIS</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>VS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Dist</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rsphd" placeholder="rsphd" value={tempCustomOrderObj?.OrderDetails?.RightTable?.rsphd} onChange={(e) => {updateRightTable(e.target.value, 'rsphd')}} autocomplete="off" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rcyld" placeholder="rcyld" value={tempCustomOrderObj?.OrderDetails?.RightTable?.rcyld} onChange={(e) => {updateRightTable(e.target.value, 'rcyld')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="raxisd" placeholder="raxisd" value={tempCustomOrderObj?.OrderDetails?.RightTable?.raxisd} onChange={(e) => {updateRightTable(e.target.value, 'raxisd')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rvisiond" placeholder="rvisiond" value={tempCustomOrderObj?.OrderDetails?.RightTable?.rvisiond} onChange={(e) => {updateRightTable(e.target.value, 'rvisiond')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Near</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rsphn" placeholder="rsphn" value={tempCustomOrderObj?.OrderDetails?.RightTable?.rsphn} onChange={(e) => {updateRightTable(e.target.value, 'rsphn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rcyln" placeholder="rcyln" value={tempCustomOrderObj?.OrderDetails?.RightTable?.rcyln} onChange={(e) => {updateRightTable(e.target.value, 'rcyln')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="raxisn" placeholder="raxisn" value={tempCustomOrderObj?.OrderDetails?.RightTable?.raxisn} onChange={(e) => {updateRightTable(e.target.value, 'raxisn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rvisionn" placeholder="rvisionn" value={tempCustomOrderObj?.OrderDetails?.RightTable?.rvisionn} onChange={(e) => {updateRightTable(e.target.value, 'rvisionn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Add</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rspha" placeholder="rspha" value={tempCustomOrderObj?.OrderDetails?.RightTable?.rspha} onChange={(e) => {updateRightTable(e.target.value, 'rspha')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rcyla" placeholder="rcyla" value={tempCustomOrderObj?.OrderDetails?.RightTable?.rcyla} onChange={(e) => {updateRightTable(e.target.value, 'rcyla')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="raxisa" placeholder="raxisa" value={tempCustomOrderObj?.OrderDetails?.RightTable?.raxisa} onChange={(e) => {updateRightTable(e.target.value, 'raxisa')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rvisiona" placeholder="rvisiona" value={tempCustomOrderObj?.OrderDetails?.RightTable?.rvisiona} onChange={(e) => {updateRightTable(e.target.value, 'rvisiona')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="w-50" style={{minWidth: '600px'}}>
                                    <h3 class="text-center" style={{fontSize: '18px'}}>Left</h3>
                                    <div class="border">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>SPH</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>CYL</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>AXIS</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>VS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Dist</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lsphd" placeholder="lsphd" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.lsphd} onChange={(e) => {updateLeftTable(e.target.value, 'lsphd')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lcyld" placeholder="lcyld" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.lcyld} onChange={(e) => {updateLeftTable(e.target.value, 'lcyld')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="laxisd" placeholder="laxisd" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.laxisd} onChange={(e) => {updateLeftTable(e.target.value, 'laxisd')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lvisiond" placeholder="lvisiond" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.lvisiond} onChange={(e) => {updateLeftTable(e.target.value, 'lvisiond')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Near</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lsphn" placeholder="lsphn" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.lsphn} onChange={(e) => {updateLeftTable(e.target.value, 'lsphn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lcyln" placeholder="lcyln" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.lcyln} onChange={(e) => {updateLeftTable(e.target.value, 'lcyln')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="laxisn" placeholder="laxisn" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.laxisn} onChange={(e) => {updateLeftTable(e.target.value, 'laxisn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lvisionn" placeholder="lvisionn" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.lvisionn} onChange={(e) => {updateLeftTable(e.target.value, 'lvisionn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Add</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lspha" placeholder="lspha" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.lspha} onChange={(e) => {updateLeftTable(e.target.value, 'lspha')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lcyla" placeholder="lcyla" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.lcyla} onChange={(e) => {updateLeftTable(e.target.value, 'lcyla')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="laxisa" placeholder="laxisa" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.laxisa} onChange={(e) => {updateLeftTable(e.target.value, 'laxisa')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lvisiona" placeholder="lvisiona" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.lvisiona} onChange={(e) => {updateLeftTable(e.target.value, 'lvisiona')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-1">
                            <h2 class="text-center">Contact Lenses</h2>
                            <div class="w-100 d-flex" style={{overflowX: 'scroll'}}>
                                <div class="mb-2 w-50 me-2" style={{minWidth: '600px'}}>
                                    <h3 class="text-center" style={{fontSize: '18px'}}>Right</h3>
                                    <div class="border">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>SPH</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>CYL</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>AXIS</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>VS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Dist</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rsphd" placeholder="rsphd" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.rsphd} onChange={(e) => {updateLensesRightTable(e.target.value, 'rsphd')}} autocomplete="off" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rcyld" placeholder="rcyld" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.rcyld} onChange={(e) => {updateLensesRightTable(e.target.value, 'rcyld')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="raxisd" placeholder="raxisd" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.raxisd} onChange={(e) => {updateLensesRightTable(e.target.value, 'raxisd')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rvisiond" placeholder="rvisiond" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.rvisiond} onChange={(e) => {updateLensesRightTable(e.target.value, 'rvisiond')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Near</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rsphn" placeholder="rsphn" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.rsphn} onChange={(e) => {updateLensesRightTable(e.target.value, 'rsphn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rcyln" placeholder="rcyln" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.rcyln} onChange={(e) => {updateLensesRightTable(e.target.value, 'rcyln')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="raxisn" placeholder="raxisn" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.raxisn} onChange={(e) => {updateLensesRightTable(e.target.value, 'raxisn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rvisionn" placeholder="rvisionn" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.rvisionn} onChange={(e) => {updateLensesRightTable(e.target.value, 'rvisionn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Add</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rspha" placeholder="rspha" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.rspha} onChange={(e) => {updateLensesRightTable(e.target.value, 'rspha')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rcyla" placeholder="rcyla" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.rcyla} onChange={(e) => {updateLensesRightTable(e.target.value, 'rcyla')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="raxisa" placeholder="raxisa" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.raxisa} onChange={(e) => {updateLensesRightTable(e.target.value, 'raxisa')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rvisiona" placeholder="rvisiona" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.rvisiona} onChange={(e) => {updateLensesRightTable(e.target.value, 'rvisiona')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="w-50" style={{minWidth: '600px'}}>
                                    <h3 class="text-center" style={{fontSize: '18px'}}>Left</h3>
                                    <div class="border">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>SPH</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>CYL</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>AXIS</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>VS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Dist</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lsphd" placeholder="lsphd" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.lsphd} onChange={(e) => {updateLensesLeftTable(e.target.value, 'lsphd')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lcyld" placeholder="lcyld" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.lcyld} onChange={(e) => {updateLensesLeftTable(e.target.value, 'lcyld')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="laxisd" placeholder="laxisd" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.laxisd} onChange={(e) => {updateLensesLeftTable(e.target.value, 'laxisd')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lvisiond" placeholder="lvisiond" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.lvisiond} onChange={(e) => {updateLensesLeftTable(e.target.value, 'lvisiond')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Near</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lsphn" placeholder="lsphn" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.lsphn} onChange={(e) => {updateLensesLeftTable(e.target.value, 'lsphn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lcyln" placeholder="lcyln" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.lcyln} onChange={(e) => {updateLensesLeftTable(e.target.value, 'lcyln')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="laxisn" placeholder="laxisn" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.laxisn} onChange={(e) => {updateLensesLeftTable(e.target.value, 'laxisn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lvisionn" placeholder="lvisionn" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.lvisionn} onChange={(e) => {updateLensesLeftTable(e.target.value, 'lvisionn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Add</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lspha" placeholder="lspha" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.lspha} onChange={(e) => {updateLensesLeftTable(e.target.value, 'lspha')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lcyla" placeholder="lcyla" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.lcyla} onChange={(e) => {updateLensesLeftTable(e.target.value, 'lcyla')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="laxisa" placeholder="laxisa" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.laxisa} onChange={(e) => {updateLensesLeftTable(e.target.value, 'laxisa')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lvisiona" placeholder="lvisiona" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.lvisiona} onChange={(e) => {updateLensesLeftTable(e.target.value, 'lvisiona')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="invoice-detail mt-2">
                            <div>
                                <div class="table-responsive" style={{overflow: 'visible'}}>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <td class="fs-6 text-dark">ITEM</td>
                                                <td class="text-start text-dark fs-6">DESCRIPTION</td>
                                                <td class="fs-6 text-dark">QTY</td>
                                                <td class="fs-6 text-dark">RATE</td>
                                                <td class="fs-6 text-dark">SUBTOTAL</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tempCustomOrderObj?.Items.map((v,k) => {
                                                return <tr key={k}>
                                                    <td>
                                                        <div style={{minWidth: '200px', fontSize: '18px', fontWeight: 'normal'}}>
                                                            <Select
                                                                value={{
                                                                    "label": v?.ItemTitle,
                                                                    "value": v?.Item_ID
                                                                }}
                                                                onChange={(e) => {handleTitleChange(e, k)}}
                                                                options={titleName}
                                                                autoFocus={false}
                                                                key={k}
                                                            />
                                                        </div>
                                                    </td>
                                                        <td key={k}>
                                                            <div class="form-group boxed ms-0 pb-0 pt-0">
                                                                <div class="input-wrapper">
                                                                    <div style={{position: 'relative'}}>
                                                                        <input type="text" class="form-control form-control-sm p-0 ps-1 text-start" id="description" placeholder="Description" onChange={(e) => {updateItemSizeCustom(e.target.value, k, v)}} value={v.CustomSIze} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', width: '100%', fontSize: '20px'}} />
                                                                        <i class="clear-input">
                                                                            <ion-icon name="close-circle"></ion-icon>
                                                                        </i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    <td>
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper d-flex justify-content-center">
                                                                <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" id="qty" placeholder="Qty" onChange={(e) => {updateItemQty(e.target.value, k)}} value={v.quantity} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', width: '100%', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="pe-0">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-group" style={{flexWrap: 'nowrap'}}>
                                                                <span class="input-group-text ps-1 pe-0 bg-white" id="basic-addon1" style={{height: '44px', border: '1px solid lightgray', borderRadius: '6px 0px 0px 6px'}}>₹</span>
                                                                <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" onChange={(e) => {updateItemMRP(e.target.value, k)}} value={v.MRP} placeholder="Rate" aria-label="Rate" min="0" aria-describedby="basic-addon1" style={{minHeight: '44px', height: '44px', width: '100%', fontSize: '20px', borderLeft: 'none'}} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="ps-0 fs-6 text-dark align-middle">₹{parseInt(v.quantity) * parseInt(v.MRP)}</td>
                                                </tr>
                                            })}
                                            <tr>
                                                <td class="text-start" colspan="5">
                                                    <button class="btn btn-success ps-1 pe-1" type="button" onClick={(e) => {handleAddItem(e)}}><ion-icon name="add-outline" disabled></ion-icon> Add Item</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="invoice-total mt-0 border-0">
                            <div class="row">
                                <div class="col-12 col-md-9 order-2 order-md-1">
                                    <div class="row">
                                        <div class="col-12 col-md-6">
                                            <div class="form-group boxed">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="remark" style={{fontSize: '14px'}}>Description</label>
                                                    <textarea id="remark" rows="4" class="form-control" value={tempCustomOrderObj?.OrderDetails?.Remark} onChange={(e) => {updateRemark(e.target.value)}}></textarea>
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="row">
                                                <div class="col-12 col-md-12">
                                                    <div class="form-group boxed">
                                                        <div class="input-wrapper">
                                                            <label class="form-label" for="image" style={{fontSize: '14px'}}>Upload Image</label>
                                                            <input type="file" class="form-control form-control-sm p-0 ps-1 text-start" id="image" placeholder="Image" autocomplete="off" onChange={(e) => {uploadRefImage(e.target.files[0])}} style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                            <i class="clear-input">
                                                                <ion-icon name="close-circle"></ion-icon>
                                                            </i>
                                                        </div>
                                                    </div>
                                                    <div class="mt-1 mb-1 d-flex" style={{overflow: 'scroll'}}>
                                                        {tempCustomOrderObj?.OrderDetails?.ReferenceImage.map((v,k) => {
                                                            return <div class="position-relative me-1 cursor-pointer" key={k}>
                                                                <img src={v} width="140px" height="140px" />
                                                                <span class="badge bg-danger position-absolute" onClick={() => {handleDeleteImage(k)}} style={{top: 0, right: 0, height: '30px'}}><ion-icon name="trash-outline" class="fs-5"></ion-icon></span>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-12">
                                                    <div class="form-group boxed">
                                                        <div class="input-wrapper">
                                                            <label class="form-label" for="discount" style={{fontSize: '14px'}}>Discount</label>
                                                        </div>
                                                        <div class="input-group">
                                                            <span class="input-group-text ps-1 pe-0 bg-white" id="basic-addon1" style={{height: '44px', border: '1px solid lightgray', borderRadius: '6px 0px 0px 6px'}}>₹</span>
                                                            <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" placeholder="Discount" value={tempCustomOrderObj?.TotalDiscount} onChange={(e) => {updateDiscount(e.target.value)}} aria-label="advance" aria-describedby="basic-addon1" required style={{minHeight: '44px', height: '44px', fontSize: '20px', borderLeft: 'none'}} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-md-6">
                                                    <div class="form-group boxed pb-0">
                                                        <div class="input-wrapper">
                                                            <label class="form-label" for="paymentMethod" style={{fontSize: '14px'}}>Payment Method</label>
                                                            <select class="form-control form-select-sm form-select" id="paymentMethod" value={paymentMethod} onChange={(e) => {updatePaymentMethod(e.target.value)}} style={{minHeight: '42px', height: '42px', fontSize: '20px'}} required>
                                                                <option value="">Select payment method</option>
                                                                <option value="Cash">Cash</option>
                                                                <option value="Card">Card</option>
                                                                <option value="UPI">UPI</option>
                                                                <option value="Payment-Gateway">Payment Gateway</option>
                                                                <option value="Cheque">Cheque</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-md-6">
                                                    <div class="form-group boxed">
                                                        <div class="input-wrapper">
                                                            <label class="form-label" for="advance" style={{fontSize: '14px'}}>Advance</label>
                                                        </div>
                                                        <div class="input-group">
                                                            <span class="input-group-text ps-1 pe-0 bg-white" id="basic-addon1" style={{height: '44px', border: '1px solid lightgray', borderRadius: '6px 0px 0px 6px'}}>₹</span>
                                                            <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" placeholder="Advance" value={tempCustomOrderObj?.Advance} onChange={(e) => {updateAdvance(e.target.value)}}aria-label="advance" aria-describedby="basic-addon1" required style={{minHeight: '44px', height: '44px', fontSize: '20px', borderLeft: 'none'}} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-12 col-md-3 order-1 order-md-2">
                                    <ul class="listview transparent simple-listview flush">
                                        <li style={{fontSize: '18px'}}>Subtotal <span class="hightext" style={{fontSize: '18px'}}>₹{tempCustomOrderObj?.Subtotal}</span></li>
                                        <li style={{fontSize: '18px'}}>
                                            CGST
                                            <span class="hightext" style={{fontSize: '18px'}}>
                                                ₹{(tempCustomOrderObj?.Cgst) ? tempCustomOrderObj?.Cgst : 0}
                                            </span>
                                        </li>
                                        <li style={{fontSize: '18px'}}>
                                            SGST
                                            <span class="hightext" style={{fontSize: '18px'}}>
                                                ₹{(tempCustomOrderObj?.Sgst) ? tempCustomOrderObj?.Sgst : 0}
                                            </span>
                                        </li>
                                        <li style={{fontSize: '18px'}}>Total<span class="totaltext text-primary" style={{fontSize: '18px'}}>₹{tempCustomOrderObj?.Subtotal}</span></li>
                                        <li style={{fontSize: '18px'}}>Balance<span class="hightext" style={{fontSize: '18px'}}>₹{tempCustomOrderObj?.Balance}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="fab-button text bottom-center responsive-bottom-fab">
                            <button class="fab btn-success fs-6 pe-2 ps-2" style={{height: '40px', fontFamily: "'Inter', sans-serif"}} disabled={disableSubmitButton}>
                                Update Order
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

const mapStateToProps = ({ data, auth, addUser }) => {
    const {
      businessData,
      items,
      editCustomOrderData
    } = data
    const {
      authToken
    } = auth
    const {
        userData
    } = addUser
    return {
      authToken,
      businessData,
      items,
      userData,
      editCustomOrderData
    }
  }
  
  const mapDispatchToProps = {
    setEditCustomOrderData
  }

export default connect(mapStateToProps, mapDispatchToProps)(OpticCustomOrderEdit)
