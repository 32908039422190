import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Moment from 'react-moment'
import { showOrderDetailModal } from '../../../features/Offcanvas/orderDetailModalSlice'
import { showSnapShotModal } from '../../../features/Offcanvas/SnapShotSlice'

const OrderTimelineView = ({
    showOrderDetailModal,
    allOrder,
    userValue,
    showSnapShotModal
}) => {
    const [allOrderList, setAllOrderList] = useState(null)
    const [orderTypeFilterName, setOrderTypeFilterName] = useState('Orders')
    const search = useLocation().search
    const pathname = window.location.pathname.split('/')
    const orderTypeFilter = new URLSearchParams(search).get('tab')
    useEffect(() => {
        if (allOrder !== undefined && allOrder !== null) {
            console.log(allOrder)
            setAllOrderList(allOrder)
        }
    }, [allOrder])
    const handleOrderDetailView = (orderDetail) => {
        showOrderDetailModal({orderDetailModalVisible: true, orderDetaildata: orderDetail})
    }
    const showShopSummarySnapShot = (shopSummarySnapShot) => {
        console.log(shopSummarySnapShot)
        showSnapShotModal({shopSummarySnapShotModalVisible: true, snapShotData: shopSummarySnapShot})
    }
    useEffect(() => {
        if (allOrderList !== null && allOrderList !== undefined) {
            if (orderTypeFilter === 'sales') {
                setOrderTypeFilterName('Orders')
            } else if (orderTypeFilter === 'purchase') {
                setOrderTypeFilterName('Purchase')
            } else if (orderTypeFilter === 'wastage') {
                setOrderTypeFilterName('Wastage')
            }
        }
    }, [orderTypeFilter, orderTypeFilterName])

    
    return (
        <>
            <div class="timeline timed">
                {(allOrderList !== null && allOrderList !== undefined && orderTypeFilterName !== null && orderTypeFilterName !== undefined && orderTypeFilterName !== '') ? allOrderList[orderTypeFilterName].slice(0).reverse().map((k,v) => {
                    let OrderType = orderTypeFilterName
                    let BorderColor = ''
                    if (OrderType === 'Orders') {
                        BorderColor = 'border-primary'
                    } else if (OrderType === 'Purchase') {
                        BorderColor = 'border-warning'
                    } else if (OrderType === 'Wastage') {
                        BorderColor = 'border-danger'
                    }
                    const tempK = JSON.parse(JSON.stringify(k))
                    // console.log(tempK)
                    tempK.quantityCount = 0
                    tempK.itemArr = []
                    tempK.Subtotal = 0
                    tempK.GrandTotal = 0
                    return <>
                        {(pathname[1] !== 'customOrders' && k.OrderType !== 'Custom') &&
                            <div class="item cursor-pointer" onClick={(tempK.Status == 't' || tempK.Status == 'f') ? (() => {showShopSummarySnapShot(tempK)}) : (() => {handleOrderDetailView(tempK)})}>
                                <span class="time" style={{left: '-85px'}}>
                                    <Moment format="hh:mm:ss A">{tempK.DateTime}</Moment>
                                </span>
                                {(tempK.OrderType === '' || tempK.OrderType === null || tempK.OrderType === undefined) && 
                                    <div class="dot bg-primary d-flex align-items-center justify-content-center" style={{height: '25px', width: '25px', left: '-12px', top: '40%' }}>
                                            <ion-icon name="receipt-outline" class="text-light" style={{fontSize: '13px'}}></ion-icon>
                                    </div>
                                }
                                {tempK.OrderType === 'Counter Sale' && 
                                    <div class="dot bg-secondary d-flex align-items-center justify-content-center" style={{height: '25px', width: '25px', left: '-12px', top: '40%' }}>
                                            <ion-icon name="storefront-outline" class="text-light" style={{fontSize: '13px'}}></ion-icon>
                                    </div>
                                }
                                {tempK.OrderType === 'Dine In' && 
                                    <div class="dot bg-warning d-flex align-items-center justify-content-center" style={{height: '25px', width: '25px', left: '-12px', top: '40%' }}>
                                        <ion-icon name="restaurant-outline" class="text-light" style={{fontSize: '13px'}}></ion-icon>
                                    </div>
                                }
                                {tempK.OrderType === 'Pick Up' && 
                                    <div class="dot bg-info d-flex align-items-center justify-content-center" style={{height: '25px', width: '25px', left: '-12px', top: '40%' }}>
                                        <ion-icon name="bag-handle-outline" class="text-light" style={{fontSize: '13px'}}></ion-icon>
                                    </div>
                                }
                                {tempK.OrderType === 'Zomato / Swiggy' && 
                                    <div class="dot bg-dark d-flex align-items-center justify-content-center" style={{height: '25px', width: '25px', left: '-12px', top: '40%' }}>
                                        <ion-icon name="phone-portrait-outline" class="text-light" style={{fontSize: '13px'}}></ion-icon>
                                    </div>
                                }
                                {tempK.OrderType === 'Custom' && 
                                    <div class="dot bg-danger d-flex align-items-center justify-content-center" style={{height: '25px', width: '25px', left: '-12px', top: '40%' }}>
                                        <ion-icon name="document-text-outline" class="text-light" style={{fontSize: '13px'}}></ion-icon>
                                    </div>
                                }
                                {tempK.Status === 't' && 
                                    <div class="dot bg-success d-flex align-items-center justify-content-center" style={{height: '25px', width: '25px', left: '-12px', top: '40%' }}>
                                        <ion-icon name="checkmark-outline" class="text-light" style={{fontSize: '13px'}}></ion-icon>
                                    </div>
                                }
                                {tempK.Status === 'f' && 
                                    <div class="dot bg-danger d-flex align-items-center justify-content-center" style={{height: '25px', width: '25px', left: '-12px', top: '40%' }}>
                                        <ion-icon name="close-outline" class="text-light" style={{fontSize: '13px'}}></ion-icon>
                                    </div>
                                }
                        
                                <div class="content d-flex align-items-center justify-content-between">
                                    <div>
                                        {(tempK.Status != 't' && tempK.Status != 'f') &&
                                            <>
                                                <div class="text">{(tempK.OrderType !== null && tempK.OrderType !== undefined) ? tempK.OrderType : 'Delivered'} . <Moment fromNow>{tempK.DateTime}</Moment></div>
                                                <h4 class="title">Bill No: {tempK.billNo} &nbsp;
                                                    <span class="text-secondary" style={{fontSize: '13px'}}>₹{k.GrandTotal} &nbsp;</span>
                                                    {tempK.BalanceAmount > 0 &&
                                                        <span class="text-danger">
                                                            (₹{tempK.BalanceAmount} Balanced)
                                                        </span>
                                                    }
                                                </h4>
                                            </>
                                        }
                                        {(tempK.Status == 't' || tempK.Status == 'f') &&
                                            <>
                                                <div class="text"><Moment fromNow>{tempK.DateTime}</Moment></div>
                                                {tempK.Status == 't' &&
                                                    <h4 class="title text-success fw-bold">Opened</h4>
                                                }
                                                {tempK.Status == 'f' &&
                                                    <h4 class="title text-danger fw-bold">Closed</h4>
                                                }
                                            </>
                                        }
                                        {(tempK.Status != 't' && tempK.Status != 'f') &&
                                            <div class="text">
                                                {tempK?.Orders?.map((v1,k1) => {
                                                    tempK.quantityCount += v1.TotalQty
                                                    tempK.Subtotal += v1.Subtotal
                                                    tempK.GrandTotal += v1.Total
                                                    {v1.Items.map((value,key) => {
                                                        if (!tempK?.itemArr?.hasOwnProperty(value.Item_ID)) {
                                                            tempK.itemArr[value.Item_ID] = {
                                                                "Image":value.Image,
                                                                "ItemTitle":value.ItemTitle,
                                                                "MRP":value.MRP,
                                                                "quantity": value.quantity,
                                                                "OrderDetails": (v1.OrderDetails) ? v1.OrderDetails : null
                                                            }
                                                        } else {
                                                            tempK.itemArr[value.Item_ID]['quantity'] = tempK.itemArr[value.Item_ID]['quantity'] + value.quantity 
                                                        }
                                                    })}
                                                })}
                                                {tempK.itemArr.map((v2,k2) => {
                                                    return <>
                                                        {(v2.Image !== '' && v2.Image !== null && v2.Image !== undefined) &&
                                                            // <img src={v2.Image} alt="avatar" class="imaged w24 rounded" style={{height: '24px', marginRight: '2px', objectFit: 'cover'}} />
                                                            <img src={v2.Image} alt="avatar" class="imaged w24 rounded" style={{marginRight: '2px'}} />
                                                        }
                                                        {(v2.Image === '' || v2.Image === null || v2.Image === undefined) &&
                                                            // <img src="https://us.123rf.com/450wm/viktorijareut/viktorijareut1503/viktorijareut150300119/37649514-grey-tray-kitchenware-trey-isolated-tray-vector-closed-tray.jpg?ver=6" alt="avatar" class="imaged w24 rounded" style={{height: '24px', marginRight: '2px', objectFit: 'cover'}} />
                                                            <img src="https://us.123rf.com/450wm/viktorijareut/viktorijareut1503/viktorijareut150300119/37649514-grey-tray-kitchenware-trey-isolated-tray-vector-closed-tray.jpg?ver=6" alt="avatar" class="imaged w24 rounded" alt="avatar" class="imaged w24 rounded" style={{marginRight: '2px'}} />
                                                        }
                                                    </>
                                                })}
                                                <span class="ms-1 badge bg-secondary" style={{height: '18px'}}>{tempK.quantityCount} Items</span>
                                            </div>
                                        }
                                    </div>
                                    {
                                        tempK.Status === -1 &&
                                        <div className='badge bg-danger me-1'>Cancelled</div>
                                    }
                                </div>
                            </div>
                        }
                        </>
                    })
                    :
                    null}
            </div>
        </>
    )
}

const mapStateToProps = () => {
    return {
    }
}
  
const mapDispatchToProps = {
    showOrderDetailModal,
    showSnapShotModal
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderTimelineView)
