import React, { useEffect, useState } from 'react'
import ReactDOM from "react-dom"
import { Chart as ChartJS, ArcElement, Tooltip, Legend , CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title} from 'chart.js'
import { Line, Bar, Doughnut } from 'react-chartjs-2'
import DataTable from "react-data-table-component"
import { connect } from "react-redux"
import { useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import axios from '../../AxiosConfig'
import '@splidejs/splide/dist/css/splide.min.css'
import Modal from 'react-bootstrap/Modal'
import { showAppToast } from '../../features/utilities/appToastSlice'
import { showMessageModal } from '../../features/Offcanvas/messageModalSlice'
import { showSwitchBusinessModal } from '../../features/Offcanvas/switchBusinessModalSlice'
import { setBusinessData, setTopProductList } from '../../features/Data/dataSlice'
import { triggerBusinessdDataCall } from '../../features/utilities/generalSlice'
import { showShopStatusModal } from '../../features/Offcanvas/shopStatusModalSlice'
import { hideMainMenuOffcanvas } from '../../features/Offcanvas/mainMenuOffcanvasSlice'
import ItemsListView from './Components/ItemsListView'
import { showShopDetailModal } from '../../features/Offcanvas/ShopDetailModalSlice'
import CategorySummaryTable from './BitComponents/CategorySummaryTable'
import OrderTypeSummaryTable from './BitComponents/OrderTypeSummaryTable'
import PaymentMethodSummaryTable from './BitComponents/PaymentMethodSummaryTable'
import CategorySizeSummaryTable from './BitComponents/CategorySizeSummaryTable'
import SearchWithButtons from './Components/SearchWithButtons'

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, PointElement, LineElement)

const Summary = ({
    showMessageModal,
    showSwitchBusinessModal,
    authToken,
    triggerFunction,
    triggerBusinessdDataCall,
    businessData,
    showShopStatusModal,
    hideMainMenuOffcanvas,
    items,
    role,
    showShopDetailModal,
    dayOrderFilter,
    branchID
}) => {
    const [summaryData, setSummaryData] = useState(null)
    const [branchName, setBranchName] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [businessLogo, setBusinessLogo] = useState('')
    const [viewFullScreen, setViewFullScreen] = useState(false)
    const [singleTopProductData, setSingleTopProductData] = useState(null)
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])
    const [sizeWiseData, setSizeWiseData] = useState([])
    const [categoryWiseData, setCategoryWiseData] = useState([])
    const [branchStatus, setBranchStatus] = useState('N/A')
    const tempShopStatus = localStorage.getItem('shopStatus')
    const localStoragetToken = localStorage.getItem('token')
    let navigate = useNavigate()
    const tokenData = jwt_decode(localStoragetToken)
    const GetOrderdate = () => {
        const currentDate = new Date()
        const hour = currentDate.getHours();
        if (hour < 3) {
            const previousDay = new Date(currentDate.setDate(currentDate.getDate()));
            return previousDay.toISOString().split('T')[0];
        } else {
            return currentDate.toISOString().split('T')[0];
        }
    }
    useEffect(() => {
        let tempColumnsData = [{
            id: 1,
            name: 'Date',
            selector: (row) => row.date,
            sortable: true,
            reorder: true
        },{
            id: 2,
            name: 'Sale',
            selector: (row) => row.sale,
            sortable: true,
            reorder: true
        }]
        setColumns(tempColumnsData)
        if (items === null || items === undefined || items === '') {
            navigate('/load')
        }
    }, [items])
    const handleReports = (name) => {
        showMessageModal({messageModalVisible: true, message: name + ' Coming Soon !!'})
    }
    const handleBusinessSwitch = () => {
        showSwitchBusinessModal({switchBusinessModalVisible: true})
    }
    useEffect(() => {
        const tokenData = jwt_decode(authToken)
        setTimeout(() => {
            triggerBusinessdDataCall({triggerFunction: false})
        }, 5000);
        if (businessData) {
            businessData.map((value, key) => {
                value.Branches.map((v,k) => {
                    if (value.ID === tokenData.userInfo.Business_ID && v.ID === tokenData.userInfo.DefaultLocationID) {
                        setBranchName(v.Name)
                        setBusinessName(value.Name)
                        setBranchStatus(v.ShopStatus)
                        setBusinessLogo(value.Logo)
                    }
                })
            })   
        }
    }, [authToken, triggerFunction, businessData])
    const handleTopProductView = (status, data) => {
        setSingleTopProductData(data)
        if (status === 'open') {
            setViewFullScreen(true)
            setTimeout(() => {
                setViewFullScreen(false)
            }, 5000)
        } else if (status === 'closed') {
            setViewFullScreen(false)
        }
    }
    const handleShopStatusModal = () => {
        showShopStatusModal({shopStatusModalVisible: true})
        hideMainMenuOffcanvas()
    }
    const data = {
        labels: ['Cake', 'Pastry', 'Half', 'Full'],
        datasets: [
          {
            label: '# of Counts',
            data: [120, 190, 300, 55],
            backgroundColor: [
              'rgba(255, 99, 132)',
              'rgba(54, 162, 235)',
              'rgba(255, 206, 86)',
              'rgba(75, 192, 192)',
              'rgba(153, 102, 255)',
              'rgba(255, 159, 64)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
    };
    const handleDetailView = () => {
        showShopDetailModal({shopDetailModalVisible: true, salesData: summaryData})
    }
    useEffect(() => {
        let dateFilterFrom = GetOrderdate()
        let dateFilterTo = GetOrderdate()
        if (dayOrderFilter === 'Today') {
            dateFilterFrom = GetOrderdate()
            dateFilterTo = GetOrderdate()
        } else if (dayOrderFilter === 'Yesterday') {
            dateFilterFrom = (new Date(new Date().setDate(new Date().getDate()-1)).toISOString().slice(0, 10))
            dateFilterTo = (new Date(new Date().setDate(new Date().getDate()-1)).toISOString().slice(0, 10))
        } else if (dayOrderFilter === 'Last7') {
            dateFilterFrom = (new Date(new Date().setDate(new Date().getDate()-7)).toISOString().slice(0, 10))
            dateFilterTo = GetOrderdate()
        } else if (dayOrderFilter === 'Last30') {
            dateFilterFrom = (new Date(new Date().setDate(new Date().getDate()-30)).toISOString().slice(0, 10))
            dateFilterTo = GetOrderdate()
        } else if (dayOrderFilter === 'All') {
            dateFilterFrom = 'All'
            dateFilterTo = 'All'
        } else if (dayOrderFilter.includes('-')) {
            dateFilterFrom = dayOrderFilter
            dateFilterTo = dayOrderFilter
        }
        let tempRowsData = []
        axios.get('/Reports/dashboardSummary/' + dateFilterFrom + '/' + dateFilterTo + '/' + branchID).then(res => {
            if (res.data.message === 'Success') {
                setSummaryData(res.data.data)
                Object.keys(res.data.data.SalesReport).map((v,k) => {
                    tempRowsData.push({
                        id: k + 1,
                        date: v,
                        sale: '₹' + res.data.data.SalesReport[v]
                    })
                })
                setRows(tempRowsData)
            } else {
                showAppToast({ toastMessage: 'Something went wrong', background: 'danger', icon: 'close', position: 'center', timeout: 1000 })
            }
        }).catch(err => {
            showAppToast({ toastMessage: 'Something went wrong', background: 'danger', icon: 'close', position: 'center', timeout: 1000 })
        });
        axios.get('/Reports/viewDailyCounters/' + dateFilterFrom + '/' + dateFilterTo + '/' + branchID).then(res => {
            if (res.data.message === 'Success') {
                setSizeWiseData(res.data.data)
            }
        })
        axios.get('/Reports/getSaleCategoryWise/' + dateFilterFrom + '/' + dateFilterTo + '/' + branchID).then(res => {
            if (res.data.message === 'Success') {
                setCategoryWiseData(res.data.data)
            }
        })
    }, [dayOrderFilter, branchID])
    return (
        <div id="appCapsule" style={{paddingTop: '152px'}}>
            <div class="extraHeader p-0" style={{zIndex: 10, display: 'block'}}>
                <div class="section full">
                    <ul class="listview image-listview">
                        <li>
                            <div class="item">
                                {(businessLogo !== null && businessLogo !== undefined) &&
                                    <img src={businessLogo} alt="image" class="image rounded-0" />
                                }
                                {(businessLogo === null || businessLogo === undefined) &&
                                    // <img src={businessLogo} alt="image" class="image rounded-0" />
                                    <ion-icon name="storefront-outline" class="me-1" style={{fontSize: '32px'}}></ion-icon>
                                }
                                <div class="in">
                                    <div style={{lineHeight: 1.2}}>
                                        {businessName}
                                        {(tokenData?.userInfo?.RoleID !== '5') &&
                                            <footer onClick={handleBusinessSwitch}><ion-icon name="location-outline" class="me-0"></ion-icon>&nbsp;{branchName}&nbsp;<span class={(branchStatus === 'open') ? "badge bg-success fs-10" : "badge bg-danger fs-10"} style={{height: '15px'}}>{(branchStatus === 'open') ? 'Open' : 'Close'}</span></footer>
                                        }
                                        {(tokenData?.userInfo?.RoleID === '5') &&
                                            <footer><ion-icon name="location-outline" class="me-0"></ion-icon>&nbsp;{branchName}&nbsp;<span class={(branchStatus === 'open') ? "badge bg-success fs-10" : "badge bg-danger fs-10"} style={{height: '15px'}}>{(branchStatus === 'open') ? 'Open' : 'Close'}</span></footer>
                                        }
                                    </div>
                                </div>
                                {role != '6' &&
                                    <span class="text-muted">
                                        {tempShopStatus === 'Open' &&
                                            <button class="bg-danger btn btn-sm fs-10" onClick={handleShopStatusModal}>
                                                <ion-icon name="log-out-outline"></ion-icon>
                                                SHOP CLOSE
                                            </button>
                                        }
                                        {tempShopStatus === 'Close' &&
                                            <button class="bg-success btn btn-sm fs-10" onClick={handleShopStatusModal}>
                                                <ion-icon name="log-in-outline"></ion-icon>
                                                SHOP OPEN
                                            </button>
                                        }
                                    </span>
                                }
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="extraHeader p-0" style={{zIndex: 10, height: '40px', top: '112px'}}>
                <ul class="nav nav-tabs lined" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="#overview" role="tab" style={{height: '40px'}}>
                            Overview
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#insights" role="tab" style={{height: '40px'}}>
                            Insights
                        </a>
                    </li>
                </ul>
            </div>
            <div class="tab-content mt-1">
                <div class="tab-pane fade show active" id="overview" role="tabpanel">
                    <div class="section full mt-1">
                        <div class="row mb-1">
                            <div class="col-12 col-md-12">
                                <SearchWithButtons pageName={'Summary'}></SearchWithButtons>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                <div class="card">
                                    <div class="card-body p-2 pb-1 pt-1">
                                        <div class="row ps-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center justify-content-between">
                                                <p class="card-text m-0 text-secondary fs-8">Total Sale</p>
                                                <span class="cursor-pointer" onClick={() => {handleDetailView()}}>
                                                    <ion-icon name="information-circle-outline" class="text-primary fs-5"></ion-icon>
                                                </span>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <h3 class="m-0 text-success fs-3">₹{summaryData?.Orders?.TotalSale}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                <div class="card">
                                    <div class="card-body p-2 pb-1 pt-1">
                                        <div class="row ps-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                <p class="card-text m-0 text-secondary fs-8">Total Expense</p>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <h3 class="m-0 text-danger fs-3">₹{summaryData?.Transactions?.TotalExpense}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                <div class="card">
                                    <div class="card-body p-2 pb-1 pt-1">
                                        <div class="row ps-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                <p class="card-text m-0 text-secondary fs-8">Customer Credit</p>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.BalanceAmount}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                <div class="card">
                                    <div class="card-body p-2 pb-1 pt-1">
                                        <div class="row ps-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                <p class="card-text m-0 text-secondary fs-8">Total Orders</p>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <h3 class="m-0 text-primary fs-3">{summaryData?.Orders?.TotalOrder}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                <div class="card">
                                    <div class="card-body p-2 pb-1 pt-1">
                                        <div class="row ps-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                <p class="card-text m-0 text-secondary fs-8">Custom Orders</p>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <h3 class="m-0 text-primary fs-3">{summaryData?.Orders?.CustomOrderCount}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                <div class="card">
                                    <div class="card-body p-2 pb-1 pt-1">
                                        <div class="row ps-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                <p class="card-text m-0 text-secondary fs-8">Total Wastage</p>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <h3 class="m-0 text-primary fs-3">₹0</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div class="listview-title mt-0 ps-0">Order Type</div>
                        <div class="row">
                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                <div class="card">
                                    <div class="card-body p-2 pb-1 pt-1">
                                        <div class="row ps-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                <p class="card-text m-0 text-secondary fs-8">Counter Sale</p>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.OrderType?.CounterSale}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                <div class="card">
                                    <div class="card-body p-2 pb-1 pt-1">
                                        <div class="row ps-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                <p class="card-text m-0 text-secondary fs-8">DineIn</p>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.OrderType?.DineIn}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                <div class="card">
                                    <div class="card-body p-2 pb-1 pt-1">
                                        <div class="row ps-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                <p class="card-text m-0 text-secondary fs-8">Pick Up</p>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.OrderType?.Parcel}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                <div class="card">
                                    <div class="card-body p-2 pb-1 pt-1">
                                        <div class="row ps-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                <p class="card-text m-0 text-secondary fs-8">Zomato / Swiggy</p>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.OrderType?.Online}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                <div class="card">
                                    <div class="card-body p-2 pb-1 pt-1">
                                        <div class="row ps-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                <p class="card-text m-0 text-secondary fs-8">Custom Order Advance</p>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <h3 class="m-0 text-primary fs-3">₹{(summaryData?.Orders?.OrderType?.CustomOrderAdvance) ? summaryData?.Orders?.OrderType?.CustomOrderAdvance : 0}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                <div class="card">
                                    <div class="card-body p-2 pb-1 pt-1">
                                        <div class="row ps-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                <p class="card-text m-0 text-secondary fs-8">Custom Order Balance / Customer Dues</p>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <h3 class="m-0 text-primary fs-3">₹{(summaryData?.Orders?.OrderType?.CustomOrderBalance) ? summaryData?.Orders?.OrderType?.CustomOrderBalance : 0 } <span class="fs-5 text-secondary">/ {(summaryData?.Orders?.OrderType?.TotalCustomOrderBalance) ? summaryData?.Orders?.OrderType?.TotalCustomOrderBalance : 0 }</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div class="row">
                            <div class="col-12 col-md-4 mb-2">
                                <div class="card">
                                    <div class="card-body p-0">
                                        <CategorySummaryTable sizeWiseData={sizeWiseData}></CategorySummaryTable>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <div class="card">
                                    <div class="card-body p-0">
                                        <OrderTypeSummaryTable salesData={summaryData}></OrderTypeSummaryTable>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <div class="card">
                                    <div class="card-body p-0">
                                        <PaymentMethodSummaryTable paymentData={summaryData}></PaymentMethodSummaryTable>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-12 mb-2">
                                <div class="card">
                                    <div class="card-body p-0">
                                        <CategorySizeSummaryTable categoryWiseData={categoryWiseData}></CategorySizeSummaryTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div class="row">
                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                <div class="card">
                                    <div class="card-body p-2 pb-1 pt-1">
                                        <div class="row ps-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                <p class="card-text m-0 text-secondary fs-8">Cash</p>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.PaymentMethod?.Cash}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                <div class="card">
                                    <div class="card-body p-2 pb-1 pt-1">
                                        <div class="row ps-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                <p class="card-text m-0 text-secondary fs-8">UPI</p>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.PaymentMethod?.UPI}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                <div class="card">
                                    <div class="card-body p-2 pb-1 pt-1">
                                        <div class="row ps-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                <p class="card-text m-0 text-secondary fs-8">Card</p>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.PaymentMethod?.Card}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                <div class="card">
                                    <div class="card-body p-2 pb-1 pt-1">
                                        <div class="row ps-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                <p class="card-text m-0 text-secondary fs-8">Cheque</p>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.PaymentMethod?.Cheque}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                <div class="card">
                                    <div class="card-body p-2 pb-1 pt-1">
                                        <div class="row ps-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                <p class="card-text m-0 text-secondary fs-8">Payment Gateway</p>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.PaymentMethod?.Online}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 mb-2">
                                <div class="card">
                                    <div class="card-body p-2 pb-1 pt-1">
                                        <div class="row ps-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                                <p class="card-text m-0 text-secondary fs-8">Other</p>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <h3 class="m-0 text-primary fs-3">₹{summaryData?.Orders?.PaymentMethod?.Other}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div class="tab-pane fade" id="insights" role="tabpanel" style={{marginLeft: '-8px', marginRight: '-8px'}}>
                    <h1 class="text-danger text-center mt-3">Under Construction</h1>
                    {/* <div class="section mb-2">
                        <div class="wide-block p-0 border">
                            <DataTable
                                columns={columns}
                                data={rows}
                                defaultSortFieldId={1}
                            />
                        </div>
                    </div>
                    <div class="section mb-2">
                        <div class="row">
                            <div class="col-12 col-md-6 mb-2">
                                <div class="card">
                                    <div class="card-body">
                                        <Doughnut data={data} />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 mb-2">
                                <div class="card">
                                    <div class="card-body">
                                        <Bar data={data} />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 mb-2">
                                <div class="card">
                                    <div class="card-body">
                                        <Line data={data} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <Modal show={viewFullScreen} backdrop={true} className="modal fade stories" style={{transition: '0.5s'}}>
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="story-image">
                            <img src={singleTopProductData?.Image} class="w-100" alt="image" style={{objectFit: 'contain'}} />
                        </div>
                        <Modal.Header>
                            <a href="javscript:void(0)" class="profile-detail">
                                {/* <img src={singleTopProductData?.Image} alt="avatar" class="imaged w36 rounded me-1" /> */}
                                <div>
                                    <h4>{singleTopProductData?.Title}</h4>
                                    <span class="text-muted">₹{singleTopProductData?.MRP}</span>
                                </div>
                            </a>
                            <a href="javscript:void(0)" data-bs-dismiss="modal" class="close-stories" onClick={() => {handleTopProductView('closed')}}>
                                <ion-icon name="close"></ion-icon>
                            </a>
                        </Modal.Header>
                        <Modal.Body class="modal-body"></Modal.Body>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

const mapStateToProps = ({ auth, general, data }) => {
    const {
        authToken,
        role
    } = auth
    const {
        triggerFunction
    } = general
    const {
        topProductList,
        businessData,
        items,
        dayOrderFilter,
        branchID
    } = data
    return {
        authToken,
        triggerFunction,
        topProductList,
        businessData,
        items,
        role,
        dayOrderFilter,
        branchID
    }
}

const mapDispatchToProps = {
    showMessageModal,
    showSwitchBusinessModal,
    setBusinessData,
    triggerBusinessdDataCall,
    setTopProductList,
    showShopStatusModal,
    hideMainMenuOffcanvas,
    showShopDetailModal
}

export default connect(mapStateToProps, mapDispatchToProps)(Summary)
