import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    addTransactionModalVisible: false,
    transactionData: null,
    addBalanceAmountModalVisible: false,
    balanceData: null
}

export const AddTransactionModalSlice = createSlice({
    name: 'addTransaction',
    initialState,
    reducers: {
      showAddTransactionModal: (state, action) => {
        const {
            addTransactionModalVisible,
            transactionData
        } = action.payload
        state.addTransactionModalVisible = addTransactionModalVisible
        state.transactionData = transactionData
      },
      hideAddTransactionModal: (state) => {
        state.addTransactionModalVisible = false
      },
      showAddBalanceAmountModal: (state, action) => {
        const {
            addBalanceAmountModalVisible,
            balanceData
        } = action.payload
        state.addBalanceAmountModalVisible = addBalanceAmountModalVisible
        state.balanceData = balanceData
      },
      hideAddBalanceAmountModal: (state) => {
        state.addBalanceAmountModalVisible = false
      }
    }
})

export const { showAddTransactionModal, hideAddTransactionModal, hideAddBalanceAmountModal, showAddBalanceAmountModal } = AddTransactionModalSlice.actions;


export default AddTransactionModalSlice.reducer