import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    discountModalVisible: false
}

export const DiscountModalSlice = createSlice({
    name: 'discount',
    initialState,
    reducers: {
        showDiscountModal: (state, action) => {
        const {
            discountModalVisible
        } = action.payload
        state.discountModalVisible = discountModalVisible
      },
      hideDiscountModal: (state) => {
        state.discountModalVisible = false
      }
    }
})

export const { showDiscountModal, hideDiscountModal } = DiscountModalSlice.actions;


export default DiscountModalSlice.reducer