import React, { useState } from 'react'
import { connect } from "react-redux"
import { setPhoneNumber } from '../../../features/Data/dataSlice'
import AddUserModal from '../../layout/Sidebar/AddUserModal'
import { showAddUserModal, setUserData, checkMobileNumber } from '../../../features/Offcanvas/addUserSlice'
import axios from '../../../AxiosConfig' 
import { showAppToast } from '../../../features/utilities/appToastSlice'

const CheckoutMobileNo = ({
    setPhoneNumber,
    showAddUserModal,
    setUserData,
    showAppToast,
    checkMobileNumber
}) => {
    const [contactBook, setContactBook] = useState(null)
    const [mobileNumberValue, setMobileNumberValue] = useState(null)
    const [contactLoading, setContactLoading] = useState(false)

    const checkExisitingNo = (mobileFlag) => {
        setMobileNumberValue(mobileFlag)
        setContactLoading(true)
        if (mobileFlag !== null && mobileFlag !== '' && mobileFlag.length >= 10) {
            checkMobileNumber({mobileNumberExist: true})
            setContactBook(true)
            setContactLoading(false)
            axios.get('/Customers/search/' + mobileFlag).then(res => {
                if (res.data.message === 'Success') {
                    if (res.data.data.length > 0) {
                        setUserData({userData: res.data.data[0]})
                    } else {
                        setUserData({userData: {'ContactNumber': mobileFlag}})
                        // showAddUserModal({addUserVisible: true})
                    }
                } else {
                  showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
                }
            })
            
        } else {
            checkMobileNumber({mobileNumberExist: false})
            setContactBook(false)
            if (mobileFlag === '' && mobileFlag.length === 0) {
                setContactLoading(false)
            }
        }
        setPhoneNumber({phone: mobileFlag})
    }

    const handleAddUser = () => {
        showAddUserModal({addUserVisible: true})
    }

    const selectContact = (userID, number) => {
        setPhoneNumber({phone: number})
        setMobileNumberValue(number)
    }
    return (
        <>
            <div class="form-group searchbox">
                <input type="text" onChange={(e) => {checkExisitingNo(e.target.value)}} class="form-control" value={mobileNumberValue} placeholder="Enter Mobile No" />
                <i class="input-icon">
                    {contactLoading === false &&
                        <ion-icon name="call-outline" class="fs-5"></ion-icon>
                    }
                    {contactLoading &&
                        <div class="spinner-border text-secondary" role="status" style={{width: '20px', height: '20px', borderWidth: '2px'}}>
                        </div>
                    }
                </i>
                {(contactBook === false || contactBook === null || contactBook === undefined) &&
                    <i class="input-icon" style={{right: 0, left: 'auto'}}>
                        <ion-icon name="search-outline" class="fs-5"></ion-icon>
                    </i>
                }
                {contactBook === true &&
                    <i class="input-icon" style={{right: 0, left: 'auto'}} onClick={() => {handleAddUser()}}>
                        <ion-icon name="person-add-outline" class="fs-5"></ion-icon>
                    </i>
                }
            </div>
            <AddUserModal></AddUserModal>
        </>
    )
}

const mapStateToProps = () => {
    return {
    }
  }
  
const mapDispatchToProps = {
    setPhoneNumber,
    showAddUserModal,
    setUserData,
    showAppToast,
    checkMobileNumber
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutMobileNo)
