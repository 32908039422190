import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { hideCategoryModal } from '../../../features/Offcanvas/categoryFullModalSlice'
import { categoryIdUpdate, setCategoryName, setTempCategoryId } from '../../../features/Data/dataSlice'

const CategoryFullModal = ({
    categoryModalVisible,
    hideCategoryModal,
    categories,
    categoryIdUpdate,
    setCategoryName,
    setTempCategoryId,
    tempCategoryId
}) => {
    // const [ID, setID] = useState(null)
    useEffect(() => {
        if (tempCategoryId === null) {
            categoryIdUpdate({categoryID: 'all'})
        }
    }, [categoryModalVisible])
    const handleCategorySwitch = (categoryID, categoryName) => {
        categoryIdUpdate({categoryID: (categoryID === undefined || categoryID === null || categoryID === '') ? 1 : categoryID})
        // setID(categoryID)
        setTempCategoryId({tempCategoryId: categoryID})
        setCategoryName({categoryName: categoryName})
        hideCategoryModal()
    }
    return (
        <Modal show={categoryModalVisible} fullscreen={true} onHide={() => {hideCategoryModal()}} style={{transition: '0.4s'}}>
            <Modal.Header closeButton class="modal-header">
                <Modal.Title>
                    <div class="fs-6">Categories</div>
                </Modal.Title>
            </Modal.Header>
            {(categories !== null && categories !== undefined) &&
                <Modal.Body class="modal-body">
                    <div class="row">
                        {/* <div class="col col-md-2 mb-1">
                            <div class={`${(ID === null || ID === 'all') ? 'card shadow-none border-secondary' : 'card shadow-none webweb-bg-secondary'}`} onClick={() => {handleCategorySwitch('all')}} style={{maxWidth: '160px', minWidth: '160px'}}>
                                <div class="card-body d-flex align-items-center justify-content-center p-1">
                                    <p class="mb-0 fs-8 d-flex justify-content-center">All</p>
                                </div>
                            </div>
                        </div> */}
                        {categories.map(value => {
                            return <div class="col col-md-2 mb-1">
                                <div class={`btn ${(tempCategoryId === value.ID) ? 'card shadow-none border-secondary' : 'card shadow-none webweb-bg-secondary'}`} onClick={() => {handleCategorySwitch(value.ID, value.Name)}} style={{maxWidth: '160px', minWidth: '160px'}}>
                                    <div class="card-body d-flex align-items-center justify-content-center p-1">
                                        <p class="mb-0 fs-8 d-flex justify-content-center">{value.Name}</p>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </Modal.Body>
            }
        </Modal>
    )
}

const mapStateToProps = ({ category, data }) => {
    const {
        categoryModalVisible
    } = category
    const {
        categories,
        tempCategoryId
    } = data
    return {
        categoryModalVisible,
        categories,
        tempCategoryId
    }
}
  
const mapDispatchToProps = {
    hideCategoryModal,
    categoryIdUpdate,
    setCategoryName,
    setTempCategoryId
}

export default connect(mapStateToProps,mapDispatchToProps)(CategoryFullModal)