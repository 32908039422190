import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from "react-redux"
import { hideCheckoutOffcanvas } from '../../../features/Offcanvas/checkoutOffcanvasSlice'
import CheckoutComponent from '../../Pages/Components/CheckoutComponent'

const CheckoutOffcanvas = ({
    checkoutOffcanvasVisible,
    hideCheckoutOffcanvas,
    topScroll,
    itemList,
    checkoutButton,
    pageName
}) => {
    return (
        <div>
            <Offcanvas show={checkoutOffcanvasVisible} placement={'end'} onHide={() => {hideCheckoutOffcanvas()}} style={{transition: '0.4s'}}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{pageName}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body class="pt-0 offcanvas-body">
                    <CheckoutComponent topScroll={topScroll} mobileNo={true} itemList={itemList} checkoutButton={checkoutButton} pageName={pageName}></CheckoutComponent>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

const mapStateToProps = ({ checkout }) => {
    const {
        checkoutOffcanvasVisible
    } = checkout
    return {
        checkoutOffcanvasVisible
    }
  }
  
  const mapDispatchToProps = {
    hideCheckoutOffcanvas
  }

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutOffcanvas)