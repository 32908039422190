import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useNavigate } from 'react-router-dom'
import { connect } from "react-redux"
import { hideAddItemOptionOffcanvas } from '../../../features/Offcanvas/addStockOptionOffcanvasSlice'
import { showFileUploadModal } from '../../../features/Offcanvas/imageEditModalSlice'

const AddItemOptionOffcanvas = ({
    hideAddItemOptionOffcanvas,
    addItemOptionVisible,
    showFileUploadModal
}) => {
    let navigate = useNavigate()
    const handleAddItem = (type) => {
        if (type === 'single') {
            navigate('/itemProfile')
            hideAddItemOptionOffcanvas()
        } else if (type === 'bulk') {
            showFileUploadModal({fileUploadModalVisible: true})
            hideAddItemOptionOffcanvas()
        }
    }
    return (
        <div>
            <Offcanvas show={addItemOptionVisible} placement={'bottom'} style={{height: 'auto', transition: '0.4s'}}>
                <Offcanvas.Header closeButton style={{minHeight: '36px'}} onHide={() => {hideAddItemOptionOffcanvas()}}>
                    <div class="d-flex justify-content-between w-100">
                        <Offcanvas.Title style={{display: 'flex', alignItems: 'center'}}>Add Item Action Type</Offcanvas.Title>
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body class="offcanvas-body">
                    <div class="section">
                        <div class="d-flex">
                            <button class="btn btn-outline-secondary me-1" onClick={() => {handleAddItem('single')}}>Add Single Item</button>
                            <button class="btn btn-outline-secondary" onClick={() => {handleAddItem('bulk')}}>Add Bulk Items</button>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

const mapStateToProps = ({ addStockOption }) => {
    const {
        addItemOptionVisible
    } = addStockOption
    return {
        addItemOptionVisible
    }
}
  
const mapDispatchToProps = {
    hideAddItemOptionOffcanvas,
    showFileUploadModal
}

export default connect(mapStateToProps, mapDispatchToProps)(AddItemOptionOffcanvas)