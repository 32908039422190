import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { setCartDraftID, cartDraftIndex, cartOrderIndex, checkoutItemType, setActiveDraftCart } from '../../../features/Data/dataSlice'
import { updateCart, tempPrice, resetPrice, updatePrice } from '../../../features/state/checkoutItemsSlice'
import { hideDraftCartListModal } from '../../../features/Offcanvas/draftCartListModalSlice'
import { showOrderTypeModal } from '../../../features/Offcanvas/orderTypeModalSlice'

const DraftCartListModal = ({
    draftData,
    renderHeaderDraft,
    setCartDraftID,
    updateCart,
    tempPrice, 
    resetPrice, 
    updatePrice,
    cartDraftIndex, 
    cartOrderIndex, 
    checkoutItemType,
    draftCartListModalVisible,
    hideDraftCartListModal,
    setActiveDraftCart,
    activeDraftID,
    showOrderTypeModal
}) => {
    const [cartDraftList, setCartDraftList] = useState(null)
    let navigate = useNavigate()
    useEffect(() => {
        setCartDraftList(draftData)
    }, [draftData, renderHeaderDraft])
    const updateDraftCart = (currentCartData, key) => {
        setActiveDraftCart({activeDraftID: key})
        navigate('/newOrder')
        if (currentCartData?.Status !== -2) {
            let tempCurrentCartData = []
            let tempCurrentCartDataObj = JSON.parse(JSON.stringify(currentCartData))
            tempCurrentCartDataObj?.Orders.map((val, key) => {
                tempCurrentCartDataObj?.Orders[key]?.Items?.map((v,k) => {
                    v.ImageURL = v.Image
                    v.ID = v.Item_ID
                    v.MRP = parseInt(v.MRP)
                    tempCurrentCartData.push({
                        'item' : v,
                        'quantity' : v.quantity,
                        'OrderStatus' : val.OrderStatus,
                        'OrderIndex' : key
                    })
                    setCartDraftID({cartDraftID: key})
                })
            })
            updateCart(tempCurrentCartData)
            tempPrice({tempSubtotal: currentCartData.Amount, tempBalance: currentCartData.BalanceAmount})
        }
        if (currentCartData?.Status === -1 || currentCartData?.Status === 0) {
            cartOrderIndex({odIndex: 0})
        }
        cartDraftIndex({draftIndex: key})
        checkoutItemType({type: currentCartData?.OrderType})
        updatePrice({
            subtotal : currentCartData?.Amount,
            cgst : 0,
            sgst : 0})
        hideDraftCartListModal()
    }
    const handleCartAction = (actionType, key, value) => {
        navigate('/newOrder')
        setActiveDraftCart({activeDraftID: key})
        if (actionType === 'add') {
            updateCart([])
            tempPrice({tempSubtotal: 0, tempBalance: 0})
            cartDraftIndex({draftIndex: key})
            cartOrderIndex({odIndex: 0})
            resetPrice('')
        } else {
            // console.log('updateCart')
        }
        if (value.OrderType === '' || value.OrderType === null || value.OrderType === undefined) {
            showOrderTypeModal({orderTypeModalVisible: true, reference: 'NewOrder'})
        }
        hideDraftCartListModal()
    }
    return (
        <div class="draftCartListModal">
            <Modal show={draftCartListModalVisible} fullscreen={true} centered={true} backdrop="true" onHide={() => {hideDraftCartListModal()}}>
                <Modal.Header closeButton style={{padding: '15px 15px 0px 15px', backgroundColor: '#e7e7e7', border: 'none'}}>
                    <div class="row w-100 position-relative">
                        <div class="col-md-7 col-6 pe-3">
                            Live Orders
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body class="modal-body webweb-bg-secondary" style={{position: 'relative'}}>
                    {/* <div class="rounded-circle bg-white text-dark d-flex justify-content-center align-items-center" style={{position: 'absolute', right: -45, top: 0, height: '32px', width: '32px', cursor: 'pointer'}}>
                        <ion-icon name="close-outline" class="fs-4"></ion-icon>
                    </div> */}
                    <div class="section mt-1 p-0">
                        <div class="row">
                            {(cartDraftList !== null && cartDraftList !== undefined && cartDraftList.length > 0) &&
                                <>
                                    {cartDraftList.map((value,key) => {
                                        let currentStatus = value.Status
                                        let borderColor = 'border-secondary'
                                        let bgColor = ''
                                        var varDate = new Date('2023-11-01').toISOString().slice(0, 10);
                                        if (currentStatus === -1 && value.TotalQuantity > 0) {
                                            borderColor = 'border-secondary'
                                            bgColor = 'btn-light'
                                        } else if (currentStatus === 0 && value.TotalQuantity > 0) {
                                            borderColor = 'allpos-border-secondary'
                                            bgColor = 'allpos-bg-secondary'
                                        }
                                        return <>
                                            {/* {(value.Date === null || value.Date === '' || value.Date === undefined || value.Date >= varDate) &&
                                                <> */}
                                                    {value.Status !== 1 &&
                                                        <div class="col-4 col-md-3 col-lg-2 mb-2 d-flex align-items-stretch">
                                                            <div class={(activeDraftID !== key) ? `card rounded shadow-none ${borderColor} ${bgColor} w-100` : `card rounded shadow-none border-success w-100`} onClick={(value.Status === -2) ? () => {handleCartAction('add', key, value)} : () => {updateDraftCart(value, key)}}>
                                                                <div class="card-body d-flex justify-content-center align-items-center p-1 text-center flex-column position-relative" style={{minWidth: '96px', minHeight: '53px'}}>
                                                                    <h6 class="mb-0 fs-10 fw-bolder rounded-circle bg-primary d-flex align-items-center justify-content-center" style={{height: '22px', width: '22px'}}>
                                                                        {/* {key + 1} */}
                                                                        {String.fromCharCode(64 + (key + 1))}
                                                                    </h6>
                                                                    {(value.OrderType !== '' && value.OrderType !== null && value.OrderType !== undefined) &&
                                                                        <h6 class="mb-0 fw-light">
                                                                            <span class="fs-10">{value.OrderType}</span>
                                                                        </h6>
                                                                    }
                                                                    {value.TotalQuantity > 0 &&
                                                                        <span class="badge bg-white text-dark fs-10" style={{height: '16px', minWidth: '16px', backgroundColor: '#e3c20e', position: 'absolute', top: -5, right: -5}}>{value.TotalQuantity}</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                {/* </>
                                            } */}
                                        </>
                                    })}
                                </>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ data, draftCartList }) => {
    const {
        draftData,
        renderHeaderDraft,
        activeDraftID
    } = data
    const {
        draftCartListModalVisible
    } = draftCartList
    return {
        draftData,
        renderHeaderDraft,
        draftCartListModalVisible,
        activeDraftID
    }
  }
  
  const mapDispatchToProps = {
    setCartDraftID,
    updateCart,
    tempPrice, 
    resetPrice, 
    updatePrice,
    cartDraftIndex, 
    cartOrderIndex, 
    checkoutItemType,
    hideDraftCartListModal,
    setActiveDraftCart,
    showOrderTypeModal
  }

export default connect(mapStateToProps, mapDispatchToProps)(DraftCartListModal)