import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from '../../../AxiosConfig' 
import { connect } from "react-redux"
import "cropperjs/dist/cropper.css"
import { hideFileUploadModal } from '../../../features/Offcanvas/imageEditModalSlice'

const FileUploadModal = ({
    hideFileUploadModal,
    fileUploadModalVisible
}) => {
    const [uploadingLoader, setUploadingLoader] = useState(false)
    const uploadCsv = (filePath) => {
        const formData = new FormData();
        formData.append('inputFile', filePath)
        setUploadingLoader(true)
        setTimeout(() => {
            setUploadingLoader(false)
        }, 5000);
        if (filePath !== null && filePath !== undefined && filePath !== null) {
            axios.post('/Items/bulkAdd', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then(res => {
            }).catch(err => {
            })
        }
    }

    const downloadSampleCsv = () => {
        let anchor = document.createElement('a');
        anchor.href = 'https://webweb.ams3.cdn.digitaloceanspaces.com/allpos/Sample-CSV-For-Bulk-Upload.csv'
        anchor.target = '_blank';
        anchor.download = 'laptops.csv';
        anchor.click();
    }
    return (
        <div>
            <Modal show={fileUploadModalVisible} backdrop='static' centered={true}>
                <Modal.Header closeButton style={{ padding: '10px 15px 10px 15px' }} onHide={() => {hideFileUploadModal()}}>
                    <div class="row w-100 position-relative">
                        <div class="col-md-12 col-12 d-flex align-items-center">
                            Upload File
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body class="pt-0 modal-body">
                    <div class="section mt-1 p-0">
                        <form style={{position: 'relative'}}>
                            <div class="custom-file-upload" id="fileUpload1">
                                <input type="file" id="fileuploadInput" onChange={(e) => {uploadCsv(e.target.files[0])}} accept="text/csv" />
                                <label for="fileuploadInput">
                                    <span>
                                        <strong>
                                            <ion-icon name="cloud-upload-outline"></ion-icon>
                                            <i>Tap to Upload</i>
                                        </strong>
                                    </span>
                                </label>
                            </div>
                            {uploadingLoader &&
                                <div class="d-flex justify-content-center align-items-center" style={{position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, backgroundColor: '#0000008a', zIndex: 1}}>
                                    <button class="btn text-white border-0 bg-transparent fw-bold">
                                        <span class="spinner-border spinner-border-sm me-05 text-white" role="status" aria-hidden="true"></span> Uploading...
                                    </button>
                                </div>
                            }
                        </form>
                        <button class="btn btn-outline-primary btn-block mt-2" onClick={downloadSampleCsv}>Download Sample CSV</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ imageEdit }) => {
    const {
        fileUploadModalVisible
    } = imageEdit
    return {
        fileUploadModalVisible
    }
}

const mapDispatchToProps = {
    hideFileUploadModal
}

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadModal)