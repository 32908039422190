import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import axios from '../../../AxiosConfig'
import { hideCustomOrderPaymentModal } from '../../../features/Offcanvas/customOrderPaymentModalSlice'
import { setCustomOrderRender } from '../../../features/Data/dataSlice'

const CustomOrdersPaymentModal = ({
    customOrderPaymentModalVisible,
    hideCustomOrderPaymentModal,
    orderPaymentData,
    orderID,
    setCustomOrderRender,
    customOrderRender
}) => {
    const [balanceAmount, setBalanceAmount] = useState(0)
    const [warningMessage, setWarningMessage] = useState(0)
    const [paymentMethod, setPaymentMethod] = useState('Cash')
    const [otherDetails, setOtherDetails] = useState('')
    const [todayDate, setTodayDate] = useState('')
    const [disableSubmitButton, setDisableSubmitButton] = useState(false)

    useEffect(() => {
        if (orderPaymentData) {
            setBalanceAmount(parseInt(orderPaymentData?.Balance))
        }
    }, [orderPaymentData])
    const handlePaymentSubmit = (e) => {
        e.preventDefault()
        setDisableSubmitButton(true)
        const paymentData = {
            'OrderID': orderID,
            'BalanceAmount': orderPaymentData?.Balance,
            'PaymentMethod': paymentMethod,
            'OtherDetail': otherDetails,
            'PaymentDate': todayDate,
            'OrderType': 'CustomOrder',
            'PaidAmount': balanceAmount
        }
        axios.put('/Order/CompleteOrder', paymentData).then(res => {
            if (res.data.message === 'Success') {
                setCustomOrderRender({customOrderRender: customOrderRender + 1})
                setDisableSubmitButton(false)
                hideCustomOrderPaymentModal()
            }
        })
    }
    const updateBalanceAmount = (value) => {
        if (value <= parseInt(orderPaymentData?.Balance)) {
            setBalanceAmount(parseInt(value))
            setWarningMessage(parseInt(orderPaymentData?.Balance) - value)
        } else {
            setWarningMessage('Exceeds balance limit')
        }
    }
    useEffect(() => {
        const today = new Date()
        const formattedDate = today.toISOString().split('T')[0] // Format: YYYY-MM-DD
        setTodayDate(formattedDate)
    }, [])
    return (
        <div>
            <Modal show={customOrderPaymentModalVisible} centered>
                {/* <Modal.Header closeButton style={{minHeight: '36px'}}>
                    <Modal.Title style={{display: 'flex', alignItems: 'center'}}>Pay Balance Amount</Modal.Title>
                </Modal.Header> */}
                <div class="rounded-circle d-flex align-items-center justify-content-center text-light" onClick={() => {hideCustomOrderPaymentModal()}} style={{height: '25px', width: '25px', background: 'rgba(0,0,0,0.3)', position: 'absolute', top: '-13px', right: '-13px', cursor: 'pointer'}}>
                    <ion-icon name="close-outline"></ion-icon>
                </div>
                <Modal.Body class="modal-body p-1">
                    <div class="section full">
                        <form onSubmit={(e) => {handlePaymentSubmit(e)}}>
                            <div class="row">
                                {/* <div class="col-12 col-md-12 d-none">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="form-label mb-0">Type <span class="text-danger">*</span></label>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" value="Expense" name="type" id="type1" />
                                            <label class="form-check-label ps-3" for="type1">
                                                Expense
                                            </label>
                                        </div>&nbsp;
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" value="Income" name="type" id="type2" checked />
                                            <label class="form-check-label ps-3" for="type2">
                                                Income
                                            </label>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div class="col-6 col-md-6 d-none">
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="category">Category <span class="text-danger">*</span></label>
                                            <select class="form-control form-select" id="category" required>
                                                <option value="1">Category</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> */}
                                <div class="col-4 col-md-4">
                                    <div class="form-group boxed">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="amount">
                                                Amount 
                                                <span class="text-danger">*</span>
                                                <span class="text-danger">&nbsp; {warningMessage}</span>
                                            </label>
                                            <input type="number" class="form-control pe-1" max={orderPaymentData?.Balance} value={balanceAmount} onChange={(e) => {updateBalanceAmount(e.target.value)}} id="amount" required />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 col-md-4">
                                    <div class="form-group boxed">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="paymentMethod">Payment Method <span class="text-danger">*</span></label>
                                            <select class="form-control form-select" onClick={(e) => {setPaymentMethod(e.target.value)}} value={paymentMethod} id="paymentMethod" required>
                                                <option value="Cash">Cash</option>
                                                <option value="Card">Card</option>
                                                <option value="UPI">UPI</option>
                                                <option value="Payment Gateway">Payment Gateway</option>
                                                <option value="Cheque">Cheque</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 col-md-4">
                                    <div class="form-group boxed">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="datepicker">Payment Date<span class="text-danger">*</span></label>
                                            <input type="date" class="form-control pe-1" value={todayDate} onChange={(e) => {setTodayDate(e.target.value)}} id="datepicker" required />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12">
                                    <div class="form-group boxed">
                                        <div class="input-wrapper">
                                            <label class="form-label" for="otherDetail">Other Detail</label>
                                            <textarea class="form-control" value={otherDetails} id="otherDetail" onChange={(e) => {setOtherDetails(e.target.value)}}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 mt-1 d-flex justify-content-center">
                                    <button type="submit" disabled={disableSubmitButton} class="btn btn-success d-flex align-items-center"><ion-icon name="checkmark-outline" class="me-0"></ion-icon>&nbsp;Deliver</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ customOrder, data }) => {
    const {
        customOrderPaymentModalVisible,
        orderPaymentData,
        orderID
    } = customOrder
    const {
        customOrderRender
    } = data
    return {
        customOrderPaymentModalVisible,
        orderPaymentData,
        orderID,
        customOrderRender
    }
  }
  
  const mapDispatchToProps = {
    hideCustomOrderPaymentModal,
    setCustomOrderRender
  }

export default connect(mapStateToProps, mapDispatchToProps)(CustomOrdersPaymentModal)