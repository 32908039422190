import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import moment from 'moment-timezone'
import axios from '../../AxiosConfig'
import { showAppToast } from '../../features/utilities/appToastSlice'
import PhoneInput from 'react-phone-number-input';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const OpticCustomOrder = ({
    businessData,
    authToken,
    items,
    showAppToast,
    userData
}) => {
    const [branchData, setBranchData] = useState(null)
    const [businessTempData, setBusinessTempData] = useState(null)
    const [selectedOption, setSelectedOption] = useState(null)
    const [tempCurrentDate, setTempCurrentDate] = useState(null)
    const [disableSubmitButton, setDisableSubmitButton] = useState(false)
    const [orderNumber, setOrderNumber] = useState(null)
    const [customSizeInput, showCustomSizeInput] = useState(false)
    // USESTATE FOR FORM FIELDS START
    const [billNo, setBillNo] = useState(null)
    const [orderDate, setOrderDate] = useState(null)
    const [deliveryDate, setDeliveryDate] = useState(null)
    const [eventType, setEventType] = useState("birthday")
    const [paymentMethod, setPaymentMethod] = useState("Cash")
    const [remark, setRemark] = useState('')
    const [eventText, setEventText] = useState(null)
    const [deliveryTime, setDeliveryTime] = useState("12:00")
    const [selectedCakeType, setSelectedCakeType] = useState({
        "label": "Normal",
        "value": "normal"
    })
    const [itemSize, setItemSize] = useState(null)
    // USESTATE FOR FORM FIELDS END
    const [titleName, setTitleName] = useState(null)
    const [tempCustomOrderObj, setTempCustomOrderObj] = useState(null)
    const [phoneValueCC, setPhoneValueCC] = useState('');
    const [imagePath, setImagePath] = useState("");
    let navigate = useNavigate()
    const calculateYear = (number) => {
        let num = number.toString()
        if (num.length === 1) {
            num = '00' + num
        } else if (num.length === 2) {
            num = '0' + num
        }
        return num
    }
    useEffect(() => {
        const tokenData = jwt_decode(authToken)
        if (businessData) {
          businessData.map((value, key) => {
            value.Branches.map((v,k) => {
                if (value.ID === tokenData.userInfo.Business_ID && v.ID === tokenData.userInfo.DefaultLocationID) {
                    setBranchData(v)
                    setBusinessTempData(value)
                }
            })
          }) 
        }
    }, [businessData, authToken])
    // const options = [
    //     {
    //       label: "Cake",
    //       options: [
    //         { label: "vanilla", value: "Vanilla" },
    //         { label: "chocolate", value: "Chocolate" }
    //       ]
    //     },
    //     {
    //         label: "Pastry",
    //         options: [
    //           { label: "strawbarry", value: "Strawbarry" },
    //           { label: "mango", value: "Mango" },
    //           { label: "chocolate", value: "ChocolatePastry" }
    //         ]
    //     }
    // ];
    useEffect(() => {
        let options = []
        if (items) {
            items.map((v,k) => {
                options.push({
                    "label": v.ItemTitle,
                    "value": v.ID,
                    "data": v
                })
            })
            setTitleName(options)
            const currentDate = new Date().toISOString().split('T')[0]
            setTempCurrentDate(currentDate)
            setOrderDate(currentDate)
            setDeliveryDate(currentDate)
        } else {
            navigate('/load')
        }
    }, [items])
    const cakeType = [
        { label: "Normal", value: "normal" },
        { label: "Rectangle", value: "rectangle" },
        { label: "Photo Cake", value: "photoCake" },
        { label: "Tower", value: "tower" },
        { label: "Doll", value: "doll" },
        { label: "Square", value: "square" },
        { label: "Round", value: "round" },
        { label: "Heart", value: "heart" },
        { label: "Other Design", value: "otherDesign" },
        { label: "Less Cream", value: "lessCream" },
        { label: "Foundant", value: "foundant" },
        { label: "Home Delivery", value: "homeDelivery" }
    ]
    function updateObj (data, dataType, extraDataType) {
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        if (dataType === 'billNo' ) {
            setBillNo(data)
            tempCustomOrderObj2.billNo = data
        } else if (dataType === 'orderDate') {
            setOrderDate(data)
            tempCustomOrderObj2.OrderDate = data
        } else if (dataType === 'deliveryDate') {
            setDeliveryDate(data)
            tempCustomOrderObj2.DeliveryDate = data
            tempCustomOrderObj2.OrderDetails.DeliveryDate = data
        } else if (dataType === 'eventType') {
            setEventType(data)
            tempCustomOrderObj2.EventType = data
            tempCustomOrderObj2.OrderDetails.EventType = data
        } else if (dataType === 'eventText') {
            setEventText(data)
            tempCustomOrderObj2.EventText = data
            tempCustomOrderObj2.OrderDetails.EventText = data
        } else if (dataType === 'deliveryTime') {
            setDeliveryTime(data)
            tempCustomOrderObj2.DeliveryTime = data
            tempCustomOrderObj2.OrderDetails.DeliveryTime = data
        } else if (dataType === 'cakeType') {
            setSelectedCakeType(data)
            tempCustomOrderObj2.CakeType = data
            tempCustomOrderObj2.OrderDetails.CakeType = data
        } else if (dataType === 'paymentMethod') {
            setPaymentMethod(data)
            tempCustomOrderObj2.PaymentMethod = data
        } else if (dataType === 'remark') {
            setRemark(data)
            tempCustomOrderObj2.OrderDetails.Remark = data
        } else if (dataType === 'phone') {
            tempCustomOrderObj2.OrderDetails.Phone = data
        } else if (dataType === 'name') {
            tempCustomOrderObj2.OrderDetails.Name = data
        } else if (dataType === 'age') {
            tempCustomOrderObj2.OrderDetails.Age = data
        } else if (dataType === 'address') {
            tempCustomOrderObj2.OrderDetails.Address = data
        } else if (dataType === 'email') {
            tempCustomOrderObj2.OrderDetails.Email = data
        } else if (extraDataType === 'rightTable') {
            if (!tempCustomOrderObj2.OrderDetails.hasOwnProperty('RightTable')) {
                tempCustomOrderObj2.OrderDetails.RightTable = {}
            }
            if (tempCustomOrderObj2.OrderDetails.RightTable.hasOwnProperty(dataType)) {
                tempCustomOrderObj2.OrderDetails.RightTable[dataType] = data
            } else {
                tempCustomOrderObj2.OrderDetails.RightTable[dataType] = data
            }
        } else if (extraDataType === 'leftTable') {
            if (!tempCustomOrderObj2.OrderDetails.hasOwnProperty('LeftTable')) {
                tempCustomOrderObj2.OrderDetails.LeftTable = {}
            }
            if (tempCustomOrderObj2.OrderDetails.LeftTable.hasOwnProperty(dataType)) {
                tempCustomOrderObj2.OrderDetails.LeftTable[dataType] = data
            } else {
                tempCustomOrderObj2.OrderDetails.LeftTable[dataType] = data
            }
        } else if (extraDataType === 'lensesRightTable') {
            if (!tempCustomOrderObj2.OrderDetails.hasOwnProperty('LensesRightTable')) {
                tempCustomOrderObj2.OrderDetails.LensesRightTable = {}
            }
            if (tempCustomOrderObj2.OrderDetails.LensesRightTable.hasOwnProperty(dataType)) {
                tempCustomOrderObj2.OrderDetails.LensesRightTable[dataType] = data
            } else {
                tempCustomOrderObj2.OrderDetails.LensesRightTable[dataType] = data
            }
        } else if (extraDataType === 'lensesLeftTable') {
            if (!tempCustomOrderObj2.OrderDetails.hasOwnProperty('LensesLeftTable')) {
                tempCustomOrderObj2.OrderDetails.LensesLeftTable = {}
            }
            if (tempCustomOrderObj2.OrderDetails.LensesLeftTable.hasOwnProperty(dataType)) {
                tempCustomOrderObj2.OrderDetails.LensesLeftTable[dataType] = data
            } else {
                tempCustomOrderObj2.OrderDetails.LensesLeftTable[dataType] = data
            }
        }
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    // ON CHANGE EVENT START
    const updateBillNo = (billNo) => {
        updateObj(billNo, 'billNo')
    }
    const updatePhone = (phone) => {
        updateObj(phone, 'phone')
    }
    const updateName = (name) => {
        updateObj(name, 'name')
    }
    const updateAge = (age) => {
        updateObj(age, 'age')
    }
    const updateAddress = (address) => {
        updateObj(address, 'address')
    }
    const updateEmail = (email) => {
        updateObj(email, 'email')
    }
    const updateOrderDate = (orderDate) => {
        updateObj(orderDate, 'orderDate')
    }
    const updateDeliveryDate = (deliveryDate) => {
        updateObj(deliveryDate, 'deliveryDate')
    }
    const updateEventType = (eventType) => {
        updateObj(eventType, 'eventType')
    }
    const updateEventText = (eventText) => {
        updateObj(eventText, 'eventText')
    }
    const updateDeliveryTime = (deliveryTime) => {
        updateObj(deliveryTime, 'deliveryTime')
    }
    const handleCakeTypeChange = (cakeType) => {
        updateObj(cakeType, 'cakeType')
    }
    const updatePaymentMethod = (paymentMethod) => {
        updateObj(paymentMethod, 'paymentMethod')
    }
    const updateRemark = (remark) => {
        updateObj(remark, 'remark')
    }
    const updateRightTable = (value, inputType) => {
        updateObj(value, inputType, 'rightTable')
    }
    const updateLeftTable = (value, inputType) => {
        updateObj(value, inputType, 'leftTable')
    }
    const updateLensesRightTable = (value, inputType) => {
        updateObj(value, inputType, 'lensesRightTable')
    }
    const updateLensesLeftTable = (value, inputType) => {
        updateObj(value, inputType, 'lensesLeftTable')
    }
    // ON CHANGE EVEN END
    let customOrderItem = []
    let customOrderObj =  {}
    const targetTimezone = 'Asia/Kolkata'
    const newDate = moment().tz(targetTimezone).format('YYYY-MM-DD HH:mm:ss')
    const onlyDate = moment().tz(targetTimezone).format('YYYY-MM-DD')
    const tempBillNo = localStorage.getItem('billNo')
    function resetForm() {
        let d = new Date();
        const year = d.getFullYear();
        const start = new Date(d.getFullYear(), 0, 0);
        const diff = d - start;
        const oneDay = 1000 * 60 * 60 * 24;
        const day = Math.floor(diff / oneDay);
        var seconds = (d.getSeconds() < 10 ? '0' : '') + d.getSeconds();
        var minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
        var hour = (d.getHours() < 10 ? '0' : '') + d.getHours();
        setOrderNumber(year + calculateYear(day) + 'AA' + 'K01' + hour + minutes + seconds)
        const tempOrderNumber = year + calculateYear(day) + 'AA' + 'K01' + hour + minutes + seconds
        for (let i = 0; i < 2; i++) {
            customOrderItem.push({
                "quantity":null,
                "MRP":null,
                "Cost":null,
                "ItemTitle":null,
                "Item_ID":null,
                "Taxes":0,
                "Status":false,
                "Image": null,
                "AlternateText": null,
                "Sizes": [],
                "CustomSizeFlag": false
            }) 
        }
        customOrderObj = {
            "OfflineInvoiceNumber": "IN" + tempOrderNumber,
            "Items":customOrderItem,
            "TotalQty":0,
            "Subtotal":0,
            "TotalTax": 0,
            "Total":0,
            "EventType":"birthday",
            "DateTime":newDate,
            "OrderNumber":tempOrderNumber,
            "Currency":"INR",
            "Phone":userData?.ContactNumber,
            "Date": onlyDate,
            "UserName":"",
            "BalanceAmount":0,
            "PaymentMethod":"Cash",
            "OrderType": "Custom",
            "Amount": 0,
            "TransactionDate": newDate,
            // "Customer_ID": "",
            "Status": 1,
            "TotalDiscount": null,
            "billNo": parseInt(tempBillNo) + 1,
            "OrderDate": onlyDate,
            "DeliveryDate": onlyDate,
            "DeliveryTime": "12:00",
            "CakeType":[{ label: "Normal", value: "normal" }],
            "Remark": "",
            "ReferenceImage": [],
            "OrderDetails":{
                "BalanceAmount":0,
                "EventType":"birthday",
                "EventText":"",
                "DeliveryDate": onlyDate,
                "DeliveryTime": "12:00",
                "CakeType":[{ label: "Normal", value: "normal" }],
                "Remark": "",
                "Address": "",
                "Age": 18,
                "Email": "",
                "ReferenceImage": []
            }
        }
        setTempCustomOrderObj(customOrderObj)
    }
    useEffect(() => {
        resetForm()
    }, [])
    const handleTitleChange = (value, index) => {
        setSelectedOption(value)
        showCustomSizeInput(false)
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        if (value.data.Sizes.length > 0) {
            tempCustomOrderObj2.Items[index].Item_ID = value.data.Item_ID
        } else {
            tempCustomOrderObj2.Items[index].Item_ID = value.value
            tempCustomOrderObj2.Items[index].AlternateText = value.data.AlternateText
            tempCustomOrderObj2.Items[index].MRP = (value.data.MRP > 0) ? value.data.MRP : tempCustomOrderObj.Items[index].MRP
            tempCustomOrderObj2.Items[index].Cost = value.data.Cost
        }
        tempCustomOrderObj2.Items[index].ItemTitle = value.label
        tempCustomOrderObj2.Items[index].Sizes = value.data.Sizes
        tempCustomOrderObj2.Items[index].Image = value.data.ImageURL
        tempCustomOrderObj2.Items[index].quantity = (tempCustomOrderObj.Items[index].quantity) ? tempCustomOrderObj.Items[index].quantity : 1
        let count = 0
        let tempSubtotal = null
        tempCustomOrderObj2.Items.map((v,k) => {
            if (v.quantity && v.MRP) {
                tempSubtotal += v.quantity * v.MRP
            }
            count ++
        })
        tempCustomOrderObj2.Subtotal = tempSubtotal
        tempCustomOrderObj2.Total = (tempCustomOrderObj2.Total) ? tempCustomOrderObj2.Total : tempSubtotal
        tempCustomOrderObj2.Amount = tempSubtotal
        tempCustomOrderObj2.Advance = (tempCustomOrderObj2.Advance) ? tempCustomOrderObj2.Advance : tempSubtotal
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const updateItemSize = (value, index) => {
        setItemSize(value)
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        let count = 0
        let tempSubtotal = 0
        tempCustomOrderObj2.Items.map((v,k) => {
            if (value !== 'custom') {
                v.Sizes.map((v1,k1) => {
                    if (value === v1.Item_ID) {
                        tempCustomOrderObj2.Items[index].SelectedSize = v1
                        tempCustomOrderObj2.Items[index].Item_ID = v1.Item_ID
                        tempCustomOrderObj2.Items[index].AlternateText = v1.AlternateText
                        tempCustomOrderObj2.Items[index].MRP = v1.MRP
                        tempCustomOrderObj2.Items[index].Cost = v1.Cost
                    }
                })
                if (v.quantity && v.MRP) {
                    tempSubtotal += v.quantity * v.MRP
                }
                count ++
            } else {
                tempCustomOrderObj2.Items[index].CustomSizeFlag = true
                // showCustomSizeInput(true)
            }
        })
        tempCustomOrderObj2.Subtotal = tempSubtotal
        tempCustomOrderObj2.Total = tempSubtotal
        tempCustomOrderObj2.Amount = tempSubtotal
        tempCustomOrderObj2.Advance = tempSubtotal
        
        tempCustomOrderObj2.Items[index].ItemSize = value
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const updateItemDescriptionCustom = (value, index, parentData) => {
        if (parentData.ItemSize === 'custom') {
            const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
            tempCustomOrderObj2.Items[index].CustomSIze = value
            tempCustomOrderObj2.Items[index].Item_ID = selectedOption.value
            setTempCustomOrderObj(tempCustomOrderObj2)    
        } else {
            const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
            tempCustomOrderObj2.Items[index].CustomSIze = value
            setTempCustomOrderObj(tempCustomOrderObj2)
        }
    }
    const updateItemQty = (value, index) => {
        setItemSize(value)
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.Items[index].quantity = value
        let count = 0
        let tempSubtotal = 0
        tempCustomOrderObj2.Items.map((v,k) => {
            if (v.quantity && v.MRP) {
                tempSubtotal += v.quantity * v.MRP
            }
            count ++
        })
        tempCustomOrderObj2.Subtotal = tempSubtotal
        tempCustomOrderObj2.Total = tempSubtotal
        tempCustomOrderObj2.Amount = tempSubtotal
        tempCustomOrderObj2.Advance = tempSubtotal
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const updateItemMRP = (value, index) => {
        setItemSize(value)
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.Items[index].MRP = value
        let count = 0
        let tempSubtotal = 0
        tempCustomOrderObj2.Items.map((v,k) => {
            if (v.quantity && v.MRP) {
                tempSubtotal += v.quantity * v.MRP
            }
            count ++
        })
        tempCustomOrderObj2.Subtotal = tempSubtotal
        tempCustomOrderObj2.Total = tempSubtotal
        tempCustomOrderObj2.Amount = tempSubtotal
        tempCustomOrderObj2.Advance = tempSubtotal
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const updateAdvance = (value, index) => {
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.Advance = (value) ? value : null
        tempCustomOrderObj2.BalanceAmount = parseInt(tempCustomOrderObj2.Total) - parseInt((tempCustomOrderObj2.Advance) ? tempCustomOrderObj2.Advance : 0)
        tempCustomOrderObj2.OrderDetails.BalanceAmount = parseInt(tempCustomOrderObj2.Total) - parseInt((tempCustomOrderObj2.Advance) ? tempCustomOrderObj2.Advance : 0)
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const updateDiscount = (value, index) => {
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.TotalDiscount = (value) ? value : null
        tempCustomOrderObj2.Total = parseInt(tempCustomOrderObj2.Subtotal) - parseInt((tempCustomOrderObj2.TotalDiscount) ? tempCustomOrderObj2.TotalDiscount : 0)
        tempCustomOrderObj2.Advance = parseInt(tempCustomOrderObj2.Subtotal) - parseInt((tempCustomOrderObj2.TotalDiscount) ? tempCustomOrderObj2.TotalDiscount : 0)
        tempCustomOrderObj2.BalanceAmount = parseInt(tempCustomOrderObj2.Subtotal) - parseInt((tempCustomOrderObj2.TotalDiscount) ? tempCustomOrderObj2.TotalDiscount : 0)
        tempCustomOrderObj2.OrderDetails.BalanceAmount = parseInt(tempCustomOrderObj2.Subtotal) - parseInt((tempCustomOrderObj2.TotalDiscount) ? tempCustomOrderObj2.TotalDiscount : 0)
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const updateCgst = (value, index) => {
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.Cgst = value
        tempCustomOrderObj2.Total = parseInt(tempCustomOrderObj2.Subtotal) + (parseInt(tempCustomOrderObj2.Cgst) ? parseInt(tempCustomOrderObj2.Cgst) : 0) + (parseInt(tempCustomOrderObj2.Sgst) ? parseInt(tempCustomOrderObj2.Sgst) : 0)
        tempCustomOrderObj2.Amount = tempCustomOrderObj2.Total
        tempCustomOrderObj2.Advance = tempCustomOrderObj2.Amount
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const updateSgst = (value, index) => {
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.Sgst = value
        tempCustomOrderObj2.Total = parseInt(tempCustomOrderObj2.Subtotal) + (parseInt(tempCustomOrderObj2.Cgst) ? parseInt(tempCustomOrderObj2.Cgst) : 0) + (parseInt(tempCustomOrderObj2.Sgst) ? parseInt(tempCustomOrderObj2.Sgst) : 0)
        tempCustomOrderObj2.Amount = tempCustomOrderObj2.Total
        tempCustomOrderObj2.Advance = tempCustomOrderObj2.Amount
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    const handleAddItem = (e) => {
        e.stopPropagation()
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.Items.push({
            "quantity":0,
            "MRP":0,
            "Cost":0,
            "ItemTitle":null,
            "Item_ID":null,
            "Taxes":0,
            "Status":false,
            "Image": null,
            "AlternateText": null,
            "Sizes": []
        })
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    
    const uploadRefImage = (filePath) => {
        setDisableSubmitButton(true)
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        const formData = new FormData();
        formData.append('inputFile', filePath)
        axios.post('/applications/upload/items', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(res => {
            tempCustomOrderObj2.ReferenceImage.push(res.data.data)
            tempCustomOrderObj2.OrderDetails.ReferenceImage.push(res.data.data)
            setTempCustomOrderObj(tempCustomOrderObj2)
            setDisableSubmitButton(false)
        }).catch(err => {
        })
    }
    const handleCustomOrderSubmit = (e) => {
        e.preventDefault()
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        let totalQuantity = 0
        tempCustomOrderObj2.Items.map((v,k) => {
            totalQuantity += parseInt(v.quantity)
        })
        tempCustomOrderObj2.TotalQty = totalQuantity
        setTempCustomOrderObj(tempCustomOrderObj2)
        setDisableSubmitButton(true)
        axios.post('/Order/CustomOrder', tempCustomOrderObj2).then(res => {
            if (res.data.message === 'Success') {
                resetForm()
                navigate('/customOrders')
                setDisableSubmitButton(false)
                showAppToast({ toastMessage:'ORDER PLACED', background: 'success', timeout: '1000', icon:'checkmark-circle-outline', position: 'toast-center', isVisible: true })
            } else {
                setDisableSubmitButton(false)
                showAppToast({ toastMessage:'Something went wrong', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center', isVisible: true })
            }
        })
    }
    const handleBackToDropdown = (Index) => {
        // showCustomSizeInput(false)
        const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        tempCustomOrderObj2.Items[Index].CustomSizeFlag = false
        setTempCustomOrderObj(tempCustomOrderObj2)
    }
    useEffect(() => {
        if (phoneValueCC) {
            updatePhone(phoneValueCC.split('+')[1])
        }
    }, [phoneValueCC])
    const handleFullImage = (imagePath) => {
        if (imagePath) {
            setImagePath(imagePath)
        } else {
            setImagePath()
        }
    }

    const [imageData, setImageData] = useState(null);

    useEffect(() => {
      // Wait for the device to be ready
      document.addEventListener('deviceready', onDeviceReady, false);
  
      // Cleanup listener on component unmount
      return () => {
        document.removeEventListener('deviceready', onDeviceReady, false);
      };
    }, []);
  
    const onDeviceReady = () => {
      console.log('Device is ready');
    };
  
    // Function to capture a photo
    const handleCamera = () => {
    //   const cameraOptions = {
    //     quality: 50,
    //     destinationType: window.Camera.DestinationType.DATA_URL, // Use FILE_URI if you need file path
    //     sourceType: window.Camera.PictureSourceType.CAMERA, // Use PHOTOLIBRARY for gallery
    //     encodingType: window.Camera.EncodingType.JPEG,
    //     mediaType: window.Camera.MediaType.PICTURE,
    //     saveToPhotoAlbum: true,
    //   };
  
    //   navigator.camera.getPicture(onSuccess, onFail, cameraOptions);
    };
  
    // Success callback for capturing the photo
    const onSuccess = (imageData) => {
      setImageData("data:image/jpeg;base64," + imageData); // Update state with the image data
    };
  
    // Failure callback
    const onFail = (message) => {
      alert('Failed because: ' + message);
    };
    return (
        <div id="appCapsule" style={{marginLeft: '-8px', marginRight: '-8px', paddingRight: '8px', paddingLeft: '8px'}}>
            <div class="section full pt-1">
                <form onSubmit={(e) => {handleCustomOrderSubmit(e)}}>
                    <div class="invoice p-0 bg-transparent" style={{boxShadow: 'none'}}>
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center flex-column">
                                <h2 class="mb-0 text-center">{businessTempData?.Name}</h2>
                                <h4 class="text-center">({branchData?.Name})</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-6 col-sm-3">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="billNo" style={{fontSize: '1rem'}}>Bill No <span class="text-danger">*</span></label>
                                                    <input type="number" class="form-control form-control-sm p-0 ps-1" id="billNo" placeholder="Bill No" min="0" value={tempCustomOrderObj?.billNo} autocomplete="off" required onChange={(e) => {updateBillNo(e.target.value)}} style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="deliveryDate" style={{fontSize: '1rem'}}>Order Date <span class="text-danger">*</span></label>
                                                    <input type="date" class="form-control form-control-sm p-0 ps-1 pe-1" id="date" placeholder="Date" value={orderDate} autocomplete="off" onChange={(e) => {updateOrderDate(e.target.value)}} min={tempCurrentDate} style={{minHeight: '44px', height: '44px', fontSize: '20px'}} required />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    
                                    <div class="col-6 col-sm-3">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="phone" style={{fontSize: '1rem'}}>Phone <span class="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <PhoneInput
                                                autoFocus
                                                international
                                                defaultCountry="IN"
                                                value={phoneValueCC}
                                                onChange={setPhoneValueCC}
                                                className="form-control form-control-sm phoneInput p-0 ps-1"
                                                style={{minHeight: '44px', height: '44px', fontSize: '20px'}}
                                            />
                                            {/* <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="phone" style={{fontSize: '1rem'}}>Phone <span class="text-danger">*</span></label>
                                                    <input type="number" class="form-control form-control-sm p-0 ps-1" id="phone" placeholder="Phone" value={tempCustomOrderObj?.phone} autocomplete="off" max-length="10" min="0" required onChange={(e) => {updatePhone(e.target.value)}} style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div> */}
                                        </p>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="name" style={{fontSize: '1rem'}}>Name <span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control form-control-sm p-0 ps-1" id="name" placeholder="Name" value={tempCustomOrderObj?.name} autocomplete="off" max-length="10" min="0" required onChange={(e) => {updateName(e.target.value)}} style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="age" style={{fontSize: '1rem'}}>Age</label>
                                                    <input type="number" class="form-control form-control-sm p-0 ps-1" id="age" placeholder="Age" value={tempCustomOrderObj?.age} autocomplete="off" max-length="10" min="0" onChange={(e) => {updateAge(e.target.value)}} style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="address" style={{fontSize: '1rem'}}>Address</label>
                                                    <input type="text" class="form-control form-control-sm p-0 ps-1" id="address" placeholder="Address" value={tempCustomOrderObj?.address} autocomplete="off" onChange={(e) => {updateAddress(e.target.value)}} style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-sm-6">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="email" style={{fontSize: '1rem'}}>Email</label>
                                                    <input type="email" class="form-control form-control-sm p-0 ps-1" id="email" placeholder="example@abc.com" value={tempCustomOrderObj?.email} autocomplete="off" onChange={(e) => {updateEmail(e.target.value)}} style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    {/* <div class="col-6 col-sm-3">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="event" style={{fontSize: '1rem'}}>Select Event</label>
                                                    <select class="form-control form-select-sm form-select" id="event" value={eventType} onChange={(e) => {updateEventType(e.target.value)}} style={{minHeight: '36px', height: '36px'}} required>
                                                        <option value="marriage">Marriage</option>
                                                        <option value="birthday">Happy Birthday</option>
                                                        <option value="annivarsary">Happy Annivarsary</option>
                                                        <option value="farewell">Farewell</option>
                                                        <option value="others">Others</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <p class="mb-1">
                                            <div class="form-group boxed">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="deliveryDate" style={{fontSize: '1rem'}}>Delivery Date</label>
                                                    <div class="d-flex">
                                                        <input type="date" class="form-control form-control-sm p-0 ps-1 me-1" id="date" placeholder="Date" autocomplete="off" onChange={(e) => {updateDeliveryDate(e.target.value)}} min={orderDate} value={deliveryDate} style={{minHeight: '36px', height: '36px'}} />
                                                        <i class="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <p class="mb-1">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="eventText" style={{textTransform: 'capitalize', fontSize: '1rem'}}> Text on Cake</label>
                                                    <input type="text" class="form-control form-control-sm p-0 ps-1" id="eventText" placeholder={'Text on Cake'} onChange={(e) => {updateEventText(e.target.value)}} value={eventText} autocomplete="off" style={{minHeight: '36px', height: '36px'}} />
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <p class="mb-1">
                                            <div class="form-group boxed">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="deliveryDate" style={{fontSize: '1rem'}}>Delivery Time</label>
                                                    <div class="d-flex">
                                                        <input type="time" class="form-control form-control-sm p-0 ps-1" id="time" placeholder="time" autocomplete="off" value={deliveryTime} onChange={(e) => {updateDeliveryTime(e.target.value)}} style={{minHeight: '36px', height: '36px'}} />
                                                        <i class="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-6 col-sm-3">
                                        <p class="mb-0">
                                            <div class="form-group boxed pb-0">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="cakeType" style={{fontSize: '1rem'}}>Cake Type</label>
                                                </div>
                                            </div>
                                            <Select
                                                value={selectedCakeType}
                                                onChange={handleCakeTypeChange}
                                                options={cakeType}
                                                autoFocus={false}
                                                isMulti
                                                required
                                            />
                                        </p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <h2 class="text-center">Glass Prescription</h2>
                            <div class="w-100 d-flex" style={{overflowX: 'scroll'}}>
                                <div class="mb-2 w-50 me-2" style={{minWidth: '600px'}}>
                                    <h3 class="text-center" style={{fontSize: '18px'}}>Right</h3>
                                    <div class="border">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>SPH</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>CYL</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>AXIS</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>VS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Dist</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rsphd" placeholder="rsphd" value={tempCustomOrderObj?.OrderDetails?.RightTable?.rsphd} onChange={(e) => {updateRightTable(e.target.value, 'rsphd')}} autocomplete="off" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rcyld" placeholder="rcyld" value={tempCustomOrderObj?.OrderDetails?.RightTable?.rcyld} onChange={(e) => {updateRightTable(e.target.value, 'rcyld')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="raxisd" placeholder="raxisd" value={tempCustomOrderObj?.OrderDetails?.RightTable?.raxisd} onChange={(e) => {updateRightTable(e.target.value, 'raxisd')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rvisiond" placeholder="rvisiond" value={tempCustomOrderObj?.OrderDetails?.RightTable?.rvisiond} onChange={(e) => {updateRightTable(e.target.value, 'rvisiond')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Near</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rsphn" placeholder="rsphn" value={tempCustomOrderObj?.OrderDetails?.RightTable?.rsphn} onChange={(e) => {updateRightTable(e.target.value, 'rsphn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rcyln" placeholder="rcyln" value={tempCustomOrderObj?.OrderDetails?.RightTable?.rcyln} onChange={(e) => {updateRightTable(e.target.value, 'rcyln')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="raxisn" placeholder="raxisn" value={tempCustomOrderObj?.OrderDetails?.RightTable?.raxisn} onChange={(e) => {updateRightTable(e.target.value, 'raxisn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rvisionn" placeholder="rvisionn" value={tempCustomOrderObj?.OrderDetails?.RightTable?.rvisionn} onChange={(e) => {updateRightTable(e.target.value, 'rvisionn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Add</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rspha" placeholder="rspha" value={tempCustomOrderObj?.OrderDetails?.RightTable?.rspha} onChange={(e) => {updateRightTable(e.target.value, 'rspha')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rcyla" placeholder="rcyla" value={tempCustomOrderObj?.OrderDetails?.RightTable?.rcyla} onChange={(e) => {updateRightTable(e.target.value, 'rcyla')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="raxisa" placeholder="raxisa" value={tempCustomOrderObj?.OrderDetails?.RightTable?.raxisa} onChange={(e) => {updateRightTable(e.target.value, 'raxisa')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rvisiona" placeholder="rvisiona" value={tempCustomOrderObj?.OrderDetails?.RightTable?.rvisiona} onChange={(e) => {updateRightTable(e.target.value, 'rvisiona')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="w-50" style={{minWidth: '600px'}}>
                                    <h3 class="text-center" style={{fontSize: '18px'}}>Left</h3>
                                    <div class="border">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>SPH</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>CYL</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>AXIS</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>VS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Dist</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lsphd" placeholder="lsphd" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.lsphd} onChange={(e) => {updateLeftTable(e.target.value, 'lsphd')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lcyld" placeholder="lcyld" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.lcyld} onChange={(e) => {updateLeftTable(e.target.value, 'lcyld')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="laxisd" placeholder="laxisd" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.laxisd} onChange={(e) => {updateLeftTable(e.target.value, 'laxisd')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lvisiond" placeholder="lvisiond" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.lvisiond} onChange={(e) => {updateLeftTable(e.target.value, 'lvisiond')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Near</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lsphn" placeholder="lsphn" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.lsphn} onChange={(e) => {updateLeftTable(e.target.value, 'lsphn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lcyln" placeholder="lcyln" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.lcyln} onChange={(e) => {updateLeftTable(e.target.value, 'lcyln')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="laxisn" placeholder="laxisn" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.laxisn} onChange={(e) => {updateLeftTable(e.target.value, 'laxisn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lvisionn" placeholder="lvisionn" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.lvisionn} onChange={(e) => {updateLeftTable(e.target.value, 'lvisionn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Add</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lspha" placeholder="lspha" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.lspha} onChange={(e) => {updateLeftTable(e.target.value, 'lspha')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lcyla" placeholder="lcyla" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.lcyla} onChange={(e) => {updateLeftTable(e.target.value, 'lcyla')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="laxisa" placeholder="laxisa" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.laxisa} onChange={(e) => {updateLeftTable(e.target.value, 'laxisa')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lvisiona" placeholder="lvisiona" value={tempCustomOrderObj?.OrderDetails?.LeftTable?.lvisiona} onChange={(e) => {updateLeftTable(e.target.value, 'lvisiona')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-1">
                            <h2 class="text-center">Contact Lenses</h2>
                            <div class="w-100 d-flex" style={{overflowX: 'scroll'}}>
                                <div class="mb-2 w-50 me-2" style={{minWidth: '600px'}}>
                                    <h3 class="text-center" style={{fontSize: '18px'}}>Right</h3>
                                    <div class="border">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>SPH</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>CYL</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>AXIS</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>VS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Dist</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rsphd" placeholder="rsphd" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.rsphd} onChange={(e) => {updateLensesRightTable(e.target.value, 'rsphd')}} autocomplete="off" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rcyld" placeholder="rcyld" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.rcyld} onChange={(e) => {updateLensesRightTable(e.target.value, 'rcyld')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="raxisd" placeholder="raxisd" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.raxisd} onChange={(e) => {updateLensesRightTable(e.target.value, 'raxisd')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rvisiond" placeholder="rvisiond" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.rvisiond} onChange={(e) => {updateLensesRightTable(e.target.value, 'rvisiond')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Near</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rsphn" placeholder="rsphn" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.rsphn} onChange={(e) => {updateLensesRightTable(e.target.value, 'rsphn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rcyln" placeholder="rcyln" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.rcyln} onChange={(e) => {updateLensesRightTable(e.target.value, 'rcyln')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="raxisn" placeholder="raxisn" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.raxisn} onChange={(e) => {updateLensesRightTable(e.target.value, 'raxisn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rvisionn" placeholder="rvisionn" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.rvisionn} onChange={(e) => {updateLensesRightTable(e.target.value, 'rvisionn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Add</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rspha" placeholder="rspha" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.rspha} onChange={(e) => {updateLensesRightTable(e.target.value, 'rspha')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rcyla" placeholder="rcyla" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.rcyla} onChange={(e) => {updateLensesRightTable(e.target.value, 'rcyla')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="raxisa" placeholder="raxisa" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.raxisa} onChange={(e) => {updateLensesRightTable(e.target.value, 'raxisa')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="rvisiona" placeholder="rvisiona" value={tempCustomOrderObj?.OrderDetails?.LensesRightTable?.rvisiona} onChange={(e) => {updateLensesRightTable(e.target.value, 'rvisiona')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="w-50" style={{minWidth: '600px'}}>
                                    <h3 class="text-center" style={{fontSize: '18px'}}>Left</h3>
                                    <div class="border">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>SPH</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>CYL</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>AXIS</th>
                                                    <th scope="col" class="fw-bold text-center" style={{fontSize: '20px'}}>VS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Dist</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lsphd" placeholder="lsphd" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.lsphd} onChange={(e) => {updateLensesLeftTable(e.target.value, 'lsphd')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lcyld" placeholder="lcyld" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.lcyld} onChange={(e) => {updateLensesLeftTable(e.target.value, 'lcyld')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="laxisd" placeholder="laxisd" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.laxisd} onChange={(e) => {updateLensesLeftTable(e.target.value, 'laxisd')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lvisiond" placeholder="lvisiond" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.lvisiond} onChange={(e) => {updateLensesLeftTable(e.target.value, 'lvisiond')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Near</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lsphn" placeholder="lsphn" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.lsphn} onChange={(e) => {updateLensesLeftTable(e.target.value, 'lsphn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lcyln" placeholder="lcyln" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.lcyln} onChange={(e) => {updateLensesLeftTable(e.target.value, 'lcyln')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="laxisn" placeholder="laxisn" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.laxisn} onChange={(e) => {updateLensesLeftTable(e.target.value, 'laxisn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lvisionn" placeholder="lvisionn" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.lvisionn} onChange={(e) => {updateLensesLeftTable(e.target.value, 'lvisionn')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="fw-bold align-middle" style={{fontSize: '20px'}}>Add</th>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lspha" placeholder="lspha" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.lspha} onChange={(e) => {updateLensesLeftTable(e.target.value, 'lspha')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lcyla" placeholder="lcyla" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.lcyla} onChange={(e) => {updateLensesLeftTable(e.target.value, 'lcyla')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="laxisa" placeholder="laxisa" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.laxisa} onChange={(e) => {updateLensesLeftTable(e.target.value, 'laxisa')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="p-1">
                                                        <div class="form-group boxed ms-0 pb-0 pt-0">
                                                            <div class="input-wrapper">
                                                                <input type="tel" class="form-control form-control-sm p-0 text-center" id="lvisiona" placeholder="lvisiona" value={tempCustomOrderObj?.OrderDetails?.LensesLeftTable?.lvisiona} onChange={(e) => {updateLensesLeftTable(e.target.value, 'lvisiona')}} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="table-responsive" style={{overflow: 'visible'}}>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <td class="text-dark fs-6">ITEM</td>
                                            <td class="text-start text-dark fs-6">DESCRIPTION</td>
                                            <td class="text-dark fs-6">QTY</td>
                                            <td class="text-dark fs-6">RATE</td>
                                            <td class="text-dark fs-6">SUBTOTAL</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tempCustomOrderObj?.Items.map((v,k) => {
                                            return <tr key={k}>
                                                
                                                <td>
                                                    <div style={{minWidth: '200px'}}>
                                                        <Select
                                                            className="custom-select-height"
                                                            value={{
                                                                "label": v?.ItemTitle,
                                                                "value": v?.Item_ID
                                                            }}
                                                            onChange={(e) => {handleTitleChange(e, k)}}
                                                            options={titleName}
                                                            autoFocus={false}
                                                            key={k}
                                                            style={{height: '44px', width: '44px', fontSize: '20px'}}
                                                        />
                                                    </div>
                                                </td>
                                                <td key={k}>
                                                    <div class="form-group boxed ms-0 pb-0 pt-0">
                                                        <div class="input-wrapper">
                                                            <div style={{position: 'relative'}}>
                                                                {/* <textarea rows="3" class="form-control form-control-sm p-0 ps-1 text-start" id="description"  placeholder={'Frame Description'} value={v.CustomSize} onChange={(e) => {updateItemDescriptionCustom(e.target.value, k, v)}}></textarea> */}
                                                                <input type="text" class="form-control form-control-sm p-0 ps-1 text-start" id="description" placeholder="Description" step="0.1" onChange={(e) => {updateItemDescriptionCustom(e.target.value, k, v)}} value={v.CustomSize} autocomplete="off" autoFocus={false} style={{minHeight: '44px', height: '44px', width: '100%', fontSize: '20px'}} />
                                                                <i class="clear-input">
                                                                    <ion-icon name="close-circle"></ion-icon>
                                                                </i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-group boxed ms-0 pb-0 pt-0">
                                                        <div class="input-wrapper">
                                                            <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" id="qty" placeholder="0" onChange={(e) => {updateItemQty(e.target.value, k)}} value={v.quantity} autocomplete="off" min="0" style={{minHeight: '44px', height: '44px', width: '100%', fontSize: '20px'}} />
                                                            <i class="clear-input">
                                                                <ion-icon name="close-circle"></ion-icon>
                                                            </i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="pe-0">
                                                    <div class="form-group boxed ms-0 pb-0 pt-0">
                                                        <div class="input-group" style={{flexWrap: 'nowrap'}}>
                                                            <span class="input-group-text ps-1 pe-0 bg-white" id="basic-addon1" style={{height: '44px', border: '1px solid lightgray', borderRadius: '6px 0px 0px 6px'}}>₹</span>
                                                            <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" onChange={(e) => {updateItemMRP(e.target.value, k)}} value={v.MRP} placeholder="0" aria-label="Rate" min="0" aria-describedby="basic-addon1" style={{minHeight: '44px', height: '44px', width: '100%', borderLeft: 'none', fontSize: '20px'}} />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="ps-0 align-middle text-end" style={{fontSize: '22px'}}>₹{parseInt((v.quantity) ? v.quantity : 0) * parseInt((v.MRP) ? v.MRP : 0)}</td>
                                            </tr>
                                        })}
                                        <tr>
                                            <td class="text-start" colspan="5">
                                                <button class="btn btn-success ps-1 pe-1" type="button" onClick={(e) => {handleAddItem(e)}}><ion-icon name="add-outline" disabled></ion-icon> Add Item</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="invoice-total mt-0 border-0">
                            <div class="row">
                                <div class="col-12 col-md-9 order-2 order-md-1">
                                    <div class="row">
                                        <div class="col-12 col-md-6">
                                            <div class="form-group boxed">
                                                <div class="input-wrapper">
                                                    <label class="form-label" for="remark" style={{fontSize: '14px'}}>Description</label>
                                                    <textarea id="remark" rows="4" class="form-control" value={tempCustomOrderObj?.OrderDetails?.Remark} onChange={(e) => {updateRemark(e.target.value)}}></textarea>
                                                    <i class="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="row">
                                                <div class="col-12 col-md-12">
                                                    {/* <a href="#!" class="btn btn-lg btn-danger mt-2 mb-2" onClick={() => {handleCamera()}}>Camera</a> */}
                                                    {imageData && <img src={imageData} alt="Captured" style={{ width: '100%' }} />}
                                                    <div class="form-group boxed">
                                                        <div class="input-wrapper">
                                                            <label class="form-label" for="image" style={{fontSize: '14px'}}>Upload Image</label>
                                                            <input type="file" class="form-control form-control-sm p-0 ps-1 text-start" id="image" placeholder="Image" autocomplete="off" onChange={(e) => {uploadRefImage(e.target.files[0])}} style={{minHeight: '44px', height: '44px', fontSize: '20px'}} />
                                                            <i class="clear-input">
                                                                <ion-icon name="close-circle"></ion-icon>
                                                            </i>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex">
                                                        {tempCustomOrderObj?.ReferenceImage?.length > 0 &&
                                                            <>
                                                                {tempCustomOrderObj?.ReferenceImage.map((v,k) => {
                                                                    return <div class="me-1 cursor-pointer" onClick={() => {handleFullImage(v)}}>
                                                                        <img src={v} class="me-2" width="70px" />
                                                                    </div>
                                                                })}
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12">
                                                    <div class="form-group boxed">
                                                        <div class="input-wrapper">
                                                            <label class="form-label" for="discount" style={{fontSize: '14px'}}>Discount</label>
                                                        </div>
                                                        <div class="input-group">
                                                            <span class="input-group-text ps-1 pe-0 bg-white" id="basic-addon1" style={{height: '44px', border: '1px solid lightgray', borderRadius: '6px 0px 0px 6px'}}>₹</span>
                                                            <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" placeholder="0" min="0" value={tempCustomOrderObj?.TotalDiscount} onChange={(e) => {updateDiscount(e.target.value)}}aria-label="discount" aria-describedby="basic-addon1" style={{minHeight: '44px', height: '44px', borderLeft: 'none', fontSize: '20px'}} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-sm-6">
                                                    <div class="form-group boxed pb-0">
                                                        <div class="input-wrapper">
                                                            <label class="form-label fs-6" for="paymentMethod" style={{fontSize: '14px'}}>Payment Method</label>
                                                            <select class="form-control form-select-sm form-select" id="paymentMethod" value={paymentMethod} onChange={(e) => {updatePaymentMethod(e.target.value)}} style={{minHeight: '44px', height: '44px', fontSize: '20px'}} required>
                                                                <option value="">Select payment method</option>
                                                                <option value="Cash">Cash</option>
                                                                <option value="Card">Card</option>
                                                                <option value="UPI">UPI</option>
                                                                <option value="Payment-Gateway">Payment Gateway</option>
                                                                <option value="Cheque">Cheque</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-sm-6">
                                                    <div class="form-group boxed">
                                                        <div class="input-wrapper">
                                                            <label class="form-label" for="advance" style={{fontSize: '14px'}}>Advance</label>
                                                        </div>
                                                        <div class="input-group">
                                                            <span class="input-group-text ps-1 pe-0 bg-white" id="basic-addon1" style={{height: '44px', border: '1px solid lightgray', borderRadius: '6px 0px 0px 6px'}}>₹</span>
                                                            <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" placeholder="Advance" min="0" value={tempCustomOrderObj?.Advance} onChange={(e) => {updateAdvance(e.target.value)}}aria-label="advance" aria-describedby="basic-addon1" required style={{minHeight: '44px', height: '44px', borderLeft: 'none', fontSize: '20px'}} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div class="col-6 col-sm-3">
                                                    <div class="form-group boxed">
                                                        <div class="input-wrapper">
                                                            <label class="form-label" for="balance">Balance</label>
                                                        </div>
                                                        <div class="input-group">
                                                            <span class="input-group-text ps-1 pe-0 bg-white" id="basic-addon1" style={{height: '38px', border: '1px solid lightgray', borderRadius: '6px 0px 0px 6px'}}>₹</span>
                                                            <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" placeholder="Balance" value={tempCustomOrderObj?.BalanceAmount} aria-label="balance" aria-describedby="basic-addon1" required style={{minHeight: '38px', height: '38px', borderLeft: 'none'}} />
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-3 order-1 order-md-2">
                                    <ul class="listview transparent simple-listview flush">
                                        <li style={{fontSize: '18px'}}>Subtotal <span class="hightext" style={{fontSize: '18px'}}>₹{tempCustomOrderObj?.Subtotal}</span></li>
                                        <li style={{fontSize: '18px'}}>
                                            CGST
                                            <span class="hightext" style={{fontSize: '18px'}}>
                                                ₹{(tempCustomOrderObj?.Cgst) ? tempCustomOrderObj?.Cgst : 0}
                                                {/* <div class="form-group boxed ms-0 pb-0 pt-0">
                                                    <div class="input-group">
                                                        <span class="input-group-text ps-1 pe-0 bg-white" id="basic-addon1" style={{height: '25px', border: '1px solid lightgray', borderRadius: '6px 0px 0px 6px', fontSize: '14px'}}>₹</span>
                                                        <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" onChange={(e) => {updateCgst(e.target.value)}} value={tempCustomOrderObj?.Cgst} placeholder="CGST" aria-label="cgst" aria-describedby="basic-addon1" required style={{minHeight: '25px', height: '25px', width: '60px', borderLeft: 'none'}} />
                                                    </div>
                                                </div> */}
                                            </span>
                                        </li>
                                        <li style={{fontSize: '18px'}}>
                                            SGST
                                            <span class="hightext" style={{fontSize: '18px'}}>
                                                ₹{(tempCustomOrderObj?.Sgst) ? tempCustomOrderObj?.Sgst : 0}
                                                {/* <div class="form-group boxed ms-0 pb-0 pt-0">
                                                    <div class="input-group">
                                                        <span class="input-group-text ps-1 pe-0 bg-white" id="basic-addon1" style={{height: '25px', border: '1px solid lightgray', borderRadius: '6px 0px 0px 6px', fontSize: '14px'}}>₹</span>
                                                        <input type="number" class="form-control form-control-sm p-0 ps-1 text-center" onChange={(e) => {updateSgst(e.target.value)}} value={tempCustomOrderObj?.Sgst} placeholder="SGST" aria-label="Sgst" aria-describedby="basic-addon1" required style={{minHeight: '25px', height: '25px', width: '60px', borderLeft: 'none'}} />
                                                    </div>
                                                </div> */}
                                            </span>
                                        </li>
                                        <li style={{fontSize: '18px'}}>Discount<span class="hightext" style={{fontSize: '18px'}}>₹{(tempCustomOrderObj?.TotalDiscount) ? tempCustomOrderObj?.TotalDiscount : 0}</span></li>
                                        <li style={{fontSize: '18px'}}>Total<span class="totaltext text-primary" style={{fontSize: '18px'}}>₹{tempCustomOrderObj?.Total}</span></li>
                                        <li style={{fontSize: '18px'}}>Balance<span class="hightext" style={{fontSize: '18px'}}>₹{tempCustomOrderObj?.BalanceAmount}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="fab-button text bottom-center responsive-bottom-fab">
                            <button class="fab btn-success fs-6 pe-2 ps-2" style={{height: '40px', fontFamily: "'Inter', sans-serif"}} disabled={disableSubmitButton}>
                                Place Order
                            </button>
                        </div>
                        {imagePath &&
                            <TransformWrapper
                                initialScale={1}
                                initialPositionX={200}
                                initialPositionY={100}
                            >
                                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                <>
                                    <TransformComponent>
                                    <img src={imagePath} alt="test" />
                                    <div>Example text</div>
                                    </TransformComponent>
                                </>
                                )}
                            </TransformWrapper>
                            // <div class="d-flex align-items-center justify-content-center flex-column" style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#333333c2', zIndex: 1000}}>
                            //     <div class="w-100 text-end pe-3 cursor-pointer" onClick={() => {handleFullImage()}}>
                            //         <ion-icon name="close" class="text-white fs-1"></ion-icon>
                            //     </div>
                            //     <img src={imagePath} width="400px" />
                            // </div>
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}

const mapStateToProps = ({ data, auth, addUser }) => {
    const {
      businessData,
      items
    } = data
    const {
      authToken
    } = auth
    const {
        userData
    } = addUser
    return {
      authToken,
      businessData,
      items,
      userData
    }
  }
  
  const mapDispatchToProps = {
    showAppToast
  }

export default connect(mapStateToProps, mapDispatchToProps)(OpticCustomOrder)
