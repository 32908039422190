import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    inwardOutwardModalVisible: false,
    inwardOutwardDetailData: null
}

export const InwardOutwardModalSlice = createSlice({
    name: 'inwardOutward',
    initialState,
    reducers: {
      showInwardOutwardModal: (state, action) => {
        const {
            inwardOutwardModalVisible,
            inwardOutwardDetailData
        } = action.payload
        state.inwardOutwardModalVisible = inwardOutwardModalVisible
        state.inwardOutwardDetailData = inwardOutwardDetailData
      },
      hideInwardOutwardModal: (state) => {
        state.inwardOutwardModalVisible = false
      }
    }
})

export const { showInwardOutwardModal, hideInwardOutwardModal } = InwardOutwardModalSlice.actions;


export default InwardOutwardModalSlice.reducer