import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import axios from '../../AxiosConfig';
import { showShopDetailModal } from '../../features/Offcanvas/ShopDetailModalSlice';
import { setOrderDayFilter } from '../../features/Data/dataSlice';
import DataTable from "react-data-table-component";

const AdminDashboard = ({
    showShopDetailModal,
    dayOrderFilter,
    setOrderDayFilter
}) => {
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [adminDashboardBranchData, setAdminDashboardBranchData] = useState(null)
    const [datePicker, setDatePicker] = useState(null)
    const [loader, setLoader] = useState(true)
    const [tableColumns, setTableColumns] = useState([])
    const [tableData, setTableData] = useState([])
    const [allBranchTotal, setAllBranchTotal] = useState(null)
    const [bakeryWiseSale, setBakeryWiseSale] = useState(null)

    const handleDateFilter = (date) => {
        if (date == 'Custom') {
            setShowDatePicker(true)
        } else {
            setOrderDayFilter({dayOrderFilter: date})
            setDatePicker(null)
            setShowDatePicker(false)
        }
    }
    const handleShopDetailModal = (v) => {
        showShopDetailModal({shopDetailModalVisible: true, shopDetailData: v})
    }
    const GetOrderdate = () => {
        const currentDate = new Date()
        const hour = currentDate.getHours();
        if (hour < 3) {
            const previousDay = new Date(currentDate.setDate(currentDate.getDate()));
            return previousDay.toISOString().split('T')[0];
        } else {
            return currentDate.toISOString().split('T')[0];
        }
    }
    let dateFilterFrom = GetOrderdate()
    let dateFilterTo = GetOrderdate()
    useEffect(() => {
        if (dayOrderFilter === 'Today') {
            dateFilterFrom = GetOrderdate()
            dateFilterTo = GetOrderdate()
        } else if (dayOrderFilter === 'Yesterday') {
            dateFilterFrom = (new Date(new Date().setDate(new Date().getDate()-1)).toISOString().slice(0, 10))
            dateFilterTo = (new Date(new Date().setDate(new Date().getDate()-1)).toISOString().slice(0, 10))
        } else if (dayOrderFilter === 'Last7') {
            dateFilterFrom = (new Date(new Date().setDate(new Date().getDate()-7)).toISOString().slice(0, 10))
            dateFilterTo = GetOrderdate()
        } else if (dayOrderFilter === 'Last30') {
            dateFilterFrom = (new Date(new Date().setDate(new Date().getDate()-30)).toISOString().slice(0, 10))
            dateFilterTo = GetOrderdate()
        } else if (dayOrderFilter === 'All') {
            dateFilterFrom = 'All'
            dateFilterTo = 'All'
        } else if (dayOrderFilter.includes('-')) {
            dateFilterFrom = dayOrderFilter
            dateFilterTo = dayOrderFilter
        }
    }, [dayOrderFilter])
    const handleGetData = async (branches) => {
        let adminDashboardData = await Promise.all(branches.map(async (v, k) => {
            let branch = {};
            let response = await axios.get('/Reports/dashboardSummary/' + dateFilterFrom + '/' + dateFilterTo + '/' + v.ID);
            branch.branchID = v.ID;
            branch.branchName = v.Name;
            branch.salesData = response.data.data;
            branch.shopStatus = v.ShopStatus;
            return branch;
        }));
        return adminDashboardData;
    };
    const handleGetDailyCounters = async (branches) => {
        let adminDashboardDailyCounters = await Promise.all(branches.map(async (v, k) => {
            let tempDailyCounters = {};
            tempDailyCounters.CustomOrderAdvance = 0
            tempDailyCounters.CustomOrderBalance = 0
            tempDailyCounters.CustomerDues = 0
            let response = await axios.get('/Reports/viewDailyCounters/' + dateFilterFrom + '/' + dateFilterTo + '/' + v.ID);
            let response2 = await axios.get('/Reports/dashboardSummary/' + dateFilterFrom + '/' + dateFilterTo + '/' + v.ID);
            tempDailyCounters.branchID = v.ID;
            tempDailyCounters.branchName = v.Name;
            tempDailyCounters.CustomOrderAdvance += (response2?.data?.data?.Orders?.OrderType?.CustomOrderAdvance) ? response2?.data?.data?.Orders?.OrderType?.CustomOrderAdvance : 0
            tempDailyCounters.CustomOrderBalance += (response2?.data?.data?.Orders?.OrderType?.CustomOrderBalance) ? response2?.data?.data?.Orders?.OrderType?.CustomOrderBalance : 0
            tempDailyCounters.CustomerDues += (response2?.data?.data?.Orders?.OrderType?.TotalCustomOrderBalance) ? response2?.data?.data?.Orders?.OrderType?.TotalCustomOrderBalance : 0
            response.data.data.map((v1,k1) => {
                if (v1.Title == 'Pastry') {
                    tempDailyCounters.TotalPastryCount = parseInt((v1.Count) ? v1.Count : 0)
                    tempDailyCounters.TotalPastrySale = parseInt((v1.Sum) ? v1.Sum : 0)
                } else if (v1.Title == 'Half Kg') {
                    tempDailyCounters.TotalHalfCount = parseInt((v1.Count) ? v1.Count : 0)
                    tempDailyCounters.TotalHalfSale = parseInt((v1.Sum) ? v1.Sum : 0)
                } else if (v1.Title == 'One Kg') {
                    tempDailyCounters.TotalFullCount = parseInt((v1.Count) ? v1.Count : 0)
                    tempDailyCounters.TotalFullSale = parseInt((v1.Sum) ? v1.Sum : 0)
                };
            })
            return tempDailyCounters;
        }));
        return adminDashboardDailyCounters;
    };
    
    useEffect(() => {
        const fetchData = async () => {
            let res = await axios.get('/Businesses/' + dateFilterFrom);
            if (res.data.message === 'Success') {
                let tempAdminDashboardData = await handleGetData(res.data.data[0].Branches);
                let viewDailyCounters = await handleGetDailyCounters(res.data.data[0].Branches)
                let tableData = []
                setLoader(false)
                let allBranchTotalPastry = 0
                let allBranchTotalPastryCount = 0
                let allBranchTotalHalf = 0
                let allBranchTotalHalfCount = 0
                let allBranchTotalFull = 0
                let allBranchTotalFullCount = 0
                let allBranchCustomOrderAdvance = 0
                let allBranchCustomOrderBalance = 0
                let allBranchCustomerDues = 0
                viewDailyCounters.map((v,k) => {
                    if ('TotalPastrySale' in v) {
                        allBranchTotalPastry += v.TotalPastrySale
                        allBranchTotalPastryCount += v.TotalPastryCount
                    }
                    if ('TotalHalfSale' in v) {
                        allBranchTotalHalf += v.TotalHalfSale
                        allBranchTotalHalfCount += v.TotalHalfCount
                    } 
                    if ('TotalFullSale' in v) {
                        allBranchTotalFull += v.TotalFullSale
                        allBranchTotalFullCount += v.TotalFullCount
                    }
                    if ('CustomOrderAdvance' in v) {
                        allBranchCustomOrderAdvance += v.CustomOrderAdvance
                    }
                    if ('CustomOrderBalance' in v) {
                        allBranchCustomOrderBalance += v.CustomOrderBalance
                    }
                    if ('CustomerDues') {
                        allBranchCustomerDues += v.CustomerDues
                    }
                })
                setAllBranchTotal({
                    'Pastry': allBranchTotalPastry,
                    'PastryCount': allBranchTotalPastryCount,
                    'Half':allBranchTotalHalf,
                    'HalfCount':allBranchTotalHalfCount,
                    'Full': allBranchTotalFull,
                    'FullCount': allBranchTotalFullCount,
                    'CustomOrderAdvance': allBranchCustomOrderAdvance,
                    'CustomOrderBalance': allBranchCustomOrderBalance,
                    'CustomerDues': allBranchCustomerDues
                })
                setAdminDashboardBranchData(tempAdminDashboardData);
                let totalSalesTotal = 0
                let MTDTotal = 0
                let YTDTotal = 0
                tempAdminDashboardData.map((v,k) => {
                    totalSalesTotal += v.salesData.Orders.TotalSale 
                    MTDTotal += v.MTD
                    YTDTotal += v.YTD
                    tableData.push({
                        id: k + 1,
                        branchName: v.branchName ,
                        TotalSale: v.salesData.Orders.TotalSale,
                        MTD: (v.MTD) ? v.MTD : '--',
                        AvgCurrentMonth: '--',
                        AvgLastMonth: '--',
                        fullValue: v,
                        shopStatus: v.shopStatus
                    },)
                })
                tableData.push({
                    id: tempAdminDashboardData.length + 1,
                    branchName: 'Total' ,
                    TotalSale: totalSalesTotal,
                    MTD: (MTDTotal) ? MTDTotal : '--',
                    AvgCurrentMonth: '--',
                    AvgLastMonth: '--',
                    shopStatus: 'open'
                },)
                const columns = [
                    {
                        name: 'Branch',
                        selector: row => row.branchName,
                        cell: row => <span style={{ fontWeight: 'bold', color: (row.shopStatus === 'open') ? '#34C759' : '#EC4433' }}>{row.branchName}</span>,
                    },
                    {
                        name: 'Total Sales (₹)',
                        selector: row => row.TotalSale,
                        sortable: true,
                        right: true,
                    },
                    {
                        name: 'Avg Current Month (₹)',
                        selector: row => row.AvgCurrentMonth,
                        sortable: true,
                        right: true,
                    },
                    {
                        name: 'Avg Last Month (₹)',
                        selector: row => row.AvgLastMonth,
                        sortable: true,
                        right: true,
                    },
                    {
                        name: 'MTD (₹)',
                        selector: row => row.MTD,
                        sortable: true,
                        right: true,
                    },
                ];
                setTableColumns(columns)
                setTableData(tableData)
            }
        };
        fetchData();

        axios.get('/reports/getSaleBakeryWise/'+ dateFilterFrom, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(res => {
            setBakeryWiseSale(res.data.data)
        }).catch(err => {
        })
    }, [dayOrderFilter]);
    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold', // override the row height
            },
        },
        rows: {
            style: {
                '&:nth-child(odd)': {
                    backgroundColor: '#e1e1e1',
                },
                '&:nth-child(even)': {
                    backgroundColor: '#ffffff',
                },
                minHeight: '43px'
            },
        },
    }
    useEffect(() => {
        if (datePicker !== null && datePicker !== '' && datePicker !== undefined) {
            setOrderDayFilter({dayOrderFilter: datePicker})
        }
    }, [datePicker])
    return (
        <div id='appCapsule'>
            {/* <div class="row mt-2">
                <div class="col-12 col-md-12 d-flex align-items-center justify-content-end">
                    {(showDatePicker == false) &&
                        <div class="dropdown">
                            <button class="badge bg-primary dropdown-toggle fs-8" data-bs-toggle="dropdown">
                                <span style={{textTransform: 'uppercase'}}>
                                    <ion-icon name="calendar-outline" class="m-0 fs-8" style={{width: '18px'}}></ion-icon>
                                    {dayOrderFilter}
                                </span>
                                <ion-icon name="chevron-down-outline" class="fs-6"></ion-icon>
                            </button>
                            <div class="dropdown-menu p-0">
                                <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleDateFilter('Today')}}>Today</a>
                                <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleDateFilter('Yesterday')}}>Yesterday</a>
                                <div class="dropdown-divider m-0"></div>
                                <a class="dropdown-item p-1 d-flex justify-content-between" href="javascript:void(0)" onClick={() => {handleDateFilter('Custom')}}>Custom</a>
                            </div>
                        </div>
                    }
                    {showDatePicker &&
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <input type="date" class="form-control form-control-sm" placeholder="Enter your name" autocomplete="off" value={datePicker}
                                        onChange={e => {
                                            setDatePicker(e.target.value)
                                        }} style={{height: '25px'}} />
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                                <label class="form-label cursor-pointer d-flex align-items-center mb-0" onClick={() => {handleDateFilter('Today')}} style={{position: 'absolute', bottom: '-15px', left: 0}}><ion-icon name="chevron-back"></ion-icon> Back</label>
                            </div>
                        </div>
                    }
                </div>
            </div> */}
            <div class="section full">
                <div class="section full mt-3 mb-3">
                    <h3>Today's Summary</h3>
                    <div class="table-responsive border bg-white">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" class="fw-bold border-end">Item</th>
                                    <th scope="col" class="fw-bold border-end text-end">Sale</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="col" class="fw-bold border-end">Pastry</th>
                                    <td class="border-end text-end">
                                        ₹{allBranchTotal?.Pastry}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="col" class="fw-bold border-end">Half Kg</th>
                                    <td class="border-end text-end">
                                        ₹{allBranchTotal?.Half}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="col" class="fw-bold border-end">One Kg </th>
                                    <td class="border-end text-end">
                                        ₹{allBranchTotal?.Full}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="col" class="fw-bold border-end">Custom Order Advance</th>
                                    <td class="border-end text-end">₹{allBranchTotal?.CustomOrderAdvance}</td>
                                </tr>
                                <tr>
                                    <th scope="col" class="fw-bold border-end">Custom Order Balance / Customers Dues</th>
                                    <td class="border-end text-end">₹{allBranchTotal?.CustomOrderBalance} / ₹{allBranchTotal?.CustomerDues}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="section full mt-1 mb-3">
                    <h3>Branch Wise</h3>
                    <div class={(loader == true) ? "border p-0 position-relative" : "border p-0"} style={{height: (loader == true) ? '300px' : 'auto'}}>
                        {(loader == false) &&
                            <DataTable
                                columns={tableColumns}
                                data={tableData}
                                defaultSortFieldId={1}
                                fixedHeader={true}
                                fixedHeaderScrollHeight={'80vh'}
                                highlightOnHover={true}
                                pointerOnHover={true}
                                customStyles={customStyles}
                                onRowClicked={(row, event) => { handleShopDetailModal(row.fullValue)}}
                            />
                        }
                        {loader &&
                            <div class="d-flex justify-content-center align-items-center flex-column" style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
                                <div class="spinner-border text-primary mb-2" role="status"></div>
                                <h3 class="text-secondary">Loading...</h3>
                            </div>
                        }
                    </div>
                </div>
                <div class="section full mt-1 mb-2">
                    {bakeryWiseSale?.map((v, k)=>{
                        return <>
                        <h3>CURRENT STATISTICS - {v.Name}</h3>
                        <div class="table-responsive border bg-white">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" rowSpan={2} class="fw-bold border-end text-center align-middle fs-6">Branch</th>
                                        <th scope="col" colSpan={3} class="fw-bold border-end text-center border-bottom fs-6">Pastries</th>
                                        <th scope="col" colSpan={3} class="fw-bold border-end text-center border-bottom fs-6">Half Kg</th>
                                        <th scope="col" colSpan={3} class="fw-bold border-end text-center border-bottom fs-6">One Kg</th>
                                        <th scope="col" colSpan={3} class="fw-bold border-end text-center border-bottom fs-6">Other</th>
                                    </tr>
                                    <tr>
                                        <th scope="col" class="fw-bold border-end text-end fs-6">Stock</th>
                                        <th scope="col" class="fw-bold border-end text-end fs-6">Sale(Qty)</th>
                                        <th scope="col" class="fw-bold border-end text-end fs-6">Sale(₹)</th>
                                        <th scope="col" class="fw-bold border-end text-end fs-6">Stock</th>
                                        <th scope="col" class="fw-bold border-end text-end fs-6">Sale(Qty)</th>
                                        <th scope="col" class="fw-bold border-end text-end fs-6">Sale(₹)</th>
                                        <th scope="col" class="fw-bold border-end text-end fs-6">Stock</th>
                                        <th scope="col" class="fw-bold border-end text-end fs-6">Sale(Qty)</th>
                                        <th scope="col" class="fw-bold border-end text-end fs-6">Sale(₹)</th>
                                        <th scope="col" class="fw-bold border-end text-end fs-6">Stock</th>
                                        <th scope="col" class="fw-bold border-end text-end fs-6">Sale(Qty)</th>
                                        <th scope="col" class="fw-bold border-end text-end fs-6">Sale(₹)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {v.Shops?.map((v1, k1)=>{
                                        return <tr>
                                            <td class="border-end fw-bold fs-6">{v1.Name}</td>
                                            <td class="border-end text-end fw-bold fs-6">{v1.Pastry.Stock}</td>
                                            <td class="border-end text-end fw-bold fs-6">{v1.Pastry.SaleQty}</td>
                                            <td class="border-end text-end fw-bold fs-6">₹{v1.Pastry.Sale}</td>
                                            <td class="border-end text-end fw-bold fs-6">{v1['Half Kg'].Stock}</td>
                                            <td class="border-end text-end fw-bold fs-6">{v1['Half Kg'].SaleQty}</td>
                                            <td class="border-end text-end fw-bold fs-6">₹{v1['Half Kg'].Sale}</td>
                                            <td class="border-end text-end fw-bold fs-6">{v1['One Kg'].Stock}</td>
                                            <td class="border-end text-end fw-bold fs-6">{v1['One Kg'].SaleQty}</td>
                                            <td class="border-end text-end fw-bold fs-6">₹{v1['One Kg'].Sale}</td>
                                            <td class="border-end text-end fw-bold fs-6">{v1['Other'].Stock}</td>
                                            <td class="border-end text-end fw-bold fs-6">{v1['Other'].SaleQty}</td>
                                            <td class="border-end text-end fw-bold fs-6">₹{v1['Other'].Sale}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        </>
                    })}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ data }) => {
    const {
        dayOrderFilter
    } = data
    return {
        dayOrderFilter
    }
  }
  
  const mapDispatchToProps = {
    showShopDetailModal,
    setOrderDayFilter
  }

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);