import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { showOrderDetailModal } from '../../../features/Offcanvas/orderDetailModalSlice'
import Moment from 'react-moment'
import axios from '../../../AxiosConfig'
import { showConfirmationModal } from '../../../features/Offcanvas/confirmationModalSlice'
import { handleRenderOrders } from '../../../features/Data/dataSlice'
import { showAddTransactionModal } from '../../../features/Offcanvas/addTransactionModalSlice'

const OrderGridView = ({
    showOrderDetailModal,
    allOrder,
    userValue,
    showConfirmationModal,
    handleRenderOrders,
    renderOrders,
    showAddTransactionModal
}) => {
    const [allOrderList, setAllOrderList] = useState(null)
    const [loader, setLoader] = useState(true)
    const [orderTypeFilterName, setOrderTypeFilterName] = useState('Orders')
    const search = useLocation().search
    const orderTypeFilter = new URLSearchParams(search).get('tab')

    useEffect(() => {
        if (allOrder !== undefined && allOrder !== null) {
            setAllOrderList(allOrder)
            if (renderOrders === true) {
                handleRenderOrders(false)
            }
        }
    }, [allOrder, renderOrders])
    const handleOrderDetailView = (orderDetail) => {
        showOrderDetailModal({orderDetailModalVisible: true, orderDetaildata: orderDetail})
    }
    useEffect(() => {
        setTimeout(() => {
            setLoader(false)
        }, 1500)
    }, [])

    useEffect(() => {
        if (allOrderList !== null && allOrderList !== undefined) {
            if (orderTypeFilter === 'sales') {
                setOrderTypeFilterName('Orders')
            } else if (orderTypeFilter === 'purchase') {
                setOrderTypeFilterName('Purchase')
            } else if (orderTypeFilter === 'wastage') {
                setOrderTypeFilterName('Wastage')
            }
        }
    }, [orderTypeFilter, orderTypeFilterName])

    const handleDeleteOrder = (e, orderID, message) => {
        e.stopPropagation()
        showConfirmationModal({confirmationModalVisible: true, ID: orderID, message: message, deleteType: 'Orders'})
    }
    
    const handleBalanceAmount = (value) => {
        showAddTransactionModal({addTransactionModalVisible: true, transactionData: value})
    }
    return (
        <div class="pt-1">
            <div class="row">
            {loader &&
                <div class="col-12 col-md-12 d-flex align-items-center justify-content-center" style={{height: '210px'}}>
                    <button class="btn btn-light btn-lg" type="button" disabled>
                        <span class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>
            }
            {!loader &&
                <>
                    {/* {(allOrderList !== null && allOrderList !== undefined) ? Object.keys(allOrderList).map((value, key) =>  {
                        return <> */}
                            {(orderTypeFilterName !== null && orderTypeFilterName !== undefined && orderTypeFilterName !== '') ? allOrderList[orderTypeFilterName].map((k,v) => {
                                let OrderType = orderTypeFilterName
                                let BorderColor = ''
                                if (OrderType === 'Orders') {
                                    BorderColor = 'border-primary'
                                } else if (OrderType === 'Purchase') {
                                    BorderColor = 'border-warning'
                                } else if (OrderType === 'Wastage') {
                                    BorderColor = 'border-danger'
                                }
                                {
                                    return <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2 position-relative">
                                        <div class="position-absolute d-flex" style={{top: '15px', zIndex: 1, right: '20px'}}>
                                            {k.BalanceAmount > 0 &&
                                                <span onClick={() => {handleBalanceAmount(k)}} style={{cursor: 'pointer'}}>
                                                    <ion-icon name="cash-outline" class="fs-4 text-danger"></ion-icon>&nbsp;&nbsp;
                                                </span>
                                            }
                                            <div class="dropdown d-flex justify-content-end" style={{cursor: 'pointer'}}>
                                                <div class="dropdown-toggle" data-bs-toggle="dropdown">
                                                    <ion-icon name="ellipsis-vertical-outline" class="fs-4"></ion-icon>
                                                </div>
                                                <div class="dropdown-menu p-0">
                                                    {/* <a class="dropdown-item p-1 dropdown-sm" href="javascript:void(0)" >View Detail</a> */}
                                                    <a class="dropdown-item p-1 dropdown-sm webweb-bg-secondary" href="javascript:void(0)">Share&nbsp;<span class="badge bg-secondary">Coming Soon</span></a>
                                                    <a class="dropdown-item p-1 dropdown-sm webweb-bg-secondary" href="javascript:void(0)">Print&nbsp;<span class="badge bg-secondary">Coming Soon</span></a>
                                                    <a class="dropdown-item p-1 dropdown-sm webweb-bg-secondary" href="javascript:void(0)">Duplicate&nbsp;<span class="badge bg-secondary">Coming Soon</span></a>
                                                    <a class="dropdown-item p-1 dropdown-sm webweb-bg-secondary" href="javascript:void(0)" onClick={(e) => {handleDeleteOrder(e, k.ID, 'Are you sure you want to cancel this Order ?')}}>Cancel Order</a>
                                                </div>
                                            </div>
                                        </div>
                                        <a href="javascript:void(0)" onClick={() => {handleOrderDetailView(allOrderList[orderTypeFilterName][v])}}>
                                            <div class={(k.ID) ? `card ${BorderColor}` : `card ${BorderColor} webweb-bg-secondary`}>
                                                <div class="card-body p-1">
                                                    <div class="row">
                                                        <div class="col-6 col-md-6 mb-2">
                                                            <p class="card-text mb-0" style={{fontSize: '10px'}}>{(k.ID) ? '#'+k.ID : 'NOT SYNC'}</p>
                                                            <div class="d-flex align-items-start flex-column">
                                                                <div class="d-flex">
                                                                    <h3 class="text-nowrap mb-0">₹{k.Total - k.BalanceAmount}</h3>
                                                                    {k.BalanceAmount > 0 &&
                                                                        <>
                                                                            &nbsp;<p class="card-text mb-0 text-nowrap" style={{fontSize: '10px'}}>{(k.BalanceAmount === 0) ? '' : k.BalanceAmount}</p>
                                                                        </>
                                                                    }
                                                                </div>
                                                                <p class="card-text d-flex align-items-center" style={{fontSize: '10px'}}><ion-icon name="cube-outline"></ion-icon>&nbsp;{k.TotalQty} Qty</p>
                                                            </div>  
                                                        </div>
                                                        <div class="col-6 col-md-6 d-flex justify-content-end">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-6 col-md-6">
                                                            <h5 class="mb-0 fw-bold">{k.Type}</h5>
                                                        </div>
                                                        <div class="col-6 col-md-6 text-end">
                                                            <h5 class="mb-0 fw-bold">{k.PaymentMethod}</h5>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-6 col-md-6 d-flex align-items-start">
                                                            <p class="card-text" style={{fontSize: '10px'}}><ion-icon name="time-outline"></ion-icon>&nbsp;<Moment fromNow>{k.DateTime}</Moment></p>
                                                        </div>
                                                        {k.Customer_ID &&
                                                            <div class="col-6 col-md-6 text-end">
                                                                <span class="fs-10 text-nowrap d-flex justify-content-end">
                                                                    <div class="bg-transparent rounded-circle">
                                                                        <ion-icon name="person-circle-outline" class="fs-10"></ion-icon>
                                                                    </div>&nbsp;
                                                                    {userValue.map(value => {
                                                                        return <>
                                                                            {k.Customer_ID === value.ID &&
                                                                                <>
                                                                                    <a href={'tel:' + value.ContactNumber}>{value.ContactNumber}</a>
                                                                                </>
                                                                            }
                                                                        </>
                                                                    })}
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                }
                            })
                            :
                            null}
                        {/* </>
                    })
                        :
                        null
                    } */}
                </>
            }
            </div>
        </div>
    )
}

const mapStateToProps = ({ data }) => {
    const {
        renderOrders
    } = data
    return {
        renderOrders
    }
}
  
const mapDispatchToProps = {
    showOrderDetailModal,
    showConfirmationModal,
    handleRenderOrders,
    showAddTransactionModal
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderGridView)
